import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  card: {
    padding: 14,
    borderRadius: 5,
    backgroundColor: theme.white1,
    boxShadow: theme.indigoShadow1
  },
  cardHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.grey7}`,
    paddingBottom: 14,
    '& > a, div[data-is-disabled=true]': {
      borderRadius: 5
    }
  },
  cardTitleIcon: {
    marginRight: 7
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 600
  },
  pendingPill: {
    margin: '0px 10px',
    color: theme.white1,
    backgroundColor: theme.teal1,
    borderRadius: 10,
    padding: '1px 10px',
    fontWeight: 600,
    fontSize: 9,
    lineHeight: '11px',
    textAlign: 'center',
    letterSpacing: '0.15em',
    textTransform: 'uppercase'
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.grey1,
    margin: '14px 0',
    '&[data-is-empty=true]': {
      color: theme.grey3
    }
  },
  navLink: {
    display: 'block',
    color: theme.grey3,
    transition: 'color 150ms linear',
    '&[data-is-disabled="false"]:hover': {
      color: theme.grey1
    },
    '&[data-is-disabled="true"]': {
      color: theme.grey4,
      pointerEvents: 'none'
    }
  },
  pushtextHrefWrapper: {
    '& > div': {
      color: theme.grey3,
      transition: 'color 150ms linear'
    },
    '&[data-is-disabled="false"] > div:hover': {
      color: theme.grey1
    }
  }
}));
