import { ReactNode } from 'react';

export enum ConditionEnums {
  Equal = 'equal',
  GreaterThan = 'greater than',
  LessThan = 'less than',
  IsTruthy = 'is truthy',
  Contains = 'contains',
  DoesNotContain = 'does not contain'
}

export type Condition = {
  type?: ConditionEnums;
  path: string;
  value?: string | number;
};

export type TransitionGroup = {
  label: string;
  condition?: Condition;
};

export type Transition = {
  [toActionKey: string]: TransitionGroup[] | string | any;
  collapsedGroups?: boolean;
};

export interface WorkflowGroup {
  rowId?: string;
  label: string;
  name: string;
  avatar?: string;
  icon?: ReactNode;
}

export type TParams = { id: string };

export type FromActionDefinition = {
  actionKey: string;
  actionTitle: string;
  nextActions: Transition[];
  transitionCollapsed?: boolean;
};

export interface Action {
  key: string;
  title: string;
}
