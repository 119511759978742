type HasNoDuplicatesFnReturn =
  | {
      message: () => string;
      pass: boolean;
    }
  | boolean;

export function hasNoDuplicates(list: string[]): HasNoDuplicatesFnReturn {
  if (list.length === 0) return true;

  const valuesSet = new Set<string>();
  const errors: string[] = [];

  list.forEach((value, idx) => {
    if (valuesSet.has(value)) {
      errors.push(`Duplicated value "${value}" in option ${idx + 1}`);
    } else {
      valuesSet.add(value);
    }
  });

  if (errors.length > 0) {
    return {
      message: () => errors.join(','),
      pass: false
    };
  }

  return true;
}

export const parseEnumNames = (
  enumNames: { key: string; value: string }[]
): string[] => enumNames.map(({ value }) => value) || [];
