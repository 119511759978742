import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  sectionRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    background: theme.grey9,
    border: `1px solid ${theme.greyTable}`,
    borderRadius: 5,
    overflow: 'hidden',
    '&[data-is-dragging=true]': {
      boxShadow: theme.indigoShadowHover
    }
  },
  titleWrapper: {
    padding: '15px 15px 0 15px',
    display: 'flex',
    alignItems: 'center',
    '&[data-has-infotooltip=false]': {
      justifyContent: 'space-between'
    }
  },
  title: {
    display: 'flex',
    whiteSpace: 'nowrap',
    marginBottom: 3,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '25px',
    letterSpacing: '0.03em',
    color: theme.grey1,
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  titleIconWrapper: {
    marginLeft: 6,
    alignSelf: 'flex-end',
    flexGrow: 1,
    width: 20
  },
  removeSectionButtonWrapper: {
    display: 'flex'
  },
  removeSectionButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15
  },
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    padding: '0 0 15px 20px',
    margin: '15px 0'
  },
  placeholder: {
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    color: theme.grey1
  },
  footer: {
    padding: '0 0 15px 15px'
  }
}));
