import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  cardActionsRoot: ({ theme: subTheme }) => ({
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '11px 8px',
    minWidth: '36px',
    background: subTheme === 'embed' ? theme.grey7 : theme.indigo2,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    '&[aria-expanded="true"]': {
      justifyContent: ({ buttonsCount }) =>
        buttonsCount > 1 ? 'space-between' : 'center'
    }
  })
}));
