import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  inputWithIconRoot: {
    flexGrow: 1,
    padding: '4px 0px ',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover $input, $input:focus': {
      '&:enabled': {
        borderRadius: 2,
        backgroundColor: theme.grey8
      }
    }
  },
  input: props => ({
    backgroundColor: 'transparent',
    flexGrow: 1,
    marginLeft: 5,
    padding: 4,
    outline: 'none',
    border: props.invalid ? `1px solid ${theme.warningRed}` : 'none',
    borderRadius: 2,
    boxSizing: 'border-box',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '18px',
    color: props.disabled ? theme.grey5 : theme.grey1,
    borderBottom: `1px solid transparent`,
    '&::placeholder': {
      color: theme.grey5
    }
  })
}));
