import React, { FC, useContext } from 'react';

import { TextField } from '../../../../fields';
import { HelperInputDefinition } from '../../../../types';
import { FormBuilderContext } from '../../../../context';
import useStyles from './body.style';

interface Props {
  path: string;
  input: HelperInputDefinition;
}

// form helper input configuration body component
export const Body: FC<Props> = ({ path, input }) => {
  const classes = useStyles();
  const { showErrors } = useContext(FormBuilderContext);

  const { content } = input;

  return (
    <TextField
      className={classes.field}
      label="Helper Content"
      value={content}
      path={`${path}.content`}
      invalid={showErrors && !content}
    />
  );
};

export default React.memo(Body);
