import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTE_WORKFLOW_DETAILS } from 'constant/routes';
import { useToggle } from 'utils/hooks';

import { IconButtonTooltip } from 'components/workflow/ui';

import DeleteModal from './deleteModal';

import useStyles from './workflowRow.style';

interface Props {
  isSuperuser?: boolean;
  workflow: { rowId: string; name: string; draft: boolean };
}

export const WorkflowRow: FC<Props> = ({ isSuperuser, workflow }) => {
  const classes = useStyles();

  const { rowId, name, draft } = workflow;
  const [showDeleteModal, toggleShowDeleteModal] = useToggle(false);
  const editWorkflowUrl = ROUTE_WORKFLOW_DETAILS.replace(':id', rowId);

  return (
    <>
      <div className={classes.row}>
        <div className={classes.workflowInfo}>
          <div className={classes.workflowId}>#{rowId}</div>
          <NavLink
            data-cy="edit-workflow-link"
            to={editWorkflowUrl}
            className={classes.workflowName}
          >
            {name}
          </NavLink>
          {draft && <div className={classes.workflowDraftStatus}>draft</div>}
        </div>
        <div className={classes.buttons}>
          <IconButtonTooltip
            dataCy="edit-workflow-btn"
            iconName="Pen"
            iconSize={25}
            tooltipMessage="Edit workflow"
            url={ROUTE_WORKFLOW_DETAILS.replace(':id', rowId)}
          />
          {isSuperuser && (
            <IconButtonTooltip
              dataCy="delete-workflow"
              iconName="Trash"
              iconSize={25}
              tooltipMessage="Delete workflow"
              onClick={toggleShowDeleteModal}
            />
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteModal workflow={workflow} close={toggleShowDeleteModal} />
      )}
    </>
  );
};

export default memo(WorkflowRow);
