import { HelperActionField } from 'schemaBuilder/types';
import { test as validate, enforce } from 'vest';

export default (field: HelperActionField, fieldPath: string) => {
  validate(
    `${fieldPath}.schemaField.helper`,
    `Please fill the required fields`,
    () => {
      enforce(field.schemaField.helper).isNotEmpty();
    }
  );
};
