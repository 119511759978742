import {
  FC,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useContext,
  Children
} from 'react';

import { useToggle, DataContext } from 'utils/hooks';

import { Icon, InfoTooltip, Pushtext } from '@stratumn/atomic';
import theme from 'style';

import { IconButton } from 'components/workflow/ui';
import ConfirmationModal from './confirmationModal';
import TitleFieldText from './titleFieldText';

import { Section as SectionDefinition } from '../../display/traceInfo/types';

import useStyles from './section.style';

const TOOLTIP_POSITION = {
  adjustPlace: true,
  place: 'bottom',
  placeShift: 0
};

interface Props extends SectionDefinition {
  children?: ReactNode;
  infoTooltip?: string;
  path: string;
  addElementFooter?: {
    label: string;
    callback?: () => void;
  };
  overviewTab?: boolean;
  sectionCollapse?: boolean;
  dragHandle?: ReactNode;
  isDragging?: boolean;
  elementRef?: any;
}

export const Section: FC<Props> = ({
  title,
  description = '',
  placeholder = '',
  children = null,
  infoTooltip = '',
  addElementFooter = null,
  path,
  overviewTab = false,
  sectionCollapse,
  dragHandle,
  isDragging,
  elementRef = null
}) => {
  const classes = useStyles();

  const { set } = useContext(DataContext);

  const [showRemoveSectionModal, toggleRemoveSectionModal] = useToggle(true);
  const [showTitleInput, toggleShowTitleInput] = useToggle(false);

  const handleRemoveSection = useCallback(
    (): void => toggleRemoveSectionModal(),
    [toggleRemoveSectionModal]
  );

  const handleCollapseSection = useCallback((): void => {
    set(`${path}.sectionCollapse`, !sectionCollapse);
  }, [path, sectionCollapse, set]);

  const capitalizedTitle: string = useMemo(
    () => title.charAt(0).toUpperCase() + title.substr(1, title.length - 1),
    [title]
  );

  const renderTitleIcon: ReactNode = useMemo(() => {
    if (!showTitleInput) {
      return (
        <div className={classes.titleIconWrapper}>
          <InfoTooltip
            dataCy="info-tooltip"
            padding="10px"
            position={TOOLTIP_POSITION}
            text={infoTooltip || description}
            textAlign="center"
            delay={0}
          >
            <Icon name="CircleInfo" size={17} />
          </InfoTooltip>
        </div>
      );
    }
    return (
      <div className={classes.titleIconWrapper}>
        <Icon name="Pen" size={25} />
      </div>
    );
  }, [classes.titleIconWrapper, description, infoTooltip, showTitleInput]);

  return (
    <section className={classes.sectionRoot} data-is-dragging={isDragging}>
      {title && (
        <header>
          <div
            className={classes.titleWrapper}
            data-has-infotooltip={!!infoTooltip}
          >
            {dragHandle}

            {showTitleInput && !overviewTab ? (
              <TitleFieldText
                title={title}
                path={path}
                onDoneEditing={toggleShowTitleInput}
              />
            ) : (
              <h2 className={classes.title} onClick={toggleShowTitleInput}>
                {title}
              </h2>
            )}

            {!overviewTab && renderTitleIcon}

            {!overviewTab && (
              <div className={classes.removeSectionButtonWrapper}>
                {!sectionCollapse && (
                  <div className={classes.removeSectionButton}>
                    <Pushtext
                      dataCy="remove-section-btn"
                      onClick={handleRemoveSection}
                      prefix={<Icon name="Trash" size={15} />}
                    >
                      Remove section
                    </Pushtext>
                  </div>
                )}

                <IconButton
                  dataCy="toggle-section"
                  name={sectionCollapse ? 'CirclePlus' : 'CircleMinus'}
                  onClick={handleCollapseSection}
                  ariaLabel={`${
                    sectionCollapse ? 'Expand' : 'Collapse'
                  } a section`}
                  color={theme.grey3}
                />
              </div>
            )}
          </div>
        </header>
      )}

      {!sectionCollapse && (
        <main
          className={classes.container}
          data-cy="section-main"
          ref={elementRef}
        >
          {Children.toArray(children).length > 0 ? (
            children
          ) : (
            <p className={classes.placeholder}>{placeholder}</p>
          )}
        </main>
      )}

      {!sectionCollapse && addElementFooter && (
        <footer className={classes.footer}>
          <Pushtext
            dataCy="add-element"
            onClick={addElementFooter.callback}
            prefix={<Icon name="CirclePlusFill" size={25} />}
          >
            {addElementFooter.label}
          </Pushtext>
        </footer>
      )}

      {!showRemoveSectionModal && (
        <ConfirmationModal
          title={capitalizedTitle}
          path={path}
          onClose={toggleRemoveSectionModal}
        />
      )}
    </section>
  );
};

export default memo(Section);
