import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  content: {
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1
  },
  contentParagraph: {
    marginBottom: 10
  },
  contentSpan: {
    fontWeight: 700,
    color: theme.indigo2,
    textTransform: 'capitalize'
  }
}));
