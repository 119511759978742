import { ReactNode, useMemo, memo } from 'react';
import type {
  RadioButtonsSchemaField,
  BaseTypeBodyProps
} from 'schemaBuilder/types';

import { DraggableType, OptionsType } from '../../ui/interfaces';
import { OptionsList } from '../../ui';

import useStyles from './radioButtons.style';

interface Props extends BaseTypeBodyProps {
  schemaField: RadioButtonsSchemaField;
}

const RadioButtons = ({ schemaField, readonly = false, path }: Props) => {
  const classes = useStyles();

  const radioIcon: ReactNode = useMemo(
    () => <div className={classes.emptyCircle} />,
    [classes.emptyCircle]
  );

  const options: OptionsType[] = useMemo(
    () =>
      schemaField?.enumNames?.reduce((acc: OptionsType[], curr: any) => {
        acc.push({ key: curr.key, value: curr.value });
        return acc;
      }, []),
    [schemaField.enumNames]
  );

  return (
    <div className={classes.root}>
      <OptionsList
        listType={DraggableType.RadioButtonsOptions}
        options={options}
        path={`${path}.schemaField.enumNames`}
        icon={radioIcon}
        placeholder="Add option"
        disabled={readonly ?? false}
      />
    </div>
  );
};

export default memo(RadioButtons);
