import React, { FC, useMemo, useContext } from 'react';

import { DataContext } from 'utils/hooks';

import { BooleanInputDefinition } from '../../../../types';

import useStyles from './body.style';

interface Props {
  path: string;
  input: BooleanInputDefinition;
}

// form boolean input configuration body component
export const Body: FC<Props> = ({ path, input }) => {
  const classes = useStyles();

  const { set } = useContext(DataContext);

  const radioIcon = useMemo(() => <div className={classes.emptyCircle} />, [
    classes.emptyCircle
  ]);

  const { trueLabel, falseLabel } = input;
  const updateTrueLabel = e =>
    set(`${path}.trueLabel`, e.target.value || undefined);
  const updateFalseLabel = e =>
    set(`${path}.falseLabel`, e.target.value || undefined);

  return (
    <div className={classes.root}>
      <div className={classes.radioLabel}>
        {radioIcon}
        <input
          className={classes.radioLabelInput}
          value={trueLabel}
          placeholder="Yes"
          onChange={updateTrueLabel}
          data-cy="true-label"
        />
      </div>
      <div className={classes.radioLabel}>
        {radioIcon}
        <input
          className={classes.radioLabelInput}
          value={falseLabel}
          placeholder="No"
          onChange={updateFalseLabel}
          data-cy="false-label"
        />
      </div>
    </div>
  );
};

export default React.memo(Body);
