import { FC, memo, useState, useCallback, useMemo, useContext } from 'react';
import generateId from 'utils/id';

import {
  Dropdown,
  OptionDrop,
  Icon,
  Pushbutton,
  Pushtext
} from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import { JsonEditor, Tray } from 'components';

import { parseDisplayToInfo } from '../../traceInfo/parsing';

import { ItemsTypesEnums } from '../../traceInfo/types';

import { tab } from '../../constant';

import useStyles from './headerButtons.style';

const sectionPresetOptions = [
  {
    label: 'attachements',
    disabled: true
  },
  {
    label: 'comments',
    disabled: true
  },
  {
    label: 'data viewer',
    disabled: true
  },
  {
    label: 'generic',
    disabled: false
  }
];

const defaultGenericSection = () => ({
  sectionKey: generateId(),
  title: 'Add a title',
  description:
    'This is a free section. You can custom the view by adding widgets you want to display on Trace',
  placeholder:
    'There is still no widget added. Please click on the “Add widget” button to add one.',
  collapsable: true,
  sectionCollapse: false,
  displayItemCount: false,
  items: [],
  itemsType: ItemsTypesEnums.KeyValue
});

interface Props {
  activeTab: string;
  data: any;
  path?: string;
}

export const HeaderButtons: FC<Props> = ({ activeTab, data, path = '' }) => {
  const classes = useStyles();

  const { append } = useContext(DataContext);

  const [selectedPreset, setSelectedPreset] = useState('');
  const [showCodePreview, setShowCodePreview] = useState(false);

  // TODO: use the trace-ui widgets framework
  const handleUIpreview = () => {};

  const toggleCodePreview = useCallback(
    (): void => setShowCodePreview(!showCodePreview),
    [showCodePreview]
  );

  const JsonEditorData: string = useMemo(() => {
    switch (activeTab) {
      case tab.TRACE_INFO:
        return parseDisplayToInfo(data);

      case tab.OVERVIEW:
        return data;

      default:
        return '';
    }
  }, [activeTab, data]);

  // TODO: to implement
  const handleSelectPreset = e => setSelectedPreset(e.target.value);

  const handleAddSection = useCallback((): void => {
    append(`${path}`, defaultGenericSection());
  }, [append, path]);

  // TODO: remove once presets are implemented
  const addButtonDisabled = sectionPresetOptions.reduce((acc, curr) => {
    if (curr.label !== selectedPreset) return acc;
    if (curr.disabled) return true;
    return acc;
  }, false);

  return (
    <>
      <div
        className={classes.headerButtonsRoot}
        data-is-overview={activeTab === tab.OVERVIEW}
      >
        {activeTab === tab.TRACE_INFO && (
          <div className={classes.addSectionWrapper}>
            <div className={classes.dropdownWrapper}>
              <Dropdown
                data-cy="select-section-preset"
                label="Select a section"
                onValueChange={handleSelectPreset}
                value={selectedPreset}
                hideLabel
              >
                {sectionPresetOptions!.map(({ label, disabled }, index) => (
                  <OptionDrop
                    key={index}
                    label={
                      <span
                        className={classes.dropdownOptionLabel}
                        data-is-disabled={disabled}
                      >
                        {label}
                      </span>
                    }
                    value={label}
                    selected={selectedPreset === label}
                  />
                ))}
              </Dropdown>
            </div>
            <div className={classes.addSectionButton}>
              <Pushbutton
                dataCy="add-section"
                primary
                onClick={handleAddSection}
                disabled={addButtonDisabled || selectedPreset === ''}
              >
                add
              </Pushbutton>
            </div>
          </div>
        )}

        <div className={classes.previewButton}>
          <Pushtext
            dataCy="toggle-ui-preview"
            onClick={handleUIpreview}
            prefix={<Icon name="Search" size={15} />}
            muted
            disabled
          >
            Preview
          </Pushtext>
        </div>

        <Pushtext
          dataCy="toggle-code-preview"
          onClick={toggleCodePreview}
          prefix={<Icon name="Code" size={15} />}
        >
          Show code
        </Pushtext>
      </div>

      {showCodePreview && (
        <Tray title={activeTab} onClose={toggleCodePreview}>
          <JsonEditor
            readOnly
            jsonString={JSON.stringify(JsonEditorData, null, 2)}
            onClose={toggleCodePreview}
          />
        </Tray>
      )}
    </>
  );
};

export default memo(HeaderButtons);
