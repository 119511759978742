import {
  FunctionDeclarationExpression,
  StatementType,
  ResolvedExpressionType as ExpressionType
} from '@stratumn/dsl';

export default {
  $expression: ExpressionType.FunctionDeclaration,
  arguments: [],
  body: [
    {
      $statement: StatementType.ForEach,
      array: {
        $expression: ExpressionType.Variable,
        query: 'formData.files'
      },
      function: {
        $expression: ExpressionType.FunctionDeclaration,
        arguments: ['file'],
        body: [
          {
            $statement: StatementType.Comment,
            comments: ['Find if a file already has that name']
          },
          {
            $statement: StatementType.SetVariable,
            path: 'matchingFile',
            value: {
              $expression: ExpressionType.Variable,
              query: {
                $expression: ExpressionType.Join,
                arguments: [
                  'state.data.files[?name==`"',
                  {
                    $expression: ExpressionType.Variable,
                    query: 'file.name'
                  },
                  '"`] | [0]'
                ]
              }
            }
          },
          {
            $statement: StatementType.Switch,
            cases: [
              {
                if: {
                  $expression: ExpressionType.Variable,
                  query: 'matchingFile'
                },
                then: [
                  {
                    $statement: StatementType.Comment,
                    comments: [
                      'File with the same name already exists, replace it',
                      'Also keep a history of versions'
                    ]
                  },
                  {
                    $statement: StatementType.SetVariable,
                    path: 'fileHistory',
                    value: {
                      $expression: ExpressionType.Concat,
                      arguments: [
                        {
                          $expression: ExpressionType.Variable,
                          query: 'matchingFile.history'
                        },
                        [
                          {
                            size: {
                              $expression: ExpressionType.Variable,
                              query: 'matchingFile.size'
                            },
                            mimetype: {
                              $expression: ExpressionType.Variable,
                              query: 'matchingFile.mimetype'
                            },
                            digest: {
                              $expression: ExpressionType.Variable,
                              query: 'matchingFile.digest'
                            },
                            key: {
                              $expression: ExpressionType.Variable,
                              query: 'matchingFile.key'
                            },
                            upload: {
                              $expression: ExpressionType.Variable,
                              query: 'matchingFile.upload'
                            }
                          }
                        ]
                      ]
                    }
                  },
                  {
                    $statement: StatementType.SetVariable,
                    path: 'matchingFile.history',
                    value: {
                      $expression: ExpressionType.Variable,
                      query: 'fileHistory'
                    }
                  },
                  {
                    $statement: StatementType.SetVariable,
                    path: 'matchingFile.size',
                    value: {
                      $expression: ExpressionType.Variable,
                      query: 'file.size'
                    }
                  },
                  {
                    $statement: StatementType.SetVariable,
                    path: 'matchingFile.mimetype',
                    value: {
                      $expression: ExpressionType.Variable,
                      query: 'file.mimetype'
                    }
                  },
                  {
                    $statement: StatementType.SetVariable,
                    path: 'matchingFile.digest',
                    value: {
                      $expression: ExpressionType.Variable,
                      query: 'file.digest'
                    }
                  },
                  {
                    $statement: StatementType.SetVariable,
                    path: 'matchingFile.key',
                    value: {
                      $expression: ExpressionType.Variable,
                      query: 'file.key'
                    }
                  },
                  {
                    $statement: StatementType.SetVariable,
                    path: 'matchingFile.upload',
                    value: {
                      date: {
                        $expression: ExpressionType.Variable,
                        query: 'file.createdAt'
                      },
                      user: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.createdBy.name'
                      },
                      accountId: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.createdBy.id'
                      },
                      group: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.owner.name'
                      },
                      groupId: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.group.id'
                      },
                      groupLabel: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.group.label'
                      }
                    }
                  }
                ]
              }
            ],
            else: [
              {
                $statement: StatementType.Comment,
                comments: ['File does not exist yet']
              },
              {
                $statement: StatementType.SetVariable,
                path: 'state.data.files',
                value: {
                  $expression: ExpressionType.Concat,
                  arguments: [
                    {
                      $expression: ExpressionType.Variable,
                      query: 'state.data.files'
                    },
                    [
                      {
                        name: {
                          $expression: ExpressionType.Variable,
                          query: 'file.name'
                        },
                        size: {
                          $expression: ExpressionType.Variable,
                          query: 'file.size'
                        },
                        mimetype: {
                          $expression: ExpressionType.Variable,
                          query: 'file.mimetype'
                        },
                        digest: {
                          $expression: ExpressionType.Variable,
                          query: 'file.digest'
                        },
                        key: {
                          $expression: ExpressionType.Variable,
                          query: 'file.key'
                        },
                        upload: {
                          date: {
                            $expression: ExpressionType.Variable,
                            query: 'file.createdAt'
                          },
                          user: {
                            $expression: ExpressionType.Variable,
                            query: 'meta.createdBy.name'
                          },
                          accountId: {
                            $expression: ExpressionType.Variable,
                            query: 'meta.createdBy.id'
                          },
                          group: {
                            $expression: ExpressionType.Variable,
                            query: 'meta.owner.name'
                          },
                          groupId: {
                            $expression: ExpressionType.Variable,
                            query: 'meta.group.id'
                          },
                          groupLabel: {
                            $expression: ExpressionType.Variable,
                            query: 'meta.group.label'
                          }
                        },
                        history: []
                      }
                    ]
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as FunctionDeclarationExpression;
