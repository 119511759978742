import { createContext } from 'react';
import { TraceFieldOptions } from 'schemaBuilder/converter/admin';

import { Action } from 'utils/trace';

export interface ActionSelectionOption {
  value: string;
  label: string;
}
export const getActionsList = (
  actions: Record<string, Action>
): ActionSelectionOption[] => {
  if (!actions) return [];
  return Object.values(actions)
    .map(({ key, title }) => ({
      value: key,
      label: title
    }))
    .sort((a1, a2) => a1.label.localeCompare(a2.label));
};

export interface DeadlinesContextDefinition {
  actions: ActionSelectionOption[];
  showErrors?: boolean;
  traceFieldOptions: TraceFieldOptions;
}

export const DeadlinesContext = createContext<DeadlinesContextDefinition>({
  actions: [],
  showErrors: false,
  traceFieldOptions: []
});
