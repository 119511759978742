import { object, string, infer as InferType } from 'zod';
import {
  PresetTemplate,
  actionPresetInputSchema,
  mergeFormSchemas,
  mergeEffects
} from 'utils/presets';
import { StatementType, ResolvedExpressionType } from '@stratumn/dsl';

const groupSelectionInputSchema = object({
  fieldTitle: string()
}).merge(actionPresetInputSchema);

export type GroupSelectionInput = InferType<typeof groupSelectionInputSchema>;

const groupSelectionPreset: PresetTemplate<GroupSelectionInput> = {
  key: 'groupSelection',
  name: 'Group selection action',
  schema: groupSelectionInputSchema,
  defaultValues: {
    action: {
      title: 'Group selection'
    },
    fieldTitle: 'Selected group'
  },
  generateAction: async (action, { key, input }) => ({
    ...action,
    key,
    title: input.action.title,
    stageName: input.action.title,
    icon: input.action?.icon || action?.icon,
    form: mergeFormSchemas(action?.form, {
      schema: {
        type: 'object',
        properties: {
          group: {
            type: 'string',
            title: input.fieldTitle,
            format: 'workflow-group'
          }
        },
        required: ['group']
      },
      uiSchema: {
        'ui:order': ['group']
      }
    }),
    effects: mergeEffects(action?.effects, [
      {
        $preset: key,
        $statement: StatementType.SetVariable,
        path: 'state.data.group',
        value: {
          $expression: ResolvedExpressionType.Variable,
          query: 'formData.group'
        }
      }
    ])
  })
};

export default groupSelectionPreset;
