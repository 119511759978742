import React, { FC, useMemo } from 'react';

import { stringify } from '@stratumn/canonicaljson';
import ReactDiffViewer from 'react-diff-viewer';
import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  Switch
} from '@stratumn/atomic';
import { useToggle } from 'utils/hooks';
import { Workflow } from 'utils/trace';

import useStyles from './diffsModal.style';

// canonicaljs transforms undefined into null
// which is not the standard js-behaviour
// so we have to cleanup the json before
// it's a bit longer but at least it's clean and it's memoized anyway...
const cleanStringify = obj => {
  // 1- remove undefined-s
  const cleanObj = JSON.parse(JSON.stringify(obj));
  // 2- canonical stringify
  return stringify(cleanObj, null, 2);
};

interface Props {
  initialData: Workflow;
  data: Workflow;
  submit?: () => void;
  cancel: () => void;
}

// Modal to display the current differences between saved workflow config and local state of the app
// if a submit function is provided we display a confirmation modal with cancel / submit button
// otherwise it's just an informative modal with an ok button
export const DiffsModal: FC<Props> = ({
  initialData,
  data,
  submit,
  cancel
}) => {
  const classes = useStyles();

  const [isDark, toggleTheme] = useToggle(false);

  const initialDataStr = useMemo(() => cleanStringify(initialData), [
    initialData
  ]);
  const dataStr = useMemo(() => cleanStringify(data), [data]);

  return (
    <Modal
      title={
        submit ? 'Submit workflow config changes' : 'Workflow config changes'
      }
      closeButtonLabel="Cancel"
      handleCollapse={cancel}
      fullscreen
    >
      <ModalContent>
        <div className={classes.subHeader}>
          {submit && (
            <div className={classes.subHeaderMsg}>
              {'Please check your changes to the config of workflow '}
              <span className={classes.workflowName}>{initialData.name}</span>
              {' before submitting them.'}
            </div>
          )}
          <div className={classes.themeSwitch}>
            <div className={classes.switchLabel} data-is-active={isDark}>
              Dark
            </div>
            <Switch
              label="isDark"
              on={!isDark}
              disabled={false}
              handleChange={toggleTheme}
              dataCy="theme-toggle"
            />
            <div className={classes.switchLabel} data-is-active={!isDark}>
              Light
            </div>
          </div>
        </div>
        <div className={classes.diffsContainer}>
          <ReactDiffViewer
            oldValue={initialDataStr}
            newValue={dataStr}
            splitView
            useDarkTheme={isDark}
          />
        </div>
      </ModalContent>
      {submit ? (
        <ModalActions
          adverseAction={
            <Pushbutton onClick={cancel} dataCy="cancel-button">
              cancel
            </Pushbutton>
          }
        >
          <Pushbutton primary onClick={submit} dataCy="submit-button">
            submit
          </Pushbutton>
        </ModalActions>
      ) : (
        <ModalActions>
          <Pushbutton onClick={cancel} dataCy="ok-button">
            ok
          </Pushbutton>
        </ModalActions>
      )}
    </Modal>
  );
};

export default React.memo(DiffsModal);
