import { memo, useContext, useCallback } from 'react';

import { useDocumentTitle, DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';

import { Pushtext } from '@stratumn/atomic';

import { DisplayContext } from '../context';
import { tab } from '../constant';

import { HeaderButtons } from '../ui';

import SectionsList from './sectionsList';

import useStyles from './traceInfo.style';

export const TraceInfo = () => {
  const classes = useStyles();

  const {
    workflowName,
    info: { path, sections }
  } = useContext(DisplayContext);

  const { update } = useContext(DataContext);

  useDocumentTitle(workflowName, 'Display trace info');

  const handleCollapseAllSections = useCallback(
    (isCollapsed: boolean): void => {
      update(
        sections.map((_, idx) => {
          return {
            type: DataUpdateType.Set,
            path: `${path}.${idx}.sectionCollapse`,
            value: isCollapsed
          };
        })
      );
    },
    [path, sections, update]
  );

  return (
    <>
      <div className={classes.accordionButtons}>
        <div className={classes.collapseButton}>
          <Pushtext
            dataCy="collapse-all-sections"
            onClick={() => handleCollapseAllSections(true)}
          >
            Collapse all
          </Pushtext>
        </div>

        <Pushtext
          dataCy="expand-all-sections"
          onClick={() => handleCollapseAllSections(false)}
        >
          Expand all
        </Pushtext>
      </div>
      <div className={classes.traceInfoRoot}>
        <HeaderButtons activeTab={tab.TRACE_INFO} data={sections} path={path} />
        <SectionsList path={path} sections={sections} />
      </div>
    </>
  );
};

export default memo(TraceInfo);
