import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  draggable: {
    // The dashedLine does not exist for fixed columns
    // Therefore we add 1px for the dashedLine's height.
    marginBottom: isFixed => (isFixed ? 11 : 10)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative'
    // padding: '15px 22px 15px 0'
  },
  dragHandle: {
    marginRight: 10,
    outline: 'none',
    color: theme.grey5
  },
  fieldTextCompactWrapper: {
    marginRight: 10,
    minWidth: 0,
    width: '100%',
    '& input': {
      width: '100%',
      height: 34
    }
  },
  columnSelector: {
    minWidth: '24em',
    marginRight: '1em',
    '& input': {
      width: '100%',
      height: 34
    }
  },
  dropdownWrapper: {
    '& span': {
      '&:first-letter': {
        textTransform: 'uppercase'
      }
    }
  },
  dropdownOptionLabel: {
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  fixColumnSwitch: {
    display: 'flex',
    marginLeft: 10,
    width: 329
  },
  dashedLineWrapper: {
    position: 'relative',
    top: 5,
    '&:hover $dashedLine': {
      borderBottomWidth: 1
    },
    '&:hover $addInBetweenColumnIcon': {
      display: 'block'
    }
  },
  dashedLine: {
    position: 'relative',
    width: '100%',
    borderBottomWidth: 0,
    borderBottomColor: theme.indigoPastel1,
    borderBottomStyle: 'dashed',
    height: 1
  },
  addInBetweenColumnIcon: {
    display: 'none',
    position: 'relative',
    left: -2,
    top: -10
  }
}));
