import { gql } from '@apollo/client';
import { FRAGMENT } from '../fragment';

export const WORKFLOW_CONFIG_MUTATION = gql`
  mutation workflowDetails_newWfConfigMutation(
    $name: String
    $description: String
    $config: WorkflowConfigInputRecordInput
    $workflowId: BigInt!
    $settings: JSON
    $draft: Boolean
  ) {
    updateWorkflowDetails(
      input: {
        workflowId: $workflowId
        config: $config
        name: $name
        description: $description
        settings: $settings
        draft: $draft
      }
    ) {
      ...workflowDetails
    }
  }
  ${FRAGMENT}
`;
