import { FC, ReactNode, memo } from 'react';
import { Theme } from 'schemaBuilder/types';

import useStyles from './cardContent.style';

export interface Props {
  children: ReactNode;
  theme?: Theme;
}

export const CardContent: FC<Props> = ({ children, theme }) => {
  const classes = useStyles({ theme });

  return <div className={classes.cardContentRoot}>{children}</div>;
};

export default memo(CardContent);
