import { memo } from 'react';
import { FieldTextCompact } from '@stratumn/atomic';
import type {
  RichTextSchemaField,
  BaseTypeBodyProps
} from 'schemaBuilder/types';
import { useSetCallback } from 'schemaBuilder/modules/action/hooks/fields';

import useStyles from './richText.style';

interface Props extends BaseTypeBodyProps {
  schemaField: RichTextSchemaField;
}

const RichTextBody = ({ schemaField, readonly = false, path }: Props) => {
  const classes = useStyles();
  const handlePlaceholderUpdate = useSetCallback(
    `${path}.schemaField.placeholder`
  );
  return (
    <div className={classes.body}>
      <FieldTextCompact
        data-cy="placeholder"
        disabled={readonly}
        value={schemaField.placeholder}
        label="Placeholder"
        onValueChange={handlePlaceholderUpdate}
      />
    </div>
  );
};

export default memo(RichTextBody);
