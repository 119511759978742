import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  el: {
    // TODO: remove when atomic text fields updated
    '& input': {
      height: 34
    }
  }
}));
