import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    marginTop: 15,
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  property: {
    backgroundColor: theme.white1,
    borderRadius: 2,
    border: `1px solid ${theme.grey6}`,
    padding: 15,
    '&[data-has-errors=true]': {
      borderColor: theme.warningRed
    },
    '&[data-is-dragging=true]': {
      boxShadow: theme.indigoShadowHover
    }
  }
}));
