import { Overview as OverviewDefinition } from 'utils/trace';

const defaultOverviewConfig = {
  minRowsHeight: 30,
  selectBoxWidth: 34,
  minColumnsWidth: 'small',
  dataSelectorPath: 'meta.traceId',
  overscanRowCount: 1,
  tableWidthBuffer: 15
};

/**
 * @params isNewConfig - if true, we add a default root configuration settings
 */
export default (
  overviewToBeParsed: OverviewDefinition | null,
  isNewConfig: boolean
): any => {
  if (!overviewToBeParsed) return null;

  // fix any overview config which are set to an empty object instead of null
  if (
    Object.keys(overviewToBeParsed).length === 0 &&
    overviewToBeParsed.constructor === Object
  )
    return null;

  if (
    overviewToBeParsed.columns?.length === 0 &&
    !overviewToBeParsed.fixedColumns
  )
    return null;

  if (
    overviewToBeParsed.columns?.length === 0 &&
    overviewToBeParsed.fixedColumns?.length === 0
  )
    return null;

  let parsedOverview = {};

  if (!isNewConfig) {
    parsedOverview = {
      ...overviewToBeParsed
    };
  } else {
    parsedOverview = {
      ...defaultOverviewConfig,
      ...overviewToBeParsed
    };
  }

  return parsedOverview;
};
