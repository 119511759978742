import {
  object,
  string,
  array,
  record,
  infer as InferType,
  boolean,
  ZodSchema,
  literal,
  unknown
} from 'zod';
import { BooleanExpression, StringExpression } from '@stratumn/dsl';
import { SchemaBuilderConfig } from 'schemaBuilder/types';
import { StatementWithPreset } from './presets';
import {
  Widget,
  WindowView,
  tableConfigSchema,
  tableColumnsSchema
} from './traceWidgets';

export const groupsSchema = array(
  object({
    name: string(),
    label: string()
  })
);

export const formSchema = object({
  schema: unknown(), // JSON Schema
  uiSchema: record(unknown()) // TODO: Add schema for UI schema
})
  .passthrough()
  .nullable();

export const actionSchema = object({
  key: string(),
  title: string(),
  description: string().optional().nullable(),
  icon: string().optional().nullable(),
  stageName: string().optional().nullable(),
  form: formSchema.optional().nullable(),
  dataImporter: unknown().optional().nullable(),
  dataEditor: unknown().optional().nullable(),
  effects: array(
    object({
      $statement: string()
    }).passthrough() as any
  )
}).passthrough();

export type Admin = Partial<SchemaBuilderConfig>;

export interface Configuration {
  version?: number;
  definitions: any;
  admin?: Admin | null;
  overview?: InferType<typeof tableConfigSchema> | null;
  info?: unknown;
  // TODO: Improve widget validation when fully implementing validation
  // info?: TraceInfo | null;
  initState: any;
  initActions: {
    [label: string]: string[];
  };
  stateSchema?: any;
  actions: {
    [actionKey: string]: Action;
  };
  nextActions?: any;
  transitions?: WorkflowTransitions;
  allowAutomation: boolean;
  rowId?: bigint;
}
export const configurationSchema: ZodSchema<Configuration> = object({
  definitions: object({}).passthrough().nullable(),
  overview: tableConfigSchema.optional().nullable(),
  info: object({
    view: object({
      type: literal('box'),
      sections: array(
        object({
          key: string().optional().nullable(),
          view: unknown().optional().nullable()
          // TODO: Improve widget validation when fully implementing validation
          // view: windowViewSchema
        })
      )
    })
  })
    .optional()
    .nullable(),
  initState: object({}).passthrough().nullable(),
  initActions: record(array(string())),
  stateSchema: object({}).passthrough().optional().nullable(),
  actions: record(actionSchema),
  nextActions: object({}).passthrough().optional().nullable(),
  allowAutomation: boolean()
}).passthrough();

export type Overview = InferType<typeof tableConfigSchema> | null;

// FixedColumns infers the same type as Columns
export type TableColumns = InferType<typeof tableColumnsSchema>;

// For simpleness sake, we assume that the trace info widget always fits this
export interface TraceInfo extends Widget {
  view: {
    type: 'box';
    sections: {
      key?: string;
      view: WindowView;
    }[];
  };
}

export type Groups = InferType<typeof groupsSchema>;
export type Form = InferType<typeof formSchema>;
export type Action = InferType<typeof actionSchema> & {
  effects: StatementWithPreset[];
};

export const TRANSITIVE = 'keep';
type ToActions =
  | 'keep'
  | {
      [toActionKey: string]: {
        groups: ListOrWildcard;
        condition?: BooleanExpression;
      }[];
    };

export type ListOrWildcard = StringExpression[] | '*';

export interface TransitionGroups {
  groups: ListOrWildcard;
  condition?: BooleanExpression;
}

export interface WorkflowTransitions {
  [fromActionKey: string]: ToActions;
}

export interface Workflow {
  rowId: string;
  name: string;
  description: string;
  settings: WorkflowSettings;
  draft: boolean;
  config: Configuration;
  traces: { totalCount: number };
  groups: {
    totalCount: number;
    nodes: { label: string; rowId: string; name: string; avatar: string }[];
  };
  owner?: {
    organization: { name: string };
  };
  createdBy?: {
    user: { name: string };
  };
  updatedAt?: string;
  updatedBy?: {
    user: { name: string };
  };
}

export interface WorkflowSettingsPresets {
  [instanceKey: string]: {
    template: string;
    input: unknown;
  };
}

export interface WorkflowSettings {
  key: string;
  presets: WorkflowSettingsPresets;
}
