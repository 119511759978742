import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  singleSectionWrapper: {
    marginBottom: 10
  },
  dragHandle: {
    marginLeft: -5,
    marginRight: 4,
    outline: 'none',
    color: theme.grey5
  }
}));
