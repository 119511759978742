import { FC, memo } from 'react';

import { Icon } from '@stratumn/atomic';
import { Theme } from 'schemaBuilder/types';

import useStyles from './iconButton.style';

export interface Props {
  name: string;
  onClick: () => void;
  dataCy?: string;
  ariaLabel?: string;
  color?: string;
  theme?: Theme;
}

export const IconButton: FC<Props> = ({
  dataCy = 'icon-button',
  name,
  onClick,
  ariaLabel = '',
  color,
  theme
}) => {
  const classes = useStyles({ theme, color });

  return (
    <button
      data-cy={dataCy}
      className={classes.iconButtonRoot}
      type="button"
      onClick={onClick}
      aria-label={ariaLabel || `${name} button`}
    >
      <Icon name={name} size={20} />
    </button>
  );
};

export default memo(IconButton);
