import { ActionField, Theme, WorkflowSchema } from 'schemaBuilder/types';
import Field from '../field';

import useStyles from './list.style';

export interface Props {
  schema: WorkflowSchema;
  fields: ActionField[];
  showFieldKeys: boolean;
  path: string;
  readonly?: boolean;
  theme?: Theme;
  availableFieldTypesOptions?: any[];
}

// this intermediate component is motivated by a tip from react-beautiful-dnd
// for perf optimization on the droppable list, to avoid rerendering the list content while dragging
// see https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md#recommended-droppable--performance-optimisation
export default ({
  fields,
  schema,
  path,
  theme,
  readonly,
  showFieldKeys,
  availableFieldTypesOptions
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {fields.map((actionField, index) => (
        <Field
          readonly={readonly}
          key={actionField.id}
          index={index}
          field={actionField}
          schema={schema}
          showFieldKeys={showFieldKeys}
          path={path}
          theme={theme}
          availableFieldTypesOptions={availableFieldTypesOptions}
        />
      ))}
    </div>
  );
};
