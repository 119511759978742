import { InputType } from '../types';

// list all possible input options
export const INPUT_OPTIONS_DATA = [
  {
    type: InputType.ShortText,
    label: 'Short text field',
    icon: 'TextParaSmall'
  },
  {
    type: InputType.Number,
    label: 'Number',
    icon: 'Ruler'
  },
  {
    type: InputType.Date,
    label: 'Date field',
    icon: 'Calendar'
  },
  {
    type: InputType.Boolean,
    label: 'Boolean field',
    icon: 'Boolean'
  },
  {
    type: InputType.Textarea,
    label: 'Textarea',
    icon: 'TextParaBig'
  },
  {
    type: InputType.RichText,
    label: 'Rich text',
    icon: 'Speech'
  },
  {
    type: InputType.Checkboxes,
    label: 'Checkboxes',
    icon: 'CheckboxDropdown'
  },
  {
    type: InputType.Dropdown,
    label: 'Dropdown',
    icon: 'LightArrowDropdown'
  },
  {
    type: InputType.RadioButtons,
    label: 'Radio buttons',
    icon: 'RadioDropdown'
  },
  {
    type: InputType.FilesUpload,
    label: 'Files upload',
    icon: 'Paperclip'
  },
  {
    type: InputType.SubForm,
    label: 'Sub-form',
    icon: 'IdLight'
  },
  {
    type: InputType.List,
    label: 'List',
    icon: 'List'
  },
  {
    type: InputType.GroupSelection,
    label: 'Group selection',
    icon: 'Group'
  },
  {
    type: InputType.Helper,
    label: 'Helper',
    icon: 'CircleInfo'
  }
];

export const SUBFORMS_DEPTH_LIMIT = 2;
