import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  formBody: {
    padding: 15,
    background: theme.grey9
  },
  formFooter: {
    display: 'flex',
    '&[data-has-fields=true]': {
      marginTop: 10
    }
  },
  addActionFieldButton: {
    marginRight: 10
  },
  showKeysWrapper: {
    display: 'inline-block',
    marginLeft: 10
  }
}));
