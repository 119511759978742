import { createUseStyles } from 'react-jss';

export default createUseStyles({
  accordionButtons: {
    display: 'flex',
    position: 'absolute',
    top: 40,
    right: 20
  },
  collapseButton: {
    marginRight: 15
  },
  traceInfoRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden'
  }
});
