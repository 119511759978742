import type { WorkflowExport } from 'utils/export/getFile';
import getConfig from './components/config';
import getWorkflow from './components/workflow';
import getPresets from './components/presets';
import getGroups from './components/groups';

// TODO: Support merging with existing configuration (likely providing it as a second argument)
export default async (file: File) => {
  const payload: WorkflowExport | null = await new Promise(
    (resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.addEventListener('loadend', () => {
        try {
          resolve(JSON.parse(fileReader.result as string));
        } catch {
          reject(new Error('Invalid JSON!'));
        }
      });
      fileReader.addEventListener('abort', () => resolve(null));
      fileReader.addEventListener('error', () => reject(fileReader.error));
      fileReader.readAsText(file);
    }
  );
  if (!payload) return {};
  const workflow = getWorkflow(payload);
  const presets = getPresets(payload);
  const config = getConfig(payload);
  const groups = getGroups(payload);
  // Some config fields have to be included in newWorkflowConfig,
  // some others in updateWorkflowConfig
  const newConfig = { ...config };
  const patchConfig: any = {};
  for (const field of ['info', 'overview']) {
    delete newConfig[field];
    patchConfig[field] = config[field];
  }
  return { workflow, presets, groups, newConfig, patchConfig };
};
