import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  row: {
    flexShrink: 0,
    height: 50,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    borderBottom: `1px solid ${theme.grey7}`,
    padding: '0 12px',
    '&:hover': {
      backgroundColor: theme.grey9,
      '& $buttons': {
        opacity: 1
      }
    }
  },
  workflowInfo: {
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10
  },
  workflowId: {
    flexShrink: 0,
    display: 'flex',
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '0.03em',
    width: 74
  },
  workflowName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.grey2,
    '&:hover': {
      color: theme.indigo2,
      textDecoration: 'underline'
    }
  },
  workflowDraftStatus: {
    borderRadius: 2,
    padding: '2px 10px',
    backgroundColor: theme.grey4,
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '13px',
    textTransform: 'uppercase',
    color: theme.white1
  },
  buttons: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: 5,
    color: theme.grey3,
    opacity: 0
  }
}));
