import { Statuses, ActionDefinition } from 'utils/interfaces/statuses';

const LABEL = 'label';
const ERROR_MESSAGE =
  'Please complete the required fields before submitting the config';

export const validateActions = (actions: ActionDefinition[]): number =>
  actions.reduce((num, action) => {
    // action labels are empty fields or no action labels exist
    if (
      Object.values(action).length !==
        Object.values(action).filter(s => s !== '').length ||
      !Object.keys(action).find(s => s === LABEL)
    ) {
      return num + 1;
    }
    return num;
  }, 0);

export const validateStatuses = (sections: Statuses): string[] => {
  let numberOfErrors = 0;

  sections?.forEach(section => {
    // no section label
    if (!section.label || section.label === '') numberOfErrors += 1;

    // no statuses defined or empty array
    if (!section.statuses || section.statuses.length === 0) numberOfErrors += 1;

    section.statuses.forEach(status => {
      // status labels are empty fields or no status labels exist
      if (
        Object.values(status).length !==
          Object.values(status).filter(s => s !== '').length ||
        !Object.keys(status).find(s => s === LABEL)
      ) {
        numberOfErrors += 1;
      }

      numberOfErrors += validateActions(status.actions);
    });
  });

  if (numberOfErrors === 0) return [];

  return [ERROR_MESSAGE];
};
