import { RefObject } from 'react';

// scroll to a ref if it exist after a timeout
export const scrollToEndOfRef = (ref: RefObject<HTMLElement>) =>
  setTimeout(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.scrollTo({
        top: currentRef.scrollHeight,
        behavior: 'smooth'
      });
    }
  });
