import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 100,
    paddingBottom: 10
  },
  main: {
    borderTop: `1px solid ${theme.grey7}`,
    marginLeft: 25 // 20px extra represents the dragNdrop icon
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 25 // 20px extra represents the dragNdrop icon
  },
  label: {
    marginLeft: 5,
    flex: 3
  },
  spacer: {
    flex: 1
  }
}));
