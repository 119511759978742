import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  decimals: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontSize: 14,
    color: theme.grey1
  },
  decimalsField: {
    width: 50,
    marginRight: 10
  }
}));
