import { Section, ItemsTypesEnums } from '../../types';

const defaultTypes = {
  BOX: 'box',
  WINDOW: 'window',
  KEY_VALUE: 'keyValue'
};

const UI_PROPERTIES_KEYS_TO_REMOVE = [
  'description',
  'placeholder',
  'sectionKey',
  'key'
];

const parseItems = (acc, { label, path, type, ...otherProperties }) => {
  const nonUIconfigurableProperties = Object.keys(otherProperties).reduce(
    (acc, currKey) => {
      if (UI_PROPERTIES_KEYS_TO_REMOVE.includes(currKey)) return acc;

      acc[currKey] = otherProperties[currKey];

      return acc;
    },
    {}
  );

  const item: any = {
    view: {
      key: label,
      type: defaultTypes.KEY_VALUE,
      value: {
        view: {
          path,
          type,
          ...nonUIconfigurableProperties
        }
      }
    }
  };

  acc.push(item);
  return acc;
};

export const parseSections = (
  acc: {
    view: {
      sections: any[];
      type: string;
    };
  },
  { collapsable, items, title, itemsType, ...otherProperties }: Section
): any => {
  const nonUIconfigurableProperties = Object.keys(otherProperties).reduce(
    (acc, currKey) => {
      if (UI_PROPERTIES_KEYS_TO_REMOVE.includes(currKey)) return acc;

      acc[currKey] = otherProperties[currKey];

      return acc;
    },
    {}
  );

  if (items) {
    acc.view.sections.push({
      view: {
        collapsable,
        items:
          // TODO: currently we only configure keyValue types. All other types are copied as the original unless it was disabled. In which case, it is deleted from the new configuration model
          itemsType !== ItemsTypesEnums.KeyValue
            ? items
            : items?.reduce(parseItems, []),
        title,
        type: defaultTypes.WINDOW,
        ...nonUIconfigurableProperties
      }
    });
  }

  return acc;
};

export default infoToDisplay => {
  const sectionsIncludingWidgets = infoToDisplay.filter(
    section => section.items.length > 0
  );

  const parsedInfo = sectionsIncludingWidgets.reduce(parseSections, {
    view: {
      sections: [],
      type: defaultTypes.BOX
    }
  });

  if (parsedInfo.view.sections.length === 0) return null;

  return parsedInfo;
};
