import {
  FunctionDeclarationExpression,
  StatementType,
  ResolvedExpressionType as ExpressionType
} from '@stratumn/dsl';

export default {
  $expression: ExpressionType.FunctionDeclaration,
  arguments: [],
  body: [
    {
      $statement: StatementType.Comment,
      comments: ['Add the comment if any']
    },
    {
      $statement: StatementType.Switch,
      cases: [
        {
          if: {
            $expression: ExpressionType.Variable,
            query: 'formData.comment'
          },
          then: [
            {
              $statement: StatementType.SetVariable,
              path: 'state.data.comments',
              value: {
                $expression: ExpressionType.Concat,
                arguments: [
                  {
                    $expression: ExpressionType.Variable,
                    query: 'state.data.comments'
                  },
                  [
                    {
                      date: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.createdAt'
                      },
                      user: {
                        accountId: {
                          $expression: ExpressionType.Variable,
                          query: 'meta.createdBy.id'
                        },
                        name: {
                          $expression: ExpressionType.Variable,
                          query: 'meta.createdBy.name'
                        },
                        avatar: {
                          $expression: ExpressionType.Variable,
                          query: 'meta.createdBy.avatar'
                        }
                      },
                      group: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.group.name'
                      },
                      groupId: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.group.id'
                      },
                      groupLabel: {
                        $expression: ExpressionType.Variable,
                        query: 'meta.group.label'
                      },
                      comment: {
                        $expression: ExpressionType.Variable,
                        query: 'formData.comment'
                      },
                      action: {
                        $expression: ExpressionType.Variable,
                        query: 'action.title'
                      }
                    }
                  ]
                ]
              }
            }
          ]
        }
      ]
    }
  ]
} as FunctionDeclarationExpression;
