import { useEffect } from 'react';
import qs from 'query-string';

import { getToken } from 'client';
import { ROUTE_HOME } from 'constant/routes';
import { setAuthToken } from 'utils';
import { RouteComponentProps } from 'react-router-dom';

export default (props: RouteComponentProps) => {
  const {
    history,
    location: { search }
  } = props;

  const getAuthCode = () => {
    const { authCode } = qs.parse(search);
    return authCode as string;
  };

  // eslint-disable-next-line
  useEffect(() => {
    const authCode = getAuthCode();
    getToken(authCode).then(token => {
      setAuthToken(token);
      history.push(ROUTE_HOME);
    });
  }, []); // eslint-disable-line

  return null;
};
