import React, { useCallback, useState, useMemo } from 'react';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  Icon
} from '@stratumn/atomic';

import useStyles from './iconSelectionModal.style';
import iconsLists from './iconsLists';

interface Props {
  iconStr: string;
  setIconStr: (iconStr: string) => void;
  close: () => void;
}

export const IconSelectionModal = ({ iconStr, setIconStr, close }: Props) => {
  const classes = useStyles();

  const [selectedIconStr, setSelectedIconStr] = useState(iconStr);

  const onSubmit = useCallback(() => {
    setIconStr(selectedIconStr);
    close();
  }, [setIconStr, selectedIconStr, close]);

  const modalTitle = useMemo(
    () => (
      <span>
        <span className={classes.modalTitleHighlight}>All actions</span> icons
      </span>
    ),
    [classes]
  );

  return (
    <Modal title={modalTitle} closeButtonLabel="Cancel" handleCollapse={close}>
      <ModalContent>
        <div className={classes.modalContent} data-cy="modal-content">
          {iconsLists.map(icon => (
            <div
              key={icon}
              className={classes.iconCard}
              data-is-selected={icon === selectedIconStr}
              onClick={() => setSelectedIconStr(icon)}
              data-cy={icon}
            >
              <Icon name={icon} size={32} />
            </div>
          ))}
        </div>
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={close} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton
          primary
          onClick={onSubmit}
          dataCy="confirm-button"
          disabled={selectedIconStr === iconStr}
        >
          confirm
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default React.memo(IconSelectionModal);
