import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  title: {
    fontWeight: 'bold',
    color: theme.grey1,
    marginLeft: '0.3em',
    lineHeight: '15px',
    fontSize: '12px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.4em'
  },
  icon: {
    color: theme.teal1
  },
  content: {
    fontWeight: 400,
    color: theme.grey2,
    lineHeight: '15px',
    fontSize: '12px'
  }
}));
