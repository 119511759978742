import { validatePropertiesList } from '../../../../utils';
import { ListInputDefinition } from '../../../../types';

// list input validation
export default (
  input: ListInputDefinition,
  errors: string[],
  depth: number = 0,
  deepValidation: boolean = false
) => {
  const { itemsProperties } = input;
  validatePropertiesList(errors, itemsProperties, depth + 1, deepValidation);
};
