import { memo } from 'react';
import { Check } from '@stratumn/atomic';

import type {
  FileUploadSchemaField,
  BaseTypeBodyProps
} from 'schemaBuilder/types';

import { useToggleCallback } from 'schemaBuilder/modules/action/hooks/fields';

import useStyles from './fileUpload.style';

interface Props extends BaseTypeBodyProps {
  schemaField: FileUploadSchemaField;
}

const FileUploadBody = ({ schemaField, readonly = false, path }: Props) => {
  const classes = useStyles();

  const onChange = useToggleCallback(`${path}.schemaField.enableMultipleFiles`);

  return (
    <div className={classes.body}>
      <Check
        label="Multiple files upload"
        showLabel
        darkLabel
        largeLabel
        checked={schemaField.enableMultipleFiles}
        handleChange={onChange}
        disabled={readonly}
      />
    </div>
  );
};

export default memo(FileUploadBody);
