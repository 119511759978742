import { JSONSchema7 } from 'json-schema';

export const fileSchema: JSONSchema7 = {
  type: 'object',
  required: ['digest', 'name', 'size', 'createdAt'],
  properties: {
    digest: {
      type: 'string'
    },
    name: {
      type: 'string'
    },
    size: {
      type: 'integer'
    },
    key: {
      type: 'string'
    },
    createdAt: {
      type: 'string',
      format: 'date-time'
    }
  }
};
