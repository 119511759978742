import React, { FC, useCallback, useContext, useMemo } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import { Pushtext, Icon } from '@stratumn/atomic';

import generateId from 'utils/id';
import { DataContext } from 'utils/hooks';

import { PropertyDefinition } from '../types';

import List from './list';

import { PropertiesListContext } from './context';
import useStyles from './propertiesList.style';

interface Props {
  path: string;
  properties?: PropertyDefinition[];
  depth?: number;
  propertiesClassName?: string;
  addButtonLabel?: string;
}

export const PropertiesList: FC<Props> = ({
  path,
  properties = [],
  depth = 0,
  propertiesClassName,
  addButtonLabel = 'Add input'
}) => {
  const classes = useStyles();

  const { append } = useContext(DataContext);

  const addProperty = useCallback(() => {
    append(path, {
      key: generateId()
    });
  }, [append, path]);

  const propertiesListContext = useMemo(
    () => ({
      properties
    }),
    [properties]
  );

  return (
    <PropertiesListContext.Provider value={propertiesListContext}>
      <Droppable droppableId={path} type={path}>
        {providedDroppable => (
          <div
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            <List
              path={path}
              properties={properties}
              depth={depth}
              propertiesClassName={propertiesClassName}
            />
            {providedDroppable.placeholder}
            <div className={classes.addPropertyBtn}>
              <Pushtext
                onClick={addProperty}
                prefix={<Icon name="CirclePlusFill" size={25} />}
                data-cy="add-property"
              >
                {addButtonLabel}
              </Pushtext>
            </div>
          </div>
        )}
      </Droppable>
    </PropertiesListContext.Provider>
  );
};

export default React.memo(PropertiesList);
