export interface DeadlineBaseDefinition {
  key: string;
  label?: string;
  isCollapsed?: boolean;
}

export enum DeadlineType {
  ActionBased = 'ActionBased',
  StateBased = 'StateBased'
}
export interface ActionBasedDeadlineDefinition extends DeadlineBaseDefinition {
  type: DeadlineType.ActionBased;
  startTrigger?: string;
  endTrigger?: string;
  deadlineDays?: number;
}
export interface StateBasedDeadlineDefinition extends DeadlineBaseDefinition {
  type: DeadlineType.StateBased;
  stateField?: string;
  endTrigger?: string;
  additionalDelay?: number;
}

export type DeadlineDefinition =
  | ActionBasedDeadlineDefinition
  | StateBasedDeadlineDefinition;

export type Deadlines = DeadlineDefinition[];
