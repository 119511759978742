import React, { FC, memo, useMemo } from 'react';
import { Icon, Pushbutton, PushtextHref, InfoTooltip } from '@stratumn/atomic';
import { NavLink } from 'react-router-dom';

import useStyles from './workflowDetailsCard.style';

const TOOLTIP_POSITION = {
  adjustPlace: true,
  place: 'bottom',
  placeShift: 0
};

export interface Props {
  dataCy?: string;
  icon?: string;
  title: string;
  btnTitle: string;
  href?: string;
  externalRef?: boolean;
  btnOnClick?: Function | null;
  content?: React.ReactNode;
  count?: number;
  disabled?: boolean;
  pending?: boolean;
  tooltip?: string;
}

export const WorkflowDetailsCard: FC<Props> = ({
  dataCy = 'workflow-details-btn',
  icon = '',
  title,
  btnTitle,
  href = '',
  externalRef,
  btnOnClick = null,
  content = null,
  count = 0,
  disabled = false,
  pending = false,
  tooltip
}) => {
  const classes = useStyles();

  const renderButton = useMemo(() => {
    if (btnOnClick) {
      return (
        <Pushbutton
          prefix={<Icon name="CirclePlus" size={22} />}
          dataCy={dataCy}
          primary
          onClick={btnOnClick}
          disabled={disabled}
        >
          {btnTitle}
        </Pushbutton>
      );
    }

    const button = externalRef ? (
      <div className={classes.pushtextHrefWrapper} data-is-disabled={disabled}>
        <PushtextHref
          dataCy={dataCy}
          primary
          href={href}
          disabled={disabled}
          external
        >
          <Icon name="Cog" size={32} />
        </PushtextHref>
      </div>
    ) : (
      <NavLink
        data-cy={dataCy}
        to={href}
        className={classes.navLink}
        data-is-disabled={disabled}
      >
        <Icon name="Cog" size={32} />
      </NavLink>
    );

    if (tooltip) {
      return (
        <InfoTooltip
          dataCy="info-tooltip"
          padding="5px"
          position={TOOLTIP_POSITION}
          text={tooltip}
          textAlign="center"
          delay={200}
        >
          {button}
        </InfoTooltip>
      );
    }
    return button;
  }, [
    btnOnClick,
    btnTitle,
    classes.navLink,
    classes.pushtextHrefWrapper,
    dataCy,
    disabled,
    href,
    externalRef,
    tooltip
  ]);

  return (
    <div className={classes.card}>
      <div className={classes.cardHead}>
        <div className={classes.cardTitle}>
          {icon && (
            <span className={classes.cardTitleIcon}>
              <Icon name={icon} size={23} />
            </span>
          )}
          <h5>{title}</h5>
          {pending && <div className={classes.pendingPill}>Pending</div>}
        </div>
        {renderButton}
      </div>
      <div className={classes.cardBody} data-is-empty={count === 0}>
        {content}
      </div>
    </div>
  );
};

export default memo(WorkflowDetailsCard);
