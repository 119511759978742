import { ViewTypes } from '../../types';
import { defaultConfig } from './widgetDefaultConfig';

/**
 * For properties which cannot be set through the UI, we currently set them using default.
 */
export const parsePathValue = (
  path: string,
  value: string
): { path: string; value: any } => {
  switch (value) {
    case ViewTypes.Avatar:
      return {
        path: `view`,
        value: defaultConfig(path).AVATAR
      };

    case ViewTypes.Date:
      return {
        path: `view`,
        value: defaultConfig(path).DATE
      };

    case ViewTypes.Icon:
      return {
        path: `view`,
        value: defaultConfig(path).ICON
      };

    case ViewTypes.Text:
      return {
        path: `view`,
        value: defaultConfig(path).TEXT
      };

    case ViewTypes.Link:
      return {
        path: `view`,
        value: defaultConfig(path).LINK
      };

    default:
      return {
        path: `view`,
        value: {
          path,
          type: value
        }
      };
  }
};

// a short map to customize the text to display when selecting the type
export const typesCustomLabel: {
  [key in ViewTypes]?: string;
} = {
  [ViewTypes.Link]: 'Link to trace'
};
