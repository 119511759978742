import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    marginTop: 7
  },
  emptyCircle: {
    flexShrink: 0,
    width: 18,
    height: 18,
    backgroundColor: theme.white1,
    border: `1px solid ${theme.grey4}`,
    borderRadius: '50%'
  },
  radioLabel: {
    flexGrow: 1,
    padding: '4px 0px ',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover $radioLabelInput': {
      borderColor: theme.grey7
    }
  },
  radioLabelInput: {
    backgroundColor: 'transparent',
    flexGrow: 1,
    marginLeft: 5,
    padding: 4,
    outline: 'none',
    border: 'none',
    borderRadius: 2,
    boxSizing: 'border-box',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1,
    borderBottom: `1px solid transparent`,
    '&::placeholder': {
      color: theme.grey4
    }
  }
}));
