import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  actionsWrapper: {
    margin: '-14px 0', // what an ugly hack....
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: theme.grey1
  }
}));
