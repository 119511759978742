import { memo, ReactElement } from 'react';
import { Card, CardActions, CardContent } from 'components/workflow/ui';

import { useValidation } from '../../hooks/validator';
import useStyles from './empty.style';

export interface Props {
  index: number;
  isDragging: boolean;
  dragElement: ReactElement;
  removeElement: ReactElement;
  typeSelectElement: ReactElement;
}

export const Empty = ({
  index,
  isDragging,
  dragElement,
  removeElement,
  typeSelectElement
}: Props) => {
  const validation = useValidation();
  const classes = useStyles();
  return (
    <Card
      isDragging={isDragging}
      invalid={validation.hasErrorsByGroup(`fields.${index}`)}
    >
      <CardContent>
        <div className={classes.header}>
          {dragElement}
          {typeSelectElement}
        </div>
      </CardContent>
      <CardActions>{removeElement}</CardActions>
    </Card>
  );
};

export default memo(Empty);
