import Header from './header';
import Body from './body';
import Settings from './settings';
import getErrors from './validation';

export default {
  header: Header,
  body: Body,
  settings: Settings,
  getErrors
};
