import { FC, memo } from 'react';

import { Section as SectionDefinition } from '../types';

import ListItem from './listItem';

interface Props {
  path: string;
  sections: SectionDefinition[];
}

const List: FC<Props> = ({ path, sections }) => (
  <>
    {sections.map((section, index) => (
      <li key={section.sectionKey}>
        <ListItem path={`${path}.${index}`} index={index} section={section} />
      </li>
    ))}
  </>
);

export default memo(List);
