export enum WidgetTypesEnums {
  CustomCode = 'custom code',
  Date = 'date',
  List = 'list',
  Array = 'array',
  Text = 'text',
  Number = 'number'
}

export enum ItemsTypesEnums {
  KeyValue = 'keyValue',
  File = 'file',
  DataTab = 'dataTab',
  Comment = 'comment'
}

export interface Widget {
  key?: string;
  label: string;
  path: string;
  type: WidgetTypesEnums | null;
}

export interface WidgetCard extends Widget {
  index: number;
  sectionKey: string;
  settings?: boolean;
  optionsTypes: String[];
}

export interface Section {
  sectionKey: string;
  title: string;
  description?: string;
  placeholder?: string;
  collapsable?: boolean;
  sectionCollapse?: boolean;
  displayItemCount?: boolean;
  items?: Widget[];
  itemsType?: ItemsTypesEnums;
}
