import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import WorkflowManager from './workflowManager';
import { FRAGMENT } from './fragment';

export const QUERY = gql`
  query workflowDetailsQuery($id: BigInt!) {
    workflowByRowId(rowId: $id) {
      ...workflowDetails
    }
  }
  ${FRAGMENT}
`;

interface Props extends RouteComponentProps<{ id: string }> {}

export const Workflow = ({ match }: Props) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { id: match.params.id }
  });

  if (error) {
    return <div> Error: {JSON.stringify(error)}</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  return <WorkflowManager data={data.workflowByRowId} />;
};

export default withRouter(Workflow);
