// Documentation:
// https://docs.google.com/document/d/1yR0B1ZD97SvR5zM8ALTNIk2U8GoNA3HlkT49oj6LUug/edit#heading=h.wyk2dw3axv8d

import {
  object,
  literal,
  string,
  array,
  infer as InferType,
  union,
  boolean,
  lazy,
  number,
  ZodSchema
} from 'zod';

export const activityViewSchema = object({
  type: literal('activity'),
  who: object({
    path: string(),
    avatarPath: string().optional().nullable()
  }),
  when: object({
    path: string(),
    format: string(),
    showCountdown: boolean().optional().nullable()
  })
});
export type ActivityView = InferType<typeof activityViewSchema>;

export const arrayViewSchema = object({
  type: literal('array'),
  itemsPath: string(),
  itemsWidget: lazy(() => widgetSchema),
  defaultSort: object({
    direction: union([literal('asc'), literal('desc')])
  })
    .optional()
    .nullable()
});
export type ArrayView = InferType<typeof arrayViewSchema>;

export const avatarViewSchema = object({
  type: literal('avatar'),
  path: string(),
  avatarPath: string().optional().nullable(),
  isGroup: boolean().optional().nullable(),
  useContext: boolean().optional().nullable()
});
export type AvatarView = InferType<typeof avatarViewSchema>;

export const boxViewSchema = object({
  type: literal('box'),
  sections: lazy(() => array(widgetSchema))
});
export type BoxView = InferType<typeof boxViewSchema>;

export const codeViewSchema = object({
  type: literal('code'),
  path: string(),
  codemirrorOptions: object({}).passthrough()
});
export type CodeView = InferType<typeof codeViewSchema>;

export const commentViewSchema = object({
  type: literal('comment'),
  namePath: string(),
  avatarPath: string(),
  groupPath: string(),
  datePath: string(),
  actionPath: string(),
  commentPath: string()
});
export type CommentView = InferType<typeof commentViewSchema>;

export const dataTabViewSchema = object({
  type: literal('dataTab'),
  namePath: string(),
  path: string(),
  editedByPath: string(),
  editedAtPath: string()
});
export type DataTabView = InferType<typeof dataTabViewSchema>;

export const dateViewSchema = object({
  type: literal('date'),
  path: string(),

  format: string().optional().nullable(),
  inputFormat: string().optional().nullable(),

  isDeadline: boolean().optional().nullable(),
  donePath: string().optional().nullable(),
  deadlineWarningBuffer: number().optional().nullable(),
  deadlineWarningIcon: string().optional().nullable()
});
export type DateView = InferType<typeof dateViewSchema>;

export const fileViewSchema = object({
  type: literal('file'),
  fileDataPath: string().optional().nullable(),
  uploadUserNamePath: string().optional().nullable(),
  uploadDatePath: string().optional().nullable()
});
export type FileView = InferType<typeof fileViewSchema>;

export const fileCompactViewSchema = object({
  type: literal('fileCompact'),
  path: string(),
  defaultIcon: string().optional().nullable()
});
export type FileCompactView = InferType<typeof fileCompactViewSchema>;

export const htmlViewSchema = object({
  type: literal('html'),
  path: string()
});
export type HtmlView = InferType<typeof htmlViewSchema>;

export const iconViewSchema = object({
  type: literal('icon'),
  icon: string().optional().nullable(),
  iconPath: string().optional().nullable(),
  labelPath: string().optional().nullable(),
  path: string().optional().nullable()
});
export type IconView = InferType<typeof iconViewSchema>;

export const keyValueViewSchema = object({
  type: literal('keyValue'),
  key: string(),
  value: lazy(() => widgetSchema)
});
export type KeyValueView = InferType<typeof keyValueViewSchema>;

export const labelsViewSchema = object({
  type: literal('labels'),
  path: string()
});
export type LabelsView = InferType<typeof labelsViewSchema>;

export const linkViewSchema = object({
  type: literal('link'),
  rootUrl: string(),
  path: string(),
  urlPath: string(),
  openInNewTab: boolean().optional().nullable()
});
export type LinkView = InferType<typeof linkViewSchema>;

export const listViewSchema = object({
  type: literal('list'),
  path: string(),
  ordered: boolean().optional().nullable(),
  small: boolean().optional().nullable(),
  light: boolean().optional().nullable()
});
export type ListView = InferType<typeof listViewSchema>;

export const numberViewSchema = object({
  type: literal('number'),
  path: string(),
  format: union([
    string(),
    object({
      options: object({
        style: string().optional().nullable(),
        minimumFractionDigits: number().optional().nullable(),
        maximumFractionDigits: number().optional().nullable()
      }).passthrough()
    }).passthrough()
  ]),
  denominatorPath: string().optional().nullable()
});
export type NumberView = InferType<typeof numberViewSchema>;

export const progressViewSchema = object({
  type: literal('progress'),
  path: string(),
  denominatorPath: string().optional().nullable()
});
export type ProgressView = InferType<typeof progressViewSchema>;

export const proseViewSchema = object({
  type: literal('prose'),
  path: string(),
  small: boolean().optional().nullable(),
  light: boolean().optional().nullable()
});
export type ProseView = InferType<typeof proseViewSchema>;

// start: Table

export enum DisplayWidths {
  VerySmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  VeryLarge = 'xlarge'
}

export const tableCellWidthSchema = union([
  literal(DisplayWidths.VerySmall),
  literal(DisplayWidths.Small),
  literal(DisplayWidths.Medium),
  literal(DisplayWidths.Large),
  literal(DisplayWidths.VeryLarge),
  number()
]);

const tableGroupsSchema = array(
  object({
    $preset: string().optional().nullable(),
    path: string(),
    label: string(),
    column: string()
  })
);

export const tableColumnsSchema = array(
  object({
    key: string(),
    header: string(),
    width: tableCellWidthSchema.optional().nullable(),
    filter: object({
      path: string(),
      type: string()
    })
      .optional()
      .nullable(),
    cell: lazy(() => widgetSchema)
  })
);

const tableDefaultDisplaySchema = object({
  rowsHeight: number().optional().nullable(),
  tableWidth: tableCellWidthSchema.optional().nullable(),
  columns: array(union([string(), object({ key: string() })]))
});

export const tableConfigSchema = object({
  // TODO: turn defaultDisplay required once we have agreed on a default config for the overview module
  defaultDisplay: tableDefaultDisplaySchema.optional(),
  groups: tableGroupsSchema.optional().nullable(),
  columns: tableColumnsSchema.optional().nullable(),
  fixedColumns: tableColumnsSchema.optional().nullable(),
  allowColumnsSelection: boolean().optional().nullable(),
  showEmptyTable: boolean().optional().nullable(),
  dataSelectorPath: string().optional().nullable(),
  overscanRowCount: number().optional().nullable(),
  tableWidthBuffer: number().optional().nullable(),
  minRowsHeight: number().optional().nullable(),
  rowsHeight: number().optional().nullable(),
  selectBoxWidth: tableCellWidthSchema.optional().nullable(),
  minColumnsWidth: tableCellWidthSchema.optional().nullable(),
  columnsWidth: tableCellWidthSchema.optional().nullable()
});
export type TableConfig = InferType<typeof tableConfigSchema>;

export const tableViewSchema = object({
  type: literal('table'),
  path: string(),
  config: tableConfigSchema,
  height: string().optional().nullable(),
  userConfigKeyPath: string().optional().nullable()
});
export type TableView = InferType<typeof tableViewSchema>;

// end: Table
// start: Text

const textEditorInputSchema = object({
  type: literal('input'),
  placeholder: string().optional().nullable(),
  delay: number().optional().nullable(),
  disabled: boolean().optional().nullable()
});
const textEditorNumberSchema = object({
  type: literal('number'),
  placeholder: string().optional().nullable(),
  delay: number().optional().nullable(),
  disabled: boolean().optional().nullable()
});
const textEditorSelectSchema = object({
  type: literal('select'),
  placeholder: string().optional().nullable(),
  options: array(string()),
  disabled: boolean().optional().nullable()
});
const textEditorCommentSchema = object({
  type: literal('comment'),
  placeholder: string().optional().nullable(),
  disabled: boolean().optional().nullable()
});
const textEditorMultiselectSchema = object({
  type: literal('multiselect'),
  placeholder: string().optional().nullable(),
  options: array(string()),
  disabled: boolean().optional().nullable()
});

export const textViewSchema = object({
  type: literal('text'),
  path: string(),
  editor: union([
    textEditorInputSchema,
    textEditorNumberSchema,
    textEditorSelectSchema,
    textEditorCommentSchema,
    textEditorMultiselectSchema
  ])
    .optional()
    .nullable()
});
export type TextView = InferType<typeof textViewSchema>;

// end: Text

export const windowViewSchema = object({
  type: literal('window'),
  title: string().optional().nullable(),
  displayItemCount: boolean().optional().nullable(),
  collapsable: boolean().optional().nullable(),
  conditionPath: string().optional().nullable(),
  items: lazy(() => array(widgetSchema))
});
export type WindowView = InferType<typeof windowViewSchema>;

export const widgetSchema: ZodSchema<Widget> = object({
  view: union([
    activityViewSchema,
    arrayViewSchema,
    avatarViewSchema,
    boxViewSchema,
    codeViewSchema,
    commentViewSchema,
    dataTabViewSchema,
    dateViewSchema,
    fileViewSchema,
    fileCompactViewSchema,
    htmlViewSchema,
    iconViewSchema,
    keyValueViewSchema,
    labelsViewSchema,
    linkViewSchema,
    listViewSchema,
    numberViewSchema,
    progressViewSchema,
    proseViewSchema,
    tableViewSchema,
    textViewSchema,
    windowViewSchema
  ]),
  modal: object({
    title: union([string(), lazy(() => widgetSchema)]),
    body: lazy(() => widgetSchema),
    large: boolean().optional().nullable(),
    fullscreen: boolean().optional().nullable(),
    conditionPath: string().optional().nullable()
  })
    .optional()
    .nullable(),
  // Helper identifiers for merging
  key: string().optional().nullable(),
  $preset: string().optional().nullable()
}).passthrough() as any; // Circular types are annoying

export interface Widget {
  key?: string;
  $preset?: string;
  view:
    | ActivityView
    | ArrayView
    | AvatarView
    | BoxView
    | CodeView
    | CommentView
    | DataTabView
    | DateView
    | FileView
    | FileCompactView
    | HtmlView
    | IconView
    | KeyValueView
    | LabelsView
    | LinkView
    | ListView
    | NumberView
    | ProgressView
    | ProseView
    | TableView
    | TextView
    | WindowView;
  modal?: {
    title: Widget;
    body: Widget;
    large?: boolean;
  };
}
