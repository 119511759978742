import { createUseStyles } from 'react-jss';

export default createUseStyles({
  trayPortalContainer: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    bottom: 0,
    right: 0,
    width: 'auto',
    display: 'flex',
    flexFlow: 'row-reverse nowrap',
    '&[data-is-left=true]': {
      right: 'unset',
      left: 0,
      flexFlow: 'row nowrap'
    }
  }
});
