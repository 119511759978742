import { createUseStyles } from 'react-jss';

export default createUseStyles({
  tabMenuRoot: {
    display: 'inline-flex',
    flexDirection: 'column',
    minWidth: 177,
    zIndex: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  }
});
