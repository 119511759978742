import { createUseStyles } from 'react-jss';

export default createUseStyles({
  input: {
    position: 'relative',
    borderRadius: 2,
    outline: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  }
});
