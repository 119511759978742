import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  homeContainer: {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.grey8
  },
  root: {
    display: 'flex',
    marginInline: 100,
    marginBlock: 50,
    gap: 40
  },
  item: {
    boxShadow: theme.indigoShadow1,
    height: 150,
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    borderRadius: 15,
    backgroundColor: 'white',
    transition: 'all 0.3s',
    color: theme.grey1,
    '&:hover': {
      boxShadow: theme.indigoShadow2
    }
  },
  title: {
    fontSize: 20
  }
}));
