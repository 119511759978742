import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    marginTop: 7
  },
  emptyBox: {
    flexShrink: 0,
    width: 18,
    height: 18,
    backgroundColor: theme.white1,
    border: `1px solid ${theme.grey4}`,
    borderRadius: 2
  }
}));
