import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: theme.grey8,
    height: `calc(100vh - ${theme.headerHeight || '70px'})`,
    minWidth: 1240, // make horizontal scrollable
    overflow: 'hidden',
    position: 'relative' // required to make the accordion buttons in trace info absolute
  },
  header: {
    margin: '10px 0 20px 0'
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '0.03em',
    color: theme.grey1
  },
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: 20,
    overflow: 'hidden'
  },
  activeTabWrapper: {
    display: 'flex',
    flexGrow: 1,
    padding: '10px 20px 20px 20px',
    boxShadow: theme.indigoShadow1,
    backgroundColor: theme.white1,
    overflow: 'hidden'
  },
  ctaButtonsWrapper: {
    display: 'flex',
    flexShrink: 0
  },
  cancelButton: {
    marginRight: 10
  }
}));
