import React, { FC, useCallback, useContext, useMemo } from 'react';

import { Dropdown, OptionDrop, Icon } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import { InputType, InputDefinition } from '../../types';
import { FormBuilderContext } from '../../context';

import { SUBFORMS_DEPTH_LIMIT, INPUT_OPTIONS_DATA } from '../constants';

import useStyles from './inputSelection.style';

interface Props {
  input?: InputDefinition;
  path: string;
  depth?: number;
}

// Dropdown to select an input type
export const InputSelection: FC<Props> = ({ path, input, depth = 0 }) => {
  const classes = useStyles();
  const { set } = useContext(DataContext);
  const { showErrors } = useContext(FormBuilderContext);

  const { type: selectedType } = input || {};

  const inputOptions = useMemo(() => {
    // if depth of the property is too high, don't allow sub forms or lists
    const restrictedOptions =
      depth >= SUBFORMS_DEPTH_LIMIT
        ? INPUT_OPTIONS_DATA.filter(
            ({ type }) => ![InputType.SubForm, InputType.List].includes(type)
          )
        : INPUT_OPTIONS_DATA;

    return restrictedOptions.map(({ type, label, icon }) => (
      <OptionDrop
        key={type}
        label={
          <div className={classes.inputTypeOption}>
            <div className={classes.inputTypeOptionIcon}>
              <Icon name={icon} size={20} />
            </div>
            {label}
          </div>
        }
        value={type}
        selected={type === selectedType}
      />
    ));
  }, [classes, selectedType, depth]);

  const onSelectType = useCallback(
    e => {
      const newSelectedType = e.target.value;
      if (selectedType !== newSelectedType) {
        set(path, { type: newSelectedType });
      }
    },
    [set, path, selectedType]
  );

  return (
    <div className={classes.inputSelection}>
      <Dropdown
        label="Input type"
        onValueChange={onSelectType}
        value={selectedType}
        hideLabel
        invalid={showErrors && !selectedType}
      >
        {inputOptions}
      </Dropdown>
    </div>
  );
};

export default React.memo(InputSelection);
