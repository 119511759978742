import { FC, memo } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import { WidgetTypesEnums, Section as SectionDefinition } from '../types';

import List from './list';
import useStyles from './sectionsList.style';

export const defaultFormatOptions: Array<String> = Object.values(
  WidgetTypesEnums
);

export interface Props {
  path: string;
  sections: SectionDefinition[];
}

export const SectionsList: FC<Props> = ({ path, sections }) => {
  const classes = useStyles();

  return (
    <Droppable droppableId={path} type={path}>
      {providedDroppable => (
        <ul
          className={classes.sectionsWrapper}
          ref={providedDroppable.innerRef}
          {...providedDroppable.droppableProps}
        >
          <List path={path} sections={sections} />
          {providedDroppable.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default memo(SectionsList);
