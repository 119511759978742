import { createContext } from 'react';

import { PropertyDefinition } from '../types';

export interface PropertiesListContextDefinition {
  properties: PropertyDefinition[];
}

export const PropertiesListContext = createContext<PropertiesListContextDefinition>(
  { properties: [] }
);
