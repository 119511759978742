import type { WorkflowExport } from 'utils/export/getFile';

interface WorkflowPayload {
  name: string;
  description?: string;
  prefix?: string;
}

export default ({
  name,
  description,
  tracePrefix
}: WorkflowExport): WorkflowPayload => {
  if (!name) throw new Error('Missing workflow "name"');
  const payload: WorkflowPayload = { name };
  if (description) payload.description = description;
  if (tracePrefix) payload.prefix = tracePrefix;
  return payload;
};
