import { Schema, object, string, infer as InferType } from 'zod';
import mergeAllOf from 'json-schema-merge-allof';
import mergeWith from 'lodash.mergewith';
import deepClone from 'lodash.clonedeep';
import { Statement } from '@stratumn/dsl';

import { Widget, TableConfig } from 'utils/traceWidgets';
import {
  Configuration,
  Action,
  Form,
  TraceInfo,
  WorkflowTransitions
} from 'utils/trace';

export type StatementWithPreset = Statement & { $preset?: string };
export type WidgetWithPreset = Widget & { $preset?: string };

// All presets that generate an action should integrate these fields
// We set the action object as "nonstrict" for back-compatibility and flexibility
// in regards to its features (e.g. accept the action.key parameter even though it's useless now)
export const actionPresetInputSchema = object({
  action: object({
    title: string(),
    icon: string().optional().nullable()
  }).nonstrict()
});

export type ActionPresetInput = InferType<typeof actionPresetInputSchema>;

export interface PresetContext {
  // The current configuration
  config: Configuration;
  // The action that is being edited, if applicable.
  // If undefined, it is treated as an action creation
  // (if the preset does generate an action)
  action?: Action;
  // The preset instances that have been applied to the configuration
  presetInstances: {
    [key: string]: PresetInstance<any>;
  };
}

export interface PresetTemplate<T extends Partial<ActionPresetInput>> {
  // The key by which the preset is identified internally.
  // Should be equal to the preset folder name.
  key: string;
  // The display name of the preset
  name: string;
  // The zod schema of the preset
  schema: Schema<T>;
  // Default values for input fields
  defaultValues: T;

  // A completion function run at first preset submission
  completeInput?: (input: T) => void;

  // A preset can create/overwrite an action through this reducer
  generateAction?: (
    action: Action | undefined,
    instance: PresetInstance<T>,
    context: PresetContext
  ) => Action | Promise<Action>;

  // A preset can affect other actions, e.g. set next actions statements
  alterAction?: (
    action: Action,
    instance: PresetInstance<T>,
    context: PresetContext
  ) => Action | Promise<Action>;

  // A preset can have global effects on workflow configuration
  alterConfig?: (
    config: Configuration,
    instance: PresetInstance<T>,
    context: PresetContext
  ) => Configuration | Promise<Configuration>;
}

export interface PresetInstance<T extends Partial<ActionPresetInput>> {
  // The workflow-unique key of the instance
  key: string;
  // The preset template used by this instance
  template: PresetTemplate<T>;
  // The input for this instance
  input: T;
}

export const applyPresetInstance = async <T extends Partial<ActionPresetInput>>(
  instance: PresetInstance<T>,
  context: PresetContext
): Promise<Configuration> => {
  const { config, presetInstances, action } = context;
  let newConfig = deepClone(config);
  // Validate the inputs against the preset schema
  await instance.template.schema.parse(instance.input);

  // complete input if required (eg fix field keys at first submit)
  if (instance.template.completeInput) {
    instance.template.completeInput(instance.input);
  }

  // generate/update action if needed
  if (instance.template.generateAction && instance.input.action) {
    let newAction = await instance.template.generateAction(
      action,
      instance,
      context
    );
    // Apply alterations from other presets
    for (const instanceKey of Object.keys(presetInstances)) {
      if (instanceKey !== instance.key) {
        const otherInstance = presetInstances[instanceKey];
        if (otherInstance.template.alterAction) {
          newAction = await otherInstance.template.alterAction(
            newAction,
            otherInstance,
            context
          );
        }
      }
    }
    // If context.action is not defined, this means the action needs to be created.
    // As such, it cannot override an existing action.
    if (!action && newConfig.actions[newAction.key]) {
      throw new Error(`Action "${newAction.key}" already exists`);
    }
    // If the action key is changed, we need to make sure that the previous
    // action key does not exist anymore, so we remove it from the list of actions
    if (action?.key) delete newConfig.actions[action.key];
    // We can then add the new action to the list of actions
    newConfig.actions[newAction.key] = newAction;
  }

  // alter other actions if needed
  if (instance.template.alterAction) {
    for (const actionKey of Object.keys(config.actions)) {
      if (actionKey !== instance.key) {
        newConfig.actions[actionKey] = await instance.template.alterAction(
          config.actions[actionKey],
          instance,
          context
        );
      }
    }
  }

  // alter config if needed
  if (instance.template.alterConfig) {
    newConfig = await instance.template.alterConfig(
      newConfig,
      instance,
      context
    );
  }

  return newConfig;
};

// Common preset utilities

// To integrate generated forms in an idempotent way, we need a way
// to merge form schemas (JSON schema and UI schema) together
export const mergeFormSchemas = (
  form1: Form | undefined,
  form2: Form
): Form => {
  const form: Form = {
    ...(form1 || {
      schema: { type: 'object' },
      uiSchema: {}
    })
  };

  // Merge form JSON schemas using json-schema-merge-allof
  // It should throw if form1.schema and form2.schema are incompatible

  // Currently, the merged schema (without allOf) is saved, but
  // if this becomes problematic, we can look into saving the unmerged schemas
  // stitched with allOf, and only using json-schema-merge-allof for validation.
  if (form2?.schema) {
    form.schema = mergeAllOf(
      {
        type: 'object',
        allOf: [form.schema || { type: 'object' }, form2.schema]
      },
      {
        ignoreAdditionalProperties: true
      }
    );
  }

  // Merge form UI schemas
  if (form2?.uiSchema) {
    const uiOrder: string[] = (form.uiSchema?.['ui:order'] as any) || [];
    const fieldSet = new Set(uiOrder);
    form.uiSchema = mergeWith(
      form.uiSchema || {},
      form2.uiSchema,
      (val, src, key) => {
        // Only append ui:order entries if they don't exist yet.
        // For now, we only append non-existing entries (i.e. no prepending),
        // we can implement declarative order-based merging in the future
        // if necessary.
        if (key === 'ui:order') {
          const res = val || [];
          src.forEach((fieldName: string) => {
            if (!fieldSet.has(fieldName)) {
              res.push(fieldName);
            }
          });
          return res;
        }
        // If customizer returns undefined, merging is handled by the method instead
        return undefined;
      }
    );
  }

  return form;
};

// We assume that elements from the same preset instance
// are going to follow one another and be appended if they don't exist yet.

// If there is an issue due to the order of the elements, it can
// be fixed by manually moving the elements around in the code editor:
// as long as the preset instance elements follow one another, the following logic
// is still going to work.

// Try to find an element with $preset === instance.key:
// if it's found, remove all elements that have the same $preset
// and append t2 from that point.
// If it's not found, just append t2 to the current elements.
const upsertInPlace = <T extends { $preset?: string | null }>(
  t1: T[] | null | undefined,
  t2: T[]
): T[] => {
  if (!t2.length) return t1 || [];
  const instanceKey = t2[0].$preset;

  if (!instanceKey) {
    throw new Error(
      'You need to flag preset generated elements with `$preset: instance.key`'
    );
  }
  let index = -1;
  const elements = (t1 || []).filter(({ $preset }, i) => {
    if ($preset === instanceKey) {
      if (index === -1) {
        index = i;
      }
      return false;
    }
    return true;
  });
  if (index === -1) {
    elements.push(...t2);
  } else {
    elements.splice(index, 0, ...t2);
  }
  return elements;
};

// To integrate generated action effects in an idempotent way,
// we add $preset: instanceKey flags to statements and leverage these
// to come up with a merging strategy for effects.
export const mergeEffects = (
  effects1: StatementWithPreset[] | undefined,
  effects2: StatementWithPreset[]
): StatementWithPreset[] => upsertInPlace(effects1, effects2);

// Merging trace info configuration and overview configuration

// Many parts of these configurations are declared through arrays,
// and the order of the elements within them may determine their place in the display as well.

// The most important thing that we do not want is inserting the same field twice,
// so we mark array elements from the preset with $preset: instance.key.
// As long as that $preset is kept, the object can be moved around within the array
// in the code editor for now.

export const mergeTraceInfo = (
  info1: TraceInfo | undefined,
  info2: TraceInfo
): TraceInfo => {
  if (!info1) return info2;
  const info = deepClone(info1);
  // Merge box sections
  info2.view.sections.forEach(section2 => {
    const section1 = info.view.sections.find(s => s.key === section2.key);
    if (section1) {
      // If the box section already exists in info1, merge window items inside it
      section1.view.items = upsertInPlace(
        section1.view.items,
        section2.view.items
      );
    } else {
      // Otherwise, append a new section
      info.view.sections.push(section2);
    }
  });
  return info;
};

export const mergeOverview = (
  overview1: TableConfig | undefined,
  overview2: TableConfig
): TableConfig => {
  if (!overview1) return overview2;
  const overview = deepClone(overview1);
  // For groups, $preset is the unique identifier
  if (overview2.groups) {
    overview.groups = upsertInPlace(overview.groups, overview2.groups);
  }
  // For columns, key is the unique identifier, but we have to
  // make sure that the element(s) are not in either column already.
  if (overview2.columns) {
    if (!overview.columns) overview.columns = [];
    overview2.columns.forEach(column => {
      const hasKey = ({ key }) => key === column.key;
      const existingColumn =
        overview.columns?.find(hasKey) || overview.fixedColumns?.find(hasKey);
      if (!existingColumn) {
        overview.columns?.push(column);
      } else {
        mergeWith(existingColumn, column);
      }
    });
  }
  if (overview2.fixedColumns) {
    if (!overview.fixedColumns) overview.fixedColumns = [];
    overview2.fixedColumns.forEach(column => {
      const hasKey = ({ key }) => key === column.key;
      const existingColumn =
        overview.columns?.find(hasKey) || overview.fixedColumns?.find(hasKey);
      if (!existingColumn) {
        overview.fixedColumns?.push(column);
      } else {
        mergeWith(existingColumn, column);
      }
    });
  }
  return mergeWith(overview, overview2, (val1, val2, key) => {
    if (['groups', 'columns', 'fixedColumns'].includes(key)) return val1;
    return undefined;
  });
};

export const mergeTransitions = (
  transitions1: WorkflowTransitions | undefined,
  transitions2: WorkflowTransitions
): WorkflowTransitions => {
  if (!transitions1) return transitions2;
  return mergeWith(deepClone(transitions1), transitions2, (val1, val2) => {
    // Handle arrays of { groups: '*' | string[], condition? }
    if (Array.isArray(val1)) {
      const itemMap = new Map();
      val1.forEach(item => itemMap.set(JSON.stringify(item), item));
      val2.forEach(item => itemMap.set(JSON.stringify(item), item));
      return Array.from(itemMap.values());
    }
    return undefined;
  });
};
