import { FC, useState, useContext, useCallback, memo, useMemo } from 'react';

import { DownArrowFill, Trash } from '@stratumn/icons';
import {
  FieldSelectCompact,
  OptionDrop,
  Check,
  Pushtext,
  UiconCirclePlusFill
} from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';
import { EventChangeHandler } from 'utils/types';
import { DataUpdateType } from 'utils/data';

import { Transition } from '../../types';
import { TransitionsContext } from '../../context';

import { FieldsRow } from './fieldsRow';

import useStyles from './transitionCard.style';

interface Props {
  path: string;
  nextActionSelected?: Transition;
  allTransitions: Transition[];
  groupsCollapsed: boolean;
}

export const TransitionCard: FC<Props> = ({
  path,
  nextActionSelected,
  allTransitions,
  groupsCollapsed
}) => {
  const classes = useStyles();

  const { append, update, set } = useContext(DataContext);
  const { actions, groups, formIsValid } = useContext(TransitionsContext);

  // TODO: placeholder for togglePriority
  const [togglePriority, setTogglePriority] = useState<boolean>(false);

  const handleSelectTransition: EventChangeHandler = useCallback(
    e => {
      set(path, {
        key: e.target.value,
        groups: nextActionSelected?.groups
      });
    },
    [nextActionSelected?.groups, path, set]
  );

  const handleAddTransitionGroup = useCallback((): void => {
    append(`${path}.groups`, { label: '' });
  }, [append, path]);

  const handleRemoveNextAction = useCallback((): void => {
    const revertMessage = 'You successfully deleted the transition';

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, path]);

  // TODO: Handle priority action
  const handlePriorityAction = useCallback(
    () => setTogglePriority(!togglePriority),
    [togglePriority]
  );

  const findGroupName = useCallback(
    (label: string): string => {
      const group = groups!.find(group => group.label === label);
      return group?.name || 'variable';
    },
    [groups]
  );

  const handleGroupsCollpased = useCallback(
    () => set(`${path}.groupsCollapsed`, !groupsCollapsed),
    [path, set, groupsCollapsed]
  );

  // remove duplicated groups' labels
  const filteredGroups = useMemo(
    () =>
      (nextActionSelected?.groups || []).reduce((acc, curr) => {
        if (!acc.find(obj => obj.label === curr.label)) {
          acc.push(curr);
        }
        return acc;
      }, []),
    [nextActionSelected]
  );

  const renderAddedGroupsList = useMemo(
    () => (
      <div className={classes.groupsListWrapper}>
        <h2 className={classes.groupsListTitle}>Added groups</h2>
        <ul
          className={classes.groupsList}
          title={filteredGroups.map(group => findGroupName(group.label))}
        >
          {filteredGroups.map(
            (group, index) =>
              // TODO: display placeholder for dynamic groups
              group.label && (
                <li key={index} className={classes.groupLabel}>
                  {findGroupName(group.label)}
                </li>
              )
          )}
        </ul>
      </div>
    ),
    [
      classes.groupLabel,
      classes.groupsList,
      classes.groupsListTitle,
      classes.groupsListWrapper,
      findGroupName,
      filteredGroups
    ]
  );

  const isTransitionDuplicated: boolean = useMemo(
    () =>
      allTransitions.filter(
        (transition: Transition) => transition.key === nextActionSelected?.key
      ).length > 1,
    [allTransitions, nextActionSelected]
  );

  return (
    <div data-cy="next-action-card" className={classes.root}>
      <div className={classes.card}>
        <div className={classes.cardWrapper}>
          <div className={classes.fieldSelectCompactWrapper}>
            <FieldSelectCompact
              data-cy="select-transition"
              onValueChange={handleSelectTransition}
              value={nextActionSelected?.key}
              label="Next action"
              invalid={isTransitionDuplicated && !formIsValid}
            >
              {/** the actions are the transitions which can be selected */}
              {actions!.map((action, index) => (
                <OptionDrop
                  key={index}
                  label={action.title}
                  value={action.key}
                  selected={action.key === nextActionSelected?.key}
                />
              ))}
            </FieldSelectCompact>
          </div>
          {nextActionSelected?.groups?.length > 0 && renderAddedGroupsList}
          <div className={classes.ctaButtonsWrapper}>
            <div className={classes.checkWrapper}>
              <Check
                className={classes.checkWrapper}
                label="Priority action"
                checked={togglePriority}
                showLabel
                largeLabel
                handleChange={handlePriorityAction}
              />
            </div>

            <div className={classes.setGroupsBtn}>
              <Pushtext
                dataCy="toggle-transition-group"
                onClick={handleGroupsCollpased}
                // disable the transition groups section if no transition has been selected
                disabled={!nextActionSelected?.key}
                suffix={
                  <DownArrowFill
                    className={classes.setGroupsDownArrow}
                    data-is-active={!nextActionSelected?.collapsedGroups}
                  />
                }
              >
                Set groups ({nextActionSelected?.groups?.length || 0})
              </Pushtext>
            </div>
          </div>
        </div>
        {!groupsCollapsed && (
          <div data-cy="transition-group-row">
            {nextActionSelected?.groups?.map(({ label, condition }, index) => (
              <div key={index} className={classes.fieldsRowWrapper}>
                <FieldsRow
                  path={`${path}.groups.${index}`}
                  groupSelected={label}
                  conditionSelected={condition}
                  allGroupsSelected={nextActionSelected?.groups}
                />
              </div>
            ))}

            <div className={classes.addGroup}>
              <Pushtext
                dataCy="add-transition-group"
                onClick={handleAddTransitionGroup}
                prefix={<UiconCirclePlusFill size={25} />}
              >
                Add Group
              </Pushtext>
            </div>
          </div>
        )}
      </div>
      <button
        data-cy="remove-transition"
        className={classes.removeBtn}
        onClick={handleRemoveNextAction}
      >
        <Trash className={classes.trashIcon} />
      </button>
    </div>
  );
};

export default memo(TransitionCard);
