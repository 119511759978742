import { ReactNode, FC, memo, useState, useCallback, useContext } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import generateId from 'utils/id';
import { DataContext } from 'utils/hooks';

import { EventChangeHandler, KeyboardEventHandler } from 'utils/types';

import { useValidation } from 'schemaBuilder/modules/action/hooks/validator';

import { DraggableType } from '../interfaces';

import List from './list';

import { InputWithIcon } from '../inputWithIcon';

import useStyles from './optionsList.style';

interface Props {
  listType: DraggableType;
  options?: { key: string; value?: string }[];
  path: string;
  icon?: ReactNode;
  placeholder?: string;
  disabled: boolean;
}

// generic editable / sortable list of options
export const OptionsList: FC<Props> = ({
  listType,
  options = [],
  path,
  icon,
  placeholder,
  disabled
}) => {
  const validation = useValidation();
  const classes = useStyles();

  const [newOption, setNewOption] = useState('');

  const { append } = useContext(DataContext);

  const addOption = useCallback((): void => {
    if (newOption === '') return;
    append(path, { key: generateId(), value: newOption });
    setNewOption('');
  }, [newOption, path, append]);

  const handleNewOptionValue: EventChangeHandler = useCallback(
    e => setNewOption(e.target.value),
    []
  );
  const handleNewOptionKeyPress: KeyboardEventHandler = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault(); // We want to addOption and not submit the form
        addOption();
      }
    },
    [addOption]
  );

  return (
    <>
      <Droppable droppableId={path} type={listType}>
        {providedDroppable => (
          <div
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            <List
              options={options}
              path={path}
              icon={icon}
              disabled={disabled ?? false}
            />
            {providedDroppable.placeholder}
            {!disabled && (
              <div className={classes.newOption}>
                <InputWithIcon
                  data-cy="add-option"
                  icon={
                    icon || (
                      <span className={classes.newOptionDefaultIcon}>-</span>
                    )
                  }
                  value={newOption}
                  placeholder={placeholder || 'Add option'}
                  onChange={handleNewOptionValue}
                  onKeyPress={handleNewOptionKeyPress}
                  onBlur={addOption}
                  disabled={disabled}
                  invalid={validation.hasErrors(`${path}.length`)}
                />
              </div>
            )}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default memo(OptionsList);
