import { TRANSITIVE } from 'utils/trace';
import { FromActionDefinition, ConditionEnums } from '../types';

type ValidateNextActions = { isValid: boolean; message?: string[] };

const hasMissingTransitionKey = (transitions, idx) =>
  transitions[idx].nextActions.some(transition => transition.key === undefined);

const hasDuplicatedGroup = (transitions, idx) =>
  transitions[idx].nextActions.some(transition => {
    if (Object.values(transition).includes(TRANSITIVE)) return false;

    const groupsLabelArr = transition.groups?.map(gp => gp.label);

    return groupsLabelArr?.length !== new Set(groupsLabelArr).size;
  });

const hasDuplicatedTransition = keys => keys.length !== new Set(keys).size;

export const conditionIsInvalid = (transitions, idx) => {
  let conditionInvalid: boolean = false;

  transitions[idx].nextActions!.forEach(transition =>
    transition.groups?.map(group => {
      /**
       * if a condition has a path, is of type different than isTruthy and has no values
       */
      if (
        group.condition?.path &&
        (group.condition?.value === '' ||
          group.condition?.value === undefined) &&
        group.condition?.type !== ConditionEnums.IsTruthy
      ) {
        conditionInvalid = true;
      }

      /**
       * if a condition is of type different than isTruthy and has no path and no values
       */
      if (
        group.condition?.type === ConditionEnums.Equal ||
        group.condition?.type === ConditionEnums.GreaterThan ||
        group.condition?.type === ConditionEnums.LessThan
      ) {
        const set = new Set(Object.values(group.condition));

        if (set.has('') || set.has(undefined)) conditionInvalid = true;
      }

      /**
       * if a condition is of type "isTruthy" and has no path
       */
      if (
        group.condition?.type === ConditionEnums.IsTruthy &&
        !group.condition?.path
      ) {
        conditionInvalid = true;
      }

      return conditionInvalid;
    })
  );
  return conditionInvalid;
};

export function validateNextActions(
  nextActions: FromActionDefinition[]
): ValidateNextActions {
  const res: ValidateNextActions = {
    isValid: true,
    message: []
  };
  for (let i = 0; i < nextActions.length; i += 1) {
    const nextActionsKeys = nextActions[i].nextActions.map(
      transition => transition.key
    );

    // an action has an empty string transition key
    if (hasMissingTransitionKey(nextActions, i)) {
      res.isValid = false;
      res.message!.push('empty transition row within an action');
    }

    // an action has a duplicated transition.
    if (hasDuplicatedTransition(nextActionsKeys)) {
      res.isValid = false;
      res.message!.push('duplicated transition key within an action');
    }

    // a transition has a duplicated group
    if (hasDuplicatedGroup(nextActions, i)) {
      res.isValid = false;
      res.message!.push('duplicated group within a transition');
    }

    // a transition group's condition is incomplete
    if (conditionIsInvalid(nextActions, i)) {
      res.isValid = false;
      res.message!.push('invalid group condition');
    }
  }
  return res;
}
