import { ZodError } from 'zod';

const pluralExceptions = {
  status: 'statuses'
};
export const pluralize = (count: number | undefined, word: string) =>
  `${count === 0 ? 'No' : count} ${
    count !== 1 ? pluralExceptions[word] || `${word}s` : word
  }`;

export const capitalize = (str: string) =>
  `${str[0].toUpperCase()}${str.slice(1)}`;

export const camelize = (str: string) =>
  str
    // Capitalize every word except the first one
    // and things that are not in lowercase
    // This also detects already camelized/capitalized stuff
    // by checking for potential uppercase starters
    .replace(/[A-Z]?[a-z0-9]+/g, (word, idx) =>
      idx ? capitalize(word) : word.toLowerCase()
    )
    // Strip all other (non word) characters
    .replace(/\W/g, '');

export const nameToInitials = (name: string) => {
  const regex = /[ ,-]+/; // space, dash or comma
  const nameArr = name.split(regex);
  if (nameArr.length > 1) {
    return nameArr[0].charAt(0) + nameArr[1].charAt(0);
  }
  return name[0];
};

export const zodErrorToString = (error: ZodError) =>
  error.errors
    .map(error => `${error.path.join('.')}: ${error.message} (${error.code})`)
    .join('\n');
