import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef
} from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

import {
  Modal,
  ModalActions,
  Pushbutton,
  Pushtext,
  Dropdown,
  OptionDrop,
  Icon
} from '@stratumn/atomic';

import { DataContext, useDataReducer } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';
import generateId from 'utils/id';
import { onDrop } from 'utils/dragAndDrop';
import { scrollToEndOfRef } from 'utils/scroll';

import { AnyDependenciesDefinition, InputType } from '../../types';
import { validateDependenciesList } from '../../utils';
import { INPUT_OPTIONS_DATA } from '../constants';
import Inputs from '../inputs';
import { FormBuilderContext } from '../../context';
import { PropertiesListContext } from '../../propertiesList/context';

import Dependency from './dependency';

import { DependenciesContext } from './context';

import useStyles from './dependenciesModal.style';

interface Props {
  inputKey: string;
  inputType?: string;
  inputLabel?: string;
  inputDepth?: number;
  initDependencies?: AnyDependenciesDefinition;
  submit: (newDependencies: AnyDependenciesDefinition | null) => void;
  cancel: () => void;
}

// The modal to modify a Property's optional dependencies
export const DependenciesModal: FC<Props> = ({
  inputKey,
  inputType,
  inputLabel,
  inputDepth,
  initDependencies = {},
  submit,
  cancel
}) => {
  const classes = useStyles();
  const conditionsRef = useRef(null);

  // local state to show errors once tried to submit
  const [showErrors, setShowErrors] = useState(false);
  const formBuilderContext = useMemo(
    () => ({
      showErrors
    }),
    [showErrors]
  );

  // we create a local reducer to manipulate a temporary new dependencies object
  // that is submitted only on confirmation from the dedicated button
  const [
    dependencies,
    dependenciesUpdateContext
  ] = useDataReducer<AnyDependenciesDefinition>(initDependencies);

  const { sourceKey = '', conditions = [] } = dependencies;

  const { update, append, set } = dependenciesUpdateContext;

  const onDragEnd = useCallback(
    dndResult => onDrop(dndResult, dependenciesUpdateContext),
    [dependenciesUpdateContext]
  );

  const addCondition = useCallback(() => {
    append('conditions', {
      key: generateId(),
      input: {
        type: inputType
      }
    });
    // scroll to the new condition after it is rendered
    scrollToEndOfRef(conditionsRef);
  }, [append, inputType]);

  const deleteAllConditions = useCallback(() => {
    const revertMessage =
      'All the **conditions** have been successfully deleted';
    update(
      [
        {
          type: DataUpdateType.Delete,
          path: 'conditions'
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update]);

  const collapseAllConditions = useCallback(() => {
    update(
      conditions.map((_, idx) => ({
        type: DataUpdateType.Set,
        path: `conditions.${idx}.collapsed`,
        value: true
      }))
    );
  }, [conditions, update]);

  // build the modal title by finding the relevant option defined in inputSelection component
  const title = useMemo(() => {
    const inputOption = INPUT_OPTIONS_DATA.find(
      option => option.type === inputType
    );
    if (!inputOption) return 'Dependencies';

    return (
      <span>
        Set conditional display for the{' '}
        <span className={classes.titleLabel}>
          {inputLabel || inputOption.label}
        </span>{' '}
        input
      </span>
    );
  }, [classes, inputType, inputLabel]);

  // get the context of properties living at the same level
  // so that we can list the options available for conditioning
  const { properties } = useContext(PropertiesListContext);
  const conditionSourceOptions = useMemo(
    () =>
      properties.reduce((options, property) => {
        const {
          key,
          label,
          input: { type } = { type: InputType.ShortText }
        } = property;
        const InputComponents = Inputs[type] || {};

        // check if property can be the condition source (also remove the property that is being conditioned)
        if (InputComponents.conditions && key !== inputKey) {
          // note: as a consequence of using jsonschema's {dependencies: {oneOf}} to produce dynamic input conditioning
          // a property cannot easily be the condition source for more than one other property
          // a possibility would be, when parsing, to produce the intersection of all the conditions on the same property
          // but that's another story...
          const isPropertyUsedAsSource =
            key !== sourceKey &&
            !!properties.find(
              prop => prop.input?.dependencies?.sourceKey === key
            );
          if (!isPropertyUsedAsSource) {
            options.push(
              <OptionDrop
                key={key}
                label={label || key}
                value={key}
                selected={sourceKey === key}
              />
            );
          }
        }
        return options;
      }, [] as any[]),
    [properties, sourceKey, inputKey]
  );

  const onSetConditionSource = useCallback(
    e => {
      const newSelectedSourceKey = e.target.value;
      if (sourceKey !== newSelectedSourceKey) {
        // conditions were already setup we need to clear them all
        // as they do not make sense anymore with the new source
        if (!conditions.length) {
          set('sourceKey', newSelectedSourceKey);
        } else {
          // clearing them requires to send a reverting message
          const revertMessage =
            'The **condition source** has been successfully changed and all the attached **conditions** deleted';
          update(
            [
              {
                type: DataUpdateType.Set,
                path: '',
                value: { sourceKey: newSelectedSourceKey }
              }
            ],
            { revert: { message: revertMessage } }
          );
        }
      }
    },
    [sourceKey, conditions, set, update]
  );

  // the condition source input passed to all conditions
  const dependenciesSourceInput = useMemo(
    () => properties.find(({ key }) => key === sourceKey)?.input,
    [sourceKey, properties]
  );

  // submit the current dependencies
  const onSubmit = useCallback(() => {
    // validate now
    const errors: string[] = [];
    validateDependenciesList(errors, dependencies, dependenciesSourceInput);
    if (errors.length) {
      // prevent submission and display errors
      setShowErrors(true);
    } else {
      submit(conditions.length ? dependencies : null);
    }
  }, [submit, conditions, dependencies, dependenciesSourceInput]);

  // build errors at the dependencies level
  let errorsStr = '';
  if (showErrors) {
    const errors: string[] = [];
    validateDependenciesList(errors, dependencies, dependenciesSourceInput);
    errorsStr = errors.join(', ');
  }

  return (
    <Modal
      title={title}
      closeButtonLabel="Cancel"
      handleCollapse={cancel}
      hideHeaderBorderBottom
      disableClickOutside
      large
    >
      <FormBuilderContext.Provider value={formBuilderContext}>
        <DataContext.Provider value={dependenciesUpdateContext}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={classes.modalContent}>
              <div className={classes.conditionsHeader}>
                <div className={classes.conditionsHeaderLeft}>
                  <div className={classes.conditionsSource}>
                    {!conditionSourceOptions.length ? (
                      <div className={classes.noConditionSourceMsg}>
                        No input available as condition source
                      </div>
                    ) : (
                      <Dropdown
                        label="Condition source"
                        onValueChange={onSetConditionSource}
                        value={sourceKey}
                        hideLabel
                        dataCy="condition-source"
                      >
                        {conditionSourceOptions}
                      </Dropdown>
                    )}
                  </div>
                </div>
                <div className={classes.conditionsHeaderRight}>
                  <div className={classes.deleteAllBtn}>
                    <Pushtext
                      dataCy="delete-all"
                      onClick={deleteAllConditions}
                      disabled={!conditions.length}
                    >
                      Delete all
                    </Pushtext>
                  </div>
                  <div className={classes.collapseAllBtn}>
                    <Pushtext
                      dataCy="collapse-all"
                      onClick={collapseAllConditions}
                      disabled={!conditions.length}
                    >
                      Collapse all
                    </Pushtext>
                  </div>
                </div>
              </div>
              <DependenciesContext.Provider value={dependencies}>
                <div
                  className={classes.conditions}
                  ref={conditionsRef}
                  data-cy="conditions"
                >
                  {conditions.map((dependency, index) => (
                    <Dependency
                      key={dependency.key}
                      path={`conditions.${index}`}
                      dependency={dependency}
                      dependencySourceInput={dependenciesSourceInput}
                      inputDepth={inputDepth}
                    />
                  ))}
                </div>
              </DependenciesContext.Provider>
              <div className={classes.conditionsFooter}>
                <div className={classes.addConditionBtn}>
                  <Pushtext
                    onClick={addCondition}
                    prefix={<Icon name="CirclePlusFill" size={25} />}
                    dataCy="add-condition"
                    disabled={!sourceKey}
                  >
                    Add condition
                  </Pushtext>
                </div>
              </div>
            </div>
          </DragDropContext>
        </DataContext.Provider>
      </FormBuilderContext.Provider>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={cancel} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton
          primary
          onClick={onSubmit}
          disabled={!!errorsStr || !conditionSourceOptions.length}
          dataCy="submit-button"
        >
          save conditions
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default React.memo(DependenciesModal);
