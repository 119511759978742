import { ChangeEvent, useContext, useCallback } from 'react';
import { BaseSchemaField } from 'schemaBuilder/types';
import { DataUpdateType } from 'utils/data';
import { DataContext } from 'utils/hooks';
import { camelize } from 'utils/strings';

interface SetLabelInput {
  schemaFieldPath: string;
  schemaField: BaseSchemaField;
}

export const useSetLabelCallback = ({
  schemaFieldPath,
  schemaField
}: SetLabelInput) => {
  const { update } = useContext(DataContext);
  return useCallback(
    (e: ChangeEvent<any>) =>
      update([
        {
          type: DataUpdateType.Set,
          path: `${schemaFieldPath}.label`,
          value: e.target.value
        },
        // We always update the provisional key so we have something
        // to fall back to if the user deletes the field key
        {
          type: DataUpdateType.Set,
          path: `${schemaFieldPath}.provisionalKey`,
          value: camelize(e.target.value) || schemaField.id
        }
      ]),
    [schemaFieldPath, update, schemaField.id]
  );
};

export const useSetCallback = (path: string) => {
  const { set } = useContext(DataContext);
  return useCallback((e: ChangeEvent<any>) => set(path, e.target.value), [
    path,
    set
  ]);
};

export const useToggleCallback = (path: string) => {
  const { toggle } = useContext(DataContext);
  return useCallback(() => toggle(path), [toggle, path]);
};
