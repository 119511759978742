import Body from './body';
import getErrors from './validation';
import conditions from './conditions';

export default {
  header: null,
  body: Body,
  settings: null,
  getErrors,
  conditions
};
