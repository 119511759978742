import { CheckboxesActionField } from 'schemaBuilder/types';
import { ConverterFieldPayload } from 'schemaBuilder/converter/types';
import { ResolvedExpressionType, StatementType } from '@stratumn/dsl';
import { key } from '../utils';

export default (field: CheckboxesActionField): ConverterFieldPayload => ({
  jsonSchema: {
    type: 'object',
    properties: {
      [key(field)]: {
        type: 'array',
        uniqueItems: true,
        title: field.schemaField.label,
        items: {
          type: 'string',
          enum: field.schemaField.enumNames.map(({ value }) => value)
        }
      }
    },
    required: field.required ? [key(field)] : undefined
  },
  uiSchema: {
    'ui:order': [key(field)],
    [key(field)]: {
      'ui:options': {
        widget: 'checkboxes',
        label: true,
        inline: false
      }
    }
  },
  effects: [
    {
      $tags: ['schemaBuilder'],
      $statement: StatementType.SetVariable,
      path: `state.data.${key(field)}`,
      value: {
        $expression: ResolvedExpressionType.Variable,
        query: `formData.${key(field)}`
      }
    }
  ]
});
