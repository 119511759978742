import { fileSchema } from 'utils/forms';

import {
  FormDefinition,
  PropertyDefinition,
  InputDefinition,
  ShortTextInputDefinition,
  NumberInputDefinition,
  DateInputDefinition,
  BooleanInputDefinition,
  RichTextInputDefinition,
  CheckboxesInputDefinition,
  DropdownInputDefinition,
  FilesUploadInputDefinition,
  SubFormInputDefinition,
  ListInputDefinition,
  InputType,
  TextareaInputDefinition,
  RadioButtonsInputDefinition,
  GroupSelectionInputDefinition,
  HelperInputDefinition,
  ConditionDefinition,
  DropdownInputConditionDefinition,
  GroupSelectionInputConditionDefinition
} from '../../types';

import { RJSF_UI_OPTIONS, RJSF_UI_FIELD, RJSF_UI_HELP } from '../constants';

import {
  isObjectEmpty,
  getPropertyKeyFromDefinition,
  getNonNullOptionsListValues
} from './utils';

interface ParsingOutput {
  schema: any;
  uiSchema: any;
}

// generate an object schema / uiSchema from its list of properties
// it will be used as a base for
// 1 - the main form
// 2 - the subForm input
// 3 - the list input's items
const buildObjectSchemas = (
  properties: PropertyDefinition[]
): ParsingOutput => {
  const schema = {
    type: 'object',
    properties: {},
    required: [] as string[]
  };
  const uiSchema = {
    [RJSF_UI_OPTIONS]: {
      order: [] as string[]
    }
  };

  // parse all the form properties
  properties.forEach((property, idx) => {
    const { label, isRequired, input } = property;

    // build the prop key form label (or shorid if label not set)
    const propKey = getPropertyKeyFromDefinition(property);

    // check if a valid dependency has been setup
    // valid means pointing at a condition source that exists
    let conditionsSource;
    if (input?.dependencies) {
      const { sourceKey } = input.dependencies;
      conditionsSource = properties.find(prop => prop.key === sourceKey);
    }

    // create either conditional or unconditional property
    if (conditionsSource) {
      addConditionalPropertySchemas(
        schema,
        uiSchema,
        propKey,
        input as InputDefinition,
        conditionsSource,
        label
      );
    } else {
      addPropertySchemas(schema, uiSchema, propKey, input, label, isRequired);
    }

    uiSchema[RJSF_UI_OPTIONS].order.push(propKey);
  });
  // note: looks like atomic/forms does not handle uiSchema['ui:options'].order properly
  // instead need to use uiSchema['ui:order']
  // TODO: remove this line when atomic/forms is fixed re ui:options
  uiSchema['ui:order'] = uiSchema[RJSF_UI_OPTIONS].order;

  return {
    schema,
    uiSchema
  };
};

const addPropertySchemas = (
  schema: any,
  uiSchema: any,
  propKey: string,
  propInput?: InputDefinition,
  propLabel?: string,
  propIsRequired?: boolean
) => {
  // get the input specific schemas
  const { schema: propSchema, uiSchema: propUiSchema } = getInputSchemas(
    propInput
  );

  // complete with generic info
  if (!propSchema.title) {
    propSchema.title = propLabel; // title might be overloaded by helper field for example
  }
  propUiSchema[RJSF_UI_HELP] = propInput?.settings?.helper; // note: looks like forms js supports only ui:help and not ui:options.help...

  if (isObjectEmpty(propUiSchema[RJSF_UI_OPTIONS])) {
    delete propUiSchema[RJSF_UI_OPTIONS];
  }

  // set the prop parameters at the right places
  schema.properties[propKey] = propSchema;
  if (propIsRequired) schema.required.push(propKey);

  if (!isObjectEmpty(propUiSchema)) {
    uiSchema[propKey] = propUiSchema;
  }
};

const addConditionalPropertySchemas = (
  schema: any,
  uiSchema: any,
  propKey: string,
  propInput: InputDefinition,
  conditionsSource: PropertyDefinition,
  propLabel?: string
) => {
  const conditionsSourcePropKey = getPropertyKeyFromDefinition(
    conditionsSource
  );

  const sourceDependency = {
    oneOf: []
  } as any;

  propInput.dependencies?.conditions?.forEach(dependency => {
    const { condition, isRequired, input: conditionInput } = dependency;

    // get the condition input specific schemas
    // note: the conditionInput "specializes" the propInput, mainly for inputs' "Body" component
    const {
      schema: conditionInputSchema,
      uiSchema: conditionInputUiSchema
    } = getInputSchemas({ ...propInput, ...conditionInput });

    // complete with generic info
    if (!conditionInputSchema.title) {
      conditionInputSchema.title = propLabel; // title might be overloaded by helper field for example
    }
    conditionInputUiSchema[RJSF_UI_HELP] = propInput.settings?.helper;
    if (isObjectEmpty(conditionInputUiSchema[RJSF_UI_OPTIONS])) {
      delete conditionInputUiSchema[RJSF_UI_OPTIONS];
    }

    // get the condition source schema
    const {
      input: conditionSourceInput = { type: InputType.ShortText }
    } = conditionsSource;
    const conditionSourceSchema = getConditionSourceSchema(
      conditionSourceInput,
      condition
    );

    // stop this condition if the source input was unable to produce a condition schema
    if (conditionSourceSchema) {
      // otherwise append this concrete condition instance

      // create the jsonschema dependency
      const dependencySchema = {
        properties: {
          [conditionsSourcePropKey]: conditionSourceSchema,
          [propKey]: conditionInputSchema
        }
      } as any;
      if (isRequired) {
        dependencySchema.required = [propKey];
      }

      sourceDependency.oneOf.push(dependencySchema);
    }

    // TODO: find a way to configure uiSchema per condition...
    // not handled yet by rjsf
    // right now we set it at the first non null uiSchema found across conditions
    if (!isObjectEmpty(conditionInputUiSchema) && !uiSchema[propKey]) {
      uiSchema[propKey] = conditionInputUiSchema;
    }
  });

  if (!schema.dependencies) {
    schema.dependencies = {};
  }
  schema.dependencies[conditionsSourcePropKey] = sourceDependency;
};

export const getInputSchemas = (input?: InputDefinition): ParsingOutput => {
  // route to form inputs' specific setup
  const inputType = input?.type || InputType.ShortText;
  switch (inputType) {
    case InputType.Number:
      return getNumberSchemas(input as NumberInputDefinition);
    case InputType.Date:
      return getDateSchemas(input as DateInputDefinition);
    case InputType.Boolean:
      return getBooleanSchemas(input as BooleanInputDefinition);
    case InputType.Textarea:
      return getTextareaSchemas(input as TextareaInputDefinition);
    case InputType.RichText:
      return getRichTextSchemas(input as RichTextInputDefinition);
    case InputType.Checkboxes:
      return getCheckboxesSchemas(input as CheckboxesInputDefinition);
    case InputType.Dropdown:
      return getDropdownSchemas(input as DropdownInputDefinition);
    case InputType.RadioButtons:
      return getRadioButtonsSchemas(input as RadioButtonsInputDefinition);
    case InputType.FilesUpload:
      return getFilesUploadSchemas(input as FilesUploadInputDefinition);
    case InputType.SubForm:
      return getSubFormSchemas(input as SubFormInputDefinition);
    case InputType.List:
      return getListSchemas(input as ListInputDefinition);
    case InputType.GroupSelection:
      return getGroupSelectionSchemas(input as GroupSelectionInputDefinition);
    case InputType.Helper:
      return getHelperSchemas(input as HelperInputDefinition);
    default:
      // default to short text field schema
      return getShortTextSchemas(input as ShortTextInputDefinition);
  }
};

const getShortTextSchemas = (
  input: ShortTextInputDefinition
): ParsingOutput => ({
  schema: {
    type: 'string'
  },
  uiSchema: {
    [RJSF_UI_OPTIONS]: {
      placeholder: input?.placeholder
    }
  }
});

const getNumberSchemas = (input: NumberInputDefinition): ParsingOutput => {
  const {
    placeholder,
    decimals,
    settings: { minValue, maxValue } = {}
  } = input;

  return {
    schema: {
      type: 'number',
      minimum: minValue,
      maximum: maxValue,
      multipleOf: decimals === undefined ? undefined : Number(`1e-${decimals}`)
    },
    uiSchema: {
      [RJSF_UI_OPTIONS]: {
        placeholder
      }
    }
  };
};

const getDateSchemas = (input: DateInputDefinition): ParsingOutput => ({
  schema: {
    type: 'string',
    format: 'alt-date'
  },
  uiSchema: {}
});

const getBooleanSchemas = (input: BooleanInputDefinition): ParsingOutput => {
  const { trueLabel = 'Yes', falseLabel = 'No' } = input;

  return {
    schema: {
      type: 'boolean',
      enumNames: [trueLabel, falseLabel]
    },
    uiSchema: {
      // note: atomic/forms does not display input title if 'ui:widget' is not set here...
      // we'll need to take a look at the reasons as we don't really want to keep this here
      'ui:widget': 'radio',
      [RJSF_UI_OPTIONS]: {
        widget: 'radio'
      }
    }
  };
};

const getTextareaSchemas = (input: TextareaInputDefinition): ParsingOutput => ({
  schema: {
    type: 'string'
  },
  uiSchema: {
    [RJSF_UI_OPTIONS]: {
      widget: 'textarea',
      placeholder: input.placeholder
    }
  }
});

const getRichTextSchemas = (input: RichTextInputDefinition): ParsingOutput => ({
  schema: {
    type: 'string',
    format: 'draft'
  },
  uiSchema: {
    [RJSF_UI_OPTIONS]: {
      placeholder: input?.placeholder
    }
  }
});

const getCheckboxesSchemas = (
  input: CheckboxesInputDefinition
): ParsingOutput => {
  const { minItems, maxItems, options = [] } = input;

  return {
    schema: {
      type: 'array',
      uniqueItems: true,
      minItems,
      maxItems,
      items: {
        type: 'string',
        enum: options.map(option => option.value)
      }
    },
    uiSchema: {
      [RJSF_UI_OPTIONS]: {
        widget: 'checkboxes',
        label: true,
        inline: false
      }
    }
  };
};

const getDropdownSchemas = (input: DropdownInputDefinition): ParsingOutput => {
  const { options = [] } = input;

  return {
    schema: {
      type: 'string',
      enum: options.map(option => option.value)
    },
    uiSchema: {}
  };
};

const getRadioButtonsSchemas = (
  input: RadioButtonsInputDefinition
): ParsingOutput => {
  const { options = [] } = input;

  return {
    schema: {
      type: 'string',
      enum: options.map(option => option.value)
    },
    uiSchema: {
      // note: atomic/forms does not display input title if 'ui:widget' is not set here...
      // we'll need to take a look at the reasons as we don't really want to keep this here
      'ui:widget': 'radio',
      [RJSF_UI_OPTIONS]: {
        widget: 'radio'
      }
    }
  };
};

const getFilesUploadSchemas = (
  input: FilesUploadInputDefinition
): ParsingOutput => ({
  schema: {
    type: 'array',
    maxItems: input.maxItems,
    items: fileSchema
  },
  uiSchema: {
    [RJSF_UI_FIELD]: 'FileUploadField'
  }
});

const getSubFormSchemas = (input: SubFormInputDefinition): ParsingOutput =>
  buildObjectSchemas(input.properties || []);

const getListSchemas = (input: ListInputDefinition): ParsingOutput => {
  const { schema: itemsSchema, uiSchema: itemsUiSchema } = buildObjectSchemas(
    input.itemsProperties || []
  );
  return {
    schema: {
      type: 'array',
      items: itemsSchema
    },
    uiSchema: {
      items: itemsUiSchema
    }
  };
};

const getGroupSelectionSchemas = (
  input: GroupSelectionInputDefinition
): ParsingOutput => ({
  schema: {
    type: 'string',
    format: 'workflow-group'
  },
  uiSchema: {}
});

// inputs acting as condition source
// get the json schema for those properties that will match the 'oneOf' in dependencies
const getConditionSourceSchema = (
  sourceInput: InputDefinition,
  condition: ConditionDefinition
) => {
  // route to source inputs' specific condition handling
  const sourceInputType = sourceInput.type || InputType.ShortText;
  switch (sourceInputType) {
    case InputType.Dropdown:
      return getDropdownConditionSchema(
        sourceInput as DropdownInputDefinition,
        condition as DropdownInputConditionDefinition
      );
    case InputType.GroupSelection:
      return getGroupSelectionConditionSchema(
        condition as GroupSelectionInputConditionDefinition
      );
    default:
      // default to no condition handling
      return null;
  }
};

const getDropdownConditionSchema = (
  input: DropdownInputDefinition,
  condition: DropdownInputConditionDefinition
) => {
  if (!condition?.values) return null;

  // options available in the dropdown input
  // handle the case where the dropdown input is itself also conditional... :(
  const inputOptions = input.dependencies?.conditions
    ? input.dependencies.conditions.reduce<string[]>(
        (currentOptions, dependency) => {
          const { input: dropdownInput } = dependency;
          if (dropdownInput.options) {
            currentOptions.push(
              ...getNonNullOptionsListValues(dropdownInput.options || [])
            );
          }
          return currentOptions;
        },
        []
      )
    : (input.options || []).map(option => option.value);

  // filter this condition's enums by comparing with available options
  const enumValues = condition.values.filter(value =>
    inputOptions.includes(value)
  );

  return {
    enum: enumValues
  };
};

const getGroupSelectionConditionSchema = (
  condition: GroupSelectionInputConditionDefinition
) => {
  if (!condition?.values) return null;
  return {
    enum: condition.values
  };
};

// A form helper on its own is implemented as a filler field of type null
// which is not expected to get filled by anything
const getHelperSchemas = (input: HelperInputDefinition): ParsingOutput => {
  const { content } = input;
  return {
    schema: { type: 'null', title: content },
    uiSchema: {}
  };
};

// build main form schemas
export default (form: FormDefinition): ParsingOutput => {
  const { title, description, properties } = form;

  // build the main form object schemas
  const { schema, uiSchema } = buildObjectSchemas(properties);

  // decorate it with some ui inputs
  Object.assign(schema, { title, description });

  return { schema, uiSchema };
};
