import { FC, memo, useCallback } from 'react';

import { IconButton } from 'components/workflow/ui';

import theme from 'style';

import useStyles from './tab.style';

interface Props {
  label: string;
  selected: boolean;
  onRemove?: () => void;
  onSelect?: () => void;
}

export const Tab: FC<Props> = ({
  label,
  selected,
  onRemove = () => {},
  onSelect = () => {}
}) => {
  const classes = useStyles();

  const handleOnSelect = useCallback(() => (!selected ? onSelect() : null), [
    onSelect,
    selected
  ]);

  return (
    <li
      data-cy={`tab-${label.toLowerCase().replace(' ', '-')}`}
      className={classes.tabRoot}
      data-is-selected={selected}
      onClick={handleOnSelect}
      aria-label={`Select the tab ${label}`}
    >
      <span className={classes.tabText}>{label}</span>

      <div className={classes.trashIconWrapper}>
        <IconButton
          dataCy="delete"
          name="Trash"
          onClick={onRemove}
          ariaLabel="Delete"
          color={theme.grey1}
        />
      </div>
    </li>
  );
};

export default memo(Tab);
