import background from 'assets/IndigoTrace_Map.svg';

export default (theme: any): any => ({
  loginBG: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: '-1',
    backgroundColor: theme.indigo1,
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '-ms-background-size': 'cover',
    '-o-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-webkit-background-size': 'cover',
    backgroundSize: 'cover'
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    marginTop: 30,
    height: 64,
    width: 'auto'
  },
  punchline: {
    marginTop: 40,
    color: 'white',
    fontWeight: 600
  },
  loginContainer: {
    marginTop: 40,
    backgroundColor: 'white',
    width: 400,
    padding: '20px 60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '4px'
  },
  loginTitle: {
    marginBottom: 20,
    fontWeight: 600
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '14px',
    paddingTop: 2,
    paddingBottom: 2
  },
  accountLogo1: {
    fill: 'white',
    height: 13,
    width: 'auto',
    marginRight: 10
  },
  loginText: {
    margin: '20px 0',
    color: theme.grey3,
    textAlign: 'center',
    fontSize: 14
  },
  signup: {
    backgroundColor: theme.white1,
    color: theme.indigo2,
    border: `1px solid ${theme.indigo2}`,
    padding: '6px 15px',
    '& svg': {
      fill: 'currentColor',
      height: 13,
      width: 'auto',
      marginRight: 10
    },
    '&:hover': {
      color: theme.white1
    }
  },
  footer: {
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
    fontSize: 12,
    '& a': {
      color: 'inherit'
    }
  }
});
