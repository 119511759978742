import { FC, ReactNode, memo, useCallback } from 'react';

import { Modal, ModalActions, Pushbutton, Tabs } from '@stratumn/atomic';

import { DataContext, useDataReducer } from 'utils/hooks';
import { Statuses } from 'utils/interfaces/statuses';

import ProgressBarsTab from './progressBarsTab';

interface Props {
  initSections: Statuses;
  onSubmit: (newSections: Statuses) => void;
  onClose: () => void;
}

export const ProgressBarsModal: FC<Props> = ({
  initSections = {},
  onSubmit,
  onClose
}) => {
  const [sections, sectionsContext] = useDataReducer<any>(initSections);

  const reducedSections: {
    key: string;
    label: string;
    component: ReactNode;
  }[] = sections.reduce((acc, curr, index) => {
    acc.push({
      key: curr.key,
      label: curr.label,
      component: (
        <ProgressBarsTab path={`${index}`} data={curr.statuses || []} />
      )
    });
    return acc;
  }, []);

  const handleOnSubmit = useCallback(() => onSubmit(sections), [
    onSubmit,
    sections
  ]);

  return (
    <Modal
      title="Set progressbars"
      handleCollapse={onClose}
      hideHeaderBorderBottom
    >
      <DataContext.Provider value={sectionsContext}>
        <Tabs tabs={reducedSections} />
      </DataContext.Provider>

      <ModalActions
        adverseAction={
          <Pushbutton dataCy="cancel-progress-bars-modal" onClick={onClose}>
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton
          dataCy="confirm-progress-bars-settings"
          primary
          onClick={handleOnSubmit}
        >
          confirm
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default memo(ProgressBarsModal);
