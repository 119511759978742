import { memo, useContext, useCallback, useRef } from 'react';

import { useDocumentTitle, DataContext } from 'utils/hooks';
import generateId from 'utils/id';
import { TableColumns as TableColumnsDefinition } from 'utils/trace';

import { scrollToEndOfRef } from 'utils/scroll';
import { Section } from 'components/workflow/ui';

import { ViewTypes } from './types';

import { DisplayContext } from '../context';
import parseDisplayToOverview from '../overview/parsing';
import { tab } from '../constant';

import { HeaderButtons } from '../ui';

import ColumnsList from './columnsList';
import FixedColumnsList from './fixedColumnsList';

import useStyles from './overview.style';

const FIXED_COLUMNS = 'fixedColumns';
const COLUMNS = 'columns';

export const defaultFormatOptions: Array<String> = Object.values(ViewTypes);

export const Overview = () => {
  const classes = useStyles();
  const sectionRef = useRef<HTMLDivElement>(null);

  const {
    workflowName,
    overview: { path, data, isNewConfig }
  } = useContext(DisplayContext);

  const { append } = useContext(DataContext);

  useDocumentTitle(workflowName, 'Display Overview');

  const {
    columns,
    fixedColumns
  }: {
    columns: TableColumnsDefinition;
    fixedColumns: TableColumnsDefinition;
  } = data;

  const handleAddColumn = useCallback((): void => {
    append(`${path}.${COLUMNS}`, {
      key: generateId()
    });
    scrollToEndOfRef(sectionRef);
  }, [append, path]);

  return (
    <div className={classes.overviewRoot}>
      <HeaderButtons
        activeTab={tab.OVERVIEW}
        data={parseDisplayToOverview(data, isNewConfig)}
      />
      <Section
        elementRef={sectionRef}
        overviewTab
        title="Columns display"
        path={`${path}.${COLUMNS}`}
        sectionKey=""
        placeholder="Please click on Add column to start configuring the overview table"
        addElementFooter={{
          label: 'Add column',
          callback: handleAddColumn
        }}
      >
        {fixedColumns && fixedColumns.length > 0 && (
          <FixedColumnsList
            path={`${path}.${FIXED_COLUMNS}`}
            columns={fixedColumns}
          />
        )}
        {columns && columns.length > 0 && (
          <ColumnsList path={`${path}.${COLUMNS}`} columns={columns} />
        )}
      </Section>
    </div>
  );
};

export default memo(Overview);
