import { SubFormActionField } from 'schemaBuilder/types';
import { test, enforce } from 'vest';

export default (
  field: SubFormActionField,
  fieldPath: string,
  makeValidateField: any
) => {
  test(
    `${fieldPath}.schemaField.label`,
    `${fieldPath} label is required`,
    () => {
      enforce(field.schemaField.label).isNotEmpty();
    }
  );

  test(`${fieldPath}.schemaField.properties`, `${fieldPath} is invalid`, () => {
    enforce(field.schemaField.label).isNotEmpty();

    const validateField = makeValidateField(
      `${fieldPath}.schemaField.properties`
    );
    field.schemaField.properties.forEach(validateField);
  });
};
