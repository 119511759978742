import { DataContextDefinition } from 'utils/hooks';

export const onDrop = (dndResult: any, context: DataContextDefinition) => {
  if (!dndResult.destination) {
    return;
  }
  const { index: fromIndex, droppableId: fromPath } = dndResult.source;
  const { index: toIndex, droppableId: toPath } = dndResult.destination;
  if (fromPath === toPath && fromIndex === toIndex) {
    return;
  }
  context.move(fromPath, fromIndex, toIndex, toPath);
};
