import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  main: {
    width: '100vw',
    height: 'calc(100vh - 70px)',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  workflows: {
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '0 24px',
    fontSize: 14
  }
}));
