export const AUTH_TOKEN_KEY = 'authToken';
export const LOCAL_STORAGE_TRANSITIONS_KEY = 'transitions_workflow_';
export const LOCAL_STORAGE_TRACE_INFO_KEY = 'trace_info_workflow_';

export const setAuthToken = (token: string) =>
  localStorage.setItem(AUTH_TOKEN_KEY, token);

export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY);

export const parseLocalStorage = (key: string): any | null => {
  const str = localStorage.getItem(key);
  if (!str) return null;
  return JSON.parse(str);
};

export const saveToLocalStorage = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value));
