export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

// -------------------------------------------------
// ------------- Feature flags ---------------------
// -------------------------------------------------

// This will disable the check for super users when someone tries to access
// the admin-ui. Any user will be able to access it.
export const DISABLE_SUPERUSER_CHECK = process.env
  .REACT_APP_DISABLE_SUPERUSER_CHECK
  ? JSON.parse(process.env.REACT_APP_DISABLE_SUPERUSER_CHECK)
  : false;
// This will hide the home naviagtion and route directly to the list of
// workflows that a user has access to. This is intended to hide features
// that should only be accessible to a super user.
export const HIDE_ROOT_NAV = process.env.REACT_APP_HIDE_ROOT_NAV
  ? JSON.parse(process.env.REACT_APP_HIDE_ROOT_NAV)
  : false;
// This will hide the overview config in the display config.
export const HIDE_OVERVIEW_CONFIG = process.env.REACT_APP_HIDE_OVERVIEW_CONFIG
  ? JSON.parse(process.env.REACT_APP_HIDE_OVERVIEW_CONFIG)
  : false;
// This will disable the link to the status config.
export const HIDE_STATUS_CONFIG = process.env.REACT_APP_HIDE_STATUS_CONFIG
  ? JSON.parse(process.env.REACT_APP_HIDE_STATUS_CONFIG)
  : false;
// This will disable the link to the deadlines config.
export const HIDE_DEADLINES_CONFIG = process.env.REACT_APP_HIDE_DEADLINES_CONFIG
  ? JSON.parse(process.env.REACT_APP_HIDE_DEADLINES_CONFIG)
  : false;
// This will hide any presets that are not yet ready to be used by clients
// in the admin-ui.
export const HIDE_UNFINISHED_PRESETS = process.env
  .REACT_APP_HIDE_UNFINISHED_PRESETS
  ? JSON.parse(process.env.REACT_APP_HIDE_UNFINISHED_PRESETS)
  : false;
