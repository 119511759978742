import { Icon } from '@stratumn/atomic';
import useStyles from './index.style';

export function PresetInfo({ preset }) {
  const classes = useStyles();

  if (!preset) return null;

  return (
    <div>
      <div className={classes.header}>
        <Icon name="CircleInfoFill" size={16} className={classes.icon} />
        <h4 className={classes.title}>{preset.label}</h4>
      </div>
      <p className={classes.content}>{preset.description}</p>
    </div>
  );
}
