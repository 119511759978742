import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 22px'
  },
  cardHeaderDescription: {
    color: theme.grey1,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17.75px'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    '&[data-group-duplicated=true]': {
      alignItems: 'baseline'
    }
  },
  selectGroupWrapper: {
    display: 'flex',
    '& button': {
      width: 260
    }
  },
  conditionWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 35
  },
  condition: {
    marginRight: 10,
    marginLeft: 10,
    fonttyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey5,
    textTransform: 'uppercase'
  },
  fieldsWrapper: {
    marginRight: 10,
    display: 'flex',
    flex: 1,
    height: 36,
    justifyContent: 'flex-start'
  },
  inputStateValueWrapper: {
    marginRight: 10,
    display: 'flex',
    flex: 1
  },
  selectConditionWrapper: {
    marginRight: 10,
    display: 'flex',
    flex: 1,
    '& button': {
      minWidth: 'unset'
    }
  },
  inputValueWrapper: {
    display: 'flex',
    flex: 1
  }
}));
