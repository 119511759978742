import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    width: 700
  },
  cardContentWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '15px 10px'
  },
  type: {
    minWidth: 200
  },
  label: {
    gridColumn: '2/4',
    // TODO: remove when atomic text fields updated
    '& input': {
      height: 34
    }
  }
}));
