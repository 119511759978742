import { FC, ReactNode, memo, Children } from 'react';
import { Theme } from 'schemaBuilder/types';

import useStyles from './cardActions.style';

export interface Props {
  children: ReactNode;
  expanded?: boolean;
  theme?: Theme;
}

export const CardActions: FC<Props> = ({
  children,
  expanded = true,
  theme
}) => {
  const classes = useStyles({
    buttonsCount: Children.toArray(children).length,
    theme
  });

  return (
    <div className={classes.cardActionsRoot} aria-expanded={expanded}>
      {children}
    </div>
  );
};

export default memo(CardActions);
