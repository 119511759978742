import { object, infer as InferType } from 'zod';
import { PresetTemplate, actionPresetInputSchema } from 'utils/presets';

const genericInputSchema = object({}).merge(actionPresetInputSchema);

const genericPreset: PresetTemplate<InferType<typeof genericInputSchema>> = {
  key: 'generic',
  name: 'Generic action',
  schema: genericInputSchema,
  defaultValues: {
    action: {
      title: 'Generic'
    }
  },
  generateAction: async (action, { key, input }) => ({
    ...action,
    key,
    title: input.action.title,
    stageName: input.action.title,
    icon: input.action.icon || action?.icon,
    effects: action?.effects || []
  })
};

export default genericPreset;
