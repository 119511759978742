import React, { FC, useCallback, useMemo } from 'react';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton
} from '@stratumn/atomic';

import { DataContext, useDataReducer } from 'utils/hooks';

import { TextField } from '../../fields';
import { InputSettingsDefinition } from '../../types';
import Inputs from '../inputs';
import { INPUT_OPTIONS_DATA } from '../constants';

interface Props {
  inputType?: string;
  initSettings?: InputSettingsDefinition;
  submit: (newSettings: InputSettingsDefinition | null) => void;
  cancel: () => void;
}

// The modal to modify a Property's optional settings
export const SettingsModal: FC<Props> = ({
  inputType,
  initSettings = {},
  submit,
  cancel
}) => {
  // we create a local reducer to manipulate a temporary new settings object
  // that is submitted only on confirmation from the dedicated button
  const [settings, settingsContext] = useDataReducer<InputSettingsDefinition>(
    initSettings
  );

  // build the modal title by finding the relevant option defined in inputSelection component
  const title = useMemo(() => {
    const inputOption = INPUT_OPTIONS_DATA.find(
      option => option.type === inputType
    );
    if (!inputOption) return 'Settings';

    return `${inputOption.label} settings`;
  }, [inputType]);

  // settings specific to the input selected
  const inputSettings = useMemo(() => {
    if (!inputType) return null;

    const InputComponents = Inputs[inputType] || {};

    return InputComponents.settings ? (
      <InputComponents.settings settings={settings} />
    ) : null;
  }, [inputType, settings]);

  // submit the current settings
  const onSubmit = useCallback(() => {
    // check if some settings are not empty
    const hasSettings = Object.values(settings).reduce(
      (prev, value) => prev || value !== undefined,
      false
    );

    submit(hasSettings ? settings : null);
  }, [submit, settings]);

  return (
    <Modal
      title={title}
      closeButtonLabel="Cancel"
      handleCollapse={cancel}
      hideHeaderBorderBottom
    >
      <ModalContent>
        <DataContext.Provider value={settingsContext}>
          <TextField
            label="Helper"
            value={settings.helper}
            path="helper"
            data-cy="settings-helper"
          />
          {inputSettings}
        </DataContext.Provider>
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={cancel} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton primary onClick={onSubmit} dataCy="submit-button">
          confirm
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default React.memo(SettingsModal);
