import { create } from 'vest';
import {
  createContext,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState
} from 'react';

import { DataUpdate } from 'utils/data';

import {
  DataContextConfig,
  DataUpdateOptions,
  DataContainer,
  DataContextDefinition
} from './types';
import { applyDataChange, DataAction, DataActionType } from './reducers';
import { getUpdateDataActions } from './actions';

export * from './types';

// This hook provides a context object that behaves like a
// reducer wired with actions to update the data provided as parameter.
// It is templatized on the type of root data.
// Functions of the context produce a new data object
// so the hook needs a callback function to be provided
// to report the update results to the consumer (eg a setState in a parent component).
// The context is guaranteed to be static
// and only the data path affected by the data updates will mutate.
// Hence optimizing sub-components re-rendering

// export react data context
const voidFn = () => {
  throw new Error('No data context provided.');
};
export const DataContext = createContext<DataContextDefinition>({
  // by default don't react to any update...
  update: voidFn,
  set: voidFn,
  delete: voidFn,
  append: voidFn,
  remove: voidFn,
  move: voidFn,
  splice: voidFn,
  toggle: voidFn,
  merge: voidFn,
  validation: create('void', () => {})()
});

export default <T = any>(
  data: T,
  callback?: (newData: T) => void,
  config?: DataContextConfig
): DataContextDefinition => {
  const [validationIsEnabled, setValidation] = useState(false);
  const dataRef = useRef<DataContainer<T>>({ data, callback });

  // update the data ref each time the input data value changes
  useEffect(() => {
    dataRef.current.data = data;
  }, [data, config, validationIsEnabled]);

  // update the data ref each time the input callback value changes
  useEffect(() => {
    dataRef.current.callback = callback;
  }, [callback]);

  // update the data ref each time the input config value changes
  useEffect(() => {
    dataRef.current.config = config;
  }, [config]);

  // dispatch data change function onto the current data referenced
  const dispatch = useCallback(
    (action: DataAction, options?: DataUpdateOptions) => {
      // get ref current data and callback
      const { data: currentData, callback: currentCallback } = dataRef.current;

      // compute the new updated data given the action requested
      const newData = applyDataChange<T>(currentData, action);

      // we define this small setData function here as it is used at 2 async places in dispatch
      // but we don't want to create a callback for it to spare some registration time
      const setData = value => {
        dataRef.current.data = value; // resyncing now is important if the caller needs to do successive calls to the dispatch
        // run the current callback with the new data
        if (currentCallback) currentCallback(value);
      };

      setData(newData);

      const { revert } = options || {};
      const { confirmationService, validationService } =
        dataRef.current.config || {};
      if (revert && confirmationService) {
        const { message = 'Revert the last action?', timeout } = revert;
        dataRef.current.closeConfirmation = confirmationService({
          message,
          timeout,
          label: 'undo',
          accept: () => {
            setData(currentData);
          }
        });
      }

      // If a validation service has been configured,
      // validate the data on each change.

      // For now, the whole data is validated on each change,
      // but we could validate only the changes if we hook the validation
      // to the data updates themselves, but it might be a bit complicated...
      if (validationIsEnabled && validationService) {
        validationService(newData);
      }
    },
    [validationIsEnabled]
  );

  const enableValidation = useCallback(() => {
    setValidation(true);
    dataRef.current.config!.validationService!(dataRef.current.data);
  }, []);

  // build the context with shorthand updates
  const context = useMemo(() => {
    const update = (updates: DataUpdate[], options?: DataUpdateOptions) =>
      dispatch(
        {
          type: DataActionType.ApplyUpdates,
          payload: {
            updates
          }
        },
        options
      );

    return {
      update,
      enableValidation,
      validation: config?.validationService?.get(),
      ...getUpdateDataActions(update)
    };
  }, [dispatch, config, enableValidation]);

  // register for cleaning up the confirmation service on unmount
  useEffect(() => {
    const currentRef = dataRef.current;
    return () => {
      if (currentRef.closeConfirmation) {
        currentRef.closeConfirmation();
      }
    };
  }, []);

  return context;
};
