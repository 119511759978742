import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  tabRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 64,
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: 180,
    padding: '22px 11px',
    borderLeft: '5px solid transparent',
    backgroundColor: theme.grey7,
    '&[data-is-selected=true]': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      backgroundColor: theme.white1,
      borderLeftColor: theme.teal1,
      boxShadow: '-20px 3px 20px rgba(75, 53, 210, 0.1)',
      '&:hover $trashIconWrapper': {
        display: 'block'
      }
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tabText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    color: theme.grey1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  trashIconWrapper: {
    display: 'none',
    zIndex: 2, // raise above the onSelect callback attached to <li>
    '&:hover > button': {
      color: theme.warningRed
    }
  }
}));
