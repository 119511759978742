import { FC, memo, useCallback, useContext } from 'react';

import { FieldTextCompact, SmartDropdown, Icon } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import { EventChangeHandler } from 'utils/types';
import {
  Condition as ConditionType,
  ConditionEnums
} from 'components/workflow/transitions/types';
import { getTraceStateFieldsUtils } from 'schemaBuilder/converter/admin';

import { TransitionsContext } from '../../../../context';

import useStyles from './condition.style';

export enum DataCy {
  ChangeStateValue = 'change-state-value',
  SelectCondition = 'select-condition',
  ChangeValue = 'change-value'
}

interface Props {
  path: string;
  conditionSelected?: ConditionType;
}

export const Condition: FC<Props> = ({ path, conditionSelected = null }) => {
  const classes = useStyles();

  const { set, delete: deleteCondition } = useContext(DataContext);

  const { formIsValid } = useContext(TransitionsContext);

  const handleChangeConditionValue: EventChangeHandler = useCallback(
    e => set(`${path}.value`, e.target.value),
    [path, set]
  );

  const handleRemoveCondition = useCallback((): void => deleteCondition(path), [
    deleteCondition,
    path
  ]);

  const generatedStateValue: string = conditionSelected?.path
    ? String(conditionSelected?.path)
    : '';

  const generatedValue: string = conditionSelected?.value
    ? String(conditionSelected?.value)
    : '';

  const { traceFieldOptions } = useContext(TransitionsContext);

  const {
    getIdFromPath,
    getTypes,
    getType,
    getPath,
    getOption
  } = getTraceStateFieldsUtils('conditions');

  const availableColumnOptions =
    traceFieldOptions?.filter(o => o.conditions.list.length > 0) ?? [];

  const fieldKey = getIdFromPath(generatedStateValue);
  const availableConditions = getTypes(traceFieldOptions, fieldKey);

  const handleChangeTraceStateValue: any = useCallback(
    option => {
      if (option) {
        const type = getType(option);
        console.log('path ?', getPath(option, type));
        set(`${path}.path`, getPath(option, type));
        set(`${path}.type`, type);
      } else {
        set(`${path}.path`, '');
        set(`${path}.type`, '');
      }
    },
    [path, set, getPath, getType]
  );

  const handleSelectConditionType: any = useCallback(
    value => {
      const option = getOption(traceFieldOptions, fieldKey);
      if (option) {
        console.log('path ?', getPath(option, value));
        set(`${path}.path`, getPath(option, value));
        set(`${path}.type`, value);
      }
    },
    [path, set, getOption, traceFieldOptions, fieldKey, getPath]
  );

  return (
    <div className={classes.root}>
      <div className={classes.inputStateValueWrapper}>
        <SmartDropdown
          dataCy={DataCy.ChangeStateValue}
          value={fieldKey}
          label="Trace state field"
          placeholder="Search for a trace state field"
          invalid={!generatedStateValue && !formIsValid}
          compact
          options={availableColumnOptions?.map(t => ({
            label: t.label,
            value: t.key
          }))}
          onSelect={selected => {
            handleChangeTraceStateValue(getOption(traceFieldOptions, selected));
          }}
        />
      </div>

      <div className={classes.selectConditionWrapper}>
        <SmartDropdown
          compact
          label="Condition"
          value={conditionSelected?.type}
          options={availableConditions!.map(value => ({
            label: value,
            value
          }))}
          onSelect={handleSelectConditionType}
          dataCy={DataCy.SelectCondition}
          invalid={!conditionSelected?.type && !formIsValid}
        />
      </div>
      {conditionSelected?.type !== ConditionEnums.IsTruthy && (
        <div className={classes.inputValueWrapper}>
          <FieldTextCompact
            dataCy={DataCy.ChangeValue}
            onValueChange={handleChangeConditionValue}
            value={generatedValue}
            label="Value"
            invalid={!generatedValue && !formIsValid}
          />
        </div>
      )}

      <div
        className={classes.action}
        onClick={handleRemoveCondition}
        data-cy="remove-condition"
      >
        <Icon name="Trash" size={20} />
        <span className={classes.actionText}>Delete condition</span>
      </div>
    </div>
  );
};

export default memo(Condition);
