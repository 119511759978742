import React from 'react';
import theme from 'style';
import { FormikProps, FieldArray } from 'formik';
import { PresetInstance } from 'utils/presets';
import { Icon } from '@stratumn/atomic';
import { Panel } from 'components/workflow/ui';
import { FormikTextField } from 'components/formik';

import ValidationValue from './value';
import { ValidationInput } from '.';

const ValidationForm: React.FC<
  FormikProps<PresetInstance<ValidationInput>>
> = ({ values }) => (
  <>
    <Panel title="Settings">
      <FormikTextField
        data-cy="assessment-field-title"
        name="input.assessmentFieldTitle"
        label="Name of the assessment form field"
      />
      <FormikTextField
        data-cy="reason-field-title"
        name="input.reasonFieldTitle"
        label="Name of the reason form field"
      />
      <FormikTextField
        data-cy="reason-comment-title"
        name="input.reasonCommentTitle"
        label="Name of the reason comment field"
      />
    </Panel>
    <Panel title="Values">
      <FieldArray name="input.assessments">
        {({ remove, push }) => (
          <>
            {values.input.assessments.map((assessment, i) => (
              <ValidationValue
                key={`input.assessments.${i}`}
                onRemove={() => remove(i)}
                name={`input.assessments.${i}`}
                value={assessment}
              />
            ))}
            <button
              data-cy="add-validation-value"
              type="button"
              onClick={() =>
                push({
                  title: '',
                  reasons: [],
                  enableComment: false
                })
              }
            >
              <Icon color={theme.indigo2} name="CirclePlusFill" size={30} />
            </button>
          </>
        )}
      </FieldArray>
    </Panel>
  </>
);

export default ValidationForm;
