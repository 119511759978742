import React, {
  FC,
  ChangeEvent,
  KeyboardEvent,
  memo,
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect
} from 'react';

import { useOnClickOutside } from '@stratumn/atomic';
import { DataContext } from 'utils/hooks';

import useStyles from './titleFieldText.style';

interface Props {
  title: string;
  path: string;
  onDoneEditing: () => void;
}

export const TitleFieldText: FC<Props> = ({ title, path, onDoneEditing }) => {
  const { set } = useContext(DataContext);

  const [value, setValue] = useState(title || '');
  const [inputWidth, setInputWidth] = useState('');

  const classes = useStyles({ inputWidth });

  const inputRef: any = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInputWidth(`${value.length + 1}ch`);
    // We only want to calculate the width on the first mount
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback((): void => {
    set(`${path}.title`, value || title);
    onDoneEditing();
  }, [set, path, value, title, onDoneEditing]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') onSubmit();
    },
    [onSubmit]
  );

  const handleInputOnChange = useCallback(
    (e: ChangeEvent<any>) => {
      if (value.length > 0) {
        setInputWidth(`100%`);
      }
      setValue(e.target.value);
    },
    [value]
  );

  const handleBlur = useCallback((): void => {
    onSubmit();
  }, [onSubmit]);

  useOnClickOutside(inputRef, onSubmit);

  return (
    <input
      data-cy="edit-title"
      className={classes.titleFieldText}
      type="text"
      placeholder={title}
      ref={inputRef}
      value={value}
      onChange={handleInputOnChange}
      onKeyPress={handleKeyPress}
      onBlur={handleBlur}
      autoFocus
    />
  );
};

export default memo(TitleFieldText);
