import {
  object,
  array,
  string,
  infer as InferType,
  union,
  literal,
  boolean
} from 'zod';
import { StatementType, ResolvedExpressionType } from '@stratumn/dsl';

import {
  PresetTemplate,
  actionPresetInputSchema,
  mergeFormSchemas,
  mergeEffects,
  StatementWithPreset
} from 'utils/presets';
import { fileSchema } from 'utils/forms';
import {
  DisplayWidths,
  TableConfig,
  tableCellWidthSchema,
  TextView,
  NumberView,
  DateView,
  IconView
} from 'utils/traceWidgets';

import addComments from '../comment/addComments';

// TODO: Enable the mapping configuration to be linked with an edit data
// preset. The goal is to be able to edit a table that was imported through
// a import data preset with an action generated through the edit data preset.

// TODO: Support aggregation
// The mapping below assumes no aggregation, therefore 1-to-1 CSV columns to
// trace state data structure bindings. It might be difficult to manage both
// behaviors of 1-to-1 and custom aggregation, so aggregation may need to be
// its own preset.

export enum DataTypes {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date'
}
export enum StringFormats {
  Short = 'short',
  Long = 'long'
}
export enum NumberFormats {
  Integer = 'integer',
  TwoDecimals = '2decimals',
  Percent = 'percent'
}
export enum BooleanFormats {
  YesNo = 'yesno',
  TrueFalse = 'truefalse',
  ZeroOne = '01'
}
export enum DateFormats {
  European = 'DD/MM/YYYY',
  International = 'DD MMM, YYYY',
  ISO = 'YYYY-MM-DD'
}
export enum TextEncodings {
  UTF8 = 'utf8',
  Latin1 = 'latin1'
}

const mappingBaseSchema = object({
  source: string(),
  isPrimary: boolean(),
  displayWidth: tableCellWidthSchema
});
const mappingStringSchema = object({
  type: literal(DataTypes.String),
  format: union([literal(StringFormats.Short), literal(StringFormats.Long)])
}).merge(mappingBaseSchema);
const mappingNumberSchema = object({
  type: literal(DataTypes.Number),
  format: union([
    literal(NumberFormats.Integer),
    literal(NumberFormats.TwoDecimals),
    literal(NumberFormats.Percent)
  ])
}).merge(mappingBaseSchema);
const mappingBooleanSchema = object({
  type: literal(DataTypes.Boolean),
  format: union([
    literal(BooleanFormats.YesNo),
    literal(BooleanFormats.TrueFalse),
    literal(BooleanFormats.ZeroOne)
  ])
}).merge(mappingBaseSchema);
const mappingDateSchema = object({
  type: literal(DataTypes.Date),
  format: union([
    literal(DateFormats.European),
    literal(DateFormats.International),
    literal(DateFormats.ISO)
  ])
}).merge(mappingBaseSchema);

const importDataInputSchema = object({
  mapping: array(
    union([
      mappingStringSchema,
      mappingNumberSchema,
      mappingBooleanSchema,
      mappingDateSchema
    ])
  ),
  encoding: union([literal(TextEncodings.UTF8), literal(TextEncodings.Latin1)]),
  csvDelimiter: string(),
  decimalDelimiter: string(),
  thousandsDelimiter: string(),
  enableComments: boolean()
}).merge(actionPresetInputSchema);
const camelize = (str: string) => {
  // Accents seem to make JMESPath bug, so we strip them out too
  const cleanStr = str.replace(/[^A-z0-9\s]+/g, '').replace(/\s+$/g, '');
  return (cleanStr.slice(0, 1).toLowerCase() + cleanStr.slice(1))
    .replace(/([-_ ]){1,}/g, ' ')
    .split(/[-_ ]/)
    .reduce((cur, acc) => cur + acc[0].toUpperCase() + acc.substring(1));
};

export type ImportDataInput = InferType<typeof importDataInputSchema>;

const importDataPreset: PresetTemplate<ImportDataInput> = {
  key: 'importData',
  name: 'Import data action',
  schema: importDataInputSchema,
  defaultValues: {
    action: {
      title: 'Import'
    },
    mapping: [
      {
        source: '',
        displayWidth: DisplayWidths.Medium,
        type: DataTypes.String,
        format: StringFormats.Short,
        isPrimary: false
      }
    ],
    encoding: TextEncodings.UTF8,
    csvDelimiter: ',',
    decimalDelimiter: '.',
    thousandsDelimiter: ',',
    enableComments: false
  },
  alterConfig: (config, { key, input }, { action }) => {
    if (!config.definitions) config.definitions = {};

    // Add comment utility function
    if (!config.definitions.addComments && input.enableComments) {
      config.definitions.addComments = addComments;
    }

    // The table config can be shared with a potential edit data preset,
    // so we save it in definitions for reuse
    let dataSelectorPath = '';
    const { dataImporter }: any = action || {};
    const table =
      dataImporter?.table && !dataImporter?.table.$ref
        ? dataImporter.table
        : {};
    const tableConfig: TableConfig = {
      // Default values if the data importer does not exist yet
      allowColumnsSelection: false,
      minColumnsWidth: DisplayWidths.Small,
      minRowsHeight: 30,
      selectBoxWidth: 50,
      showEmptyTable: true,
      tableWidthBuffer: 15,

      ...config.definitions[key],
      // If the existing action already has a data importer table,
      // transfer its props here
      ...table,

      columns:
        input.mapping.map(
          ({ source, displayWidth, isPrimary, type, format }) => {
            const key = camelize(source);

            // The data selector path depends on the column that was marked as "primary".
            // There should only be one and only one primary column.
            if (isPrimary) {
              if (dataSelectorPath) {
                throw new Error('Only one column should be marked as primary');
              }
              dataSelectorPath = key;
            }

            let view: TextView | NumberView | DateView | IconView;
            let modal: unknown | undefined;
            switch (type) {
              case DataTypes.Number:
                if (format === NumberFormats.Percent) {
                  view = {
                    type: 'number',
                    path: key,
                    format: {
                      options: {
                        style: 'percent',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    }
                  };
                } else {
                  view = {
                    type: 'number',
                    path: key,
                    format: {
                      options: {
                        maximumFractionDigits:
                          format === NumberFormats.TwoDecimals ? 2 : 0
                      }
                    }
                  };
                }
                break;
              case DataTypes.Date:
                view = {
                  // The date mapping format corresponds to a Y/M/D format
                  format,
                  type: 'date',
                  path: key
                };
                break;
              case DataTypes.Boolean:
                if (format === BooleanFormats.YesNo) {
                  view = {
                    type: 'text',
                    path: key
                  };
                } else {
                  // Use a checkbox display for non-text based booleans
                  view = {
                    type: 'icon',
                    path: key,
                    icon: 'CheckboxTick'
                  };
                }
                break;
              default:
                if (format === StringFormats.Short) {
                  view = {
                    type: 'text',
                    path: key
                  };
                } else {
                  view = {
                    type: 'icon',
                    icon: 'Comment',
                    labelPath: '`Long text...`'
                  };
                  modal = {
                    title: {
                      view: {
                        type: 'text',
                        path: `\`${source}\``
                      }
                    },
                    body: {
                      type: 'text',
                      path: key
                    }
                  };
                }
            }
            return {
              key,
              header: source,
              width: displayWidth || DisplayWidths.Medium,
              cell: { view, modal }
            };
          }
        ) || []
    };
    tableConfig.dataSelectorPath = dataSelectorPath;

    config.definitions[key] = tableConfig;
    return config;
  },
  generateAction: (action, { key, input }) => {
    const mapping = {
      columns:
        input.mapping.map(({ source, type, format }) => {
          const mapping: any = {
            from: source,
            to: camelize(source)
          };
          switch (type) {
            case DataTypes.Number:
              mapping.parser = {
                type: 'number',
                delimiters: {
                  thousands: input.thousandsDelimiter,
                  decimal: input.decimalDelimiter
                }
              };
              break;
            case DataTypes.Date:
              mapping.parser = {
                type: 'date',
                inputFormat: format
              };
              break;
            case DataTypes.Boolean:
              // Yes/No texts should be preserved
              if (format !== BooleanFormats.YesNo) {
                mapping.parser = {
                  type: 'boolean'
                };
              }
              break;
            default:
          }
          return mapping;
        }) || []
    };

    const dataProperties = {};
    input.mapping.forEach(({ source, type, format }) => {
      const key = camelize(source);
      switch (type) {
        case DataTypes.Date:
          // ISO date string
          dataProperties[key] = {
            type: 'string'
          };
          break;
        case DataTypes.Boolean:
          dataProperties[key] = {
            type: format === BooleanFormats.YesNo ? 'string' : 'boolean'
          };
          break;
        default:
          dataProperties[key] = {
            type: type || 'string'
          };
      }
    });

    return {
      ...action,
      key,
      title: input.action.title,
      stageName: input.action.title,
      icon: input.action?.icon || action?.icon,

      dataImporter: {
        importedDataKey: 'data',
        addComments: input.enableComments,
        addFile: true,
        parsing: {
          encoding: input.encoding,
          csvParser: {
            delimiter: input.csvDelimiter
          }
        },
        table: { $ref: key },
        mapping
      },

      form: mergeFormSchemas(action?.form, {
        schema: {
          type: 'object',
          properties: {
            data: {
              title: input.action.title,
              type: 'array',
              items: {
                type: 'object',
                properties: dataProperties,
                additionalProperties: false
              }
            },
            comment: {
              title: 'Comment',
              type: 'string',
              format: 'draft'
            },
            file: fileSchema
          },
          required: ['data'],
          additionalProperties: false
        },
        uiSchema: {}
      }),

      effects: mergeEffects(
        action?.effects,
        [
          {
            $preset: key,
            $statement: StatementType.SetVariable,
            path: `state.data.${key}`,
            value: {
              $expression: ResolvedExpressionType.Variable,
              query: 'formData.data'
            }
          },
          input.enableComments && {
            $preset: key,
            $statement: StatementType.FunctionCall,
            function: { $ref: 'addComments' } as any
          }
        ].filter(Boolean) as StatementWithPreset[]
      )
    };
  }
};

export default importDataPreset;
