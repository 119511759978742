import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  inputSelection: {
    '& button': {
      cursor: 'pointer',
      backgroundColor: theme.white1
    }
  },
  inputTypeOption: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  inputTypeOptionIcon: {
    flexShrink: 0,
    marginRight: 5
  }
}));
