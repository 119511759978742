import { memo, useContext, useCallback } from 'react';
import { FieldTextCompact, SmartDropdown } from '@stratumn/atomic';
import { getTraceStateFieldsUtils } from 'schemaBuilder/converter/admin';

import { EventChangeHandler } from 'utils/types';
import { DataContext, useToggle } from 'utils/hooks';

import { StateBasedDeadlineDefinition } from 'utils/interfaces/deadlines';

import { DeadlinesContext } from '../../context';

import useStyles from './stateBased.style';

export interface Props {
  path: string;
  data: StateBasedDeadlineDefinition;
}

export const StateBased = ({ path, data }: Props) => {
  const classes = useStyles();
  const { actions, showErrors, traceFieldOptions } = useContext(
    DeadlinesContext
  );
  const { set } = useContext(DataContext);

  const { stateField, endTrigger, additionalDelay } = data;

  const { getIdFromPath, getPath, getOption } = getTraceStateFieldsUtils(
    'deadlines'
  );

  const fieldKey = getIdFromPath(stateField);

  const handleSelectStateField: (selected: string) => void = useCallback(
    selected => {
      const option = getOption(traceFieldOptions, selected);
      if (option) {
        set(`${path}.stateField`, getPath(option));
      } else {
        set(`${path}.stateField`, '');
      }
    },
    [path, set, traceFieldOptions, getOption, getPath]
  );

  const handleSelectEndTrigger = useCallback(
    (actionKey: string) => {
      set(`${path}.endTrigger`, actionKey);
    },
    [path, set]
  );

  const handleEditAdditionalDelay: EventChangeHandler = useCallback(
    e => {
      if (!e.target.value) {
        set(`${path}.additionalDelay`, undefined);
        return;
      }

      const newValue = Number(e.target.value);
      if (Number.isNaN(newValue)) return;

      set(`${path}.additionalDelay`, newValue);
    },
    [path, set]
  );
  const [
    additionalDelayFieldFocused,
    toggleAdditionalDelayFieldFocused
  ] = useToggle(false);
  const additionalDelayValuePrefix = additionalDelayFieldFocused ? '' : '+ ';
  const additionalDelayValueSuffix = additionalDelayFieldFocused ? '' : ' days';
  const additionalDelayValue =
    additionalDelay === undefined
      ? ''
      : additionalDelayValuePrefix +
        String(additionalDelay) +
        additionalDelayValueSuffix;

  return (
    <>
      <div className={classes.el}>
        <SmartDropdown
          options={traceFieldOptions?.map(t => ({
            label: t.label,
            value: t.key
          }))}
          dataCy="state-field"
          label="Trace state field"
          placeholder="Search for a trace state field"
          noMatchMessage="No field found"
          onSelect={handleSelectStateField}
          value={fieldKey}
          invalid={showErrors && !stateField}
          compact
          shadows
        />
      </div>
      <div className={classes.el}>
        <SmartDropdown
          options={actions}
          dataCy="end-trigger"
          label="End trigger"
          placeholder="End trigger"
          noMatchMessage="No action found"
          onSelect={handleSelectEndTrigger}
          value={endTrigger}
          invalid={showErrors && !endTrigger}
          compact
          shadows
        />
      </div>
      <div className={classes.el}>
        <FieldTextCompact
          dataCy="additional-delay"
          label="Additional delay (+days)"
          placeholder="Additional delay (+days)"
          onValueChange={handleEditAdditionalDelay}
          onFocus={toggleAdditionalDelayFieldFocused}
          onBlur={toggleAdditionalDelayFieldFocused}
          value={additionalDelayValue}
        />
      </div>
    </>
  );
};

export default memo(StateBased);

export const validate = (data: StateBasedDeadlineDefinition) =>
  !data.stateField || !data.endTrigger;
