export default (): any => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 12
  },
  comment: {
    color: 'grey',
    fontSize: 'medium'
  },
  setVariable: {
    marginLeft: 12,
    display: 'flex'
  },
  variable: {
    display: 'flex'
  },
  arguments: {
    marginLeft: 12
  },
  object: {
    border: '1px solid black',
    padding: 12
  },
  objectProperty: {
    marginLeft: 12
  },
  listItem: {
    display: 'list-item',
    listStylePosition: 'inside'
  }
});
