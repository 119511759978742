import { FC, memo } from 'react';
import { createPortal } from 'react-dom';

import { Draggable } from 'react-beautiful-dnd';

import { Icon } from '@stratumn/atomic';
import { Section } from 'components/workflow/ui';

import { Section as SectionDefinition, ItemsTypesEnums } from '../../types';

import GenericSection from '../../genericSection';

import useStyles from './listItem.style';

interface Props {
  path: string;
  index: number;
  section: SectionDefinition;
}

export const ListItem: FC<Props> = ({ path, index, section }) => {
  const classes = useStyles();
  const { sectionKey, itemsType } = section;

  const getDraggableItem = (providedDraggable, snapshotDraggable) => {
    const dragHandle = (
      <div
        className={classes.dragHandle}
        {...providedDraggable.dragHandleProps}
      >
        <Icon name="Drag" size={20} />
      </div>
    );

    let draggableItem;
    if (itemsType === ItemsTypesEnums.KeyValue) {
      draggableItem = (
        <GenericSection
          path={path}
          section={section}
          dragHandle={dragHandle}
          isDragging={snapshotDraggable.isDragging}
        />
      );
    } else {
      const {
        title,
        description,
        items,
        sectionCollapse,
        placeholder
      } = section;
      draggableItem = (
        <Section
          path={path}
          key={sectionKey}
          sectionKey={sectionKey}
          title={title}
          infoTooltip={description}
          placeholder="Hidden section from trace info. Currently not configurable from this module."
          items={items}
          sectionCollapse={sectionCollapse}
          dragHandle={dragHandle}
          isDragging={snapshotDraggable.isDragging}
        >
          {items && items.length > 0 && <p>{placeholder}</p>}
        </Section>
      );
    }
    draggableItem = (
      <div
        ref={providedDraggable.innerRef}
        {...providedDraggable.draggableProps}
        className={classes.singleSectionWrapper}
      >
        {draggableItem}
      </div>
    );

    return snapshotDraggable.isDragging
      ? createPortal(draggableItem, document.body)
      : draggableItem;
  };

  return (
    <Draggable draggableId={section.sectionKey} index={index}>
      {getDraggableItem}
    </Draggable>
  );
};

export default memo(ListItem);
