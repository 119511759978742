import { Droppable } from 'react-beautiful-dnd';
import { subFormFieldTypesOptions } from '../../../constants';
import FieldList from '../../../list';

export function SubFormList({
  properties,
  readonly,
  schema,
  path,
  showFieldKeys
}: any) {
  return (
    <div>
      <Droppable droppableId={path} type={path}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <FieldList
              showFieldKeys={showFieldKeys}
              readonly={readonly}
              fields={properties || []}
              schema={schema}
              path={path}
              theme="embed"
              availableFieldTypesOptions={subFormFieldTypesOptions}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
