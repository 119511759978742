import { ActionFieldType, SchemaFieldType } from 'schemaBuilder/types';

import helper from './fields/helper';
import shortText from './fields/shortText';
import boolean from './fields/boolean';
import date from './fields/date';
import richText from './fields/richText';
import number from './fields/number';
import groups from './fields/groups';
import checkboxes from './fields/checkboxes';
import fileUpload from './fields/fileUpload';
import textarea from './fields/textarea';
import radioButtons from './fields/radioButtons';
import dropdown from './fields/dropdown';
import subForm from './fields/subForm';
import list from './fields/list';
import richList from './fields/richList';

const simpleTypes = {
  [SchemaFieldType.ShortText]: shortText,
  [SchemaFieldType.Boolean]: boolean,
  [SchemaFieldType.Groups]: groups,
  [SchemaFieldType.Date]: date,
  [SchemaFieldType.RichText]: richText,
  [SchemaFieldType.Textarea]: textarea,
  [SchemaFieldType.Number]: number,
  [SchemaFieldType.Checkboxes]: checkboxes,
  [SchemaFieldType.FileUpload]: fileUpload,
  [SchemaFieldType.Dropdown]: dropdown,
  [SchemaFieldType.Helper]: helper,
  [SchemaFieldType.RadioButtons]: radioButtons
};

const composedTypes = {
  [SchemaFieldType.SubForm]: subForm,
  [SchemaFieldType.RichList]: richList,
  [SchemaFieldType.List]: list
};

const allTypes = {
  ...simpleTypes,
  ...composedTypes
};

function convertField(field, schema, converters) {
  const normalizedField =
    field.type === ActionFieldType.Existing
      ? {
          ...field,
          type: ActionFieldType.Local,
          schemaField: schema[field.ref || '']
        }
      : field;

  const convert =
    field.type === ActionFieldType.Existing
      ? converters[schema[field.ref]?.type]
      : converters[field.schemaField?.type];

  if (!convert) {
    throw new Error(
      `Unknown field type ${JSON.stringify({ field, schema }, null, 2)}`
    );
  }

  return convert(normalizedField, convertSimples(schema));
}

const convertSimples = schema => field =>
  convertField(field, schema, simpleTypes);

export const convert = schema => field => convertField(field, schema, allTypes);
