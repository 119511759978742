import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  fieldTextWrapper: {
    marginBottom: 15,
    maxWidth: 300,
    '& div:nth-child(2)': {
      backgroundColor: theme.grey8
    },
    '& input': {
      height: 30
    }
  },
  statusValueCardWrapper: {
    marginBottom: 15,
    marginRight: 15
  }
}));
