import { FC, useState, useCallback, memo, useMemo } from 'react';

import { Pushtext, Icon } from '@stratumn/atomic';
import theme from 'style';

import { BTN_TYPE_ADD } from 'components/workflow/transitions/utils/constants';

import useStyles from './button.style';

export interface Props {
  type: string;
  label: string;
  onClick: () => void;
}

export const Button: FC<Props> = ({ type, label, onClick }) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const classes = useStyles();

  const handleToggle = useCallback(() => setToggle(!toggle), [toggle]);

  const dataCyLabel = useMemo(
    () => label.toString().toLowerCase().replace(' ', '-'),
    [label]
  );

  const iconName = useMemo(() => {
    const operator = type === BTN_TYPE_ADD ? 'Plus' : 'Minus';

    switch (toggle) {
      case true:
        return `Circle${operator}Fill`;
      case false:
        return `Circle${operator}`;

      default:
        return '';
    }
  }, [toggle, type]);

  const iconColor = type === BTN_TYPE_ADD ? theme.indigo2 : theme.warningRed;

  return (
    <div
      data-cy={`${dataCyLabel}-wrapper`}
      data-is-plus={type === BTN_TYPE_ADD}
      className={classes.root}
      onMouseEnter={handleToggle}
      onMouseLeave={handleToggle}
    >
      <Pushtext
        data-cy={dataCyLabel}
        onClick={onClick}
        prefix={<Icon color={iconColor} name={iconName} size={15} />}
      >
        {label}
      </Pushtext>
    </div>
  );
};

export default memo(Button);
