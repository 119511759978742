import axios from 'axios';
import { TRACE_API_URL } from 'constant/api';

export { default as traceClient } from './trace';
export { default as accountClient } from './account';

export const getToken = async (authCode: string) => {
  const response = await axios.request({
    url: `${TRACE_API_URL}/oauth`,
    method: 'POST',
    data: JSON.stringify({ authCode }),
    headers: { 'Content-Type': 'application/json' }
  });

  return response.data.token;
};
