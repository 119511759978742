import { createContext } from 'react';

import { Action, Transition, WorkflowGroup } from './types';

export interface TransitionsContextDefinition {
  data: Transition[];
  actions: Action[];
  groups: WorkflowGroup[];
  formIsValid: boolean;
}

export const TransitionsContext = createContext<
  TransitionsContextDefinition | any
>({});
