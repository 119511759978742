import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  Header,
  HeaderSection,
  HeaderSectionItem,
  ConfirmationModal,
  ProfileButton,
  LogoMark,
  PushtextHref,
  BreadCrumbs
} from '@stratumn/atomic';

import { logout } from 'client/account';
import { ROUTE_LOGIN, ROUTE_HOME, ROUTE_WORKFLOWS } from 'constant/routes';
import { TRACE_URL, ACCOUNT_URL } from 'constant/api';

import { useFunctionAsState } from 'utils/hooks';

import { WORKFLOW_HOMEPAGE } from 'constant/pages';

import { UserContext } from 'components/privateRoute';

import useStyles from './header.style';

const goToProfile = () => window.open(ACCOUNT_URL);
const navigateOutsideWorkflow = path =>
  [ROUTE_HOME, ROUTE_WORKFLOWS].includes(path);

interface Props extends RouteComponentProps {
  loading?: boolean;
  breadcrumbs?: {
    icon?: string;
    label?: string;
    path?: string;
  }[];
  dirty?: {
    module?: string;
    moduleIsDirty?: boolean;
    stateIsDirty: boolean;
  };
}

export const HeaderLayout: FC<Props> = ({
  history,
  loading,
  breadcrumbs,
  dirty = {
    module: '',
    moduleIsDirty: false,
    stateIsDirty: false
  }
}) => {
  const classes = useStyles();

  const { avatar } = useContext(UserContext);

  const [navigationCallback, setNavigationCallback] = useFunctionAsState(
    null as any
  );

  const { module, moduleIsDirty, stateIsDirty } = dirty;

  const hasSubHeader = (breadcrumbs || []).length > 0;

  // callback run before routing
  const checkNavigation = useCallback(
    (checkState: boolean, navigate: () => void) => {
      if (
        (moduleIsDirty || (checkState && stateIsDirty)) &&
        !navigationCallback
      ) {
        setNavigationCallback(navigate);
        return;
      }
      navigate();
    },
    [moduleIsDirty, stateIsDirty, navigationCallback, setNavigationCallback]
  );
  const confirmNavigation = useCallback(() => {
    setNavigationCallback(null);
    if (navigationCallback) navigationCallback();
  }, [navigationCallback, setNavigationCallback]);
  const cancelNavigation = useCallback(() => {
    setNavigationCallback(null);
  }, [setNavigationCallback]);

  const modalTitle =
    dirty.module !== WORKFLOW_HOMEPAGE
      ? `Quit ${module} module`
      : `Quit ${WORKFLOW_HOMEPAGE}`;

  const modalContent = `You’re about to quit ${
    dirty.module !== WORKFLOW_HOMEPAGE
      ? `${dirty.module} module`
      : WORKFLOW_HOMEPAGE
  }  without saving your changes.<br>**All unsaved changes will be lost**.<br>Are you sure?`;

  const title = useMemo(
    () =>
      hasSubHeader ? (
        <b className={classes.secondaryTitle}>workflow admin</b>
      ) : (
        <span className={classes.primaryTitle}>
          <b>workflow</b> admin
        </span>
      ),
    [classes, hasSubHeader]
  );

  const profileButton = useMemo(() => {
    const signOut = async () => {
      await checkNavigation(true, async () => {
        await logout();
        history.push(ROUTE_LOGIN);
      });
    };

    return (
      <ProfileButton
        loading={loading}
        avatar={avatar}
        avatarSize={hasSubHeader ? 25 : 32}
        goToProfile={goToProfile}
        signOut={signOut}
      />
    );
  }, [loading, avatar, hasSubHeader, history, checkNavigation]);

  const linkToTrace = useMemo(
    () => (
      <PushtextHref
        dataCy="link-to-trace"
        primary
        prefix={<LogoMark size={20} />}
        external
        href={TRACE_URL}
      >
        Go to Trace
      </PushtextHref>
    ),
    []
  );

  const breadcrumbsSetup = useMemo(
    () =>
      breadcrumbs?.map(({ icon, label, path }) => ({
        icon,
        label,
        onClick: path
          ? () =>
              checkNavigation(navigateOutsideWorkflow(path), () =>
                history.push(path)
              )
          : undefined
      })),
    [breadcrumbs, checkNavigation, history]
  );

  return (
    <>
      <Header
        loading={loading}
        subheaderPrimary={
          breadcrumbs ? <BreadCrumbs items={breadcrumbsSetup} /> : null
        }
      >
        <HeaderSection level="primary">{title}</HeaderSection>
        <HeaderSection level="secondary">
          <HeaderSectionItem>{linkToTrace}</HeaderSectionItem>
          <HeaderSectionItem>{profileButton}</HeaderSectionItem>
        </HeaderSection>
      </Header>
      {navigationCallback && (
        <ConfirmationModal
          data-cy="undo-confirmation-modal"
          warning
          title={modalTitle}
          content={modalContent}
          confirmBtnText="quit module"
          confirm={confirmNavigation}
          cancel={cancelNavigation}
        />
      )}
    </>
  );
};

export default withRouter(memo(HeaderLayout));
