import type { WorkflowExport } from 'utils/export/getFile';
import { Configuration, configurationSchema } from 'utils/trace';
import { zodErrorToString } from 'utils/strings';

// TODO: Data validation, e.g. transitions refer to existing actions and groups, etc...
export default ({
  stateSchema,
  initState,
  initActions,
  actions,
  info,
  overview,
  definitions,
  transitions,
  allowAutomation
}: WorkflowExport): Configuration => {
  // Validate potential configuration with zod schema
  const result = configurationSchema.safeParse({
    stateSchema,
    initState,
    initActions,
    actions,
    info,
    overview,
    definitions,
    transitions,
    allowAutomation
  });
  if (result.success) return result.data;
  throw new Error(
    `Invalid workflow configuration! Please try to save the original workflow again and export it.
${zodErrorToString(result.error)}`
  );
};
