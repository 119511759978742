import type { WorkflowExport } from 'utils/export/getFile';
import { Groups, groupsSchema } from 'utils/trace';
import { zodErrorToString } from 'utils/strings';

export default ({ groups }: WorkflowExport): Groups => {
  const result = groupsSchema.safeParse(groups || []);
  if (result.success) return result.data;
  throw new Error(
    `Invalid groups! Please try to export the original workflow again.
${zodErrorToString(result.error)}`
  );
};
