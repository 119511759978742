import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  FieldTextCompact,
  SmartDropdown,
  Pushbutton,
  Icon,
  InfoTooltip
} from '@stratumn/atomic';

import { ROUTE_SCHEMA_BUILDER } from 'constant/routes';
import { getWorkflowBreadCrumbs } from 'constant/pages';

import { DataContext, useDataReducer, useToggle } from 'utils/hooks';
import { onDrop } from 'utils/dragAndDrop';

import { isConfigDirty } from 'components/workflow/utils';
import IconSelectionModal from 'components/workflow/ui/iconSelectionModal';
import { HeaderLayout } from 'components/layouts';

import { Action, ActionFieldType, WorkflowSchema } from '../../types';

import { createValidator } from './hooks/validator';

import SchemaBuilderForm from './form';

import useStyles from './action.style';
import { PresetInfo } from './preset';

const infoTooltipPosition = {
  place: 'below',
  placeShift: 3,
  adjustPlace: true
};

export interface Props extends RouteComponentProps {
  schema: WorkflowSchema;
  action?: Action | null;
  submit: (action: Partial<Action>) => void;
  workflowRowId: string;
  workflowName: string;
}

export const ActionForm = ({
  history,
  match,
  schema,
  action: current,
  submit,
  workflowRowId,
  workflowName
}: Props) => {
  const classes = useStyles();
  const validator = useMemo(() => createValidator(schema), [schema]);
  const [errorMessage, setErrorMessage] = useState('');
  const [action, actionContext] = useDataReducer<Partial<Action>>(
    current || { fields: [] },
    { validator }
  );

  const [showIconSelectionModal, toggleShowIconSelectionModal] = useToggle(
    false
  );

  const workflowRootPath = useMemo(
    () => ROUTE_SCHEMA_BUILDER.replace(':id', (match.params as any).id),
    [match.params]
  );

  const { set, validation, enableValidation } = actionContext;

  const isDirty = useMemo(() => isConfigDirty(current || {}, action), [
    current,
    action
  ]);

  const handleCancel = useCallback(() => history.push(workflowRootPath), [
    history,
    workflowRootPath
  ]);

  const handleSubmit = useCallback(() => {
    if (!action) return;

    enableValidation!();

    if (!validation!.isValid()) {
      // TODO: Consider the case of multiple errors/conflicts
      // For now, we display one single generic message in case of multiple errors
      // to avoid cluttering the UI.
      const messages = Object.values(validation!.getErrors());
      const message =
        messages.length > 1
          ? 'You have multiple errors. Please solve them before saving changes again.'
          : messages[0][0];
      setErrorMessage(message);
      return;
    }

    // For every field, fill in key entries with the provisional keys
    // if they haven't been filled in manually or previously defined
    action.fields?.forEach(field => {
      if (field.type !== ActionFieldType.New) return;
      if (!field.schemaField.key) {
        field.schemaField.key = field.schemaField.provisionalKey || field.id;
        delete field.schemaField.provisionalKey;
      }
    });

    submit(action);
    history.push(workflowRootPath);
  }, [action, submit, history, workflowRootPath, validation, enableValidation]);

  const handleNameChange = useCallback(
    (e: ChangeEvent<any>) => set('name', e.currentTarget.value),
    [set]
  );

  const handleSelectIcon = useCallback(
    (iconStr: string) => set('icon', iconStr),
    [set]
  );

  const [currentPreset] = useState('setData');

  // TODO: Come up with "new preset" implementations

  const presetsDescriptions = {
    setData: {
      label: 'Set data action',
      description:
        'This preset allows yout to create forms thanks to the builder opposite'
    }
  };

  // The general principle of "new presets" should be adding fields to the
  // form builder automatically, anything beyond the action form is up for debate,
  // but can be handled in the converter in a switch on the preset type.

  // Some of them may replace the form builder by something else,
  // e.g. the data importer and data editor replace the user form
  const presetsDropdownOptions = Object.entries(
    presetsDescriptions
  ).map(([key, value]) => ({ value: key, label: value.label }));

  const handleSelectPresets = () => {};

  const onDragEnd = useCallback(
    (result: any) => onDrop(result, actionContext),
    [actionContext]
  );

  const actionTitle = current?.name || 'New Action';
  const header = useMemo(
    () => (
      <HeaderLayout
        dirty={{
          module: 'Actions',
          moduleIsDirty: isDirty,
          stateIsDirty: isDirty
        }}
        breadcrumbs={[
          ...getWorkflowBreadCrumbs(workflowName, workflowRowId, true),
          { label: actionTitle }
        ]}
      />
    ),
    [isDirty, workflowName, workflowRowId, actionTitle]
  );

  return (
    <>
      {header}
      <DataContext.Provider value={actionContext}>
        <div className={classes.container}>
          <div className={classes.leftPanel}>
            <div className={classes.title}>
              {current
                ? `Edit "${current.name}" action`
                : 'Create a new action'}
            </div>
            <SmartDropdown
              compact
              dataCy="select-preset"
              label="Preset"
              placeholder="Select a preset"
              shadows
              options={presetsDropdownOptions}
              value={currentPreset}
              onSelect={handleSelectPresets}
            />
            <div className={classes.actionNameField}>
              <FieldTextCompact
                invalid={validation!.hasErrors('name')}
                label="Action name"
                value={action.name}
                onValueChange={handleNameChange}
              />
            </div>
            <InfoTooltip
              text="Set the preset icon"
              position={infoTooltipPosition}
              textAlign="center"
              padding={7}
              delay={300}
            >
              <Pushbutton
                dataCy="toggle-icon-selection"
                onClick={toggleShowIconSelectionModal}
                sexyShadow
                suffix={action.icon && <Icon name={action.icon} size={20} />}
              >
                Action icon
              </Pushbutton>
            </InfoTooltip>
            <div className={classes.ctaButtonsWrapper}>
              <Pushbutton type="button" onClick={handleCancel}>
                Cancel
              </Pushbutton>
              <Pushbutton
                primary
                type="submit"
                disabled={!isDirty || validation!.hasErrors()}
                onClick={handleSubmit}
              >
                Done
              </Pushbutton>
            </div>
            {errorMessage && (
              <div className={classes.errors}>
                <Icon name="CircleInfoFill" size={15} />
                <span className={classes.errorsContent}>{errorMessage}</span>
              </div>
            )}
            <br />
            <hr />
            <br />
            <PresetInfo preset={presetsDescriptions[currentPreset]} />
          </div>

          <div className={classes.rightColumn}>
            <DragDropContext onDragEnd={onDragEnd}>
              <SchemaBuilderForm schema={schema} action={action} />
            </DragDropContext>
          </div>
        </div>
      </DataContext.Provider>
      {showIconSelectionModal && (
        <IconSelectionModal
          data-cy="icon-selection-modal"
          iconStr={action.icon || ''}
          setIconStr={handleSelectIcon}
          close={toggleShowIconSelectionModal}
        />
      )}
    </>
  );
};

export default withRouter(ActionForm);
