import { FC, useState, useContext, useCallback, memo, useMemo } from 'react';

import {
  SmartDropdown,
  Check,
  Pushtext,
  UiconCirclePlusFill
} from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';
import { EventChangeHandler } from 'utils/types';
import { DataUpdateType } from 'utils/data';

import { Transition } from '../../types';
import { TransitionsContext } from '../../context';

import { FieldsRow } from './fieldsRow';

import useStyles from './transitionCard.style';
import { MyCard } from './myCard/MyCard';

interface Props {
  path: string;
  nextActionSelected?: Transition;
  allTransitions: Transition[];
  groupsCollapsed: boolean;
}

export const TransitionCard: FC<Props> = ({
  path,
  nextActionSelected,
  allTransitions,
  groupsCollapsed
}) => {
  const classes = useStyles();

  const { append, update, set } = useContext(DataContext);
  const { actions, groups, formIsValid } = useContext(TransitionsContext);

  // TODO: placeholder for togglePriority
  const [togglePriority, setTogglePriority] = useState<boolean>(false);

  const handleSelectTransition: EventChangeHandler = useCallback(
    value => {
      set(path, {
        key: value,
        groups: nextActionSelected?.groups
      });
    },
    [nextActionSelected?.groups, path, set]
  );

  const handleAddTransitionGroup = useCallback((): void => {
    append(`${path}.groups`, { label: '' });
  }, [append, path]);

  const handleRemoveNextAction = useCallback((): void => {
    const revertMessage = 'You successfully deleted the transition';

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, path]);

  // TODO: Handle priority action
  const handlePriorityAction = useCallback(
    () => setTogglePriority(!togglePriority),
    [togglePriority]
  );

  const findGroupName = useCallback(
    (label: string): string => {
      const group = groups!.find(group => group.label === label);
      return group?.name || 'variable';
    },
    [groups]
  );

  const handleGroupsCollpased = useCallback(
    () => set(`${path}.groupsCollapsed`, !groupsCollapsed),
    [path, set, groupsCollapsed]
  );

  // remove duplicated groups' labels
  const filteredGroups = useMemo(
    () =>
      (nextActionSelected?.groups || []).reduce((acc, curr) => {
        if (!acc.find(obj => obj.label === curr.label)) {
          acc.push(curr);
        }
        return acc;
      }, []),
    [nextActionSelected]
  );

  const renderAddedGroupsList = useMemo(
    () => (
      <div className={classes.groupsListWrapper}>
        <h2 className={classes.groupsListTitle}>Added groups</h2>
        <ul
          className={classes.groupsList}
          title={filteredGroups?.map(group => findGroupName(group.label))}
        >
          {filteredGroups?.map(
            (group, index) =>
              // TODO: display placeholder for dynamic groups
              group.label && (
                <li key={index} className={classes.groupLabel}>
                  {findGroupName(group.label)}
                </li>
              )
          )}
        </ul>
      </div>
    ),
    [
      classes.groupLabel,
      classes.groupsList,
      classes.groupsListTitle,
      classes.groupsListWrapper,
      findGroupName,
      filteredGroups
    ]
  );

  const isTransitionDuplicated: boolean = useMemo(
    () =>
      allTransitions.filter(
        (transition: Transition) => transition.key === nextActionSelected?.key
      ).length > 1,
    [allTransitions, nextActionSelected]
  );

  return (
    <div data-cy="next-action-card" className={classes.root}>
      <MyCard
        isOpen={!groupsCollapsed}
        onOpen={handleGroupsCollpased}
        toggleDataCy="toggle-transition-group"
        header={
          <div className={classes.cardWrapper}>
            <div className={classes.fieldSelectCompactWrapper}>
              <SmartDropdown
                compact
                label="Next action"
                value={nextActionSelected?.key}
                options={actions.map(obj => ({
                  value: obj.key,
                  label: obj.title
                }))}
                onSelect={handleSelectTransition}
                dataCy="select-transition"
                invalid={isTransitionDuplicated && !formIsValid}
              />
            </div>
            {nextActionSelected?.groups?.length > 0 && renderAddedGroupsList}
            <div className={classes.ctaButtonsWrapper}>
              <Check
                label="Priority action"
                checked={togglePriority}
                showLabel
                largeLabel
                handleChange={handlePriorityAction}
              />
            </div>
          </div>
        }
        handleRemove={handleRemoveNextAction}
        removeDataCy="remove-transition"
      >
        <div data-cy="transition-group-row">
          {nextActionSelected?.groups?.map(({ label, condition }, index) => (
            <div key={index} className={classes.fieldsRowWrapper}>
              <FieldsRow
                path={`${path}.groups.${index}`}
                groupSelected={label}
                conditionSelected={condition}
                allGroupsSelected={nextActionSelected?.groups}
              />
            </div>
          ))}

          <div className={classes.addGroup}>
            <Pushtext
              dataCy="add-transition-group"
              onClick={handleAddTransitionGroup}
              prefix={<UiconCirclePlusFill size={25} />}
            >
              Add Group
            </Pushtext>
          </div>
        </div>
      </MyCard>
    </div>
  );
};

export default memo(TransitionCard);
