import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 22px'
  },
  fieldTextWrapper: {
    marginRight: 10,
    width: 268,
    '& input': {
      height: 35
    }
  },
  cardHeaderDescription: {
    color: theme.grey1,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17.75px'
  },
  beginWithButton: {
    marginTop: 15,
    marginLeft: 22,
    display: 'flex',
    alignItems: 'center',
    marginRight: 15
  },
  statusActionCardsContainer: {
    marginLeft: 20
  },
  statusActionCardWrapper: {
    marginTop: 15
  }
}));
