import { ReactElement, useCallback, useContext, memo } from 'react';
import { createPortal } from 'react-dom';
import { Draggable } from 'react-beautiful-dnd';
import { Icon, SmartDropdown } from '@stratumn/atomic';
import {
  ActionFieldType,
  SchemaFieldType,
  ActionField,
  WorkflowSchema,
  Theme
} from 'schemaBuilder/types';
import { DataContext, useToggle } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';
import { IconButton } from 'components/workflow/ui';

import { useValidation } from '../hooks/validator';
import Existing from './existing';
import Empty from './empty';
import EditKeyModal from './editKeyModal';

import {
  fieldTypesMap,
  fieldTypesOptions,
  localOnlyFieldTypes
} from './constants';

import useStyles from './field.style';

export interface Props {
  index: number;
  field: ActionField;
  schema: WorkflowSchema;
  showFieldKeys: boolean;
  path: string;
  theme?: Theme;
  readonly?: boolean;
  droppable?: boolean;
  availableFieldTypesOptions?: any[];
}

const PortalIfDragging = ({ isDragging, children }) =>
  isDragging ? createPortal(children, document.body) : children;

export const Field = ({
  index,
  field,
  schema,
  path = 'fields',
  theme = 'default',
  readonly,
  showFieldKeys,
  availableFieldTypesOptions
}: Props) => {
  const classes = useStyles({ theme });

  const validation = useValidation();
  const [showEditKeyModal, toggleEditKeyModal] = useToggle(false);
  const { set, update, delete: del } = useContext(DataContext);

  const fieldType =
    field.type === ActionFieldType.Existing
      ? { label: 'Existing' }
      : fieldTypesMap.get(field.schemaField?.type);

  const revertMessage = `The${
    fieldType ? ` **${fieldType.label}** ` : ' '
  }field has been successfully deleted`;

  const handleRemove = useCallback(
    () =>
      update(
        [
          {
            type: DataUpdateType.Splice,
            path,
            startIndex: index,
            deleteCount: 1
          }
        ],
        {
          revert: {
            message: revertMessage
          }
        }
      ),
    [update, revertMessage, index, path]
  );

  const handleFieldTypeSelect = useCallback(
    (fieldType: SchemaFieldType) => {
      if (!fieldType) {
        set(`${path}.${index}`, { id: field.id });
      } else {
        update([
          {
            type: DataUpdateType.Set,
            path: `${path}.${index}.type`,
            value: localOnlyFieldTypes.has(fieldType)
              ? ActionFieldType.Local
              : ActionFieldType.New
          },
          {
            type: DataUpdateType.Set,
            path: `${path}.${index}.schemaField`,
            value: {
              id: field.id,
              provisionalKey: field.id,
              type: fieldType
            }
          }
        ]);
      }
    },
    [set, update, index, field.id, path]
  );

  const handleEditKey = useCallback(
    (key: string | null) => {
      if (key) {
        set(`${path}.${index}.schemaField.key`, key);
      } else {
        del(`${path}.${index}.schemaField.key`);
      }
      toggleEditKeyModal();
    },
    [set, del, index, toggleEditKeyModal, path]
  );

  let InnerField: (props: any) => ReactElement | null;

  if (field.type === ActionFieldType.Existing) {
    InnerField = Existing;
  } else {
    InnerField = fieldTypesMap.get(field.schemaField?.type)?.component || Empty;
  }

  let fieldKeyElement: ReactElement | null;
  if (showFieldKeys) {
    if (field.type === ActionFieldType.Existing) {
      fieldKeyElement = (
        <div className={classes.fieldKeyDisplay}>
          #{schema[field.ref || ''].key}
        </div>
      );
    } else {
      fieldKeyElement = (
        <button
          type="button"
          data-cy="open-edit-field-key-modal"
          className={classes.fieldKeyButton}
          title="edit field key"
          onClick={toggleEditKeyModal}
        >
          <span className={classes.fieldKey} data-cy="field-key">
            #{field.schemaField?.key || field.schemaField?.provisionalKey}
          </span>
          <span className={classes.penIcon}>
            <Icon name="Pen" size={18} />
          </span>
        </button>
      );
    }
  }

  return (
    <>
      <Draggable draggableId={field.id} index={index}>
        {(provided, snapshot) => (
          <PortalIfDragging isDragging={snapshot.isDragging}>
            <div
              ref={provided.innerRef}
              className={classes.root}
              {...provided.draggableProps}
            >
              <InnerField
                readonly={readonly}
                path={`${path}.${index}`}
                schema={schema}
                theme={theme}
                field={field}
                isDragging={snapshot.isDragging}
                dragElement={
                  readonly ? undefined : (
                    <div
                      className={classes.dragHandle}
                      {...provided.dragHandleProps}
                    >
                      <Icon name="Drag" size={20} />
                    </div>
                  )
                }
                removeElement={
                  readonly ? undefined : (
                    <IconButton
                      theme={theme}
                      dataCy="remove-field"
                      name="Trash"
                      onClick={handleRemove}
                      ariaLabel="Remove field"
                    />
                  )
                }
                typeSelectElement={
                  <div>
                    <SmartDropdown
                      disabled={readonly}
                      invalid={validation.hasErrors(`${path}.${index}.type`)}
                      options={availableFieldTypesOptions || fieldTypesOptions}
                      dataCy="select-field-type"
                      label="Select a field type"
                      noMatchMessage="No type found"
                      placeholder="Field type"
                      onSelect={handleFieldTypeSelect}
                      value={
                        field.type === ActionFieldType.Existing
                          ? ActionFieldType.Existing
                          : field.schemaField?.type
                      }
                    />
                  </div>
                }
                fieldKeyElement={fieldKeyElement}
                showFieldKeys={showFieldKeys}
              />
            </div>
          </PortalIfDragging>
        )}
      </Draggable>
      {showEditKeyModal && field.type !== ActionFieldType.Existing && (
        <EditKeyModal
          fieldKey={field.schemaField?.key || field.schemaField?.provisionalKey}
          submit={handleEditKey}
          cancel={toggleEditKeyModal}
        />
      )}
    </>
  );
};

export default memo(Field);
