import React, { FC, useState, useCallback, useMemo } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  FieldTextCompact,
  Dropdown,
  OptionDrop,
  Pushbutton,
  withSnackbarsContext
} from '@stratumn/atomic';

import parseConfig from 'utils/export/parse';

import { accountClient } from 'client';
import { HeaderLayout } from 'components/layouts';
import { ROUTE_WORKFLOWS, ROUTE_WORKFLOW_DETAILS } from 'constant/routes';
import { getWorkflowsBreadCrumbs } from 'constant/pages';

import { ImportErrorModal } from './importErrorModal';
import useStyles from './newWorkflow.style';

/**
 * TODO: clean this up...
 * We query organizations twice, because they return different
 * resuls. The first one is for the case of normal users, who
 * should only be able to create workflows in orgs they are a
 * member of. me.organizations only returns orgs that a user
 * is a member of.
 *
 * organizations is for the superuser case because it returns
 * all of the orgs regardless of membership status.
 */
const QUERY = gql`
  query organizationList {
    me {
      isSuperuser
      organizations {
        nodes {
          rowId
          name
          accountId
        }
      }
    }
    organizations {
      nodes {
        rowId
        name
        accountId
      }
    }
  }
`;

const NEW_WORKFLOW_MUTATION = gql`
  mutation newWorkflow($workflow: WorkflowInputRecordInput!) {
    createWorkflow(input: { workflow: $workflow }) {
      workflow {
        rowId
      }
    }
  }
`;

const NEW_WORKFLOW_CONFIG_MUTATION = gql`
  mutation newWorkflowConfigMutation(
    $config: NewWorkflowConfigInputRecordInput!
  ) {
    newWorkflowConfig(input: { config: $config }) {
      workflowConfig {
        rowId
      }
    }
  }
`;

const UPDATE_WORKFLOW_CONFIG_MUTATION = gql`
  mutation updateWorkflowConfigMutation(
    $rowId: BigInt!
    $patch: WorkflowConfigPatch!
  ) {
    updateWorkflowConfigByRowId(input: { rowId: $rowId, patch: $patch }) {
      workflowConfig {
        rowId
      }
    }
  }
`;

const CREATE_GROUP_MUTATION = gql`
  mutation createGroupMutation(
    $workflowId: BigInt!
    $ownerId: BigInt!
    $label: String!
    $name: String!
  ) {
    createGroup(
      input: {
        group: {
          workflowId: $workflowId
          ownerId: $ownerId
          label: $label
          name: $name
        }
      }
    ) {
      group {
        rowId
      }
    }
  }
`;

interface Props extends RouteComponentProps {
  successSnackbar: (message: string) => void;
}

const NewWorkflow: FC<Props> = ({ history, successSnackbar }) => {
  const [workflow, setWorkflow] = useState<any>({});
  const [newConfig, setNewConfig] = useState<any>(null);
  const [presets, setPresets] = useState<any>(null);
  const [groups, setGroups] = useState<any>(null);
  const [patchConfig, setPatchConfig] = useState<any>(null);
  const { loading, error, data } = useQuery(QUERY, { client: accountClient });
  const [createWorkflow] = useMutation(NEW_WORKFLOW_MUTATION);
  const [createConfig] = useMutation(NEW_WORKFLOW_CONFIG_MUTATION);
  const [createGroup] = useMutation(CREATE_GROUP_MUTATION);
  const [updateConfig] = useMutation(UPDATE_WORKFLOW_CONFIG_MUTATION);
  const [importError, setImportError] = useState<Error>();
  const disabled = !workflow.name || !workflow.ownerId;

  const classes = useStyles();

  const setName = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) =>
      setWorkflow({ ...workflow, name: e.currentTarget.value }),
    [workflow, setWorkflow]
  );

  const setPrefix = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) =>
      setWorkflow({ ...workflow, prefix: e.currentTarget.value }),
    [workflow, setWorkflow]
  );

  const setDescription = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) =>
      setWorkflow({ ...workflow, description: e.currentTarget.value }),
    [workflow, setWorkflow]
  );

  const setOwnerId = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) =>
      setWorkflow({ ...workflow, ownerId: e.currentTarget.value }),
    [workflow, setWorkflow]
  );

  const onImportWorkflow = useCallback(
    async (e: React.SyntheticEvent<HTMLInputElement>) => {
      try {
        const { files } = e.target as HTMLInputElement;
        if (!files || !files[0]) return;
        const {
          workflow: importWorkflow,
          groups,
          presets,
          newConfig,
          patchConfig
        } = await parseConfig(files[0]);
        if (!importWorkflow || !newConfig) return;
        setWorkflow({ ...workflow, ...importWorkflow });
        setPresets(presets);
        setGroups(groups);
        setNewConfig(newConfig);
        setPatchConfig(patchConfig);

        successSnackbar(`The import has been successfully added.`);
      } catch (err: any) {
        setImportError(err);
      }
    },
    [
      setWorkflow,
      setPresets,
      setNewConfig,
      setPatchConfig,
      workflow,
      successSnackbar
    ]
  );

  const closeImportError = useCallback(() => {
    setImportError(undefined);
  }, [setImportError]);

  const onSubmit = useCallback(async () => {
    const settings: any = { presets };
    if (workflow.prefix) {
      settings.key = workflow.prefix;
    }
    const { data } = await createWorkflow({
      variables: {
        workflow: {
          settings,
          name: workflow.name,
          description: workflow.description,
          ownerId: workflow.ownerId
        }
      }
    });
    const workflowRowId = data.createWorkflow.workflow.rowId;

    // Group creation and config creation+update can be parallelized
    // TODO: Apollo might even be able to batch GraphQL queries if they're fired
    // close enough in time with the right configuration
    async function doConfig() {
      if (newConfig) {
        const { data } = await createConfig({
          variables: { config: { ...newConfig, workflowRowId } }
        });
        const workflowConfigRowId = data.newWorkflowConfig.workflowConfig.rowId;
        await updateConfig({
          variables: { rowId: workflowConfigRowId, patch: patchConfig }
        });
      }
    }
    const doGroups = async () =>
      groups &&
      Promise.all(
        groups.map(group =>
          createGroup({
            variables: {
              workflowId: workflowRowId,
              // By default, the workflow owner will also be
              // the groups owner, but that can be fixed manually
              // when adding the right accounts in the group management
              // page anyway.
              ownerId: workflow.ownerId,
              ...group
            }
          })
        )
      );
    await Promise.all([doConfig(), doGroups()]);

    successSnackbar(
      `The **${workflow.name}** workflow has been successfully created.`
    );

    history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [
    workflow,
    presets,
    groups,
    newConfig,
    patchConfig,
    createWorkflow,
    createConfig,
    createGroup,
    updateConfig,
    history,
    successSnackbar
  ]);

  const onCancel = useCallback(async () => {
    history.push(ROUTE_WORKFLOWS);
  }, [history]);

  const header = useMemo(
    () => (
      <HeaderLayout
        breadcrumbs={[
          ...getWorkflowsBreadCrumbs(true),
          { label: 'New workflow' }
        ]}
      />
    ),
    []
  );

  if (error) {
    return <div> Error: {JSON.stringify(error)}</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  // TODO: clean up once proper admin ui acl is in place
  const organizations = data.me.isSuperuser
    ? data.organizations
    : data.me.organizations;

  return (
    <div>
      {header}
      <div className={classes.root}>
        <div className={classes.creationWindow}>
          <div className={classes.title}>
            Create a new workflow
            <label className={classes.importLabel}>
              <input
                className={classes.importInput}
                type="file"
                onChange={onImportWorkflow}
              />
              <Pushbutton>Import workflow</Pushbutton>
            </label>
          </div>
          <div className={classes.row}>
            <div className={classes.name}>
              <FieldTextCompact
                label="Workflow name"
                value={workflow.name}
                onValueChange={setName}
              />
            </div>
            <div className={classes.prefix}>
              <FieldTextCompact
                label="Trace prefix"
                value={workflow.prefix}
                onValueChange={setPrefix}
              />
            </div>
          </div>
          <div className={classes.row}>
            <FieldTextCompact
              label="Workflow description"
              value={workflow.description}
              onValueChange={setDescription}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.owner}>
              <Dropdown
                value={workflow.ownerId}
                label="Owner"
                hideLabel
                onValueChange={setOwnerId}
              >
                {organizations.nodes.map(o => (
                  <OptionDrop
                    label={o.name}
                    key={o.accountId}
                    value={o.accountId}
                    selected={o.accountId === workflow.ownerId}
                  />
                ))}
              </Dropdown>
            </div>
          </div>
          <div className={classes.row}>
            <Pushbutton onClick={onCancel}>Cancel</Pushbutton>
            <Pushbutton primary onClick={onSubmit} disabled={disabled}>
              Create workflow
            </Pushbutton>
          </div>
        </div>
        {importError && (
          <ImportErrorModal error={importError} close={closeImportError} />
        )}
      </div>
    </div>
  );
};

export default withRouter(withSnackbarsContext(NewWorkflow));
