import React, { FC } from 'react';

import PropertiesList from '../../../../propertiesList';
import { SubFormInputDefinition } from '../../../../types';
import useStyles from './body.style';

interface Props {
  path: string;
  input: SubFormInputDefinition;
  depth: number; // this is the depth level of the parent object of this sub-form
}

// form sub form input configuration body component
export const Body: FC<Props> = ({ path, input, depth }) => {
  const classes = useStyles();

  const { properties } = input;

  return (
    <div className={classes.root}>
      <PropertiesList
        path={`${path}.properties`}
        properties={properties}
        depth={depth + 1} // increment depth so that the property input selector can know whether to disable sub-forms or lists
        propertiesClassName={classes.property}
        addButtonLabel="Add sub-form input"
      />
    </div>
  );
};

export default React.memo(Body);
