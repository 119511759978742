import React, { FC, useMemo, useContext, useCallback } from 'react';

import { MultiSelect } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import { WorkflowContext } from 'components/workflow/context';

import {
  GroupSelectionInputDefinition,
  GroupSelectionInputConditionDefinition
} from '../../../../types';

import { DependenciesContext } from '../../../dependenciesModal/context';

import { FormBuilderContext } from '../../../../context';

import useStyles from './conditions.style';

interface Props {
  path: string;
  input: GroupSelectionInputDefinition;
  condition: GroupSelectionInputConditionDefinition;
  dependencyKey: string;
}

// form group selection condition interface
// this is the interface displayed for a specific dependency
export const Condition: FC<Props> = ({
  path,
  input, // note: input left here as this is the generic condition interface signature but not used in this case
  condition,
  dependencyKey
}) => {
  const classes = useStyles();
  const { set } = useContext(DataContext);

  // it needs to know all the currentDependencies to be able to list the remaining groups
  const currentDependencies = useContext(DependenciesContext);

  // read any potential workflow context provided
  const workflow = useContext(WorkflowContext);

  // build list of groups labels used by other conditions to filter them out
  const disabledGroups = useMemo(
    () =>
      currentDependencies.conditions?.reduce(
        (list, { key: depKey, condition: depCondition = {} }) => {
          if (dependencyKey !== depKey) {
            list.push(
              ...((depCondition as GroupSelectionInputConditionDefinition)
                .values || [])
            );
          }
          return list;
        },
        [] as string[]
      ) || [],
    [dependencyKey, currentDependencies]
  );

  // build list of available groups for this workflow in the multiselect
  const multiselectGroups = useMemo(() => {
    if (!workflow) return [];
    return (workflow.groups || []).reduce((currentGroups, { label, name }) => {
      if (!disabledGroups.includes(label)) {
        currentGroups.push({
          label: name || label,
          value: label
        });
      }
      return currentGroups;
    }, [] as { label: string; value: string }[]);
  }, [workflow, disabledGroups]);

  // build list of selected groups for this condition and callback to update
  // note: we apply an extra filter to remove values that could be invalid
  const multiselectValues = useMemo(() => {
    const availableGroups = multiselectGroups.map(({ value }) => value);
    return (condition?.values || []).filter(label =>
      availableGroups.includes(label)
    );
  }, [condition, multiselectGroups]);
  const onSelect = useCallback(
    selectedLabels => set(`${path}.values`, selectedLabels),
    [set, path]
  );

  // check from form builder context if errors shall be displayed
  const { showErrors } = useContext(FormBuilderContext);
  const invalid = useMemo(() => showErrors && !condition?.values?.length, [
    condition,
    showErrors
  ]);

  return (
    <div className={classes.multiselect}>
      <MultiSelect
        dataCy="dropdown-condition"
        displayValue="Group"
        options={multiselectGroups}
        selectedValues={multiselectValues}
        onSelect={onSelect}
        displayLabelIfOneSelected
        shadows
        invalid={invalid}
      />
    </div>
  );
};

export const getErrors = (
  _: GroupSelectionInputDefinition,
  errors: string[],
  condition?: GroupSelectionInputConditionDefinition
) => {
  if (!condition?.values?.length) {
    errors.push('No group condition value selected');
  }
};

export default {
  component: React.memo(Condition),
  getErrors
};
