import { HelperActionField } from 'schemaBuilder/types';
import { ConverterFieldPayload } from 'schemaBuilder/converter/types';

export default (field: HelperActionField): ConverterFieldPayload => ({
  jsonSchema: {
    type: 'object',
    properties: {
      // Make sure that no key conflicts happen by using the
      // universally unique field ID instead of the camelized key
      [field.schemaField.id]: {
        type: 'null',
        title: field.schemaField.helper
      }
    }
  },
  uiSchema: {
    'ui:order': [field.schemaField.id]
  }
});
