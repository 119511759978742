import { ReactNode, useCallback, useContext, useMemo, memo } from 'react';
import { createPortal } from 'react-dom';

import { Draggable } from 'react-beautiful-dnd';

import {
  FieldTextCompact,
  Switch,
  Icon,
  SmartDropdown
} from '@stratumn/atomic';
import theme from 'style';

import { DataContext, useToggle } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';
import { getTraceStateFieldsUtils } from 'schemaBuilder/converter/admin';

import {
  Card,
  CardActions,
  CardContent,
  IconButton
} from 'components/workflow/ui';

import { DisplayContext } from '../../context';

import { parsePathValue, typesCustomLabel } from './utils';
import WidgetSettingsMap from './widgetSettings';
import WidgetSettingsModal from './settingsModal';

import useStyles from './columnFields.style';

enum FieldEnums {
  Label = 'label',
  Type = 'type',
  Path = 'path'
}

export interface Props {
  index: number;
  path: string;
  value: {
    key: string;
    header: string;
    // TODO: strongly type
    // a few widgets are still not being supported such as activity and icon
    cell?: any;
  };
  isFixed?: boolean;
  optionsTypes: String[];
  insertColumn?: (index: number) => void; // insert a new column after this one
}

const initialCellValues = {
  view: {
    path: '',
    type: ''
  }
};

export const ColumnFields = ({
  index,
  path,
  value,
  isFixed,
  insertColumn
}: Props) => {
  const classes = useStyles(isFixed);

  const {
    key,
    header = '',
    cell: { view: { path: viewPath = '', type } } = initialCellValues
  } = value;

  const { traceFieldOptions } = useContext(DisplayContext);

  const {
    getIdFromPath,
    getTypes,
    getType,
    getPath,
    getOption
  } = getTraceStateFieldsUtils('overview');

  const fieldKey = getIdFromPath(viewPath);

  const availablesTypes = useMemo(() => {
    return getTypes(traceFieldOptions, fieldKey);
  }, [getTypes, traceFieldOptions, fieldKey]);

  const { set, update, delete: delData, append, splice } = useContext(
    DataContext
  );

  const handleUpdateColumn = useCallback(
    (field: string, value: string): void => {
      if (field === FieldEnums.Label) {
        return set(`${path}.header`, value || undefined);
      }

      if (field === FieldEnums.Type) {
        const { path: parsedPath, value: parsedValue } = parsePathValue(
          viewPath,
          value
        );
        return set(`${path}.cell.${parsedPath}`, parsedValue || undefined);
      }

      return set(`${path}.cell.view.${field}`, value || undefined);
    },

    [path, set, viewPath]
  );

  const onSelectColumn = useCallback(
    (option: any) => {
      if (option) {
        handleUpdateColumn(FieldEnums.Label, option.label);

        const type = getType(option);
        handleUpdateColumn(FieldEnums.Type, getType(option));
        handleUpdateColumn(FieldEnums.Path, getPath(option, type));
      } else {
        // Clean action on input
        handleUpdateColumn(FieldEnums.Label, '');
        handleUpdateColumn(FieldEnums.Type, '');
        handleUpdateColumn(FieldEnums.Path, '');
      }
    },
    [handleUpdateColumn, getType, getPath]
  );

  const onSelectType = useCallback(
    (selected: string) => {
      if (selected) {
        const option = getOption(traceFieldOptions, fieldKey);
        if (option) {
          handleUpdateColumn(FieldEnums.Type, selected);
          handleUpdateColumn(FieldEnums.Path, getPath(option, selected));
        }
      } else {
        handleUpdateColumn(FieldEnums.Type, '');
      }
    },
    [handleUpdateColumn, traceFieldOptions, fieldKey, getPath, getOption]
  );

  const handleRemoveColumn = useCallback((): void => {
    const revertMessage = `The **column** has been successfuly deleted.`;

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, path]);

  const handleAddInBetweenColumn = useCallback((): void => {
    if (!insertColumn) return;
    insertColumn(index);
  }, [insertColumn, index]);

  const handleFixColumn = useCallback(() => {
    const columnValuesToMigrate = { ...value };

    delData(path);

    if (isFixed) {
      splice('overview.columns', 0, 0, [columnValuesToMigrate]);
    } else {
      append('overview.fixedColumns', columnValuesToMigrate);
    }
  }, [append, isFixed, path, splice, delData, value]);

  // settings modal
  const [showSettingsModal, toggleSettingsModal] = useToggle(false);
  const onSubmitSettings = useCallback(
    newCell => {
      set(`${path}.cell`, newCell);
      toggleSettingsModal();
    },
    [set, path, toggleSettingsModal]
  );

  const renderCardActionsButtons = useMemo(() => {
    // check if this widget has specific settings
    // and build the generic button leading to the modal
    let widgetSpecificButton: ReactNode;
    const widgetSettings = WidgetSettingsMap[type];
    if (widgetSettings) {
      const { icon, label } = widgetSettings;
      widgetSpecificButton = (
        <IconButton
          dataCy="open-settings"
          name={icon}
          onClick={toggleSettingsModal}
          ariaLabel={label}
        />
      );
    }

    return (
      <CardActions>
        {widgetSpecificButton}
        <IconButton
          dataCy="remove-column"
          name="Trash"
          onClick={handleRemoveColumn}
          ariaLabel="Remove column"
        />
      </CardActions>
    );
  }, [handleRemoveColumn, type, toggleSettingsModal]);

  const getDraggableColumn = (providedDraggable, snapshotDraggable) => {
    const draggableColumn = (
      <div
        ref={providedDraggable.innerRef}
        className={classes.draggable}
        {...providedDraggable.draggableProps}
      >
        <Card isDragging={snapshotDraggable.isDragging}>
          <CardContent>
            <div className={classes.row}>
              <div
                className={classes.dragHandle}
                {...providedDraggable.dragHandleProps}
              >
                <Icon name="Drag" size={20} />
              </div>

              <div className={classes.columnSelector}>
                <SmartDropdown
                  compact
                  label="Trace state field"
                  placeholder="Search for a trace state field"
                  value={fieldKey}
                  options={traceFieldOptions?.map(t => ({
                    label: t.label,
                    value: t.key
                  }))}
                  onSelect={selected => {
                    const option = traceFieldOptions.find(
                      s => s.key === selected
                    );
                    onSelectColumn(option);
                  }}
                  dataCy="edit-column-path"
                />
              </div>
              <div className={classes.fieldTextCompactWrapper}>
                <FieldTextCompact
                  dataCy="edit-column-header"
                  onValueChange={e =>
                    handleUpdateColumn(FieldEnums.Label, e.target.value)
                  }
                  value={header}
                  label="Column label"
                  disabled={!fieldKey}
                />
              </div>
              <div className={classes.dropdownWrapper}>
                <SmartDropdown
                  compact
                  label="Format"
                  value={type}
                  options={availablesTypes!.map(value => ({
                    label: typesCustomLabel[value] || value,
                    value
                  }))}
                  onSelect={onSelectType}
                  dataCy="select-column-type"
                  disabled={!fieldKey || availablesTypes.length === 1}
                />
              </div>
              <div className={classes.fixColumnSwitch}>
                <Switch
                  label="Fix column"
                  on={isFixed}
                  handleChange={handleFixColumn}
                  dataCy="toggle-fix-column"
                  showLabel
                  invert
                />
              </div>
            </div>
          </CardContent>

          {renderCardActionsButtons}
        </Card>
        {!!insertColumn && (
          <div data-cy="dashed-line" className={classes.dashedLineWrapper}>
            <div className={classes.dashedLine}>
              <div className={classes.addInBetweenColumnIcon}>
                <IconButton
                  dataCy="add-in-between-column"
                  name="CirclePlusFill"
                  color={theme.indigo2}
                  onClick={handleAddInBetweenColumn}
                  ariaLabel="Add in between column"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );

    return snapshotDraggable.isDragging
      ? createPortal(draggableColumn, document.body)
      : draggableColumn;
  };

  return (
    <>
      <Draggable draggableId={key} index={index}>
        {getDraggableColumn}
      </Draggable>
      {showSettingsModal && (
        <WidgetSettingsModal
          initWidget={value.cell}
          submit={onSubmitSettings}
          cancel={toggleSettingsModal}
          data-cy="widget-settings-modal"
        />
      )}
    </>
  );
};

export default memo(ColumnFields);
