import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    marginBottom: 34
  },
  header: {
    display: 'flex',
    marginBottom: 18,
    cursor: ({ nextActionsCount }) =>
      nextActionsCount > 0 ? 'pointer' : 'unset'
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.03em',
    color: theme.grey1
  },
  title: {
    marginRight: 4
  },
  counter: {
    marginRight: 10
  },
  collapsibleBtn: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out',
    '&[data-is-collapse="true"]': {
      transform: 'rotate(-90deg)'
    }
  },
  addBtn: {
    color: '#4B35D2',
    cursor: 'pointer',
    width: 25
  },
  collapseBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginRight: 42, // width of trash icon plus its margin
    '& > button': {
      fontSize: 12,
      lineHeight: '15px',
      color: theme.grey1
    }
  },
  separator: {
    margin: '0 5px',
    color: theme.grey5
  }
}));
