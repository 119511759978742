import { memo } from 'react';
import { FieldTextCompact } from '@stratumn/atomic';
import { Card, CardActions, CardContent } from 'components/workflow/ui';
import { HelperActionField, BaseTypeProps } from 'schemaBuilder/types';

import { useSetCallback } from 'schemaBuilder/modules/action/hooks/fields';
import { useValidation } from 'schemaBuilder/modules/action/hooks/validator';

import useStyles from './helper.style';

export interface Props extends BaseTypeProps {
  field: HelperActionField;
}

export const Helper = ({
  isDragging,
  field,
  dragElement,
  removeElement,
  typeSelectElement,
  path,
  theme,
  readonly
}: Props) => {
  const validation = useValidation();
  const classes = useStyles();
  const handleContentUpdate = useSetCallback(`${path}.schemaField.helper`);

  return (
    <Card
      isDragging={isDragging}
      invalid={validation.hasErrorsByGroup(`${path}`)}
    >
      <CardContent theme={theme}>
        <div className={classes.header}>
          {dragElement}
          <div className={classes.content}>
            <FieldTextCompact
              disabled={readonly}
              invalid={validation.hasErrors(`${path}.schemaField.helper`)}
              value={field.schemaField.helper}
              label="Helper content"
              onValueChange={handleContentUpdate}
            />
          </div>
          {typeSelectElement}
        </div>
      </CardContent>
      <CardActions theme={theme}>{removeElement}</CardActions>
    </Card>
  );
};

export default memo(Helper);
