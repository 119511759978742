import React, {
  FC,
  useMemo,
  useCallback,
  memo,
  useEffect,
  ReactNode
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Pushbutton,
  Pushtext,
  withSnackbarsContext,
  ConfirmationModal
} from '@stratumn/atomic';

import {
  parseLocalStorage,
  LOCAL_STORAGE_TRANSITIONS_KEY
} from 'utils/localStorage';

import { getTraceFieldOptions } from 'schemaBuilder/converter/admin';
import { HeaderLayout } from 'components/layouts';

import { ROUTE_WORKFLOW_DETAILS } from 'constant/routes';
import { getWorkflowBreadCrumbs } from 'constant/pages';

import {
  DataContext,
  useDataReducer,
  useToggle,
  useConfirmExit
} from 'utils/hooks';
import { DataUpdateType } from 'utils/data';
import { ActionRow } from './actionRow';
import { isConfigDirty } from '../../../components/workflow/utils';

import { TransitionsContext, TransitionsContextDefinition } from './context';
import { buildTransitionsArr } from './utils/parsing';

import {
  State,
  UPDATE_TRANSITIONS,
  ReducerAction
} from '../../../components/workflow/reducers';
import { TParams, FromActionDefinition } from './types';
import { validateNextActions } from './utils/validate';
import { buildNextActionsObj } from './utils/unparsing';

import useStyles from './transitions.style';

const getTransitionLocalStorageKey = (wfId: string): string =>
  LOCAL_STORAGE_TRANSITIONS_KEY + wfId;

interface Props extends RouteComponentProps<TParams> {
  state: State;
  dispatch: React.Dispatch<ReducerAction>;
  successSnackbar: (m: string) => void;
  errorSnackbar: (m: string) => void;
}

interface IDataReducer {
  data: FromActionDefinition[];
  formIsValid: boolean;
}

export const Transitions: FC<Props> = ({
  history,
  match: {
    params: { id: workflowRowId }
  },
  state,
  successSnackbar,
  errorSnackbar,
  dispatch: dispatchWorkflowManager
}) => {
  const classes = useStyles();

  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);

  const initialTransitionsContext: IDataReducer = useMemo(
    () => ({
      data:
        parseLocalStorage(getTransitionLocalStorageKey(workflowRowId))?.data ||
        buildTransitionsArr(state) ||
        [],
      formIsValid: false
    }),
    [state, workflowRowId]
  );

  const [transitions, transitionsContext] = useDataReducer<IDataReducer>(
    initialTransitionsContext,
    { localStorageKey: getTransitionLocalStorageKey(workflowRowId) }
  );

  const { set, update } = transitionsContext;

  const transitionIsDirty = useMemo(() => {
    return isConfigDirty(
      state.workflow.config.transitions,
      buildNextActionsObj(transitions.data)
    );
  }, [state.workflow.config.transitions, transitions.data]);

  useConfirmExit(transitionIsDirty || state.isDirty);

  // set doc title on mount
  useEffect(() => {
    const {
      workflow: { name }
    } = state || {};
    if (name) document.title = `Transitions - ${name} - Admin UI`;
  }, [state]);

  const actions: { key: string; title: string }[] = useMemo(() => {
    if (!state?.workflow?.config) return [];

    return Object.keys(state.workflow.config.actions).map(key => ({
      key,
      title: state.workflow.config.actions[key].title
    }));
  }, [state]);

  const handleCancel = () => {
    if (transitionIsDirty && !showConfirmationModal)
      return toggleShowConfirmationModal();
    localStorage.removeItem(getTransitionLocalStorageKey(workflowRowId));

    return history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  };

  const handleSubmit = useCallback((): void => {
    const { isValid, message } = validateNextActions(transitions.data);

    if (!isValid) {
      // This will trigger inline validation
      set('formIsValid', false);
      return errorSnackbar(message!.join(', '));
    }

    dispatchWorkflowManager({
      type: UPDATE_TRANSITIONS,
      data: buildNextActionsObj(transitions.data)
    });

    // since the dispatchWorkflowManager triggers the api mutation, we can remove the transitions from the local storage
    localStorage.removeItem(getTransitionLocalStorageKey(workflowRowId));

    successSnackbar(
      'The workflow transitions was updated locally. Please commit your changes.'
    );

    return history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [
    dispatchWorkflowManager,
    errorSnackbar,
    history,
    set,
    successSnackbar,
    transitions.data,
    workflowRowId
  ]);

  const transitionsContextValues: TransitionsContextDefinition = useMemo(
    () => ({
      data: transitions.data,
      actions,
      groups: state?.workflow?.groups?.nodes,
      formIsValid: transitions.formIsValid,
      traceFieldOptions: getTraceFieldOptions(state?.workflow?.config?.admin)
    }),
    [
      actions,
      state?.workflow?.groups?.nodes,
      state?.workflow?.config?.admin,
      transitions.data,
      transitions.formIsValid
    ]
  );

  const handleCollapseAllActions = useCallback(
    (isCollapsed: boolean): void => {
      update(
        transitions.data.map((_, idx) => {
          return {
            type: DataUpdateType.Set,
            path: `data.${idx}.transitionCollapsed`,
            value: isCollapsed
          };
        })
      );
    },
    [transitions.data, update]
  );

  const collapsedAllActions: boolean = useMemo(
    () => transitions.data.some(transition => transition.transitionCollapsed),
    [transitions.data]
  );

  const renderFromActions: ReactNode = useMemo(
    () => (
      <ul className={classes.nextActionsWrapper}>
        {transitions.data.map(
          (
            { actionKey, actionTitle, nextActions, transitionCollapsed },
            index
          ) => (
            <li key={`${actionKey}${index}`}>
              <ActionRow
                path={`data.${index}`}
                actionTitle={actionTitle}
                nextActions={nextActions}
                transitionCollapsed={transitionCollapsed}
              />
            </li>
          )
        )}
      </ul>
    ),
    [classes.nextActionsWrapper, transitions.data]
  );

  const header = useMemo(
    () => (
      <HeaderLayout
        dirty={{
          module: 'Transitions',
          moduleIsDirty: transitionIsDirty,
          stateIsDirty: state.isDirty
        }}
        breadcrumbs={[
          ...getWorkflowBreadCrumbs(
            state.workflow.name,
            state.workflow.rowId,
            true
          ),
          { label: 'Transitions' }
        ]}
      />
    ),
    [
      transitionIsDirty,
      state.isDirty,
      state.workflow.name,
      state.workflow.rowId
    ]
  );

  return (
    <>
      {header}
      <DataContext.Provider value={transitionsContext}>
        <TransitionsContext.Provider value={transitionsContextValues}>
          <div className={classes.root}>
            <header className={classes.header}>
              <h1 className={classes.h1}>Transitions</h1>
              <div className={classes.collapseBtnWrapper}>
                <Pushtext
                  dataCy="expand-action-rows"
                  onClick={() => handleCollapseAllActions(false)}
                  disabled={!collapsedAllActions}
                  mute={!collapsedAllActions}
                >
                  Expand
                </Pushtext>
                <span className={classes.separator}> | </span>
                <Pushtext
                  dataCy="collapse-action-rows"
                  onClick={() => handleCollapseAllActions(true)}
                  disabled={collapsedAllActions}
                  mute={collapsedAllActions}
                >
                  Collapse
                </Pushtext>
              </div>
            </header>

            {renderFromActions}

            <div className={classes.actionButtonWrapper}>
              <div className={classes.cancelButton}>
                <Pushbutton dataCy="cancel-btn" onClick={handleCancel}>
                  cancel
                </Pushbutton>
              </div>
              <Pushbutton
                dataCy="done-btn"
                primary
                onClick={handleSubmit}
                disabled={!transitionIsDirty}
              >
                done
              </Pushbutton>
            </div>
          </div>
          {showConfirmationModal && transitionIsDirty && (
            <ConfirmationModal
              warning
              title="Quit Transitions module"
              content="You’re about to quit Transitions module without saving your changes.<br>**All unsaved changes will be lost**.<br>Are you sure?"
              confirmBtnText="quit module"
              confirm={handleCancel}
              cancel={toggleShowConfirmationModal}
            />
          )}
        </TransitionsContext.Provider>
      </DataContext.Provider>
    </>
  );
};

export default withSnackbarsContext(memo(Transitions));
