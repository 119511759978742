import { FC, memo, ReactNode } from 'react';

import useStyles from './tabNav.style';

interface Props {
  children: ReactNode;
}

export const TabNav: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <nav className={classes.tabMenuRoot}>
      <ul>{children}</ul>
    </nav>
  );
};

export default memo(TabNav);
