import { FC, memo, useCallback, useContext } from 'react';

import { FieldTextCompact, SmartDropdown } from '@stratumn/atomic';

import {
  Card,
  CardContent,
  CardActions,
  IconButton
} from 'components/workflow/ui';
import {
  getTraceStateFieldsUtils,
  TraceFieldOption
} from 'schemaBuilder/converter/admin';

import { DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';
import { EventChangeHandler } from 'utils/types';
import { ActionDefinition } from 'utils/interfaces/statuses';

import { StatusContext } from '../../../context';

import useStyles from './actionCard.style';

export interface Props {
  path: string;
  data: ActionDefinition;
}

export const ActionCard: FC<Props> = ({ path, data }) => {
  const classes = useStyles();

  const { set, update } = useContext(DataContext);
  const { actions, showErrors, traceFieldOptions } = useContext(StatusContext);

  const {
    getIdFromPath,
    getTypes,
    getPath,
    getType,
    getOption
  } = getTraceStateFieldsUtils('conditions');

  const { key, label, condition } = data;

  let errorsStr = '';
  if (showErrors && (!label || label === '')) {
    errorsStr = 'empty field';
  }

  const handleSelectAction: (value: string) => void = useCallback(
    value => {
      const selectedAction = actions.find(action => action.key === value);

      set(`${path}.key`, value);
      set(`${path}.label`, selectedAction?.title || '');
    },
    [actions, path, set]
  );

  const handleEditStateValue: (option?: TraceFieldOption) => void = useCallback(
    option => {
      if (option) {
        const type = getType(option);
        set(`${path}.condition.stateValue`, getPath(option, type));
        set(`${path}.condition.operator`, type);
      } else {
        set(`${path}.condition.stateValue`, '');
        set(`${path}.condition.operator`, '');
      }
    },
    [path, set, getPath, getType]
  );

  const fieldKey = getIdFromPath(condition?.stateValue);

  const handleEditConditionOperator: (string) => void = useCallback(
    value => {
      const option = getOption(traceFieldOptions, fieldKey);
      console.log('??', value, fieldKey, traceFieldOptions);
      if (option) {
        set(`${path}.condition.stateValue`, getPath(option, value));
        set(`${path}.condition.operator`, value);
      }
    },
    [path, set, getOption, getPath, traceFieldOptions, fieldKey]
  );

  const handleEditConditionComparedValue: EventChangeHandler = useCallback(
    e => {
      set(`${path}.condition.comparedValue`, e.target.value);
    },
    [path, set]
  );

  const handleRemoveStatusAction = useCallback((): void => {
    const revertMessage = `The **status action** has been successfully deleted.`;

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, path]);

  const availableFieldsOptions =
    traceFieldOptions?.filter(o => o.conditions.list.length > 0) ?? [];

  const availableConditions = getTypes(traceFieldOptions, fieldKey);

  return (
    <Card>
      <CardContent>
        <div className={classes.container}>
          <div
            className={classes.selectGroupWrapper}
            data-cy="select-beginning-action-wrapper"
          >
            <SmartDropdown
              compact
              label="Begin action"
              value={key}
              options={actions!.map(({ key, title }) => ({
                label: title,
                value: key
              }))}
              onSelect={handleSelectAction}
              dataCy="select-beginning-action"
              invalid={!!errorsStr}
            />
          </div>
          <div className={classes.conditionWrapper}>
            <span className={classes.condition}>if</span>
            <div className={classes.fieldsWrapper}>
              <div className={classes.inputStateValueWrapper}>
                <SmartDropdown
                  dataCy="edit-state-value"
                  value={fieldKey}
                  label="Trace state field"
                  placeholder="Search for a trace state field"
                  compact
                  options={availableFieldsOptions?.map(t => ({
                    label: t.label,
                    value: t.key
                  }))}
                  onSelect={selected => {
                    const option = availableFieldsOptions.find(
                      s => s.key === selected
                    );
                    handleEditStateValue(option);
                  }}
                />
              </div>

              <div className={classes.selectConditionWrapper}>
                <SmartDropdown
                  compact
                  label="Condition"
                  value={condition?.operator || ''}
                  options={availableConditions!.map(value => ({
                    label: value,
                    value
                  }))}
                  onSelect={handleEditConditionOperator}
                  dataCy="edit-condition-operator"
                  disabled={
                    availableConditions.length === 0 ||
                    availableConditions.length === 1
                  }
                />
              </div>

              <div className={classes.inputValueWrapper}>
                <FieldTextCompact
                  dataCy="edit-condition-compared-value"
                  onValueChange={handleEditConditionComparedValue}
                  value={condition?.comparedValue || ''}
                  label="Value"
                />
              </div>
            </div>
          </div>
        </div>
      </CardContent>

      <CardActions>
        <IconButton
          dataCy="remove-action-card"
          name="Trash"
          onClick={handleRemoveStatusAction}
          ariaLabel="Remove status action card"
        />
      </CardActions>
    </Card>
  );
};

export default memo(ActionCard);
