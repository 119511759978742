import { ReactElement } from 'react';
import { Icon } from '@stratumn/atomic';
import { SchemaFieldType } from 'schemaBuilder/types';
import ShortText from './types/shortText';
import ShortTextBody from './types/shortText/body';
import Helper from './types/helper';
import Boolean from './types/boolean';
import BooleanBody from './types/boolean/body';
import Date from './types/date';
import Checkboxes from './types/checkboxes';
import CheckboxesBody from './types/checkboxes/body';
import Groups from './types/groups';
import RichTextBody from './types/richText/body';
import RichText from './types/richText';
import TextareaBody from './types/textarea/body';
import Textarea from './types/textarea';
import DropdownBody from './types/dropdown/body';
import Dropdown from './types/dropdown';
import Number from './types/number';
import FileUpload from './types/fileUpload';
import RadioButtons from './types/radioButtons';
import RadioButtonsBody from './types/radioButtons/body';
import SubForm from './types/subForm';
import SubFormBody from './types/subForm/body';
import List from './types/list';
import ListBody from './types/list/body';

export interface FieldType {
  type?: SchemaFieldType;
  label: string;
  icon: string;
  component?: (props: any) => ReactElement | null;
  body?: (props: any) => ReactElement | null;
}

const fieldTypes: FieldType[] = [
  {
    type: SchemaFieldType.ShortText,
    label: 'Short text',
    icon: 'TextParaSmall',
    component: ShortText,
    body: ShortTextBody
  },
  {
    type: SchemaFieldType.Boolean,
    label: 'Boolean',
    icon: 'Boolean',
    component: Boolean,
    body: BooleanBody
  },
  {
    type: SchemaFieldType.Date,
    label: 'Date',
    icon: 'Calendar',
    component: Date
  },
  {
    type: SchemaFieldType.Groups,
    label: 'Group selection',
    icon: 'Group',
    component: Groups
  },
  {
    type: SchemaFieldType.RichText,
    label: 'Rich text',
    icon: 'TextParaSmall',
    component: RichText,
    body: RichTextBody
  },
  {
    type: SchemaFieldType.SubForm,
    label: 'Sub-form',
    icon: 'IdLight',
    component: SubForm,
    body: SubFormBody
  },
  {
    type: SchemaFieldType.List,
    label: 'List',
    icon: 'IdLight',
    component: List,
    body: ListBody
  },
  {
    type: SchemaFieldType.RichList,
    label: 'Rich list',
    icon: 'IdLight',
    component: SubForm,
    body: SubFormBody
  },
  {
    type: SchemaFieldType.Textarea,
    label: 'Textarea',
    icon: 'TextParaSmall',
    component: Textarea,
    body: TextareaBody
  },
  {
    type: SchemaFieldType.Dropdown,
    label: 'Dropdown',
    icon: 'LightArrowDown',
    component: Dropdown,
    body: DropdownBody
  },
  {
    type: SchemaFieldType.Number,
    label: 'Number',
    icon: 'Ruler',
    component: Number
  },
  {
    type: SchemaFieldType.Checkboxes,
    label: 'Checkboxes',
    icon: 'CheckboxDropdown',
    component: Checkboxes,
    body: CheckboxesBody
  },
  {
    type: SchemaFieldType.FileUpload,
    label: 'File Upload',
    icon: 'Paperclip',
    component: FileUpload
  },
  {
    type: SchemaFieldType.Helper,
    label: 'Helper',
    icon: 'CircleInfo',
    component: Helper
  },
  {
    type: SchemaFieldType.RadioButtons,
    label: 'Radio buttons',
    icon: 'RadioDropdown',
    component: RadioButtons,
    body: RadioButtonsBody
  }
];

export function getBody(type) {
  return fieldTypes.find(t => t.type === type)?.body;
}

export const fieldTypesMap = new Map(
  fieldTypes.map(field => [field.type, field])
);

const toSmartDropdownOption = (type: any) => ({
  label: type.label,
  value: type.type,
  prefix: <Icon name={type.icon} size={20} />
});

export const subFormFieldTypesOptions = fieldTypes
  .filter(
    (t: FieldType) =>
      !t.type ||
      ![SchemaFieldType.List, SchemaFieldType.SubForm].includes(t.type)
  )
  .map(toSmartDropdownOption);

export const simpleListFieldTypesOptions = fieldTypes
  .filter(
    (t: FieldType) =>
      t.type &&
      [
        SchemaFieldType.Number,
        SchemaFieldType.Date,
        SchemaFieldType.ShortText
      ].includes(t.type)
  )
  .map(toSmartDropdownOption);

export const fieldTypesOptions = fieldTypes.map(toSmartDropdownOption);

export const localOnlyFieldTypes = new Set([SchemaFieldType.Helper]);
