import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&[data-group-duplicated=true]': {
      alignItems: 'baseline'
    }
  },
  removeBtn: {
    display: 'flex',
    '&:focus': {
      outline: 'none'
    }
  },
  trashIcon: {
    height: 22,
    color: theme.grey1,
    transition: '200ms',
    '&:hover': {
      color: theme.warningRed
    }
  },
  selectGroupWrapper: {
    width: 283
  },
  addCondition: {
    marginLeft: 21,
    width: '100%'
  },
  variableIcon: {
    padding: '1px 3px',
    backgroundColor: theme.grey3,
    borderRadius: 10,
    lineHeight: '8px',
    fontWeight: 600,
    fontSize: 6,
    letterSpacing: 0.15,
    color: theme.white1,
    textTransform: 'uppercase'
  }
}));
