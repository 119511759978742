import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 300,
    paddingBottom: 10
  },
  footer: {
    marginLeft: 25,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  field: {
    marginLeft: 15,
    width: 320
  },
  bodyWrapper: {
    borderTop: `1px solid ${theme.grey7}`,
    marginLeft: 25
  },
  spacer: {
    flex: 1
  }
}));
