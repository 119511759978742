import { useState, useCallback } from 'react';

/**
 * @function useToggle
 * @desc this hook provides a toggle state
 * @desc the callback provided enables to switch the toggle status
 * @param {boolean} initToggle - whether the state is initially toggled
 * @returns {Array} - an array that contains toggle, switchToggle
 */
export default (initToggle: boolean): [boolean, () => void] => {
  // State and setter for toggle
  const [toggle, setToggle] = useState(initToggle);

  // callback
  const switchToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  return [toggle, switchToggle];
};
