import React, { FC, useCallback, useContext } from 'react';
import classnames from 'classnames';

import { FieldTextCompact } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import useStyles from './text.style';

interface Props {
  label: string;
  value?: string;
  path: string;
  className?: string;
  invalid?: boolean;
  disabled?: boolean;
}

// a text field interface wired with the data update mechanism
// used at several places across the form builder
export const TextField: FC<Props> = ({
  label,
  value,
  path,
  className,
  invalid,
  disabled
}) => {
  const classes = useStyles();
  const { set } = useContext(DataContext);

  const onValueChange = useCallback(
    e => set(path, e.target.value || undefined),
    [set, path]
  );

  return (
    <div className={classnames(classes.textField, className)}>
      <FieldTextCompact
        label={label}
        value={value || ''}
        onValueChange={onValueChange}
        invalid={invalid}
        disabled={disabled}
      />
    </div>
  );
};

export default React.memo(TextField);
