import React from 'react';

import { FormikProps, useField } from 'formik';
import { PresetInstance } from 'utils/presets';
import FormBuilder from 'components/formBuilder';
import { SetDataInput } from '.';

import useStyles from './form.style';

const SetDataForm: React.FC<
  FormikProps<PresetInstance<SetDataInput>>
> = props => {
  const classes = useStyles();
  const {
    values: {
      input: { form = { properties: [] } }
    },
    submitCount
  } = props;

  const [, , helpers] = useField('input.form');

  return (
    <div className={classes.root}>
      <div className={classes.formBuilder}>
        <FormBuilder
          form={form}
          updateForm={helpers.setValue}
          showErrors={submitCount > 0}
        />
      </div>
    </div>
  );
};

export default SetDataForm;
