import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  groupLabelWrapper: {
    display: 'flex'
  },
  groupAvatar: {
    marginRight: 8,
    flexShrink: 0
  }
});
