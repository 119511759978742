import { memo } from 'react';

import type {
  RadioButtonsActionField,
  BaseTypeProps
} from 'schemaBuilder/types';

import { TypeWrapper } from '../../ui';

import RadioButtonsBody from './body';

export interface Props extends BaseTypeProps {
  field: RadioButtonsActionField;
}

export const RadioButtons = ({
  isDragging,
  field,
  dragElement,
  removeElement,
  typeSelectElement,
  fieldKeyElement,
  path,
  theme,
  readonly
}: Props) => {
  return (
    <TypeWrapper
      readonly={readonly}
      schemaField={field.schemaField}
      required={field.required}
      isDragging={isDragging}
      dragElement={dragElement}
      typeSelectElement={typeSelectElement}
      removeElement={removeElement}
      fieldKeyElement={fieldKeyElement}
      path={path}
      theme={theme}
    >
      <RadioButtonsBody
        readonly={readonly}
        schemaField={field.schemaField}
        path={path}
      />
    </TypeWrapper>
  );
};

export default memo(RadioButtons);
