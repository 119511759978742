import { NumberActionField } from 'schemaBuilder/types';
import { ConverterFieldPayload } from 'schemaBuilder/converter/types';
import { ResolvedExpressionType, StatementType } from '@stratumn/dsl';
import { key } from '../utils';

export default (field: NumberActionField): ConverterFieldPayload => ({
  jsonSchema: {
    type: 'object',
    properties: {
      [key(field)]: {
        type: 'number',
        title: field.schemaField.label
      }
    },
    required: field.required ? [key(field)] : undefined
  },
  uiSchema: {
    'ui:order': [key(field)]
  },
  effects: [
    {
      $tags: ['schemaBuilder'],
      $statement: StatementType.SetVariable,
      path: `state.data.${key(field)}`,
      value: {
        $expression: ResolvedExpressionType.Variable,
        query: `formData.${key(field)}`
      }
    }
  ]
});
