import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    height: '100vh',
    overflowY: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    padding: 10,
    backgroundColor: theme.grey8
  },
  left: {
    padding: '0px 10px',
    margin: '10px 0px',
    flexGrow: 1,
    overflow: 'auto'
  },
  right: {
    width: '30%',
    padding: 10,
    overflow: 'hidden'
  },
  rightContent: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between'
  },
  formWriter: {
    flexGrow: 1,
    borderRadius: 10,
    backgroundColor: theme.white1,
    padding: 10,
    overflow: 'auto',
    position: 'relative',
    fontSize: 18
  },
  jsonWrapper: {
    borderRadius: 10,
    overflow: 'hidden',
    height: '100%'
  },
  rightFooter: {
    padding: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  viewerSwitch: {
    alignSelf: 'flex-end',
    width: 150,
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  switchLabel: {
    color: theme.grey4,
    '&[data-is-active=true]': {
      color: theme.grey1,
      fontWeight: 600
    }
  },
  rightFooterBtn: {
    height: 25,
    color: theme.grey5,
    display: 'flex',
    alignItems: 'center',
    transition: '200ms',
    '&:focus': {
      outline: 'none'
    },
    '&:not([disabled]):hover': {
      color: theme.grey3
    },
    '&[data-warning=true]:hover': {
      color: theme.warningRed
    },
    '&:disabled': {
      cursor: 'not-allowed'
    }
  },
  trashIcon: {
    height: 25,
    width: 25
  },
  formWriterError: {
    color: theme.warningRed,
    fontSize: 14
  },
  formWriterErrorTitle: {
    fontWeight: 'bold'
  },
  formWriterErrorDeleteData: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 12,
    gap: '10px'
  }
}));
