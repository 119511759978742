import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  titleLabel: {
    color: theme.indigo3
  },
  modalContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  conditionsHeader: {
    padding: 14,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  conditionsHeaderLeft: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  conditionsSource: {
    '& button': {
      cursor: 'pointer',
      backgroundColor: theme.white1
    }
  },
  noConditionSourceMsg: {
    fontSize: 14,
    color: theme.warningRed
  },
  conditionsHeaderRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'alignItems',
    '& button': {
      marginLeft: 15,
      fontSize: 12,
      lineHeight: '15px',
      color: theme.grey1
    }
  },
  deleteAllBtn: {
    '& > button:hover': {
      color: theme.warningRed
    }
  },
  collapseAllBtn: {
    '& > button:hover': {
      color: theme.indigo2
    }
  },
  conditions: {
    height: 330,
    overflowY: 'auto',
    margin: '0 7px',
    padding: '0 7px'
  },
  conditionsFooter: {
    flexShrink: 0,
    padding: '15px 14px 5px 14px'
  },
  addConditionBtn: {
    color: theme.indigo1
  }
}));
