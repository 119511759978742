import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    minHeight: `calc(100vh - ${theme.headerHeight || '70px'})`,
    background: theme.grey8
  },
  left: {
    flex: 1,
    padding: 20,
    height: `calc(100vh - ${theme.headerHeight || '70px'})`,
    overflow: 'auto'
  },
  actionHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  actionHeaderTitle: {
    fontSize: 24,
    fontWeight: 600
  },
  iconButton: {
    margin: '4px 0',
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    textTransform: 'none',
    fontSize: 14,
    lineHeight: '18px'
  },
  actionMeta: {
    display: 'flex',
    alignItems: 'center',
    '& > label': {
      marginRight: 5,
      width: 300
    },
    '& > div': {
      flexGrow: 1,
      margin: 0
    },
    paddingBottom: 10,
    borderBottom: '1px solid #D0CED5'
  },
  actionForm: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  validationError: {
    color: 'red'
  },
  submit: {
    margin: '1em 0 2em',
    display: 'flex',
    '& > button': {
      margin: 5
    }
  },
  right: {
    width: '33%',
    minWidth: 400,
    maxWidth: 800,
    height: `calc(100vh - ${theme.headerHeight || '70px'})`,
    overflow: 'auto'
  }
}));
