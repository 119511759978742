import { ViewTypes } from '../../types';

export const defaultConfig = path => ({
  AVATAR: {
    path,
    type: ViewTypes.Avatar,
    isGroup: true,
    useContext: true
  },
  DATE: {
    path,
    type: ViewTypes.Date,
    format: 'DD/MM/YYYY'
  },
  ICON: {
    type: ViewTypes.Icon,
    labelPath: path,
    iconPath: "meta.head.action.icon || 'Document'"
  },
  TEXT: {
    path,
    type: ViewTypes.Text
  },
  LINK: {
    path,
    type: ViewTypes.Link,
    openInNewTab: true,
    rootUrl: '/trace/',
    urlPath: 'meta.traceId'
  }
});
