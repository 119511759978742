import { createUseStyles } from 'react-jss';
import theme from 'style';

export default createUseStyles({
  container: {
    padding: 10,
    display: 'flex',
    background: theme.grey9,
    border: `1px solid ${theme.grey6}`,
    marginBottom: 10
  },
  left: {
    width: '40%'
  },
  right: {
    width: '60%'
  },
  trashIcon: {
    height: 18,
    color: theme.grey1,
    margin: '-4px -7px'
  },
  columnTitle: {
    fontSize: '1.25em',
    fontWeight: 'bold'
  },
  reason: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flexGrow: 1
    }
  },
  reasonsFiller: {
    height: 10
  }
});
