import React, { useState, useEffect } from 'react';
import Md from 'react-markdown';
import { Panel } from 'components/workflow/ui';

import useStyles from './documentation.style';

interface Doc {
  title: string;
  body: string;
}
interface DocumentationInput {
  source: string;
}

export const Documentation: React.FC<DocumentationInput> = ({ source }) => {
  const classes = useStyles();

  const [doc, setDoc] = useState<Doc | null>(null);

  useEffect(() => {
    const fetchTxt = async () => {
      const txt = await (await fetch(source)).text();

      const idx = txt.indexOf('\n');
      if (idx === -1) setDoc(null);

      setDoc({
        title: txt.substring(0, idx),
        body: txt.substring(idx)
      });
    };
    fetchTxt();
  }, [source]);

  if (!doc) return <div>Loading documentation...</div>;

  return (
    <Panel title={<Md source={doc.title} />}>
      <div className={classes.documentation}>
        <Md source={doc.body} />
      </div>
    </Panel>
  );
};

export default React.memo(Documentation);
