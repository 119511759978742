// Use an artificial link element to trigger a browser download
export default (fileName: string, content: Blob) => {
  const link = URL.createObjectURL(content);
  const elt = document.createElement('a');
  elt.href = link;
  elt.download = fileName;
  elt.click();
  // Clean up
  URL.revokeObjectURL(link);
};
