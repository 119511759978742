import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon, InfoTooltip } from '@stratumn/atomic';

import useStyles from './iconButtonTooltip.style';

const infoTooltipPosition = {
  place: 'below',
  placeShift: 3,
  adjustPlace: true
};

interface Props {
  dataCy?: string;
  iconName: string;
  iconSize?: number;
  tooltipMessage: string;
  url?: string;
  onClick?: () => void;
}

export const IconButtonTooltip: FC<Props> = ({
  dataCy,
  iconName,
  iconSize = 20,
  tooltipMessage,
  url = '',
  onClick = () => {}
}) => {
  const classes = useStyles();

  const sharedWrapperProps = {
    'data-cy': dataCy,
    className: classes.iconWrapper
  };

  const sharedIconProps = {
    name: iconName,
    size: iconSize
  };

  return (
    <InfoTooltip
      text={tooltipMessage}
      position={infoTooltipPosition}
      textAlign="center"
      padding={5}
      delay={300}
    >
      {!url ? (
        <div {...sharedWrapperProps} onClick={onClick}>
          <Icon {...sharedIconProps} />
        </div>
      ) : (
        <NavLink {...sharedWrapperProps} to={url}>
          <Icon {...sharedIconProps} />
        </NavLink>
      )}
    </InfoTooltip>
  );
};

export default memo(IconButtonTooltip);
