import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    backgroundColor: theme.white1,
    width: props => props.width || '100%',
    padding: '15px 20px',
    borderRadius: 5,
    margin: '10px 0',
    boxShadow: theme.indigoShadow1,
    boxSizing: 'border-box'
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: 600,
    letterSpacing: '0.03em'
  },
  collapseBtn: {
    outline: 'none',
    alignSelf: 'center',
    color: theme.grey3
  },
  body: {
    paddingTop: 15,
    '&[data-has-title=true]': {
      borderTop: `1px solid ${theme.grey8}`,
      marginTop: 15
    }
  }
}));
