import { ReactNode, useMemo, memo } from 'react';
import type {
  CheckboxesSchemaField,
  BaseTypeBodyProps
} from 'schemaBuilder/types';

import { DraggableType, OptionsType } from '../../ui/interfaces';
import { OptionsList } from '../../ui';

import useStyles from './checkboxes.style';

interface Props extends BaseTypeBodyProps {
  schemaField: CheckboxesSchemaField;
}

const Checkboxes = ({ schemaField, readonly = false, path }: Props) => {
  const classes = useStyles();

  const icon: ReactNode = useMemo(
    () => <div className={classes.emptyBoxIcon} />,
    [classes.emptyBoxIcon]
  );

  const options: OptionsType[] = useMemo(
    () =>
      schemaField?.enumNames?.reduce((acc: OptionsType[], curr: any) => {
        acc.push({ key: curr.key, value: curr.value });
        return acc;
      }, []),
    [schemaField.enumNames]
  );

  return (
    <div className={classes.root}>
      <OptionsList
        listType={DraggableType.CheckboxesOptions}
        options={options}
        path={`${path}.schemaField.enumNames`}
        icon={icon}
        placeholder="Add checkbox option"
        disabled={readonly ?? false}
      />
    </div>
  );
};

export default memo(Checkboxes);
