import React, { FC, useMemo, useContext, useCallback } from 'react';

import { MultiSelect } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import {
  DropdownInputDefinition,
  DropdownInputConditionDefinition
} from '../../../../types';

import { DependenciesContext } from '../../../dependenciesModal/context';

import { FormBuilderContext } from '../../../../context';

import useStyles from './conditions.style';

interface Props {
  path: string;
  input: DropdownInputDefinition;
  condition: DropdownInputConditionDefinition;
  dependencyKey: string;
}

// form dropdown condition interface
// this is the interface displayed for a specific dependency
export const Condition: FC<Props> = ({
  path,
  input,
  condition,
  dependencyKey
}) => {
  const classes = useStyles();
  const { set } = useContext(DataContext);

  // it needs to know all the currentDependencies to be able to list the remaining options
  const currentDependencies = useContext(DependenciesContext);

  // build list of options used by other conditions to filter them out
  const disabledOptions = useMemo(
    () =>
      currentDependencies.conditions?.reduce(
        (list, { key: depKey, condition: depCondition = {} }) => {
          if (dependencyKey !== depKey) {
            list.push(
              ...((depCondition as DropdownInputConditionDefinition).values ||
                [])
            );
          }
          return list;
        },
        [] as string[]
      ) || [],
    [dependencyKey, currentDependencies]
  );

  // build list of available options for this dropdown
  const options = useMemo(() => {
    // options available in the dropdown input
    // handle the case where the dropdown input is itself also conditional... :(
    const inputOptions = input.dependencies?.conditions
      ? input.dependencies.conditions.reduce<string[]>(
          (currentOptions, dependency) => {
            const { input: dropdownInput } = dependency;
            if (dropdownInput.options) {
              currentOptions.push(
                ...(dropdownInput.options || []).map(opt => opt.value || '')
              );
            }
            return currentOptions;
          },
          []
        )
      : (input.options || []).map(option => option.value);

    // remove disabled options, empty values and duplicates
    return [...new Set(inputOptions)].filter(
      val => !!val && !disabledOptions.includes(val)
    );
  }, [input, disabledOptions]);

  // build list of options for the multiselect
  const multiselectOptions = useMemo(
    () => options.map(value => ({ label: value, value })),
    [options]
  );

  // build list of selected options for this condition and callback to update
  // note: we apply an extra filter to remove values that could be invalid
  // eg if the user changed the dropdown values and then went back to this conditioning
  const multiselectValues = useMemo(
    () => (condition?.values || []).filter(val => options.includes(val)),
    [condition, options]
  );
  const onSelect = useCallback(
    selectedValues => set(`${path}.values`, selectedValues),
    [set, path]
  );

  // check from form builder context if errors shall be displayed
  const { showErrors } = useContext(FormBuilderContext);
  const invalid = useMemo(() => showErrors && !condition?.values?.length, [
    condition,
    showErrors
  ]);

  return (
    <div className={classes.multiselect}>
      <MultiSelect
        dataCy="dropdown-condition"
        displayValue="Option"
        options={multiselectOptions}
        selectedValues={multiselectValues}
        onSelect={onSelect}
        displayLabelIfOneSelected
        shadows
        invalid={invalid}
      />
    </div>
  );
};

export const getErrors = (
  _: DropdownInputDefinition,
  errors: string[],
  condition?: DropdownInputConditionDefinition
) => {
  if (!condition?.values?.length) {
    errors.push('No condition value selected');
  }
};

export default {
  component: React.memo(Condition),
  getErrors
};
