import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.grey8,
    overflow: 'auto',
    height: `calc(100vh - ${theme.headerHeight || '70px'})`
  },
  workflowInfoWrapper: {
    display: 'flex',
    width: '100%',
    padding: 20,
    position: 'fixed',
    background: theme.white1,
    boxShadow: theme.indigoShadow1,
    zIndex: 2,
    overflow: 'auto',
    whiteSpace: ''
  },
  main: {
    marginTop: 120,
    marginBottom: 40,
    padding: 20
  },
  outerGrid: {
    display: 'grid',
    gridTemplateColumns: `1fr 2fr`,
    gridGap: 20,
    margin: '20px 0',
    '@media (max-width: 1190px)': {
      gridTemplateColumns: `repeat(auto-fit, minmax(370px, 1fr))`
    }
  },
  innerGrid: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, minmax(300px, 1fr))`,
    gridAutoRows: `minmax(min-content, max-content)`,
    gridGap: 20,
    '@media (max-width: 1190px)': {
      gridTemplateColumns: `repeat(auto-fit, minmax(370px, 1fr))`
    }
  }
}));
