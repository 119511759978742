import React, { FC, useCallback, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { Draggable } from 'react-beautiful-dnd';

import { Switch, Icon } from '@stratumn/atomic';

import { useToggle, DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';

import { TextField } from '../fields';
import { InputType, PropertyDefinition } from '../types';
import { FormBuilderContext } from '../context';
import { getPropertyKey, validateProperty } from '../utils';

import { INPUT_OPTIONS_DATA } from './constants';
import InputSelection from './inputSelection';
import Inputs from './inputs';
import DependenciesModal from './dependenciesModal';
import SettingsModal from './settingsModal';
import EditPropertyKeyModal from './editPropertyKeyModal';

import useStyles from './property.style';

interface Props {
  index: number;
  path: string;
  property: PropertyDefinition;
  className?: string;
  depth?: number; // this is the depth level of the parent object of the property
}

export const Property: FC<Props> = ({
  index,
  path,
  property,
  className,
  depth = 0
}) => {
  const classes = useStyles();
  const { update, set, delete: deleteData, toggle } = useContext(DataContext);
  const { showPropertiesKey, showErrors } = useContext(FormBuilderContext);

  const [showDependenciesModal, toggleShowDependenciesModal] = useToggle(false);
  const [showSettingsModal, toggleShowSettingsModal] = useToggle(false);
  const [showEditPropertyKeyModal, toggleShowEditPropertyKeyModal] = useToggle(
    false
  );

  const { key, propertyKey, label, isRequired, input } = property;
  const { type: inputType, settings, dependencies } = input || {};

  const onToggleRequired = useCallback(() => toggle(`${path}.isRequired`), [
    toggle,
    path
  ]);

  const onSubmitDependencies = useCallback(
    value => {
      if (value) {
        set(`${path}.input`, {
          type: input?.type,
          dependencies: value,
          settings: input?.settings
        });
      } else {
        deleteData(`${path}.input.dependencies`);
      }
      toggleShowDependenciesModal();
    },
    [set, deleteData, path, input, toggleShowDependenciesModal]
  );

  const onSubmitSettings = useCallback(
    value => {
      if (value) {
        set(`${path}.input.settings`, value);
      } else {
        deleteData(`${path}.input.settings`);
      }
      toggleShowSettingsModal();
    },
    [set, deleteData, path, toggleShowSettingsModal]
  );

  const onSubmitEditPropertyKey = useCallback(
    (value: string | null): void => {
      if (value) {
        update([
          {
            type: DataUpdateType.Merge,
            path,
            value: {
              propertyKey: value
            }
          }
        ]);
      } else {
        deleteData(`${path}.propertyKey`);
      }
      toggleShowEditPropertyKeyModal();
    },
    [toggleShowEditPropertyKeyModal, update, path, deleteData]
  );

  const onRemoveProperty = useCallback(() => {
    // when the user deletes a property we send a revert option message
    const inputTypeLabel = INPUT_OPTIONS_DATA.find(
      option => option.type === inputType
    )?.label;

    const revertMessage = `The ${
      inputTypeLabel ? `**${inputTypeLabel}** ` : ''
    }input ${label ? `**${label}** ` : ''}has been successfully deleted`;

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, path, inputType, label]);

  const { header: inputHeader, body: inputBody } = useMemo(() => {
    if (!input) return {};

    const InputComponents = Inputs[input.type] || {};

    return {
      header: InputComponents.header ? (
        <InputComponents.header path={`${path}.input`} input={input} />
      ) : null,
      body: InputComponents.body ? (
        <InputComponents.body
          path={`${path}.input`}
          input={input}
          depth={depth}
        />
      ) : null
    };
  }, [path, input, depth]);

  // build errors at the property level
  let errorsStr = '';
  if (showErrors) {
    const errors: string[] = [];
    validateProperty(errors, property, depth);
    errorsStr = errors.join(', ');
  }

  const isHelperField = input?.type === InputType.Helper;

  const renderKey = useMemo(
    () => getPropertyKey(key, label, propertyKey, isHelperField),
    [key, label, propertyKey, isHelperField]
  );

  const getDraggableProperty = (providedDraggable, snapshotDraggable) => {
    const draggableProperty = (
      <div
        ref={providedDraggable.innerRef}
        className={classes.draggable}
        {...providedDraggable.draggableProps}
      >
        <div
          className={className || classes.rootDefault}
          data-has-errors={!!errorsStr}
          data-is-dragging={snapshotDraggable.isDragging}
        >
          <div className={classes.header}>
            <div
              className={classes.dragHandle}
              {...providedDraggable.dragHandleProps}
            >
              <Icon name="Drag" size={20} />
            </div>
            <TextField
              className={classes.inputLabel}
              label="Label"
              value={label}
              path={`${path}.label`}
              invalid={showErrors && !label && !isHelperField}
              disabled={isHelperField}
              data-cy="property-label"
            />
            <div className={classes.inputHeader}>
              {!dependencies && inputHeader}
            </div>
            <div className={classes.inputType}>
              <InputSelection
                input={input}
                path={`${path}.input`}
                depth={depth}
              />
            </div>
          </div>
          {dependencies ? (
            <div className={classes.conditionalDisplaySetMsg}>
              Conditional display has been setup
            </div>
          ) : (
            inputBody
          )}
          <div className={classes.footer}>
            <div className={classes.footerLeft}>
              {errorsStr && (
                <span className={classes.errors} data-cy="errors">
                  {errorsStr}
                </span>
              )}
            </div>
            <div className={classes.footerRight}>
              {showPropertiesKey && (
                <button
                  type="button"
                  data-cy="open-edit-property-key-modal"
                  className={classes.propertyKeyButton}
                  title="edit property key"
                  onClick={toggleShowEditPropertyKeyModal}
                >
                  <span className={classes.propertyKey} data-cy="property-key">
                    #{renderKey}
                  </span>
                  <span className={classes.penIcon}>
                    <Icon name="Pen" size={18} />
                  </span>
                </button>
              )}
              {!isHelperField && (
                <>
                  <Switch
                    label="Required"
                    showLabel
                    invert
                    on={isRequired}
                    handleChange={onToggleRequired}
                    data-cy="toggle-required"
                    disabled={!!dependencies}
                  />
                  <div className={classes.footerSep} />
                </>
              )}
              <button
                type="button"
                className={classes.footerBtn}
                onClick={toggleShowDependenciesModal}
                data-cy="open-dependencies"
                disabled={!inputType}
              >
                <Icon
                  name={dependencies ? 'ConditionFill' : 'Condition'}
                  size={25}
                />
              </button>
              {!isHelperField && (
                <button
                  type="button"
                  className={classes.footerBtn}
                  onClick={toggleShowSettingsModal}
                  data-cy="open-settings"
                  disabled={!inputType}
                >
                  <Icon name={settings ? 'CogFill' : 'Cog'} size={25} />
                </button>
              )}
              <button
                type="button"
                className={classes.footerBtn}
                data-warning
                onClick={onRemoveProperty}
                data-cy="remove-property"
              >
                <Icon name="Trash" size={25} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return snapshotDraggable.isDragging
      ? createPortal(draggableProperty, document.body)
      : draggableProperty;
  };

  return (
    <>
      <Draggable draggableId={key} index={index}>
        {getDraggableProperty}
      </Draggable>
      {showDependenciesModal && (
        <DependenciesModal
          inputKey={key}
          inputType={inputType}
          inputLabel={label}
          inputDepth={depth}
          initDependencies={dependencies}
          submit={onSubmitDependencies}
          cancel={toggleShowDependenciesModal}
        />
      )}
      {showSettingsModal && (
        <SettingsModal
          inputType={inputType}
          initSettings={settings}
          submit={onSubmitSettings}
          cancel={toggleShowSettingsModal}
        />
      )}
      {showEditPropertyKeyModal && (
        <EditPropertyKeyModal
          propertyKey={renderKey}
          submit={onSubmitEditPropertyKey}
          cancel={toggleShowEditPropertyKeyModal}
        />
      )}
    </>
  );
};

export default React.memo(Property);
