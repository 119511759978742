import { FC, ReactNode, memo } from 'react';

import useStyles from './card.style';

export interface Props {
  children: ReactNode;
  isDragging?: boolean;
  invalid?: boolean;
}

export const Card: FC<Props> = ({ children, isDragging, invalid = false }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.cardRoot}
      data-is-dragging={isDragging}
      data-has-errors={invalid}
    >
      {children}
    </div>
  );
};

export default memo(Card);
