import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  headerButtonsRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 10,
    '&[data-is-overview=true]': {
      marginBottom: 10,
      marginTop: 0
    }
  },
  addSectionWrapper: {
    display: 'flex',
    flexGrow: 1
  },
  dropdownWrapper: {
    '& span': {
      '&:first-letter': {
        textTransform: 'uppercase'
      }
    },
    '& label': {
      height: 35
    }
  },
  dropdownOptionLabel: {
    '&:first-letter': {
      textTransform: 'uppercase'
    },
    '&[data-is-disabled=true]': {
      color: theme.grey4
    }
  },
  addSectionButton: {
    marginLeft: 10,
    '& button': {
      height: 35
    }
  },
  previewButton: {
    marginRight: 20
  }
}));
