import React, { FC, useCallback, useContext, useMemo } from 'react';

import { Icon, Switch } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';

import {
  AnyDependencyDefinition,
  InputDefinition,
  InputType
} from '../../../types';

import { TextField } from '../../../fields';

import { FormBuilderContext } from '../../../context';

import { validateDependency } from '../../../utils';

import Inputs from '../../inputs';

import useStyles from './dependency.style';

interface Props {
  path: string;
  dependency: AnyDependencyDefinition;
  dependencySourceInput?: InputDefinition;
  inputDepth?: number;
}

// A single dependency inside a DependenciesModal
export const Dependency: FC<Props> = ({
  path,
  dependency,
  dependencySourceInput,
  inputDepth
}) => {
  const classes = useStyles();

  const { key, collapsed, label, condition, input, isRequired } = dependency;

  const { showErrors } = useContext(FormBuilderContext);

  const { update, toggle } = useContext(DataContext);

  const onToggleCollapsed = useCallback(() => toggle(`${path}.collapsed`), [
    toggle,
    path
  ]);

  const collapseBtn = useMemo(
    () => (
      <button
        type="button"
        className={classes.collapseBtn}
        onClick={onToggleCollapsed}
        data-cy="toggle-collapsed"
      >
        <Icon name={collapsed ? 'CirclePlus' : 'CircleMinus'} size={23} />
      </button>
    ),
    [classes, collapsed, onToggleCollapsed]
  );

  const isHelperField = input.type === InputType.Helper;
  const onToggleRequired = useCallback(() => toggle(`${path}.isRequired`), [
    toggle,
    path
  ]);
  const requiredSwitch = useMemo(
    () => (
      <Switch
        label="Required"
        showLabel
        invert
        on={isRequired}
        handleChange={onToggleRequired}
        dataCy="toggle-required"
      />
    ),
    [isRequired, onToggleRequired]
  );

  const onRemoveCondition = useCallback(() => {
    const revertMessage = `The condition${
      label ? ` **${label}**` : ''
    } has been successfully deleted`;
    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, path, label]);

  const removeBtn = useMemo(
    () => (
      <button
        type="button"
        className={classes.deleteBtn}
        onClick={onRemoveCondition}
        data-cy="remove-dependency"
      >
        <Icon name="Trash" size={23} />
      </button>
    ),
    [classes, onRemoveCondition]
  );

  // condition
  const conditionEl = useMemo(() => {
    if (!dependencySourceInput) return null;

    // check if the source input selected can be a condition
    // and call its condition ui rendering method
    const SourceInputComponents = Inputs[dependencySourceInput.type] || {};
    return SourceInputComponents.conditions ? (
      <SourceInputComponents.conditions.component
        path={`${path}.condition`}
        input={dependencySourceInput}
        condition={condition}
        dependencyKey={key}
      />
    ) : null;
  }, [path, dependencySourceInput, condition, key]);

  // input display
  const { header: inputHeader, body: inputBody } = useMemo(() => {
    if (collapsed) return {};

    const InputComponents = Inputs[input.type] || {};

    return {
      header: InputComponents.header ? (
        <div className={classes.inputHeader}>
          <InputComponents.header path={`${path}.input`} input={input} />
        </div>
      ) : null,
      body: InputComponents.body ? (
        <InputComponents.body
          path={`${path}.input`}
          input={input}
          depth={inputDepth}
        />
      ) : null
    };
  }, [classes, collapsed, path, input, inputDepth]);

  // build errors at the dependency level
  let errorsStr = '';
  if (showErrors) {
    const errors: string[] = [];
    validateDependency(errors, dependency, dependencySourceInput, inputDepth);
    errorsStr = errors.join(', ');
  }

  return (
    <div className={classes.dependency} data-has-errors={!!errorsStr}>
      <div className={classes.header}>
        <div className={classes.conditionInterface}>{conditionEl}</div>
        <TextField
          className={classes.conditionLabel}
          label="Condition label (optional)"
          value={label}
          path={`${path}.label`}
          data-cy="condition-label"
        />
        <div className={classes.errors} data-cy="header-errors">
          {errorsStr && collapsed && errorsStr}
        </div>
        {collapseBtn}
      </div>
      {!collapsed && (
        <>
          <div className={classes.body}>
            {inputHeader}
            {inputBody}
          </div>
          <div className={classes.footer}>
            <div className={classes.footerLeft}>
              {errorsStr && (
                <span className={classes.errors} data-cy="footer-errors">
                  {errorsStr}
                </span>
              )}
            </div>
            <div className={classes.footerRight}>
              {!isHelperField && (
                <>
                  {requiredSwitch}
                  <div className={classes.footerSep} />
                </>
              )}
              {removeBtn}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(Dependency);
