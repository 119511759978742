import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    minWidth: 700,
    backgroundColor: theme.white1,
    borderRadius: 5,
    boxShadow: theme.indigoShadow1,
    padding: '15px 20px',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  title: {
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: 600,
    letterSpacing: '0.03em',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'baseline'
  },
  showPropertiesKeyWrapper: {
    marginLeft: 10,
    flexGrow: 1,
    '& > span': {
      fontSize: 12
    }
  },
  collapseBtn: {
    outline: 'none',
    alignSelf: 'center',
    color: theme.grey3
  },
  separator: {
    marginTop: 15,
    borderBottom: `1px solid ${theme.grey8}`
  },
  header: {
    padding: '15px 0px 20px 0px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  formTitle: {
    '& input': {
      backgroundColor: theme.grey9
    },
    marginRight: 10,
    width: '40%',
    minWidth: 300,
    flexShrink: 0
  },
  formDescription: {
    '& input': {
      backgroundColor: theme.grey9
    },
    width: '60%'
  },
  errors: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 10,
    whiteSpace: 'nowrap',
    color: theme.warningRed,
    fontSize: 11
  }
}));
