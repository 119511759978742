import React, { FC } from 'react';

import { Tray } from '@stratumn/atomic';

import { TRAY_PORTAL_RIGHT } from '../trayPortal';

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  onClose: Function;
}

// tray internal component
// just package some props of the atomic tray to avoid repeating
const TrayInt: FC<Props> = ({ title, children, onClose }: Props) => (
  <Tray
    portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
    title={title}
    width={450}
    minWidth={450}
    onClose={onClose}
  >
    {children}
  </Tray>
);

export default TrayInt;
