import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  modalTitleHighlight: {
    fontWeight: 600,
    color: theme.indigo3
  },
  modalContent: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 10
  },
  iconCard: {
    display: 'block',
    cursor: 'pointer',
    padding: 8,
    borderRadius: 5,
    border: `1px solid ${theme.grey8}`,
    transition: '200ms',
    '&:hover': {
      borderColor: theme.indigo2
    },
    '&:active': {
      borderColor: theme.indigo2,
      backgroundColor: theme.grey8
    },
    '&[data-is-selected=true]': {
      borderColor: theme.indigo3,
      backgroundColor: theme.indigo3,
      color: theme.white1
    }
  }
}));
