import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#F2F1F5',
    overflow: 'auto',
    height: `calc(100vh - ${theme.headerHeight || '70px'})`,
    minWidth: 1160 // make horizontal scrollable
  },
  header: {
    margin: '10px 0 20px 0',
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.grey5}`
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '0.03em',
    color: theme.grey1
  },
  collapseBtnWrapper: {
    display: 'flex',
    '& > button': {
      fontSize: 12,
      lineHeight: '15px',
      color: theme.grey1
    }
  },
  separator: {
    margin: '0 5px',
    color: theme.grey5
  },
  nextActionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: 20,
    paddingRight: 18,
    overflowY: 'auto',
    width: '100%'
  },
  actionButtonWrapper: {
    display: 'flex',
    flexShrink: 0
  },
  cancelButton: {
    marginRight: 10
  }
}));
