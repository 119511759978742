import React, { FC, useCallback, useContext } from 'react';
import classnames from 'classnames';

import { DataContext } from 'utils/hooks';

import useStyles from './number.style';

interface Props {
  label?: string;
  value?: number;
  path: string;
  className?: string;
  max?: number;
  min?: number;
  step?: number;
  invalid?: boolean;
}

// a number field interface wired with the data update mechanism
// used at several places across the form builder
export const NumberField: FC<Props> = ({
  label,
  value,
  path,
  className,
  invalid,
  ...otherProps
}) => {
  const classes = useStyles();
  const { set } = useContext(DataContext);

  const onValueChange = useCallback(
    e => {
      const { value } = e.target;
      set(path, value === '' ? undefined : Number(value));
    },
    [set, path]
  );

  return (
    <input
      type="number"
      className={classnames(classes.numberField, className)}
      data-is-set={value !== undefined}
      placeholder={label}
      value={value === undefined ? '' : value}
      onChange={onValueChange}
      data-is-invalid={invalid}
      {...otherProps}
    />
  );
};

export default React.memo(NumberField);
