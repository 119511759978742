// @ts-nocheck
import { SubFormActionField } from 'schemaBuilder/types';
import { ConverterFieldPayload } from 'schemaBuilder/converter/types';
import { ResolvedExpressionType, StatementType } from '@stratumn/dsl';
import { key } from '../utils';

export default (
  field: SubFormActionField,
  convertSimples
): ConverterFieldPayload => {
  const {
    properties,
    uiOptions,
    uiOrder,
    uiFields
  } = field.schemaField.properties.reduce(
    (props, prop) => {
      const item = convertSimples(prop);
      const id = key(prop);
      props.properties[id] = item.jsonSchema.properties[id];
      props.uiOrder.push(id);
      props.uiOptions.order.push(id);
      props.uiFields[id] = item.uiSchema[id];
      return props;
    },
    { properties: {}, uiOptions: { order: [] }, uiOrder: [], uiFields: {} }
  );
  return {
    jsonSchema: {
      type: 'object',
      properties: {
        [key(field)]: {
          type: 'object',
          title: field.schemaField.label,
          required: field.required
            ? field.schemaField.properties
                .map(prop => (prop.required ? key(prop) : undefined))
                .filter(Boolean)
            : undefined,
          properties
        }
      },
      required: field.required ? [key(field)] : undefined
    },
    uiSchema: {
      'ui:order': [key(field)],
      [key(field)]: {
        'ui:options': uiOptions,
        'ui:order': uiOrder,
        ...uiFields
      }
    },
    effects: [
      {
        $tags: ['schemaBuilder'],
        $statement: StatementType.SetVariable,
        path: `state.data.${key(field)}`,
        value: {
          $expression: ResolvedExpressionType.Variable,
          query: `formData.${key(field)}`
        }
      }
    ]
  };
};
