import { TRACE_API_URL } from 'constant/api';
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  ServerError
} from '@apollo/client';
import { onError } from '@apollo/link-error';

import { history } from 'components/root';
import { ROUTE_LOGIN } from 'constant/routes';

import { dataIdFromObject } from './cache';

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    console.error(networkError);
    const { statusCode } = networkError as ServerError;
    if (statusCode === 401) {
      // use second argument (state) to pass redirect variable
      history.push(ROUTE_LOGIN, {
        redirect: `${window.location.pathname}${window.location.search}`
      });
    }
  }
});

const httpLink = new HttpLink({
  uri: `${TRACE_API_URL}/graphql`,
  credentials: 'include'
});

export default new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject,
    addTypename: true
  }),
  link: from([errorLink, httpLink])
});
