import { memo } from 'react';
import { FieldTextCompact } from '@stratumn/atomic';
import type {
  ShortTextSchemaField,
  BaseTypeBodyProps
} from 'schemaBuilder/types';

import { useSetCallback } from 'schemaBuilder/modules/action/hooks/fields';

import useStyles from './shortText.style';

interface Props extends BaseTypeBodyProps {
  schemaField: ShortTextSchemaField;
}

const ShortTextBody = ({ schemaField, readonly = false, path }: Props) => {
  const classes = useStyles();
  const handlePlaceholderUpdate = useSetCallback(`${path}.placeholder`);

  return (
    <div className={classes.body}>
      <FieldTextCompact
        data-cy="short-text-placeholder"
        disabled={readonly}
        value={schemaField.placeholder}
        label="Placeholder"
        onValueChange={handlePlaceholderUpdate}
      />
    </div>
  );
};

export default memo(ShortTextBody);
