export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;

export const TRACE_API_URL = process.env.REACT_APP_TRACE_API_URL as string;
export const GRAPHQL_TRACE_API_URL = `${TRACE_API_URL}/graphql`;
export const TRACE_URL = process.env.REACT_APP_TRACE_URL as string;

export const ACCOUNT_API_URL = process.env.REACT_APP_ACCOUNT_API_URL as string;
export const GRAPHQL_ACCOUNT_API_URL = `${ACCOUNT_API_URL}/graphql`;
export const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL as string;
export const ACCOUNT_LOGIN_URL = `${ACCOUNT_URL}/login`;

export const MEDIA_API_URL = process.env.REACT_APP_MEDIA_API_URL as string;
