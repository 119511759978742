import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  barWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 200
  },
  label: {
    marginBottom: 7,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.03em',
    color: theme.grey1
  },
  fullBar: {
    display: 'flex',
    height: 8,
    width: '100%',
    backgroundColor: theme.grey8,
    borderRadius: 10
  },
  dynamicBar: {
    height: '100%',
    width: percent => `${percent * 100}%` || 0,
    background: 'linear-gradient(90deg, #30CECA 0%, #6BEDD8 100%)',
    borderRadius: 'inherit',
    transition: 'width 200ms linear'
  },
  fieldTextWrapper: {
    '& input': {
      height: 35,
      maxWidth: 89
    }
  }
}));
