import { createContext } from 'react';
import { TraceFieldOptions } from 'schemaBuilder/converter/admin';

import { Statuses } from 'utils/interfaces/statuses';

import { ActionBase } from '.';

export interface StatusContextDefinition {
  sections: Statuses;
  selectedTabKey: string;
  actions: ActionBase[];
  showErrors?: boolean;
  traceFieldOptions: TraceFieldOptions;
}

export const StatusContext = createContext<StatusContextDefinition>({
  sections: [],
  selectedTabKey: '',
  actions: [],
  showErrors: false,
  traceFieldOptions: []
});
