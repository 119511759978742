import { createContext } from 'react';

export interface FormBuilderDataContextDefinition {
  showPropertiesKey?: boolean;
  showErrors?: boolean;
}

export const FormBuilderContext = createContext<FormBuilderDataContextDefinition>(
  {
    showPropertiesKey: false,
    showErrors: false
  }
);
