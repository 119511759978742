import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  rootProgressBarsTab: {
    padding: 6,
    maxHeight: 295,
    overflowY: 'auto'
  },
  placeholderMessage: {
    padding: 6,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1
  },
  progressBar: {
    marginBottom: 20
  }
}));
