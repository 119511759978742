import { memo } from 'react';

import type { ShortTextActionField, BaseTypeProps } from 'schemaBuilder/types';

import { TypeWrapper } from '../../ui';

import ShortTextBody from './body';

export interface Props extends BaseTypeProps {
  field: ShortTextActionField;
}

export const ShortText = ({
  isDragging,
  field,
  dragElement,
  removeElement,
  typeSelectElement,
  fieldKeyElement,
  path,
  theme,
  readonly
}: Props) => {
  return (
    <TypeWrapper
      readonly={readonly}
      schemaField={field.schemaField}
      required={field.required}
      isDragging={isDragging}
      dragElement={dragElement}
      typeSelectElement={typeSelectElement}
      removeElement={removeElement}
      fieldKeyElement={fieldKeyElement}
      path={path}
      theme={theme}
    >
      <ShortTextBody
        readonly={readonly}
        schemaField={field.schemaField}
        path={`${path}.schemaField`}
      />
    </TypeWrapper>
  );
};

export default memo(ShortText);
