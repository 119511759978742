import { memo } from 'react';

import type { ListActionField, BaseTypeProps } from 'schemaBuilder/types';

import { TypeWrapper } from '../../ui';

import Body from './body';

export interface Props extends BaseTypeProps {
  field: ListActionField;
  showFieldKeys: boolean;
}

export const List = ({
  isDragging,
  field,
  dragElement,
  removeElement,
  typeSelectElement,
  fieldKeyElement,
  path,
  readonly,
  showFieldKeys
}: Props) => {
  return (
    <TypeWrapper
      readonly={readonly}
      schemaField={field.schemaField}
      required={field.required}
      isDragging={isDragging}
      dragElement={dragElement}
      typeSelectElement={typeSelectElement}
      removeElement={removeElement}
      path={path}
      fieldKeyElement={fieldKeyElement}
    >
      <Body readonly={readonly} schemaField={field.schemaField} path={path} />
    </TypeWrapper>
  );
};

export default memo(List);
