import { FC, memo, useCallback, useContext, useMemo } from 'react';

import { FieldText, SmartDropdown } from '@stratumn/atomic';
import {
  Card,
  CardContent,
  CardActions,
  IconButton
} from 'components/workflow/ui';

import { DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';
import { EventChangeHandler } from 'utils/types';
import {
  ActionBasedDeadlineDefinition,
  StateBasedDeadlineDefinition,
  DeadlineDefinition,
  DeadlineType
} from 'utils/interfaces/deadlines';

import { DeadlinesContext } from '../context';

import { DEADLINE_OPTIONS } from './constants';
import ActionBased from './actionBased';
import StateBased from './stateBased';

import useStyles from './deadlineCard.style';

export interface Props {
  index: number;
  path: string;
  data: DeadlineDefinition;
}

export const DeadlineCard: FC<Props> = ({ index, path, data }) => {
  const classes = useStyles();

  const { set, update } = useContext(DataContext);
  const { showErrors } = useContext(DeadlinesContext);

  const { key, label, isCollapsed, type } = data;

  const cardHasErrors = useMemo(
    (): boolean | undefined => showErrors && (!label || !type),
    [showErrors, label, type]
  );

  const handleEditDeadlineLabel: EventChangeHandler = useCallback(
    e => {
      set(`${path}.label`, e.target.value);
    },
    [path, set]
  );

  const handleSelectDeadlineType = useCallback(
    type => {
      // only preserve label and key when switching types
      set(path, {
        key,
        label,
        type
      });
    },
    [path, set, key, label]
  );

  const handleToggleDeadlineCard = useCallback(
    (): void => set(`${path}.isCollapsed`, !isCollapsed),
    [isCollapsed, path, set]
  );

  const handleRemoveDeadlineCard = useCallback((): void => {
    const revertMessage = `The deadline **${
      label || `#${index + 1}`
    }** has been successfully deleted.`;

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, index, label, path]);

  const displayTypeSpecificContent = !!type && !isCollapsed;
  return (
    <div className={classes.root}>
      <Card invalid={cardHasErrors}>
        <CardContent>
          <div className={classes.cardContentWrapper}>
            <div className={classes.type}>
              <SmartDropdown
                options={DEADLINE_OPTIONS}
                dataCy="deadline-type"
                placeholder="Type of deadline"
                noMatchMessage="No type found"
                onSelect={handleSelectDeadlineType}
                value={type}
                invalid={showErrors && !type}
                shadows
              />
            </div>
            <div className={classes.label}>
              <FieldText
                dataCy="deadline-label"
                label="deadline label"
                placeholder="Deadline label"
                onValueChange={handleEditDeadlineLabel}
                value={label}
                invalid={showErrors && !label}
                hideLabel
              />
            </div>
            {displayTypeSpecificContent &&
              (type === DeadlineType.ActionBased ? (
                <ActionBased
                  path={path}
                  data={data as ActionBasedDeadlineDefinition}
                />
              ) : (
                <StateBased
                  path={path}
                  data={data as StateBasedDeadlineDefinition}
                />
              ))}
          </div>
        </CardContent>
        <CardActions>
          {!!type && (
            <IconButton
              dataCy="toggle-deadline-card"
              name={isCollapsed ? 'CirclePlus' : 'CircleMinus'}
              onClick={handleToggleDeadlineCard}
              ariaLabel="Toggle collapse deadline card"
            />
          )}
          {(!type || !isCollapsed) && (
            <IconButton
              dataCy="remove-deadline-card"
              name="Trash"
              onClick={handleRemoveDeadlineCard}
              ariaLabel="Remove deadline card"
            />
          )}
        </CardActions>
      </Card>
    </div>
  );
};

export default memo(DeadlineCard);
