import { useContext } from 'react';
import {
  Action,
  ActionFieldType,
  BooleanActionField,
  DateActionField,
  HelperActionField,
  RichTextActionField,
  SchemaFieldType,
  ShortTextActionField,
  NumberActionField,
  GroupsActionField,
  CheckboxesActionField,
  FileUploadActionField,
  TextareaActionField,
  RadioButtonsActionField,
  DropdownActionField,
  WorkflowSchema,
  BaseSchemaField,
  SubFormActionField,
  ListActionField
} from 'schemaBuilder/types';

import { create, test as validate, group as vestGroup, enforce } from 'vest';

import { DataContext } from 'utils/hooks';

import validateEmptyField from 'schemaBuilder/modules/action/fields/empty/validation';
import validateExistingField from 'schemaBuilder/modules/action/fields/existing/validation';
import validateBooleanField from 'schemaBuilder/modules/action/fields/types/boolean/validation';
import validateDateField from 'schemaBuilder/modules/action/fields/types/date/validation';
import validateHelperField from 'schemaBuilder/modules/action/fields/types/helper/validation';
import validateRichTextField from 'schemaBuilder/modules/action/fields/types/richText/validation';
import validateShortTextField from 'schemaBuilder/modules/action/fields/types/shortText/validation';
import validateNumberField from 'schemaBuilder/modules/action/fields/types/number/validation';
import validateGroupsField from 'schemaBuilder/modules/action/fields/types/groups/validation';
import validateCheckboxesField from 'schemaBuilder/modules/action/fields/types/checkboxes/validation';
import validateFileUploadField from 'schemaBuilder/modules/action/fields/types/fileUpload/validation';
import validateTextareaField from 'schemaBuilder/modules/action/fields/types/textarea/validation';
import validateRadioButtonsField from 'schemaBuilder/modules/action/fields/types/radioButtons/validation';
import validateDropdownField from 'schemaBuilder/modules/action/fields/types/dropdown/validation';
import validateSubFormField from 'schemaBuilder/modules/action/fields/types/subForm/validation';
import validateListField from 'schemaBuilder/modules/action/fields/types/list/validation';

const checkFormFieldConflicts = (
  fieldPath: string,
  fieldKey: string | null | undefined,
  formKeys: Set<string>
) => {
  if (!fieldKey) return;
  validate(fieldPath, `#${fieldKey} should only be used once`, () => {
    enforce(formKeys.has(fieldKey)).isFalsy();
  });
  formKeys.add(fieldKey);
};

// This prevents adding new schema fields with already existing keys,
// but still allows editing schema fields from existing forms.

// The logic is that new action fields from new forms will have completely
// new nanoids that will not be featured in the workflow schema, but "new"
// action fields from existing forms are already persisted in the schema.

// We also don't allow duplicate field labels because schema fields will only
// be listed by their label, and having duplicate labels is really confusing.
const checkSchemaConflicts = (
  fieldPath: string,
  schemaField: BaseSchemaField & { label?: string },
  schema: WorkflowSchema
) => {
  if (schemaField.key && schema[schemaField.key]) {
    validate(fieldPath, `#${schemaField.key} already exists in schema`, () => {
      enforce(schema[schemaField.key].id).equals(schemaField.id);
    });
  }

  if (schemaField.label) {
    const labelConflictField = Object.values(schema).find(
      field => field.label === schemaField.label
    );
    if (labelConflictField) {
      validate(
        `${fieldPath}.schemaField.label`,
        `Label "${schemaField.label}" is already used in schema`,
        () => {
          enforce(schema[labelConflictField.key].id).equals(schemaField.id);
        }
      );
    }
  }
};

export function makeValidateField(schema) {
  const formKeys = new Set<string>();
  const fn = rootPath => (field, i) => {
    const fieldPath = `${rootPath}.${i}`;
    vestGroup(fieldPath, () => {
      switch (field.type) {
        case ActionFieldType.Existing:
          validateExistingField(field, fieldPath);
          checkFormFieldConflicts(fieldPath, field.ref, formKeys);
          return null;
        case ActionFieldType.New:
          const fieldKey =
            field.schemaField.key || field.schemaField.provisionalKey;
          checkSchemaConflicts(fieldPath, field.schemaField, schema);
          checkFormFieldConflicts(fieldPath, fieldKey, formKeys);
          break;
        default:
          if (!field.type) {
            return validateEmptyField(field, fieldPath);
          }
      }

      switch (field.schemaField.type) {
        case SchemaFieldType.Boolean:
          return validateBooleanField(field as BooleanActionField, fieldPath);
        case SchemaFieldType.Date:
          return validateDateField(field as DateActionField, fieldPath);
        case SchemaFieldType.Helper:
          return validateHelperField(field as HelperActionField, fieldPath);
        case SchemaFieldType.ShortText:
          return validateShortTextField(
            field as ShortTextActionField,
            fieldPath
          );
        case SchemaFieldType.List:
          return validateListField(field as ListActionField, fieldPath);
        case SchemaFieldType.SubForm:
        case SchemaFieldType.RichList:
          return validateSubFormField(
            field as SubFormActionField,
            fieldPath,
            fn
          );
        case SchemaFieldType.RichText:
          return validateRichTextField(field as RichTextActionField, fieldPath);
        case SchemaFieldType.Groups:
          return validateGroupsField(field as GroupsActionField, fieldPath);
        case SchemaFieldType.Textarea:
          return validateTextareaField(field as TextareaActionField, fieldPath);
        case SchemaFieldType.Number:
          return validateNumberField(field as NumberActionField, fieldPath);
        case SchemaFieldType.Checkboxes:
          return validateCheckboxesField(
            field as CheckboxesActionField,
            fieldPath
          );
        case SchemaFieldType.FileUpload:
          return validateFileUploadField(
            field as FileUploadActionField,
            fieldPath
          );

        case SchemaFieldType.RadioButtons:
          return validateRadioButtonsField(
            field as RadioButtonsActionField,
            fieldPath
          );

        case SchemaFieldType.Dropdown:
          return validateDropdownField(field as DropdownActionField, fieldPath);
        default:
          // This case should only happen in development, but unexpected form states may happen,
          // in which case fall back to a 100% fail.
          return validate(fieldPath, 'Unhandled field type', () => {
            enforce(false).isTruthy();
          });
      }
    });
  };

  return fn;
}

export const createValidator = (schema: WorkflowSchema) =>
  create('Action form', (action: Action) => {
    validate('name', 'Action name is required', () => {
      enforce(action.name).isNotEmpty();
    });

    const validateField = makeValidateField(schema)('fields');

    // Note: Actions that only require signing can have 0 fields and still be valid.
    action.fields?.forEach(validateField);
  });

export const useValidation = () => {
  const { validation } = useContext(DataContext);
  // Since we passed a validator to the data context,
  // we are sure that validation exists
  return validation!;
};
