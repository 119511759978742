import React, { FC } from 'react';

import { TextField } from '../../../../fields';
import { NumberInputDefinition } from '../../../../types';
import useStyles from './body.style';

interface Props {
  path: string;
  input: NumberInputDefinition;
}

// form number input configuration body component
export const Body: FC<Props> = ({ path, input }) => {
  const classes = useStyles();

  const { placeholder } = input;

  return (
    <TextField
      className={classes.field}
      label="Placeholder"
      value={placeholder}
      path={`${path}.placeholder`}
    />
  );
};

export default React.memo(Body);
