import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'inline-block',
    '&[data-is-plus=false]': {
      '& button': {
        color: theme.warningRed
      }
    }
  }
}));
