import { createContext } from 'react';

import { Workflow } from 'utils/trace';

// workflow context for sharing info eg on groups
// to deep components (eg some fields in the form builder)
export interface WorkflowGroup {
  rowId: string; // group rowId, if queries might be required later on
  label: string; // group label, that will potentially be stored in form data
  name?: string; // name of the group owner
  avatar?: string; // group avatar used to display in groups selection list
}

export interface WorkflowContextDefinition {
  rowId: string; // workflow rowId, if queries might be required later on
  groups: WorkflowGroup[]; // all the groups that can act on the workflow
}

export const WorkflowContext = createContext<WorkflowContextDefinition | null>(
  null
);

// build workflow context from a workflow instance
export const buildWorkflowContext = (
  workflow: Workflow
): WorkflowContextDefinition => {
  const {
    rowId,
    groups: { nodes: groups }
  } = workflow;

  return {
    rowId,
    groups: groups.map(({ rowId, label, name, avatar }) => ({
      rowId,
      label,
      name,
      avatar
    }))
  };
};
