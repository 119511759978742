import { FC, memo, useCallback, useContext, useMemo } from 'react';

import { FieldText, Pushtext, Icon } from '@stratumn/atomic';
import {
  Card,
  CardContent,
  CardActions,
  IconButton
} from 'components/workflow/ui';

import { DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';
import { EventChangeHandler } from 'utils/types';
import { StatusDefinition } from 'utils/interfaces/statuses';

import generateId from 'utils/id';

import { StatusContext } from '../../context';
import { validateActions } from '../../utils';

import { ActionCard } from './actionCard';

import useStyles from './statusCard.style';

export interface Props {
  path: string;
  data: StatusDefinition;
}

export const StatusCard: FC<Props> = ({ path, data }) => {
  const classes = useStyles();

  const { set, update, append } = useContext(DataContext);
  const { showErrors } = useContext(StatusContext);

  const { label, isCollapsed, actions } = data;

  let errorsStr = '';
  if (showErrors && (!label || label === '')) {
    errorsStr = 'empty field';
  }

  const cardHasErrors = useMemo(
    (): boolean | undefined =>
      showErrors && (!label || label === '' || validateActions(actions) > 0),
    [actions, showErrors, label]
  );

  const handleAddStatusAction = useCallback((): void => {
    append(`${path}.actions`, { key: generateId() });
  }, [append, path]);

  const handleEditStatusLabel: EventChangeHandler = useCallback(
    e => {
      set(`${path}.label`, e.target.value);
    },
    [path, set]
  );

  const handleToggleStatusCard = useCallback(
    (): void => set(`${path}.isCollapsed`, !isCollapsed),
    [isCollapsed, path, set]
  );

  const handleRemoveStatusCard = useCallback((): void => {
    const revertMessage = `The **status value** has been successfully deleted.`;

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [update, path]);

  return (
    <Card invalid={cardHasErrors}>
      <CardContent>
        <div className={classes.cardHeader}>
          <div className={classes.fieldTextWrapper}>
            <FieldText
              dataCy="edit-status-label"
              label="status label"
              placeholder="Status value"
              onValueChange={handleEditStatusLabel}
              value={label}
              invalid={!!errorsStr}
              hideLabel
            />
          </div>

          {!isCollapsed && (
            <span className={classes.cardHeaderDescription}>Begins with:</span>
          )}
        </div>
        {!isCollapsed && (
          <>
            <ul className={classes.statusActionCardsContainer}>
              {data?.actions?.length > 0 &&
                data.actions.map((action, index) => (
                  <li
                    key={action.key}
                    className={classes.statusActionCardWrapper}
                  >
                    <ActionCard
                      path={`${path}.actions.${index}`}
                      data={action}
                    />
                  </li>
                ))}
            </ul>
            <div className={classes.beginWithButton}>
              <Pushtext
                dataCy="add-status-action"
                onClick={handleAddStatusAction}
                prefix={<Icon name="CirclePlus" size={15} />}
              >
                Or begins with
              </Pushtext>
            </div>
          </>
        )}
      </CardContent>

      <CardActions>
        <IconButton
          dataCy="toggle-status-card"
          name={isCollapsed ? 'CirclePlus' : 'CircleMinus'}
          onClick={handleToggleStatusCard}
          ariaLabel="Toggle collapse status card"
        />
        {!isCollapsed && (
          <IconButton
            dataCy="remove-status-card"
            name="Trash"
            onClick={handleRemoveStatusCard}
            ariaLabel="Remove status card"
          />
        )}
      </CardActions>
    </Card>
  );
};

export default memo(StatusCard);
