import React from 'react';

import { Icon } from '@stratumn/atomic';

import { useToggle } from 'utils/hooks';

import useStyles from './panel.style';

interface PanelProps {
  title?: React.ReactNode;
  body?: React.ReactNode;
  children: React.ReactNode;
  width?: number | string;
  initCollapsed?: boolean;
}

export const Panel: React.FC<PanelProps> = ({
  title,
  body,
  width,
  children,
  initCollapsed = false
}) => {
  const classes = useStyles({ width });
  const [collapsed, toggleCollapsed] = useToggle(initCollapsed);
  return (
    <div className={classes.root}>
      {title && (
        <div className={classes.head}>
          <div className={classes.title}>{title}</div>
          <button
            type="button"
            className={classes.collapseBtn}
            onClick={toggleCollapsed}
            data-cy="toggle-collapsed"
          >
            <Icon name={collapsed ? 'CirclePlus' : 'CircleMinus'} size={23} />
          </button>
        </div>
      )}
      {!collapsed && (
        <div className={classes.body} data-has-title={!!title}>
          {body || children}
        </div>
      )}
    </div>
  );
};

export default React.memo(Panel);
