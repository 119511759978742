import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  subHeader: {
    marginBottom: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subHeaderMsg: {
    marginRight: 20,
    textAlign: 'justify',
    fontSize: 13
  },
  workflowName: {
    color: theme.indigo2,
    fontWeight: 700
  },
  themeSwitch: {
    width: 100,
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  switchLabel: {
    color: theme.grey4,
    '&[data-is-active=true]': {
      color: theme.grey1,
      fontWeight: 600
    }
  },
  diffsContainer: {
    borderRadius: 2,
    border: `1px solid ${theme.grey6}`
  }
}));
