import { DeadlineType } from 'utils/interfaces/deadlines';

// list all possible deadline options
export const DEADLINE_OPTIONS = [
  {
    value: DeadlineType.ActionBased,
    label: 'Action based deadline'
  },
  {
    value: DeadlineType.StateBased,
    label: 'Trace state based deadline'
  }
];
