import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
    padding: '0 20px',
    background: theme.grey8,
    height: `calc(100vh - ${theme.headerHeight || '70px'})`,
    overflowY: 'hidden'
  },
  leftPanel: {
    paddingTop: 20,
    flex: 1,
    minWidth: '25%'
  },
  rightColumn: {
    paddingTop: 20,
    width: '60%',
    flex: 4,
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 20
  },
  actionNameField: {
    margin: '15px 0'
  },
  ctaButtonsWrapper: {
    display: 'flex',
    marginTop: 15,
    '& button:first-child': {
      marginRight: 10
    }
  },
  errors: {
    overflow: 'hidden',
    color: theme.warningRed,
    marginTop: 11,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'normal',
    '& > div': {
      display: 'inline-block',
      position: 'relative',
      top: 3
    }
  },
  errorsContent: {
    marginLeft: 3
  }
}));
