import React, { ChangeEvent, useCallback } from 'react';
import { useField } from 'formik';

import { FieldSelectCompact, Option } from '@stratumn/atomic';

import useStyles from './select.style';

interface SelectProps {
  inline?: boolean;
  name: string;
  label: string;
  options?: {
    label: string;
    value: string;
  }[];
}

export const FormikSelect: React.FC<SelectProps> = props => {
  const classes = useStyles();
  const [field, meta, helpers] = useField(props.name);

  const onChange = useCallback(
    (e: ChangeEvent<any>) => {
      helpers.setValue(e.currentTarget.value);
    },
    [helpers]
  );

  return (
    <div className={classes.container} data-inline={props.inline}>
      <FieldSelectCompact onValueChange={onChange} {...field} {...props}>
        {props.options?.map(({ label, value }) => (
          <Option
            label={label}
            value={value}
            key={value}
            selected={meta.value === value}
          />
        ))}
      </FieldSelectCompact>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};
