export interface FormDefinition {
  collapsed?: boolean;
  showPropertiesKey?: boolean;
  title?: string;
  description?: string;
  properties: PropertyDefinition[];
}

export interface PropertyDefinition {
  key: string;
  propertyKey?: string;
  label?: string;
  isRequired?: boolean;
  input?: InputDefinition;
}

export interface DependencyDefinition<T> {
  key: string;
  collapsed?: boolean;
  label?: string;
  condition?: ConditionDefinition;
  isRequired?: boolean; // can specialize required per condition
  input: T;
}
export interface DependenciesDefinition<T> {
  sourceKey?: string;
  conditions?: DependencyDefinition<T>[];
}

export enum InputType {
  ShortText = 'shortText',
  Number = 'number',
  Date = 'date',
  Boolean = 'boolean',
  Textarea = 'textarea',
  RichText = 'richText',
  Checkboxes = 'checkboxes',
  Dropdown = 'dropdown',
  RadioButtons = 'radioButtons',
  FilesUpload = 'filesUpload',
  SubForm = 'subForm',
  List = 'list',
  GroupSelection = 'groupSelection',
  Helper = 'helper'
}

export type OptionsList = { key: string; value?: string }[];

export interface InputCommonSettingsDefinition {
  helper?: string;
}

// Short Text Input
export interface ShortTextInputDefinition {
  type: InputType.ShortText;
  placeholder?: string;
  settings?: ShortTextInputSettingsDefinition;
  dependencies?: DependenciesDefinition<ShortTextInputDefinition>;
}
export interface ShortTextInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Number Input
export interface NumberInputDefinition {
  type: InputType.Number;
  placeholder?: string;
  decimals?: number;
  settings?: NumberInputSettingsDefinition;
  dependencies?: DependenciesDefinition<NumberInputDefinition>;
}
export interface NumberInputSettingsDefinition
  extends InputCommonSettingsDefinition {
  minValue?: number;
  maxValue?: number;
}

// Date Input
export interface DateInputDefinition {
  type: InputType.Date;
  settings?: DateInputSettingsDefinition;
  dependencies?: DependenciesDefinition<DateInputDefinition>;
}
export interface DateInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Boolean Input
export interface BooleanInputDefinition {
  type: InputType.Boolean;
  trueLabel?: string;
  falseLabel?: string;
  settings?: BooleanInputSettingsDefinition;
  dependencies?: DependenciesDefinition<BooleanInputDefinition>;
}
export interface BooleanInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Textarea Input
export interface TextareaInputDefinition {
  type: InputType.Textarea;
  placeholder?: string;
  settings?: TextareaInputSettingsDefinition;
  dependencies?: DependenciesDefinition<TextareaInputDefinition>;
}
export interface TextareaInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Rich Text Input
export interface RichTextInputDefinition {
  type: InputType.RichText;
  placeholder?: string;
  settings?: RichTextInputSettingsDefinition;
  dependencies?: DependenciesDefinition<RichTextInputDefinition>;
}
export interface RichTextInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Checkboxes Input
export interface CheckboxesInputDefinition {
  type: InputType.Checkboxes;
  minItems?: number;
  maxItems?: number;
  options?: OptionsList;
  settings?: CheckboxesInputSettingsDefinition;
  dependencies?: DependenciesDefinition<CheckboxesInputDefinition>;
}
export interface CheckboxesInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Dropdown Input
export interface DropdownInputDefinition {
  type: InputType.Dropdown;
  options?: OptionsList;
  settings?: DropdownInputSettingsDefinition;
  dependencies?: DependenciesDefinition<DropdownInputDefinition>;
}
export interface DropdownInputSettingsDefinition
  extends InputCommonSettingsDefinition {}
export interface DropdownInputConditionDefinition {
  values?: string[];
}

// Radio Button Input
export interface RadioButtonsInputDefinition {
  type: InputType.RadioButtons;
  options?: OptionsList;
  settings?: RadioButtonsInputSettingsDefinition;
  dependencies?: DependenciesDefinition<RadioButtonsInputDefinition>;
}
export interface RadioButtonsInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Files Upload Input
export interface FilesUploadInputDefinition {
  type: InputType.FilesUpload;
  maxItems?: number;
  settings?: FilesUploadInputSettingsDefinition;
  dependencies?: DependenciesDefinition<FilesUploadInputDefinition>;
}
export interface FilesUploadInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Sub Form Input
export interface SubFormInputDefinition {
  type: InputType.SubForm;
  properties?: PropertyDefinition[];
  settings?: SubFormInputSettingsDefinition;
  dependencies?: DependenciesDefinition<SubFormInputDefinition>;
}
export interface SubFormInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// List Input
export interface ListInputDefinition {
  type: InputType.List;
  itemsProperties?: PropertyDefinition[];
  settings?: ListInputSettingsDefinition;
  dependencies?: DependenciesDefinition<ListInputDefinition>;
}
export interface ListInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Group Selection Input
export interface GroupSelectionInputDefinition {
  type: InputType.GroupSelection;
  settings?: GroupSelectionInputSettingsDefinition;
  dependencies?: DependenciesDefinition<GroupSelectionInputDefinition>;
}
export interface GroupSelectionInputSettingsDefinition
  extends InputCommonSettingsDefinition {}
export interface GroupSelectionInputConditionDefinition {
  values?: string[];
}

// Helper Input
export interface HelperInputDefinition {
  type: InputType.Helper;
  content?: string;
  settings?: HelperInputSettingsDefinition;
  dependencies?: DependenciesDefinition<HelperInputDefinition>;
}
export interface HelperInputSettingsDefinition
  extends InputCommonSettingsDefinition {}

// Global types
export type InputDefinition =
  | ShortTextInputDefinition
  | NumberInputDefinition
  | DateInputDefinition
  | BooleanInputDefinition
  | TextareaInputDefinition
  | RichTextInputDefinition
  | CheckboxesInputDefinition
  | DropdownInputDefinition
  | RadioButtonsInputDefinition
  | FilesUploadInputDefinition
  | SubFormInputDefinition
  | ListInputDefinition
  | GroupSelectionInputDefinition
  | HelperInputDefinition;

export type InputSettingsDefinition =
  | ShortTextInputSettingsDefinition
  | NumberInputSettingsDefinition
  | DateInputSettingsDefinition
  | BooleanInputSettingsDefinition
  | TextareaInputSettingsDefinition
  | RichTextInputSettingsDefinition
  | CheckboxesInputSettingsDefinition
  | DropdownInputSettingsDefinition
  | RadioButtonsInputSettingsDefinition
  | FilesUploadInputSettingsDefinition
  | SubFormInputSettingsDefinition
  | ListInputSettingsDefinition
  | GroupSelectionInputSettingsDefinition;

export type ConditionDefinition =
  | DropdownInputConditionDefinition
  | GroupSelectionInputConditionDefinition;

export type AnyDependenciesDefinition = DependenciesDefinition<InputDefinition>;
export type AnyDependencyDefinition = DependencyDefinition<InputDefinition>;

export enum DraggableType {
  Properties = 'properties',
  CheckboxesOptions = 'checkboxesOptions',
  DropdownOptions = 'dropdownOptions',
  RadioButtonsOptions = 'radioButtonsOptions'
}
