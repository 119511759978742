import React from 'react';
import { Panel } from 'components/workflow/ui';
import { FormikProps } from 'formik';
import { PresetInstance } from 'utils/presets';
import { FormikCheckbox } from 'components/formik';

import { CommentInput } from '.';

const CommentForm: React.FC<FormikProps<PresetInstance<CommentInput>>> = () => (
  <Panel title="Settings">
    <FormikCheckbox name="input.enableFiles" label="Allow file upload" />
  </Panel>
);

export default CommentForm;
