import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    backgroundColor: '#F2F1F5',
    height: `calc(100vh - ${theme.headerHeight || '70px'})`,
    display: 'flex',
    justifyContent: 'center'
  },
  creationWindow: {
    backgroundColor: 'white',
    width: 500,
    height: 300,
    borderRadius: 10,
    marginTop: 70,
    display: 'flex',
    padding: 20,
    flexDirection: 'column'
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between'
  },
  // Some hacks allowing to have a file input displayed as a PushButton
  importLabel: {
    position: 'relative',
    overflow: 'hidden'
  },
  importInput: {
    cursor: 'pointer',
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    fontSize: 1000
  },
  row: {
    display: 'flex',
    margin: '8px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:last-child': {
      marginTop: 'auto'
    }
  },
  name: {
    width: 340
  },
  prefix: {
    width: 108,
    marginLeft: 10,
    // Need this otherwise the input overflow for some mysterious reason...
    '&>label': {
      display: 'block'
    }
  },
  owner: {
    width: 220
  }
}));
