import generateId from 'utils/id';
import {
  Section,
  Widget,
  WidgetTypesEnums,
  ItemsTypesEnums
} from '../../types';

const defaultPlaceholder =
  'There is still no widget added. Please click on the “Add widget” button to add one.';

const defaultTraceInfoSections = [
  {
    description: 'Information that characterise a Trace in your Workflow',
    itemsType: ItemsTypesEnums.KeyValue
  },
  {
    description:
      'Information that are generated by the Status and Deadline modules that you want to keep in the Trace Info',
    itemsType: ItemsTypesEnums.KeyValue
  },
  {
    description: 'Attachments related to the trace',
    itemsType: ItemsTypesEnums.File
  },
  {
    description: 'Visualize the data that you imported through a trace',
    itemsType: ItemsTypesEnums.DataTab
  },
  {
    description: 'Comments posted on the trace',
    itemsType: ItemsTypesEnums.Comment
  }
];

interface ICurrentItem {
  view: {
    key: string;
    type: string;
    value: { view: { path: string; type: WidgetTypesEnums } };
  };
}

export const parseItems = (acc: Widget[], currentItem): Widget[] => {
  if (currentItem.view.type === ItemsTypesEnums.KeyValue) {
    const {
      view: {
        key,
        value: {
          view: { path, type, ...otherProperties }
        }
      }
    }: ICurrentItem = currentItem;

    acc.push({
      key: generateId(),
      label: key,
      path,
      type,
      ...otherProperties
    });

    return acc;
  }

  /**
   *  TODO: once product comes up with a logic for types other than keyValue, we'll implement its items data structure. For now, we just push the item as is.
   * */
  acc.push(currentItem);

  return acc;
};

const parseDescription = (item, index) => {
  let description = '';

  if (!item) return description;

  if (Object.keys(item.view).includes('itemsPath')) {
    description =
      defaultTraceInfoSections.find(
        ({ itemsType }) => itemsType === item.view.itemsWidget.view.type
      )?.description || '';
  }

  if (item.view.type === ItemsTypesEnums.DataTab) {
    description =
      defaultTraceInfoSections.find(
        ({ itemsType }) => itemsType === ItemsTypesEnums.DataTab
      )?.description || '';
  }

  if (item.view.type === ItemsTypesEnums.KeyValue) {
    // This only works if we keep the same two sections in order.
    description = defaultTraceInfoSections[index]?.description || '';
  }

  return description;
};

const parsePlaceHolder = (type: ItemsTypesEnums, title: string): string => {
  if (type === ItemsTypesEnums.KeyValue) return defaultPlaceholder;
  return `All ${title} from ${title} preset action are listed here. No option there for the moment.`;
};

export const parseSections = (
  acc: Section[],
  {
    view: { title, collapsable, displayItemCount, items, ...otherProperties }
  }: any,
  index: number
): Section[] => {
  const remainingProperties = Object.keys(otherProperties).reduce(
    (acc, currKey) => {
      // The ui doesn't need to be aware of the section type
      if (currKey === 'type') return acc;
      acc[currKey] = otherProperties[currKey];
      return acc;
    },
    {}
  );

  acc.push({
    sectionKey: generateId(),
    title,
    description: parseDescription(items[0], index),
    placeholder: parsePlaceHolder(items[0]?.view.type, title),
    collapsable: collapsable || true,
    ...(displayItemCount !== undefined && { displayItemCount }),
    items: items.reduce(parseItems, []),
    itemsType: items[0]?.view.type || ItemsTypesEnums.KeyValue,
    ...remainingProperties
  });
  return acc;
};

export default (info: any): Section[] => {
  if (info?.view?.type !== 'box') return [];

  const parsedInfo = info?.view.sections.reduce(parseSections, []);
  return parsedInfo;
};
