import { TRACE_URL } from './api';

export const ROUTE_WORKFLOW_GROUPS = `${TRACE_URL}/workflow/:id/settings/groups`;

export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_OAUTH = '/oauth';

export const ROUTE_WORKFLOWS = '/workflows';
export const ROUTE_NEW_WORKFLOW = '/newWorkflow';
export const ROUTE_ORGANIZATIONS = '/organizations';
export const ROUTE_DEMO_WORKFLOWS = '/demoWorkflows';

export const ROUTE_WORKFLOW_DETAILS = '/workflow/:id';
export const ROUTE_WORKFLOW_ACTION = '/workflow/:id/action';
export const ROUTE_WORKFLOW_TRANSITIONS = '/workflow/:id/transitions';
export const ROUTE_WORKFLOW_DISPLAY = '/workflow/:id/display';
export const ROUTE_WORKFLOW_DISPLAY_OVERVIEW = '/workflow/:id/display/overview';
export const ROUTE_WORKFLOW_DISPLAY_TRACE_INFO =
  '/workflow/:id/display/traceInfo';
export const ROUTE_STATUS = '/workflow/:id/status';
export const ROUTE_DEADLINES = '/workflow/:id/deadlines';

export const ROUTE_FORM_BUILDER = '/formBuilder';

export const ROUTE_SCHEMA_BUILDER = '/schemaBuilder/:id';
export const ROUTE_SCHEMA_ACTION = '/schemaBuilder/:id/action';
export const ROUTE_SCHEMA_EDIT_ACTION = '/schemaBuilder/:id/action/:action';
