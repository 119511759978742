import { FC, memo } from 'react';

import WorkflowRow from './workflowRow';

import useStyles from './workflowsList.style';

interface Props {
  isSuperuser?: boolean;
  loading?: boolean;
  workflows?: {
    totalCount: number;
    nodes: { rowId: string; name: string; draft: boolean }[];
  };
  filterStr?: string;
}

export const WorkflowsList: FC<Props> = ({
  isSuperuser,
  loading,
  workflows = { totalCount: 0, nodes: [] },
  filterStr
}) => {
  const classes = useStyles();

  const { totalCount, nodes } = workflows;

  if (totalCount === 0) {
    if (loading) {
      return <div>Loading...</div>;
    }
    return filterStr ? (
      <div>
        No result found for &quot;
        <span className={classes.filterStr}>{filterStr}</span>
        &quot;.
      </div>
    ) : (
      <div>
        You are not the admin of any workflows... please create one to get
        started!
      </div>
    );
  }

  return (
    <>
      {nodes.map(workflow => (
        <WorkflowRow
          key={workflow.rowId}
          isSuperuser={isSuperuser}
          workflow={workflow}
        />
      ))}
    </>
  );
};

export default memo(WorkflowsList);
