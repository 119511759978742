import React, { useState, useRef } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import { Modal, Pushbutton } from '@stratumn/atomic';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/matchesonscrollbar.css';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/scroll/annotatescrollbar';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/json-lint';

import useStyles from './jsonEditor.style';

interface Props {
  inModal?: boolean;
  title?: string;
  readOnly?: boolean;
  jsonString: string;
  onSubmit?: (str: string) => void;
  onClose?: () => void;
  codemirrorOptions?: any;
  submitButtonLabel?: string;
  closeAfterSubmit?: boolean;
  detach?: boolean;
  defaultFold?: boolean;
}

// json editor is a modal with title, editable codemirror body and a submit button
const JsonEditor: React.FC<Props> = ({
  inModal,
  readOnly,
  title,
  jsonString,
  onSubmit,
  onClose,
  codemirrorOptions,
  submitButtonLabel,
  closeAfterSubmit,
  detach,
  defaultFold
}: Props) => {
  const classes = useStyles();
  const [parsingErr, setParsingErr] = useState<string | null>(null);
  const editorRef = useRef<any>(null);

  const [submitting, setSubmitting] = useState(false);

  const submit = async () => {
    if (!onSubmit) return;
    const currentStr = editorRef.current.getValue();
    try {
      if (currentStr) JSON.parse(currentStr);
      setParsingErr(null);
    } catch (e) {
      const errorStr = `Error parsing object:\n${e.toString()}`;
      setParsingErr(errorStr);
      console.error(errorStr);
      return;
    }
    setSubmitting(true);
    await onSubmit(currentStr);
    setSubmitting(false);
    if (closeAfterSubmit && onClose) {
      onClose();
    }
  };

  // ctrl + enter to submit
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.ctrlKey) {
      submit();
    }
  };

  // json editor component
  const editorComponent = (
    <div className={classes.jsonEditor} data-is-in-modal={inModal}>
      <div
        className={classes.jsonEditorBody}
        onKeyPress={handleKeyPress}
        data-is-in-modal={inModal}
      >
        <CodeMirror
          detach={detach}
          value={jsonString}
          options={{
            readOnly,
            lineNumbers: true,
            lineWrapping: false,
            mode: {
              name: 'javascript',
              json: true
            },
            tabMode: 'shift',
            tabSize: 2,
            indentUnit: 2,
            indentWithTabs: true,
            autoCloseTags: true,
            autoCloseBrackets: true,
            matchTags: false,
            matchBrackets: true,
            foldGutter: true,
            gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
            theme: 'material',
            ...codemirrorOptions
          }}
          className="codemirrorEditor"
          editorDidMount={(editor: any) => {
            editorRef.current = editor;
            if (defaultFold) {
              editor.execCommand('foldAll');
              editor.foldCode(0);
            }
          }}
        />
      </div>
      {(parsingErr || onSubmit || onClose) && (
        <div className={classes.jsonEditorFooter}>
          <div className={classes.jsonEditorErrorMessage}>
            {parsingErr && 'Invalid JSON string !'}
          </div>
          <div className={classes.jsonEditorActions}>
            {onClose && (
              <div className={classes.jsonEditorAction}>
                <Pushbutton secondary onClick={onClose} disabled={false}>
                  CLOSE
                </Pushbutton>
              </div>
            )}
            {onSubmit && (
              <div className={classes.jsonEditorAction}>
                <Pushbutton primary onClick={submit} disabled={submitting}>
                  {submitButtonLabel || 'Save'}
                </Pushbutton>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  if (!inModal) return editorComponent;
  return (
    <Modal title={title || 'Edit JSON string'} handleCollapse={onClose} large>
      {editorComponent}
    </Modal>
  );
};

JsonEditor.defaultProps = {
  inModal: false,
  readOnly: false,
  title: '',
  onSubmit: undefined,
  onClose: undefined,
  codemirrorOptions: {},
  submitButtonLabel: '',
  closeAfterSubmit: false,
  detach: false,
  defaultFold: false
};

export default React.memo(JsonEditor);
