import React, { useState, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';

import debounce from 'lodash.debounce';

import { JsonEditor } from 'components';

import * as presets from 'presets';

import generateId from 'utils/id';
import { useDocumentTitle } from 'utils/hooks';
import { Action } from 'utils/trace';
import {
  PresetInstance,
  applyPresetInstance,
  PresetContext
} from 'utils/presets';

import { ROUTE_WORKFLOW_DETAILS } from 'constant/routes';
import { getWorkflowBreadCrumbs } from 'constant/pages';

import { HeaderLayout } from 'components/layouts';

import ActionEditorForm from './form';
import useStyles from './actionEditor.style';

import {
  State,
  ReducerAction,
  UPDATE_CONFIG,
  UPDATE_WORKFLOW_SETTINGS_PRESETS
} from '../reducers';

import { WorkflowContext, buildWorkflowContext } from '../context';

interface Props extends RouteComponentProps<{ key: string }> {
  state: State;
  dispatch: React.Dispatch<ReducerAction>;
}

export const ActionEditor = ({ location, history, state, dispatch }: Props) => {
  const classes = useStyles();
  const presetInstances = useMemo(
    () =>
      Object.keys(state.workflow.settings?.presets || {}).reduce((obj, key) => {
        const instance = state.workflow.settings.presets[key];
        obj[key] = {
          key,
          template: presets[instance.template],
          input: instance.input
        };
        return obj;
      }, {} as { [key: string]: PresetInstance<any> }),
    [state]
  );
  const [actionEditorIsDirty, setActionEditorIsDirty] = useState(false);

  const queryParams = qs.parse(location.search);
  const selectedActionKey = queryParams.key as string;

  // If "selectedAction" is undefined, this is a new action:
  // Generate a new identifier (nanoid) and use it for the "session"

  // If "selectedAction" is defined, we are editing an action:
  // The preset instance key should be equal to the action key, so we use it
  // to find the preset instance. If there's no corresponding preset instance,
  // we create a new generic preset instance with the action key as a preset instance key.
  const selectedAction: Action | undefined = selectedActionKey
    ? state.workflow.config.actions[selectedActionKey]
    : undefined;
  let selectedPresetInstance: PresetInstance<any> | undefined;
  let actionTitle = 'New Action';
  if (selectedAction) {
    selectedPresetInstance = presetInstances[selectedAction.key] || {
      key: selectedAction.key,
      template: presets.generic, // redirect actions without preset to generic (just for display)
      input: {
        action: {
          title: selectedAction.title,
          icon: selectedAction.icon
        }
      }
    };
    actionTitle = selectedAction.title;
  } else {
    const key = generateId();
    selectedPresetInstance = {
      key,
      template: presets.setData,
      input: {
        action: {},
        form: {
          properties: []
        }
      }
    };
  }
  useDocumentTitle(`${actionTitle} - ${state.workflow.name}`);

  const presetContext: PresetContext = {
    presetInstances,
    config: state.workflow.config,
    action: selectedAction
  };

  const updateAction = debounce(async (instance: PresetInstance<any>) => {
    setAction(
      await instance.template.generateAction?.(
        selectedAction,
        instance,
        presetContext
      )
    );
  }, 250);

  const validate = async (instance: PresetInstance<any>) => {
    setActionEditorIsDirty(true);
    try {
      await instance.template.schema.parse(instance.input);
    } catch (err) {
      return {
        input: err.errors.reduce((obj: any, { path, message }) => {
          obj[path.join('.')] = message;
          return obj;
        }, {})
      };
    }
    // If the preset input is valid, refresh the action preview
    updateAction(instance);
    return null;
  };

  const onSubmit = async (
    instance: PresetInstance<any>,
    helpers: FormikHelpers<PresetInstance<any>>
  ) => {
    try {
      const config = await applyPresetInstance(instance, presetContext);
      dispatch({ type: UPDATE_CONFIG, config });
      dispatch({
        type: UPDATE_WORKFLOW_SETTINGS_PRESETS,
        presets: {
          [instance.key]: {
            template: instance.template.key,
            input: instance.input
          }
        }
      });
      history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', state.workflow.rowId));
    } catch (err) {
      helpers.setFieldError('$form', err.errors || [err]);
    }
  };

  const onCancel = () => {
    history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', state.workflow.rowId));
  };

  const [action, setAction] = useState(selectedAction);

  const workflowContext = useMemo(() => buildWorkflowContext(state.workflow), [
    state.workflow
  ]);

  const header = useMemo(
    () => (
      <HeaderLayout
        dirty={{
          module: 'Actions',
          moduleIsDirty: actionEditorIsDirty,
          stateIsDirty: state.isDirty
        }}
        breadcrumbs={[
          ...getWorkflowBreadCrumbs(state.workflow.name, state.workflow.rowId),
          { label: actionTitle }
        ]}
      />
    ),
    [
      actionEditorIsDirty,
      state.isDirty,
      state.workflow.name,
      state.workflow.rowId,
      actionTitle
    ]
  );

  return (
    <>
      {header}
      <WorkflowContext.Provider value={workflowContext}>
        <div className={classes.root}>
          <div className={classes.left}>
            <Formik
              onSubmit={onSubmit}
              validate={validate}
              initialValues={selectedPresetInstance}
            >
              {formikProps => (
                <ActionEditorForm
                  formTitle={
                    !selectedActionKey ? 'Create new action' : 'Edit action'
                  }
                  formikProps={formikProps}
                  onCancel={onCancel}
                  stateIsDirty={state.isDirty}
                />
              )}
            </Formik>
          </div>
          <div className={classes.right}>
            <JsonEditor readOnly jsonString={JSON.stringify(action, null, 2)} />
          </div>
        </div>
      </WorkflowContext.Provider>
    </>
  );
};

export default withRouter(React.memo(ActionEditor));
