import { useCallback, useMemo, Dispatch } from 'react';
import { Pushbutton, Icon, ConfirmationModal } from '@stratumn/atomic';
import { RouteComponentProps } from 'react-router';

import { ROUTE_WORKFLOW_DETAILS } from 'constant/routes';
import { getWorkflowBreadCrumbs } from 'constant/pages';

import {
  DataContext,
  useDataReducer,
  useDocumentTitle,
  useToggle,
  useConfirmExit
} from 'utils/hooks';
import { getTraceFieldOptions } from 'schemaBuilder/converter/admin';

import { HeaderLayout } from 'components/layouts';
import { TabNav } from 'components/workflow/ui';

import { Action } from 'utils/trace';
import { Statuses, SectionDefinition } from 'utils/interfaces/statuses';

import { isConfigDirty } from '../../../components/workflow/utils';

import { validateStatuses } from './utils';
import { StatusContext, StatusContextDefinition } from './context';

import {
  State,
  ReducerAction,
  UPDATE_STATUSES
} from '../../../components/workflow/reducers';

import TabsList from './tabsList';
import ActiveSection from './section';
import ProgressBarsModal from './progressBarsModal';

import useStyles from './status.style';

export interface ActionBase {
  key: string;
  title: string;
}

interface ReduceActionTitles {
  (actions: { [key: string]: Action }): ActionBase[];
}

const reduceActionTitlesSorted: ReduceActionTitles = actions => {
  if (!actions) return [];
  return Object.values(actions)
    .reduce((acc: ActionBase[], { key, title }: ActionBase) => {
      acc.push({ key, title });

      return acc;
    }, [])
    .sort((a1, a2) => a1.title.localeCompare(a2.title));
};

interface Props extends RouteComponentProps<{ id: string }> {
  state: State;
  dispatch: Dispatch<ReducerAction>;
}

interface IDataReducer {
  sections: Statuses;
  selectedTabKey: string;
  actions: ActionBase[];
  showErrors: boolean;
}

export const Status = ({
  history,
  match: {
    params: { id: workflowRowId }
  },
  state: { workflow, isDirty: stateIsDirty },
  dispatch
}: Props) => {
  const classes = useStyles();

  const [showProgressBarsModal, toggleProgressBarsModal] = useToggle(true);
  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);

  const {
    config: { actions, admin }
  } = workflow;

  const statuses = useMemo(
    () =>
      admin?.statuses && Array.isArray(admin.statuses) ? admin.statuses : [],
    [admin?.statuses]
  );

  const [statusConfig, statusConfigContext] = useDataReducer<IDataReducer>({
    sections: statuses,
    selectedTabKey: statuses[0] ? statuses[0].key : '',
    actions: reduceActionTitlesSorted(actions),
    showErrors: false
  });

  const statusContextValues: StatusContextDefinition = useMemo(
    () => ({
      sections: statusConfig.sections,
      selectedTabKey: statusConfig.selectedTabKey,
      actions: statusConfig.actions,
      showErrors: statusConfig.showErrors,
      traceFieldOptions: getTraceFieldOptions(admin)
    }),
    [statusConfig, admin]
  );

  const { set } = statusConfigContext;

  useDocumentTitle(workflow.name, 'Status');

  const statusIsDirty = useMemo(() => {
    return isConfigDirty(statusConfig.sections, statuses);
  }, [statusConfig.sections, statuses]);

  useConfirmExit(statusIsDirty || stateIsDirty);

  const handleCancel = useCallback((): void => {
    if (statusIsDirty && !showConfirmationModal)
      return toggleShowConfirmationModal();
    return history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [
    statusIsDirty,
    history,
    showConfirmationModal,
    toggleShowConfirmationModal,
    workflowRowId
  ]);

  const handleOnSubmitProgressBars = useCallback(
    newSections => {
      set('sections', newSections);
      toggleProgressBarsModal();
    },
    [set, toggleProgressBarsModal]
  );

  const mainStatusesErrorsStr = useMemo(() => {
    if (!statusContextValues.showErrors) return undefined;
    const errors: string[] = validateStatuses(statusContextValues.sections);
    return errors.join(', ');
  }, [statusContextValues.sections, statusContextValues.showErrors]);

  const handleSubmit = useCallback((): void => {
    const errors: string[] = validateStatuses(statusContextValues.sections);

    if (errors.length > 0) {
      return set('showErrors', true);
    }

    dispatch({
      type: UPDATE_STATUSES,
      data: statusConfig.sections
    });
    return history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [
    dispatch,
    history,
    set,
    statusConfig,
    statusContextValues,
    workflowRowId
  ]);

  const activeGroup: SectionDefinition | undefined = useMemo(() => {
    return (
      statusContextValues.sections?.find(
        section => section.key === statusContextValues.selectedTabKey
      ) || undefined
    );
  }, [statusContextValues]);

  const activeGroupIndex: number | undefined = useMemo(() => {
    return statusContextValues.sections?.findIndex(
      section => section.key === statusContextValues.selectedTabKey ?? 0
    );
  }, [statusContextValues]);

  const header = useMemo(
    () => (
      <HeaderLayout
        dirty={{
          module: 'Status',
          moduleIsDirty: statusIsDirty,
          stateIsDirty
        }}
        breadcrumbs={[
          ...getWorkflowBreadCrumbs(workflow.name, workflow.rowId, true),
          { label: 'Status' }
        ]}
      />
    ),
    [statusIsDirty, stateIsDirty, workflow.name, workflow.rowId]
  );

  return (
    <>
      {header}
      <DataContext.Provider value={statusConfigContext}>
        <StatusContext.Provider value={statusContextValues}>
          <div className={classes.root}>
            <header className={classes.header}>
              <h1 className={classes.h1}>Status management</h1>
              <Pushbutton
                dataCy="toggle-progressbars-modal"
                primary
                onClick={toggleProgressBarsModal}
                disabled={statusContextValues.sections?.length === 0}
              >
                Manage progressbars
              </Pushbutton>
            </header>

            <div className={classes.wrapper}>
              <TabNav>
                <TabsList path="sections" data={statusContextValues} />
              </TabNav>

              <main className={classes.statusMain}>
                <ActiveSection
                  path={`sections.${activeGroupIndex}`}
                  data={activeGroup}
                />
              </main>
            </div>

            <div className={classes.ctaButtonsWrapper}>
              <div className={classes.cancelButton}>
                <Pushbutton dataCy="cancel-btn" onClick={handleCancel}>
                  cancel
                </Pushbutton>
              </div>
              <Pushbutton
                dataCy="done-btn"
                primary
                onClick={handleSubmit}
                disabled={!statusIsDirty || !!mainStatusesErrorsStr}
              >
                done
              </Pushbutton>
              {mainStatusesErrorsStr && (
                <div className={classes.errors}>
                  <Icon name="CircleInfoFill" size={15} />
                  <p className={classes.errorsContent}>
                    {mainStatusesErrorsStr}
                  </p>
                </div>
              )}
            </div>
          </div>
          {!showProgressBarsModal && (
            <ProgressBarsModal
              initSections={statusContextValues.sections}
              onSubmit={handleOnSubmitProgressBars}
              onClose={toggleProgressBarsModal}
            />
          )}
          {showConfirmationModal && statusIsDirty && (
            <ConfirmationModal
              warning
              title="Quit Status module"
              content="You’re about to quit Status module without saving your changes.<br>**All unsaved changes will be lost**.<br>Are you sure?"
              confirmBtnText="quit module"
              confirm={handleCancel}
              cancel={toggleShowConfirmationModal}
            />
          )}
        </StatusContext.Provider>
      </DataContext.Provider>
    </>
  );
};

export default Status;
