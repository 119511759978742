import { icons } from '@stratumn/atomic';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { HeaderLayout } from 'components/layouts';
import { ROUTE_WORKFLOWS, ROUTE_DEMO_WORKFLOWS } from 'constant/routes';
import { useDocumentTitle } from 'utils/hooks';

import useStyles from './home.style';

export const QUERY = gql`
  query privateRouteMyProfileQuery {
    me {
      isSuperuser
    }
  }
`;

const Home = () => {
  const classes = useStyles();
  const ICON_SIZE: number = 50;
  useDocumentTitle('Home');

  const { data } = useQuery(QUERY);
  if (data?.me?.isSuperuser) {
    return (
      <div className={classes.homeContainer}>
        <HeaderLayout />
        <div className={classes.root}>
          <Link
            data-cy="go-to-workflows"
            className={classes.item}
            to={ROUTE_WORKFLOWS}
            draggable={false}
          >
            <icons.ArrowIncomingFill size={ICON_SIZE} />
            <div className={classes.title}>Manage your Workflows</div>
          </Link>
          {/* <Link
            data-cy="go-to-organizations"
            className={classes.item}
            to={ROUTE_ORGANIZATIONS}
          >
            <icons.CircleProfile size={64} />
            <div>Organizations</div>
          </Link> */}
          <Link
            data-cy="go-to-demo-workflows"
            className={classes.item}
            to={ROUTE_DEMO_WORKFLOWS}
            draggable={false}
          >
            <icons.DocEye size={ICON_SIZE} />
            <div className={classes.title}>Demo workflows</div>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div>
      <HeaderLayout />
      <div className={classes.root}>
        <Link
          data-cy="go-to-workflows"
          className={classes.item}
          to={ROUTE_WORKFLOWS}
        >
          <icons.ArrowIncomingFill size={ICON_SIZE} />
          <div>Workflows</div>
        </Link>
      </div>
    </div>
  );
};

export default Home;
