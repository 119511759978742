import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  cardContentRoot: ({ theme: subTheme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    background: subTheme === 'embed' ? theme.grey9 : 'transparent'
  })
}));
