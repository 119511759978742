import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  maxFiles: {
    marginTop: 15,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontSize: 14,
    color: theme.grey1
  },
  maxFilesField: {
    width: 50,
    marginRight: 10
  }
}));
