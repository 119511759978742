import { memo } from 'react';

import type { SubFormActionField, BaseTypeProps } from 'schemaBuilder/types';

import { TypeWrapper } from '../../ui';

import SubFormBody from './body';

export interface Props extends BaseTypeProps {
  field: SubFormActionField;
  showFieldKeys: boolean;
}

export const SubForm = ({
  isDragging,
  field,
  dragElement,
  removeElement,
  typeSelectElement,
  fieldKeyElement,
  path,
  readonly,
  showFieldKeys
}: Props) => {
  return (
    <TypeWrapper
      readonly={readonly}
      schemaField={field.schemaField}
      required={field.required}
      isDragging={isDragging}
      dragElement={dragElement}
      typeSelectElement={typeSelectElement}
      removeElement={removeElement}
      path={path}
      fieldKeyElement={fieldKeyElement}
    >
      <SubFormBody
        showFieldKeys={showFieldKeys}
        readonly={readonly}
        schemaField={field.schemaField}
        path={path}
      />
    </TypeWrapper>
  );
};

export default memo(SubForm);
