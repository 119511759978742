import React, { createContext } from 'react';
import {
  Route,
  RouteProps,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import { useQuery, gql, TypedDocumentNode } from '@apollo/client';
import compose from 'lodash.flowright';
import { withSnackbarsContext } from '@stratumn/atomic';
import { accountClient } from 'client';

import { ROUTE_LOGIN } from 'constant/routes';
import { DISABLE_SUPERUSER_CHECK } from 'constant/environment';

type QueryType = {
  me: {
    name: string;
    isSuperuser: boolean;
    avatar: string;
    organizations: {
      edges: {
        node: {
          name: string;
          isLeader: boolean;
        };
      }[];
    };
  };
};

const QUERY: TypedDocumentNode<QueryType> = gql`
  query privateRouteMyProfileQuery {
    me {
      name
      isSuperuser
      avatar
      organizations {
        edges {
          node {
            name
            isLeader
          }
        }
      }
    }
  }
`;

export interface UserContextDefinition {
  rowId?: string;
  isSuperuser?: boolean;
  name?: string;
  avatar?: string;
}
export const UserContext = createContext<UserContextDefinition>({});

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
  errorSnackbar: (m: string) => void;
}

export const PrivateRoute = ({
  component: Component,
  errorSnackbar,
  ...rest
}: Props) => {
  const { data, loading, error } = useQuery(QUERY, { client: accountClient });

  if (error) {
    return <div> Error: {JSON.stringify(error)}</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>The data is undefined</div>;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (
          !DISABLE_SUPERUSER_CHECK &&
          !data.me?.isSuperuser &&
          !data.me?.organizations?.edges?.[0].node.isLeader
        ) {
          errorSnackbar(
            'Only superusers or organization leaders can access the admin dashboard'
          );
          props.history.push(ROUTE_LOGIN);
          return null;
        }

        return (
          <UserContext.Provider value={data.me}>
            <Component {...rest} {...props} />
          </UserContext.Provider>
        );
      }}
    />
  );
};

export default compose(
  withRouter,
  withSnackbarsContext as (p: any) => (p2: any) => React.ReactNode
)(React.memo(PrivateRoute));
