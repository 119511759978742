import { object, infer as InferType, string, boolean } from 'zod';
import { StatementType } from '@stratumn/dsl';
import { fileSchema } from 'utils/forms';
import {
  StatementWithPreset,
  PresetTemplate,
  actionPresetInputSchema,
  mergeFormSchemas,
  mergeEffects
} from 'utils/presets';

import addComments from 'presets/comment/addComments';
import saveFiles from './saveFiles';

const fileUploadInputSchema = object({
  fieldTitle: string(),
  enableHistory: boolean(),
  enableComments: boolean(),
  enableMultipleFiles: boolean()
}).merge(actionPresetInputSchema);

export type FileUploadInput = InferType<typeof fileUploadInputSchema>;

const fileUploadPreset: PresetTemplate<FileUploadInput> = {
  key: 'fileUpload',
  name: 'File upload action',
  schema: fileUploadInputSchema,
  defaultValues: {
    action: {
      title: 'File upload'
    },
    fieldTitle: 'Upload file',
    enableHistory: false,
    enableComments: false,
    enableMultipleFiles: false
  },
  generateAction: (action, { key, input }) => ({
    ...action,
    key,
    title: input.action.title,
    stageName: input.action.title,
    icon: input.action.icon || action?.icon,
    form: mergeFormSchemas(action?.form, {
      schema: {
        type: 'object',
        properties: {
          files: {
            type: 'array',
            title: input.fieldTitle,
            items: fileSchema,
            ...(input.enableMultipleFiles ? {} : { maxItems: 1 })
          }
        },
        required: ['files']
      },
      uiSchema: {
        'ui:order': ['files'],
        files: {
          'ui:field': 'FileUploadField'
        }
      }
    }),
    effects: mergeEffects(
      action?.effects,
      [
        input.enableComments && {
          $preset: key,
          $statement: StatementType.FunctionCall,
          function: { $ref: 'addComments' } as any
        },
        input.enableHistory && {
          $preset: key,
          $statement: StatementType.FunctionCall,
          function: { $ref: 'saveFiles' } as any
        }
      ].filter(Boolean) as StatementWithPreset[]
    )
  }),
  alterConfig: (config, { input }) => {
    if (!config.definitions) config.definitions = {};
    if (!config.definitions.saveFiles && input.enableHistory) {
      config.definitions.saveFiles = saveFiles;
    }
    if (!config.definitions.addComments && input.enableComments) {
      config.definitions.addComments = addComments;
    }
    return config;
  }
};

export default fileUploadPreset;
