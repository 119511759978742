import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  inputStateValueWrapper: {
    display: 'flex',
    minWidth: 190,
    flex: 1,
    marginRight: '10px',
    maxHeight: 36
  },
  selectConditionWrapper: {
    display: 'flex',
    minWidth: 199,
    flex: 1,
    marginRight: '10px',
    maxHeight: 36
  },
  inputValueWrapper: {
    display: 'flex',
    minWidth: 100,
    flex: 1,
    marginRight: '10px',
    maxHeight: 36
  },
  action: {
    display: 'flex',
    minWidth: 100,
    flex: 1,
    marginRight: '10px',
    color: theme.grey3,
    cursor: 'pointer'
  },
  actionText: {
    fontWeight: 400,
    marginLeft: '0.3em'
  }
}));
