import { DataUpdate, updateData } from 'utils/data';

// reducer actions
export enum DataActionType {
  ApplyUpdates = 'applyUpdates'
}

// apply updates on the reducer data
interface ApplyUpdatesAction {
  type: DataActionType.ApplyUpdates;
  payload: {
    updates: DataUpdate[];
  };
}

const applyUpdates = <T>(currentData: T, action: ApplyUpdatesAction): T =>
  updateData(currentData, action.payload.updates) as T;

// main exposed reducer

// note: so far only one action 'applyUpdates'
// but later we might add actions to undo updates etc... (eg by adding an updates history)
export type DataAction = ApplyUpdatesAction;

export const applyDataChange = <T>(currentData: T, action: DataAction): T => {
  // filter action required
  switch (action.type) {
    case DataActionType.ApplyUpdates:
      return applyUpdates(currentData, action as ApplyUpdatesAction);
    default:
      throw new Error(`Unknown data reducer type ${action.type}`);
  }
};
