import React, { FC, useMemo } from 'react';

import { CheckboxesInputDefinition, DraggableType } from '../../../../types';
import { OptionsList } from '../../../../fields';

import useStyles from './body.style';

interface Props {
  path: string;
  input: CheckboxesInputDefinition;
}

// form checkboxes input configuration body component
export const Body: FC<Props> = ({ path, input }) => {
  const classes = useStyles();

  const { options } = input;

  const icon = useMemo(() => <div className={classes.emptyBox} />, [
    classes.emptyBox
  ]);

  return (
    <div className={classes.root}>
      <OptionsList
        listType={DraggableType.CheckboxesOptions}
        options={options}
        path={`${path}.options`}
        icon={icon}
        placeholder="Add checkbox option"
      />
    </div>
  );
};

export default React.memo(Body);
