import { useCallback, useMemo, Dispatch, useRef } from 'react';
import {
  Pushbutton,
  Pushtext,
  Icon,
  ConfirmationModal
} from '@stratumn/atomic';
import { RouteComponentProps } from 'react-router';

import { ROUTE_WORKFLOW_DETAILS } from 'constant/routes';
import { getWorkflowBreadCrumbs } from 'constant/pages';

import {
  DataContext,
  useDataReducer,
  useDocumentTitle,
  useToggle,
  useConfirmExit
} from 'utils/hooks';

import { HeaderLayout } from 'components/layouts';

import { DataUpdateType } from 'utils/data';
import generateId from 'utils/id';
import { scrollToEndOfRef } from 'utils/scroll';
import { getTraceFieldOptions } from 'schemaBuilder/converter/admin';

import { Deadlines } from 'utils/interfaces/deadlines';

import { isConfigDirty } from '../../../components/workflow/utils';

import { validateDeadlines } from './utils';
import {
  DeadlinesContext,
  DeadlinesContextDefinition,
  getActionsList
} from './context';

import {
  State,
  ReducerAction,
  UPDATE_DEADLINES
} from '../../../components/workflow/reducers';

import DeadlineCard from './deadlineCard';

import useStyles from './deadlines.style';

interface Props extends RouteComponentProps<{ id: string }> {
  state: State;
  dispatch: Dispatch<ReducerAction>;
}

interface IDataReducer {
  deadlines: Deadlines;
  showErrors: boolean;
}

export const DeadlinesModule = ({
  history,
  match: {
    params: { id: workflowRowId }
  },
  state: { workflow, isDirty: stateIsDirty },
  dispatch
}: Props) => {
  const classes = useStyles();
  const deadlinesListRef = useRef<HTMLDivElement>(null);

  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);

  const {
    config: { actions, admin }
  } = workflow;

  const initDeadlines = useMemo(
    () =>
      admin?.deadlines && Array.isArray(admin.deadlines) ? admin.deadlines : [],
    [admin?.deadlines]
  );

  const [
    deadlinesConfig,
    deadlinesConfigContext
  ] = useDataReducer<IDataReducer>({
    deadlines: initDeadlines,
    showErrors: false
  });
  const { set, append, update } = deadlinesConfigContext;

  const { deadlines, showErrors } = deadlinesConfig;

  const actionsList = useMemo(() => getActionsList(actions), [actions]);
  const deadlinesContextValues: DeadlinesContextDefinition = useMemo(
    () => ({
      showErrors,
      actions: actionsList,
      traceFieldOptions: getTraceFieldOptions(workflow.config.admin)
    }),
    [showErrors, actionsList, workflow.config.admin]
  );

  const addDeadline = useCallback((): void => {
    append('deadlines', { key: generateId() });
    // scroll to the new deadline card after it is rendered
    scrollToEndOfRef(deadlinesListRef);
  }, [append]);

  useDocumentTitle(workflow.name, 'Deadlines');

  const deadlinesAreDirty = useMemo(() => {
    return isConfigDirty(deadlines, initDeadlines);
  }, [deadlines, initDeadlines]);

  useConfirmExit(deadlinesAreDirty || stateIsDirty);

  const handleCancel = useCallback((): void => {
    if (deadlinesAreDirty && !showConfirmationModal)
      return toggleShowConfirmationModal();
    return history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [
    deadlinesAreDirty,
    history,
    showConfirmationModal,
    toggleShowConfirmationModal,
    workflowRowId
  ]);

  const mainDeadlinesErrorsStr = useMemo(() => {
    if (!showErrors) return undefined;
    const errors: string[] = validateDeadlines(deadlines);
    return errors.join(', ');
  }, [deadlines, showErrors]);

  const handleSubmit = useCallback((): void => {
    const errors: string[] = validateDeadlines(deadlines);

    if (errors.length > 0) {
      return set('showErrors', true);
    }

    dispatch({
      type: UPDATE_DEADLINES,
      data: deadlines
    });
    return history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [dispatch, history, set, deadlines, workflowRowId]);

  // push text button to collapse / uncollapse all the deadlines
  const collapseStateBtn = useMemo(() => {
    if (deadlines.length < 2 || deadlines.every(d => -!d.type)) return null;

    // if at least one card is uncollapsed, show a Collapse all button
    const oneExpanded = deadlines.some(d => !!d.type && !d.isCollapsed);
    if (oneExpanded) {
      const onClick = () =>
        update(
          deadlines.map((_, idx) => ({
            type: DataUpdateType.Set,
            path: `deadlines.${idx}.isCollapsed`,
            value: true
          }))
        );
      return (
        <Pushtext dataCy="collapse-all" onClick={onClick}>
          Collapse all
        </Pushtext>
      );
    }

    // otherwise show an uncollapse button
    const onClick = () =>
      update(
        deadlines.map((_, idx) => ({
          type: DataUpdateType.Set,
          path: `deadlines.${idx}.isCollapsed`,
          value: false
        }))
      );
    return (
      <Pushtext dataCy="expand-all" onClick={onClick}>
        Expand all
      </Pushtext>
    );
  }, [deadlines, update]);

  const header = useMemo(
    () => (
      <HeaderLayout
        dirty={{
          module: 'Deadlines',
          moduleIsDirty: deadlinesAreDirty,
          stateIsDirty
        }}
        breadcrumbs={[
          ...getWorkflowBreadCrumbs(workflow.name, workflow.rowId, true),
          { label: 'Deadlines' }
        ]}
      />
    ),
    [deadlinesAreDirty, stateIsDirty, workflow.name, workflow.rowId]
  );

  return (
    <>
      {header}
      <DataContext.Provider value={deadlinesConfigContext}>
        <DeadlinesContext.Provider value={deadlinesContextValues}>
          <div className={classes.root}>
            <header className={classes.header}>
              <h1 className={classes.h1}>Deadlines management</h1>
              {collapseStateBtn}
            </header>

            <main className={classes.list} ref={deadlinesListRef}>
              <div className={classes.listContent}>
                {deadlines.map((deadline, idx) => (
                  <DeadlineCard
                    key={deadline.key}
                    index={idx}
                    path={`deadlines.${idx}`}
                    data={deadline}
                  />
                ))}
              </div>
            </main>

            <div className={classes.addButton}>
              <Pushtext
                dataCy="add-deadline"
                onClick={addDeadline}
                prefix={<Icon name="CirclePlusFill" size={25} />}
              >
                Create a deadline
              </Pushtext>
            </div>

            <footer className={classes.ctaButtonsWrapper}>
              <div className={classes.cancelButton}>
                <Pushbutton dataCy="cancel-btn" onClick={handleCancel}>
                  cancel
                </Pushbutton>
              </div>
              <Pushbutton
                dataCy="done-btn"
                primary
                onClick={handleSubmit}
                disabled={!deadlinesAreDirty || !!mainDeadlinesErrorsStr}
              >
                done
              </Pushbutton>
              {mainDeadlinesErrorsStr && (
                <div className={classes.errors}>
                  <Icon name="CircleInfoFill" size={15} />
                  <p className={classes.errorsContent}>
                    {mainDeadlinesErrorsStr}
                  </p>
                </div>
              )}
            </footer>
          </div>
          {showConfirmationModal && deadlinesAreDirty && (
            <ConfirmationModal
              warning
              title="Quit Deadlines module"
              content="You’re about to quit Deadlines module without saving your changes.<br>**All unsaved changes will be lost**.<br>Are you sure?"
              confirmBtnText="quit module"
              confirm={handleCancel}
              cancel={toggleShowConfirmationModal}
            />
          )}
        </DeadlinesContext.Provider>
      </DataContext.Provider>
    </>
  );
};

export default DeadlinesModule;
