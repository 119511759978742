import { createUseStyles } from 'react-jss';
import theme from 'style';

export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  trashIcon: {
    height: 22,
    color: theme.grey1,
    transition: '200ms',
    '&:hover': {
      color: theme.warningRed
    }
  }
});
