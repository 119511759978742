import React, { FC, memo, useState, useCallback } from 'react';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  FieldText
} from '@stratumn/atomic';

import { EventChangeHandler } from 'utils/types';

import useStyles from './editPropertyKeyModal.style';

interface Props {
  propertyKey?: string;
  submit: (value: string | null) => void;
  cancel: () => void;
}

export const EditPropertyKeyModal: FC<Props> = ({
  propertyKey = '',
  submit,
  cancel
}) => {
  const [key, setKey] = useState<string>(propertyKey);

  const classes = useStyles();

  const handleOnSubmit = useCallback((): void => submit(key || null), [
    key,
    submit
  ]);

  const handleChange: EventChangeHandler = useCallback(
    e => setKey(e.target.value),
    []
  );

  return (
    <Modal
      title="Edit property key"
      closeButtonLabel="Cancel"
      handleCollapse={cancel}
      hideHeaderBorderBottom
    >
      <ModalContent>
        <FieldText
          dataCy="input-property-key"
          label="property key"
          placeholder={key}
          value={key}
          onValueChange={handleChange}
          hideLabel
        />

        <p className={classes.modalContent}>
          <span>Caution:</span> You’re about to update a key. This action will
          have a direct impact on all the admin process inputs related to this
          key.
        </p>
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={cancel} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton primary onClick={handleOnSubmit} dataCy="submit-button">
          confirm
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default memo(EditPropertyKeyModal);
