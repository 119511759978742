import React, { useCallback } from 'react';
import { useField } from 'formik';

import { Check } from '@stratumn/atomic';

import useStyles from './checkbox.style';

interface CheckboxProps {
  name: string;
  label: string;
}

export const FormikCheckbox: React.FC<CheckboxProps> = props => {
  const [field, meta, helpers] = useField(props.name);
  const classes = useStyles();

  const onChange = useCallback(() => {
    helpers.setValue(!meta.value);
  }, [helpers, meta]);

  return (
    <div className={classes.container}>
      <Check
        showLabel
        darkLabel
        largeLabel
        {...field}
        {...props}
        checked={meta.value}
        handleChange={onChange}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};
