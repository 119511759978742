import { FC, memo } from 'react';

import { StatusDefinition } from 'utils/interfaces/statuses';

import ProgressBar from '../progressBar';

import useStyles from './progressBarsTab.style';

interface Props {
  path: string;
  data: StatusDefinition[];
}

export const ProgressBarsTab: FC<Props> = ({ path, data }) => {
  const classes = useStyles();

  if (data.length === 0)
    return (
      <p className={classes.placeholderMessage}>
        No status have been created here yet.
      </p>
    );

  return (
    <ul className={classes.rootProgressBarsTab}>
      {data.map(
        (
          { key, label = 'New Status value', percentProgress: percent = 0 },
          index
        ) => (
          <li key={key} className={classes.progressBar}>
            <ProgressBar
              path={`${path}.statuses.${index}`}
              data={{ label, percent }}
            />
          </li>
        )
      )}
    </ul>
  );
};

export default memo(ProgressBarsTab);
