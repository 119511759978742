import { ConditionEnums as Condition } from 'components/workflow/transitions/types';
import { ViewTypes as View } from 'schemaBuilder/modules/display/overview/types';
import { WidgetTypesEnums as Widget } from 'schemaBuilder/modules/display/traceInfo/types';
import { SchemaFieldType } from 'schemaBuilder/types';
import { Admin } from 'utils/trace';

export const Mapping = {
  [SchemaFieldType.ShortText]: {
    overview: {
      default: View.Text,
      list: [View.Text, View.Link]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.Boolean]: {
    overview: {
      default: View.Text,
      list: [View.Text, View.Number]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text, Widget.Number]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.IsTruthy]
    }
  },
  [SchemaFieldType.Date]: {
    overview: {
      default: View.Date,
      list: [View.Date]
    },
    traceInfo: {
      default: Widget.Date,
      list: [Widget.Date]
    },
    conditions: {
      list: []
    }
  },
  [SchemaFieldType.Groups]: {
    overview: {
      default: View.Text,
      list: [View.Text, View.Avatar]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.RichText]: {
    overview: {
      default: View.Text,
      list: [View.Text]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.Textarea]: {
    overview: {
      default: View.Text,
      list: [View.Text]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.Dropdown]: {
    overview: {
      default: View.Text,
      list: [View.Text]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.Number]: {
    overview: {
      default: View.Number,
      list: [View.Number]
    },
    traceInfo: {
      default: Widget.Number,
      list: [Widget.Number]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.GreaterThan, Condition.LessThan]
    }
  },
  [SchemaFieldType.Checkboxes]: {
    overview: {
      default: View.ListCompact,
      list: [View.ListCompact]
    },
    traceInfo: {
      default: Widget.List,
      list: [Widget.List]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.FileUpload]: {
    overview: {
      default: View.Text,
      list: [View.Text, View.FileCompact]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      list: []
    }
  },
  [SchemaFieldType.RadioButtons]: {
    overview: {
      default: View.Text,
      list: [View.Text]
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.List]: {
    overview: {
      default: View.ListCompact,
      list: [View.ListCompact]
    },
    traceInfo: {
      default: Widget.List,
      list: [Widget.List]
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.SubForm]: {
    overview: {
      list: []
    },
    traceInfo: {
      list: []
    },
    conditions: {
      default: Condition.Equal,
      list: [Condition.Equal, Condition.Contains, Condition.DoesNotContain]
    }
  },
  [SchemaFieldType.RichList]: {
    overview: {
      list: []
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text]
    },
    conditions: {
      list: []
    }
  },
  Status: {
    overview: {
      default: View.Text,
      list: [View.Text, View.Number, View.Progress],
      subPaths: {
        [View.Text]: 'statusLabel',
        [View.Number]: 'percentProgress',
        [View.Progress]: 'percentProgress'
      }
    },
    traceInfo: {
      default: Widget.Text,
      list: [Widget.Text, Widget.Number],
      subPaths: {
        [Widget.Text]: 'statusLabel',
        [Widget.Number]: 'percentProgress'
      }
    },
    conditions: {
      default: Condition.Equal,
      list: [
        Condition.Equal,
        Condition.Contains,
        Condition.DoesNotContain,
        Condition.LessThan,
        Condition.GreaterThan
      ],
      subPaths: {
        [Condition.Equal]: 'statusLabel',
        [Condition.Contains]: 'statusLabel',
        [Condition.DoesNotContain]: 'statusLabel',
        [Condition.LessThan]: 'percentProgress',
        [Condition.GreaterThan]: 'percentProgress'
      }
    }
  }
};

export function convertAdminSchemaToColumnSelectionOptions(
  source: string,
  list?: Array<{
    key: string;
    label: string;
    type: SchemaFieldType | 'Status';
  }>
) {
  return (list || []).map(field => {
    return {
      source,
      key: field.key,
      label: field.label,
      overview: Mapping[field.type]?.overview ?? [],
      traceInfo: Mapping[field.type]?.traceInfo ?? [],
      conditions: Mapping[field.type]?.conditions ?? []
    };
  });
}

const metadatas = [
  {
    key: 'traceName',
    label: 'Trace',
    type: SchemaFieldType.ShortText
  },
  {
    key: 'traceCreatedAt',
    label: 'Creation date',

    type: SchemaFieldType.Date
  },
  {
    key: 'head.action.title',
    label: 'Last Action',
    type: SchemaFieldType.ShortText
  },
  {
    key: 'links.totalCount',
    label: 'N° of actions',
    type: SchemaFieldType.Number
  },
  {
    key: 'head.group.name',
    label: 'Last group',
    type: SchemaFieldType.ShortText
  },
  {
    key: 'head.createdAt',
    label: 'Last Activity',
    type: SchemaFieldType.Date
  },
  {
    key: 'head.createdBy.name',
    label: 'Last performer',
    type: SchemaFieldType.ShortText
  }
];

export type TraceFieldOption = {
  source: string;
  key: string;
  label: string;
  overview: { default?: string; list: string[]; subPaths: any };
  traceInfo: { default?: string; list: string[]; subPaths: any };
  conditions: { default?: string; list: string[]; subPaths: any };
};

export type TraceFieldOptions = TraceFieldOption[];

export function getTraceFieldOptions(admin?: Admin | null): TraceFieldOptions {
  return convertAdminSchemaToColumnSelectionOptions(
    'data',
    Object.values(admin?.schema || {})
  )
    .concat(convertAdminSchemaToColumnSelectionOptions('meta', metadatas))
    .concat(
      convertAdminSchemaToColumnSelectionOptions(
        'data.statuses',
        admin?.statuses?.map(({ key, label }, i) => ({
          key: key ?? `status_${i}`,
          label: label ?? '',
          type: 'Status'
        }))
      )
    );
}

type Ctx = 'overview' | 'traceInfo' | 'conditions' | 'deadlines';

export function getTraceStateFieldsUtils(ctx: Ctx) {
  function getType(option: TraceFieldOption) {
    return option.traceInfo.list.length === 1
      ? option[ctx]?.list[0]
      : option[ctx]?.default;
  }
  return {
    getTypes: (options: TraceFieldOptions, fieldKey: string | null) => {
      const option = options?.find(c => c.key === fieldKey);
      if (!option) return [];
      return option[ctx]?.list;
    },
    getOption: (options: TraceFieldOptions, key: string | null) =>
      options?.find(s => s.key === key),
    getType,
    getPath(option: TraceFieldOption, type?: string | null) {
      return `${option.source}.${option.key}${
        type && option[ctx]?.subPaths
          ? `.${option[ctx].subPaths[type]}`
          : '' ?? ''
      }`;
    },
    getIdFromPath(path: string = '') {
      if (path.startsWith('data.statuses.')) {
        const sub = path.replace('data.statuses.', '');
        const prefixIndex = sub.indexOf('.');
        if (prefixIndex === -1) return sub;
        return sub.substring(0, prefixIndex);
      }
      if (path.startsWith('data.')) return path.replace('data.', '');
      if (path.startsWith('meta.')) return path.replace('meta.', '');
      return null;
    }
  };
}
