import { create } from 'vest';
import { useState, useCallback, useMemo, useContext } from 'react';

import debounce from 'lodash.debounce';

import { SnackbarsContext } from '@stratumn/atomic';

import useDataContextBuilder, {
  DataContextDefinition,
  DataContextConfig
} from '../useDataContextBuilder';

// this hook is simply a useState chained with a useDataContextBuilder
// to provide a local state data reducer
type DataInitializer<T> = T | (() => T);

interface DataReducerOptions {
  localStorageKey?: string;
  validator?: ReturnType<typeof create>;
}

export default <T = any>(
  initData: DataInitializer<T>,
  options?: DataReducerOptions
): [T, DataContextDefinition] => {
  const { localStorageKey, validator } = options || {};
  // declare a simple data state
  const [data, setData] = useState<T>(initData);

  // build a memo function that debounces the update of local storage at specified key
  const saveToLocalStorage = useMemo(
    () =>
      localStorageKey
        ? debounce(
            (newData: T) =>
              localStorage.setItem(localStorageKey, JSON.stringify(newData)),
            500
          )
        : () => {},
    [localStorageKey]
  );

  // build the callback passed to useDataContextBuilder by checking if data need to be saved to local storage after each action
  const contextCallback = useCallback(
    (newData: T) => {
      setData(newData);
      saveToLocalStorage(newData);
    },
    [saveToLocalStorage]
  );

  // build the configuration of the data context
  const { successSnackbar, closeSnackbars } = useContext(SnackbarsContext);
  const dataContextConfig = useMemo<DataContextConfig>(
    () => ({
      confirmationService: ({ message, label, accept, timeout }) => {
        successSnackbar(message, label, accept, timeout, true); // clear the snackbar stack, always only one bar available
        return closeSnackbars;
      },
      validationService: validator
    }),
    [successSnackbar, closeSnackbars, validator]
  );

  // connect it to a data context
  const dataContext = useDataContextBuilder<T>(
    data,
    contextCallback,
    dataContextConfig
  );

  // return the state and the context
  return [data, dataContext];
};
