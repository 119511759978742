import { ReactElement, ReactNode, memo } from 'react';
import { FieldTextCompact, Switch } from '@stratumn/atomic';

import {
  useSetLabelCallback,
  useToggleCallback
} from 'schemaBuilder/modules/action/hooks/fields';
import { BaseSchemaField, Theme } from 'schemaBuilder/types';
import { useValidation } from 'schemaBuilder/modules/action/hooks/validator';
import {
  Card,
  CardActions,
  CardContent,
  IconButton
} from 'components/workflow/ui';

import useStyles from './typeWrapper.style';

export interface Props {
  schemaField: BaseSchemaField & { label: string };
  required: boolean;
  isDragging: boolean;
  dragElement: ReactElement;
  typeSelectElement: ReactElement;
  removeElement: ReactElement;
  fieldKeyElement: ReactElement;
  children?: ReactNode;
  path: string;
  theme?: Theme;
  readonly?: boolean;
}

export const TypeWrapper = ({
  schemaField,
  required,
  isDragging,
  dragElement,
  typeSelectElement,
  removeElement,
  fieldKeyElement,
  children,
  path,
  theme,
  readonly
}: Props) => {
  const validation = useValidation();
  const classes = useStyles();

  const handleLabelUpdate = useSetLabelCallback({
    schemaFieldPath: `${path}.schemaField`,
    schemaField
  });

  const handleRequireToggle = useToggleCallback(`${path}.required`);

  return (
    <Card
      isDragging={isDragging}
      invalid={validation.hasErrorsByGroup(`${path}`)}
    >
      <CardContent theme={theme}>
        <div className={classes.header}>
          {dragElement}
          <div className={classes.label}>
            <FieldTextCompact
              invalid={validation.hasErrors(`${path}.schemaField.label`)}
              dataCy="update-field-label"
              value={schemaField.label}
              label="Input label"
              onValueChange={handleLabelUpdate}
              disabled={readonly}
            />
          </div>
          <div className={classes.spacer} />
          {typeSelectElement}
        </div>

        {children && <div className={classes.main}>{children}</div>}
        <div className={classes.footer}>
          <Switch
            disabled={readonly}
            data-cy="toggle-required"
            label="Required"
            showLabel
            invert
            on={required}
            handleChange={handleRequireToggle}
          />
          <div className={classes.spacer} />
          {fieldKeyElement}
        </div>
      </CardContent>
      <CardActions theme={theme}>
        <IconButton
          theme={theme}
          dataCy="toggle-custom-code"
          name="Code"
          onClick={() => {}}
          ariaLabel="Toggle custom code"
        />
        {removeElement}
      </CardActions>
    </Card>
  );
};

TypeWrapper.defaultProps = {
  children: null
};

export default memo(TypeWrapper);
