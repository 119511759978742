import { NumberActionField } from 'schemaBuilder/types';
import { test as validate, enforce } from 'vest';

export default (field: NumberActionField, fieldPath: string) => {
  validate(
    `${fieldPath}.schemaField.label`,
    `Please fill the required fields`,
    () => {
      enforce(field.schemaField.label).isNotEmpty();
    }
  );
};
