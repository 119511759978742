import { InputType } from '../../types';

import ShortTextInput from './shortText';
import NumberInput from './number';
import BooleanInput from './boolean';
import TextareaInput from './textarea';
import RichTextInput from './richText';
import CheckboxesInput from './checkboxes';
import DropdownInput from './dropdown';
import RadioButtonsInput from './radioButtons';
import FilesUploadInput from './filesUpload';
import SubFormInput from './subForm';
import ListInput from './list';
import GroupSelection from './groupSelection';
import Helper from './helper';

export default {
  [InputType.ShortText]: ShortTextInput,
  [InputType.Number]: NumberInput,
  [InputType.Boolean]: BooleanInput,
  [InputType.Textarea]: TextareaInput,
  [InputType.RichText]: RichTextInput,
  [InputType.Checkboxes]: CheckboxesInput,
  [InputType.Dropdown]: DropdownInput,
  [InputType.RadioButtons]: RadioButtonsInput,
  [InputType.FilesUpload]: FilesUploadInput,
  [InputType.SubForm]: SubFormInput,
  [InputType.List]: ListInput,
  [InputType.GroupSelection]: GroupSelection,
  [InputType.Helper]: Helper
};
