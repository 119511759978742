import { useContext, useCallback, memo } from 'react';

import generateId from 'utils/id';
import { DataContext } from 'utils/hooks';

import { TableColumns } from 'utils/trace';

import { ViewTypes } from '../types';

import ColumnFields from '../columnFields';

export const defaultFormatOptions: Array<String> = [
  ViewTypes.Text,
  ViewTypes.Number,
  ViewTypes.Date,
  ViewTypes.FileCompact,
  ViewTypes.Progress,
  ViewTypes.Link
];

interface Props {
  path: string;
  columns: TableColumns | null;
  isFixed: boolean;
}

// this intermediate component is motivated by a tip from react-beautiful-dnd
// for perf optimization on the droppable list, to avoid rerendering the list content while dragging
// see https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md#recommended-droppable--performance-optimisation
export const List = ({ path, columns, isFixed }: Props) => {
  const { splice } = useContext(DataContext);

  const insertColumn = useCallback(
    (index: number) => {
      splice(path, index + 1, 0, [
        {
          key: generateId()
        }
      ]);
    },
    [splice, path]
  );

  return (
    <>
      {columns &&
        columns.length > 0 &&
        columns.map((columnDef, index) => (
          <li key={columnDef.key}>
            <ColumnFields
              index={index}
              path={`${path}.${index}`}
              value={columnDef}
              isFixed={isFixed}
              optionsTypes={defaultFormatOptions}
              insertColumn={
                index === columns.length - 1 || isFixed
                  ? undefined
                  : insertColumn
              }
            />
          </li>
        ))}
    </>
  );
};

export default memo(List);
