import { useCallback, memo, FC, useContext, useMemo } from 'react';

import {
  UiconCirclePlusFill,
  UiconArrowDown,
  Pushtext
} from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';

import { FromActionDefinition } from '../types';

import { TransitionCard } from './transitionCard';

import useStyles from './actionRow.style';

type Props = {
  path: string;
  transitionCollapsed?: boolean;
} & Omit<FromActionDefinition, 'actionKey'>;

export const ActionRow: FC<Props> = ({
  path,
  actionTitle = '',
  nextActions = [],
  transitionCollapsed = false
}) => {
  const { append, set, update } = useContext(DataContext);

  const transitiveFromAction = useMemo(
    () => nextActions.find(transition => transition.key === 'keep'),
    [nextActions]
  );

  // Count of next actions already defined wihtin the action
  const nextActionsCount: number = transitiveFromAction
    ? 0
    : nextActions.length;

  const classes = useStyles({ nextActionsCount }); // we pass the count to the stylesheet in order to set the cursor's type

  const handleCollapseAction = useCallback(
    () => set(`${path}.transitionCollapsed`, !transitionCollapsed),
    [transitionCollapsed, path, set]
  );

  const handleCollapseAllGroups = useCallback(
    isCollapsed =>
      update(
        nextActions.map((_, idx) => ({
          type: DataUpdateType.Set,
          path: `${path}.nextActions.${idx}.groupsCollapsed`,
          value: isCollapsed
        }))
      ),
    [nextActions, path, update]
  );

  const handleAddTransition = useCallback((): void => {
    append(`${path}.nextActions`, {
      groupsCollapsed: false,
      groups: []
    });
    set(`${path}.transitionCollapsed`, false);
  }, [append, path, set]);

  const headerProps = useMemo(
    () => ({
      className: classes.header,
      'data-is-collapse': transitionCollapsed
    }),
    [classes.header, transitionCollapsed]
  );

  const collapsedAllGroups: boolean = useMemo(
    () => nextActions.some(transition => transition.groupsCollapsed),
    [nextActions]
  );

  const collapsableBtnProps = {
    className: classes.collapsibleBtn,
    'data-is-collapse': transitionCollapsed
  };

  const addBtnProps = {
    'data-cy': 'add-next-action',
    className: classes.addBtn,
    onClick: handleAddTransition,
    title: 'Add a transition'
  };

  const renderTransitiveFromAction = useMemo(
    () => (
      <div className={classes.root}>
        <div {...headerProps}>
          <div className={classes.titleWrapper}>
            <h2 className={classes.title}>{actionTitle}</h2>
            <span className={classes.counter}>(Transitive)</span>
          </div>
        </div>
      </div>
    ),
    [
      actionTitle,
      classes.counter,
      classes.root,
      classes.title,
      classes.titleWrapper,
      headerProps
    ]
  );

  return transitiveFromAction ? (
    renderTransitiveFromAction
  ) : (
    <div className={classes.root}>
      <div {...headerProps}>
        <div
          data-cy="collapse-expand-btn"
          className={classes.titleWrapper}
          onClick={handleCollapseAction}
        >
          <h2 className={classes.title}>{actionTitle}</h2>
          <span className={classes.counter}>({nextActionsCount})</span>
          {nextActionsCount > 0 && (
            <div {...collapsableBtnProps}>
              <UiconArrowDown size={20} active={transitionCollapsed} />
            </div>
          )}
        </div>

        <div className={classes.collapseBtnWrapper}>
          <Pushtext
            dataCy="expand-all-transition-groups"
            onClick={() => handleCollapseAllGroups(false)}
            disabled={!collapsedAllGroups}
          >
            Expand groups
          </Pushtext>
          <span className={classes.separator}> | </span>
          <Pushtext
            dataCy="collapse-all-transition-groups"
            onClick={() => handleCollapseAllGroups(true)}
            disabled={collapsedAllGroups}
          >
            Collapse groups
          </Pushtext>
        </div>
      </div>

      {!transitionCollapsed && (
        <ul>
          {/**
           * note: the non selected next actions and groups are passed to the TransitionCard via context
           * */}
          {nextActions.map((nextAction, index) => (
            <li key={index}>
              <TransitionCard
                path={`${path}.nextActions.${index}`}
                nextActionSelected={nextAction}
                allTransitions={nextActions}
                groupsCollapsed={nextAction.groupsCollapsed}
              />
            </li>
          ))}
        </ul>
      )}
      <div {...addBtnProps}>
        <UiconCirclePlusFill size={25} />
      </div>
    </div>
  );
};

export default memo(ActionRow);
