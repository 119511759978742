import { object, infer as InferType, string, array, boolean } from 'zod';
import {
  PresetTemplate,
  actionPresetInputSchema,
  mergeFormSchemas,
  mergeEffects,
  StatementWithPreset
} from 'utils/presets';
import { Action } from 'utils/trace';
import { StatementType, ResolvedExpressionType } from '@stratumn/dsl';

const validationInputSchema = object({
  assessmentFieldTitle: string(),
  reasonFieldTitle: string(),
  reasonCommentTitle: string(),
  assessments: array(
    object({
      title: string(),
      reasons: array(string()),
      enableComment: boolean()
    })
  )
}).merge(actionPresetInputSchema);

export type ValidationInput = InferType<typeof validationInputSchema>;

const validationPreset: PresetTemplate<ValidationInput> = {
  key: 'validation',
  name: 'Validation action',
  schema: validationInputSchema,
  defaultValues: {
    action: {
      title: 'Validation'
    },
    assessmentFieldTitle: 'Assessment',
    reasonFieldTitle: 'Reasons',
    reasonCommentTitle: 'Reasons',
    assessments: [
      {
        title: 'Valid',
        reasons: [],
        enableComment: false
      },
      {
        title: 'Rejected',
        reasons: [],
        enableComment: false
      }
    ]
  },
  generateAction: async (action, { key, input }) => {
    const assessments = input.assessments || [
      { title: 'Valid' },
      { title: 'Rejected' }
    ];
    // We want to allow custom statements in the assessment switch statement.
    // To do that, we need to handle that switch statement in a particular way.
    // TODO: Add next actions inside the switch thens
    const switchStatement = action?.effects?.find(
      ({ $preset, $statement }) =>
        $preset === key && $statement === StatementType.Switch
    ) || {
      $preset: key,
      $statement: StatementType.Switch,
      cases: assessments.map(({ title }) => ({
        if: {
          $expression: ResolvedExpressionType.Equal,
          arguments: [
            title,
            {
              $expression: ResolvedExpressionType.Variable,
              query: 'formData.assessment'
            }
          ]
        },
        then: []
      }))
    };

    const res: Action = {
      ...action,
      key,
      title: input.action.title,
      stageName: input.action.title,
      icon: input.action?.icon || action?.icon,
      form: mergeFormSchemas(action?.form, {
        schema: {
          type: 'object',
          properties: {
            assessment: {
              enum: assessments.map(({ title }) => title),
              title: input.assessmentFieldTitle
            }
          },
          required: ['assessment'],
          // For each assessment value, add a list of reasons that can be picked
          // This requires a specific layout of the form schema, as described here
          // https://react-jsonschema-form.readthedocs.io/en/latest/usage/dependencies/
          dependencies: {
            assessment: {
              oneOf: assessments.map(({ title, reasons, enableComment }) => {
                const res: any = {
                  properties: {
                    assessment: {
                      enum: [title]
                    }
                  }
                };
                // If comment is enabled, disable the reasons
                if (reasons?.length && !enableComment) {
                  res.properties.assessmentReasons = {
                    type: 'array',
                    title: input.reasonFieldTitle,
                    items: {
                      type: 'string',
                      enum: reasons
                    },
                    uniqueItems: true
                  };
                  res.required = ['assessmentReasons'];
                }
                if (enableComment) {
                  res.properties.assessmentComment = {
                    type: 'string',
                    format: 'draft',
                    title: input.reasonCommentTitle
                  };
                }
                return res;
              })
            }
          }
        },
        uiSchema: {
          'ui:order': ['assessment', 'assessmentReasons', 'assessmentComment'],
          assessment: {
            'ui:widget': 'radio'
          },
          assessmentReasons: {
            'ui:widget': 'checkboxes'
          }
        }
      }),
      effects: mergeEffects(action?.effects, [
        {
          $preset: key,
          $statement: StatementType.SetVariable,
          path: 'state.data.assessment',
          value: {
            value: {
              $expression: ResolvedExpressionType.Variable,
              query: 'formData.assessment'
            },
            reasons: {
              $expression: ResolvedExpressionType.Variable,
              query: 'formData.assessmentReasons'
            },
            comment: {
              $expression: ResolvedExpressionType.Variable,
              query: 'formData.assessmentComment'
            }
          }
        },
        switchStatement as StatementWithPreset
      ])
    };
    return res;
  }
};

export default validationPreset;
