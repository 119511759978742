import { useMemo, memo, ReactNode } from 'react';
import type {
  BooleanSchemaField,
  BaseTypeBodyProps
} from 'schemaBuilder/types';
import { useSetCallback } from 'schemaBuilder/modules/action/hooks/fields';

import { InputWithIcon } from '../../ui';

import useStyles from './boolean.style';

interface Props extends BaseTypeBodyProps {
  schemaField: BooleanSchemaField;
}

const Boolean = ({ schemaField, readonly = false, path }: Props) => {
  const classes = useStyles();

  const radioIcon: ReactNode = useMemo(
    () => <div className={classes.emptyCircleIcon} />,
    [classes.emptyCircleIcon]
  );

  const updateTrueLabel = useSetCallback(`${path}.schemaField.enumNames.0`);

  const updateFalseLabel = useSetCallback(`${path}.schemaField.enumNames.1`);

  return (
    <div className={classes.booleanRoot}>
      <InputWithIcon
        data-cy="true-label"
        icon={radioIcon}
        value={(schemaField.enumNames && schemaField.enumNames[0]) ?? ''}
        placeholder="Yes"
        onChange={updateTrueLabel}
        disabled={readonly}
      />

      <InputWithIcon
        data-cy="false-label"
        icon={radioIcon}
        value={(schemaField.enumNames && schemaField.enumNames[1]) ?? ''}
        placeholder="No"
        onChange={updateFalseLabel}
        disabled={readonly}
      />
    </div>
  );
};

export default memo(Boolean);
