import {
  FC,
  memo,
  useCallback,
  useRef,
  ChangeEvent,
  useState,
  KeyboardEvent
} from 'react';
import classnames from 'classnames';

import useStyles from './editableText.style';

interface Props {
  inputClassName?: string;
  inputValue?: string;
  onSubmit: (value: string) => void;
}

export const EditableText: FC<Props> = ({
  inputClassName = '',
  inputValue = '',
  onSubmit
}) => {
  const classes = useStyles();

  const [value, setValue] = useState(inputValue || '');

  const inputRef: any = useRef<HTMLDivElement>(null);

  const handleOnSubmit = useCallback((): void => {
    onSubmit(value || inputValue);
  }, [inputValue, onSubmit, value]);

  const handleOnChange = useCallback((e: ChangeEvent<any>) => {
    setValue(e.target.value);
  }, []);

  const handleOnKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') return handleOnSubmit();
      return null;
    },
    [handleOnSubmit]
  );

  const handleOnBlur = useCallback(() => {
    return handleOnSubmit();
  }, [handleOnSubmit]);

  return (
    <input
      data-cy="input"
      className={classnames(classes.input, inputClassName)}
      type="text"
      placeholder={inputValue}
      ref={inputRef}
      value={value}
      onChange={handleOnChange}
      onKeyPress={handleOnKeyPress}
      onBlur={handleOnBlur}
    />
  );
};

export default memo(EditableText);
