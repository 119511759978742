import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
    maxWidth: '80%',
    padding: '15px 22px 15px 0'
  },
  fieldTextCompactWrapper: {
    marginRight: 10,
    minWidth: 0,
    width: '100%',
    '& input': {
      width: '100%',
      height: 35
    }
  },
  dropdownWrapper: {
    '& button': {
      minWidth: 123
    },
    '& span': {
      '&:first-letter': {
        textTransform: 'uppercase'
      }
    }
  },
  dropdownOptionLabel: {
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  index: {
    padding: 3,
    marginRight: 15,
    marginLeft: 3,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.grey3
  }
}));
