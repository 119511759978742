import { memo, useContext, useEffect } from 'react';
import type { BaseTypeBodyProps, ListSchemaField } from 'schemaBuilder/types';
import { DataContext } from 'utils/hooks';
import { SmartDropdown } from '@stratumn/atomic';

import useStyles from './style';
import { getBody, simpleListFieldTypesOptions } from '../../constants';

interface Props extends BaseTypeBodyProps {
  schemaField: ListSchemaField;
}

const ListBody = ({ schemaField, readonly = false, path }: Props) => {
  const classes = useStyles();

  const { set } = useContext(DataContext);

  useEffect(() => {
    if (!schemaField.property) {
      set(`${path}.schemaField.property`, {});
    }
  });

  if (!schemaField.property) {
    return null;
  }

  const BobyComponent = getBody(schemaField.property.type);

  return (
    <div className={classes.body}>
      <SmartDropdown
        options={simpleListFieldTypesOptions}
        disabled={readonly}
        value={schemaField.property.type}
        onSelect={selected =>
          set(`${path}.schemaField.property`, {
            type: selected
          })
        }
      />
      {BobyComponent && (
        <BobyComponent
          readonly={readonly}
          schemaField={schemaField.property}
          path={`${path}.schemaField.property`}
        />
      )}
    </div>
  );
};

export default memo(ListBody);
