import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  booleanRoot: {
    marginTop: 6
  },
  emptyCircleIcon: {
    flexShrink: 0,
    width: 18,
    height: 18,
    backgroundColor: theme.white1,
    border: `1px solid ${theme.grey4}`,
    borderRadius: '50%'
  }
}));
