import { BooleanActionField } from 'schemaBuilder/types';
import { test as validate, enforce } from 'vest';
import { hasNoDuplicates } from '../../../utils/validation';

export default (field: BooleanActionField, fieldPath: string) => {
  // Label required
  validate(
    `${fieldPath}.schemaField.label`,
    `Please fill the required fields`,
    () => {
      enforce(field.schemaField.label).isNotEmpty();
    }
  );

  // No enumNames duplicates
  if (field.schemaField.enumNames) {
    validate(
      `${fieldPath}.schemaField.enumNames`,
      `"${field.schemaField.label}" has duplicate options`,
      () => {
        enforce.extend({ hasNoDuplicates });

        enforce(Object.values(field.schemaField.enumNames)).hasNoDuplicates();
      }
    );
  }
};
