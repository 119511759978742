// generic immutable data updates
// a new object is returned at each update
// see doc: https://github.com/debitoor/dot-prop-immutable
import dotProp from 'dot-prop-immutable';

export type Path = string | number | (string | number)[];

const checkIsList = (list: any, path: Path) => {
  if (!Array.isArray(list)) {
    throw new Error(
      `Data at path ${JSON.stringify(path)} was not an array in append update`
    );
  }
};

export enum DataUpdateType {
  Set = 'set',
  Delete = 'delete',
  Append = 'append',
  Remove = 'remove',
  Toggle = 'toggle',
  Merge = 'merge',
  Move = 'move',
  Splice = 'splice'
}
// map storing the updater functions by DataUpdateType
const updatersMap = {};

// Set a value at a given path
interface DataUpdateSet {
  type: DataUpdateType.Set;
  path: Path;
  value: any;
}
updatersMap[DataUpdateType.Set] = (source: any, update: DataUpdateSet) => {
  const { path, value } = update;
  if (path === '') return value;
  // If the source is null, initialize the object
  return dotProp.set(source || {}, path, value);
};

// delete a value at a given path
interface DataUpdateDelete {
  type: DataUpdateType.Delete;
  path: Path;
}
updatersMap[DataUpdateType.Delete] = (
  source: any,
  update: DataUpdateDelete
) => {
  if (!source) return undefined;
  const { path } = update;
  if (path === '') return undefined;
  return dotProp.delete(source, path);
};

// Append a value to a list
interface DataUpdateAppend {
  type: DataUpdateType.Append;
  path: Path;
  value: any;
}
updatersMap[DataUpdateType.Append] = (
  source: any,
  update: DataUpdateAppend
) => {
  // use a function as dotProp.set argument
  // to check if variable at path is indeed a list
  const { path, value } = update;

  if (path === '') {
    checkIsList(source, '');
    return [...source, value];
  }

  // note: if no data at path we can create a new array with only the new value... let's be easygoing
  if (dotProp.get(source, path) === undefined) {
    // If the source is null, initialize the object
    return dotProp.set(source || {}, path, [value]);
  }

  return dotProp.set(source || {}, path, list => {
    checkIsList(list, path);
    return [...list, value];
  });
};

// Remove a value form a list if found
interface DataUpdateRemove {
  type: DataUpdateType.Remove;
  path: Path;
  value: any;
}
updatersMap[DataUpdateType.Remove] = (
  source: any,
  update: DataUpdateRemove
) => {
  // use a function as dotProp.set argument
  // to check if variable at path is indeed a list
  const { path, value } = update;
  return dotProp.set(source, path, list => {
    checkIsList(list, path);

    // if value found remove it
    const valueIdx = list.findIndex(el => el === value);
    if (valueIdx >= 0) {
      const newList = [...list];
      newList.splice(valueIdx, 1);
      return newList;
    }

    // if value not found in list just return the original list
    return list;
  });
};

// move a list item from a list to another
// toPath is optional, unset meaning move to the same list
interface DataUpdateMove {
  type: DataUpdateType.Move;
  path: Path;
  fromIndex: number;
  toIndex: number;
  toPath?: Path;
}
updatersMap[DataUpdateType.Move] = (source: any, update: DataUpdateMove) => {
  // use a function as dotProp.set argument
  // to check if variable at path is indeed a list
  const { path, fromIndex, toIndex, toPath } = update;

  // if in the same list
  if (toPath === undefined || toPath === path) {
    return dotProp.set(source, path, list => {
      checkIsList(list, path);

      const newList = Array.from(list);
      const [removed] = newList.splice(fromIndex, 1);
      newList.splice(toIndex, 0, removed);

      return newList;
    });
  }

  // different lists
  // there are 2 actions to perform at the same time
  let removed: any = null;
  const itemRemoved = dotProp.set(source, path, list => {
    checkIsList(list, path);

    const newList = Array.from(list);
    [removed] = newList.splice(fromIndex, 1);
    return newList;
  });

  // note: if no data at toPath we can create a new array with only the new value...
  if (dotProp.get(itemRemoved, toPath) === undefined) {
    return dotProp.set(itemRemoved, toPath, [removed]);
  }

  return dotProp.set(itemRemoved, toPath, list => {
    checkIsList(list, toPath);

    const newList = Array.from(list);
    newList.splice(toIndex, 0, removed);
    return newList;
  });
};

// splice a list (insert and remove at index)
interface DataUpdateSplice {
  type: DataUpdateType.Splice;
  path: Path;
  startIndex?: number;
  deleteCount?: number;
  values?: any[];
}
updatersMap[DataUpdateType.Splice] = (
  source: any,
  update: DataUpdateSplice
) => {
  // use a function as dotProp.set argument
  // to check if variable at path is indeed a list
  const { path, startIndex = 0, deleteCount = 0, values = [] } = update;

  if (path === '') {
    checkIsList(source, '');
    const newList = [...source];
    newList.splice(startIndex, deleteCount, ...values);
    return newList;
  }

  // note: if no data at path we can create a new array with only the new value... let's be easygoing
  if (dotProp.get(source, path) === undefined) {
    // If the source is null, initialize the object
    return dotProp.set(source || {}, path, [...values]);
  }

  return dotProp.set(source || {}, path, list => {
    checkIsList(list, path);
    const newList = [...list];
    newList.splice(startIndex, deleteCount, ...values);
    return newList;
  });
};

// toggle a boolean: value = > !value
interface DataUpdateToggle {
  type: DataUpdateType.Toggle;
  path: Path;
}
updatersMap[DataUpdateType.Toggle] = (
  source: any,
  update: DataUpdateToggle
) => {
  const { path } = update;
  if (path === '') return !source;
  // If the source is null, initialize the object
  return dotProp.toggle(source || {}, path);
};

// merge the content of the source value onto the target at path
interface DataUpdateMerge {
  type: DataUpdateType.Merge;
  path: Path;
  value: any;
}
updatersMap[DataUpdateType.Merge] = (source: any, update: DataUpdateMerge) => {
  const { path, value } = update;
  // If the source is null, initialize the object
  return dotProp.merge(source || {}, path, value);
};

export type DataUpdate =
  | DataUpdateSet
  | DataUpdateDelete
  | DataUpdateAppend
  | DataUpdateRemove
  | DataUpdateMove
  | DataUpdateSplice
  | DataUpdateToggle
  | DataUpdateMerge;

// apply one date update, just find updater required in map
const applySingleUpdate = (source: any, update: DataUpdate): any => {
  const updaterType = update.type;
  const updater = updatersMap[updaterType];
  if (!updater) {
    throw new Error(`Unknown data update type ${updaterType}`);
  }
  return updater(source, update);
};

// chained data updates
export const updateData = (source: any, updates: DataUpdate[] = []): any =>
  updates.reduce(applySingleUpdate, source);
