import { FC, memo, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  withSnackbarsContext
} from '@stratumn/atomic';

export const WORKFLOW_DELETE_MUTATION = gql`
  mutation deleteModal_deleteWfMutation($workflowId: BigInt!) {
    deleteWorkflow(input: { workflowRowId: $workflowId }) {
      deletedWorkflowRowId
    }
  }
`;

interface Props {
  workflow: { rowId: string; name: string };
  close: () => void;
  errorSnackbar: (m: string) => void;
  successSnackbar: (m: string) => void;
}

export const DeleteModal: FC<Props> = ({
  workflow: { rowId, name },
  close,
  successSnackbar,
  errorSnackbar
}) => {
  const [deleteWorkflow] = useMutation(WORKFLOW_DELETE_MUTATION, {
    refetchQueries: ['workflowsQuery']
  });

  const handleDelete = useCallback(async () => {
    try {
      const { data: res } = await deleteWorkflow({
        variables: {
          workflowId: rowId
        }
      });

      close();
      successSnackbar(
        `The workflow #${res.deleteWorkflow.deletedWorkflowRowId} has been successfully deleted.`
      );
    } catch (e) {
      console.error(e);
      errorSnackbar('Error while deleting');
    }
  }, [deleteWorkflow, rowId, close, successSnackbar, errorSnackbar]);

  return (
    <Modal
      title={`Delete workflow #${rowId}`}
      closeButtonLabel="Cancel"
      handleCollapse={close}
    >
      <ModalContent>
        <p>
          You&apos;re about to delete the workflow <b>{name}</b> workflow. This
          action is irreversible
        </p>
        <p>Are you sure you want to delete it?</p>
      </ModalContent>

      <ModalActions>
        <Pushbutton onClick={close} dataCy="cancel-button">
          Cancel
        </Pushbutton>
        <Pushbutton primary onClick={handleDelete} dataCy="delete-button">
          Delete workflow #{rowId}
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default withSnackbarsContext(memo(DeleteModal));
