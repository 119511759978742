import { useState, ReactNode } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  IconButton
} from 'components/workflow/ui';

type Props = {
  header: ReactNode;
  children: ReactNode;
  handleRemove?: () => void;
  removeDataCy?: string;
  toggleDataCy?: string;
  isOpen?: boolean;
  onOpen?: () => void;
};

export function MyCard({
  header,
  children,
  handleRemove = () => {},
  toggleDataCy = 'toggle-action',
  removeDataCy,
  isOpen,
  onOpen
}: Props) {
  const [open, setOpen] = useState(true);

  const toggleValue = onOpen ? isOpen : open;
  const toggleHandler = onOpen || (() => setOpen(!open));

  return (
    <Card>
      <CardContent>
        {header}
        {toggleValue && children}
      </CardContent>
      <CardActions>
        <IconButton
          dataCy={toggleDataCy}
          name={toggleValue ? 'CircleMinus' : 'CirclePlus'}
          onClick={toggleHandler}
          ariaLabel="Hide/Remove block"
        />
        {toggleValue && (
          <IconButton
            dataCy={removeDataCy}
            name="Trash"
            onClick={() => handleRemove()}
            ariaLabel="Remove"
          />
        )}
      </CardActions>
    </Card>
  );
}

MyCard.defaultProps = {
  handleRemove: () => {},
  removeDataCy: '',
  toggleDataCy: '',
  isOpen: null,
  onOpen: null
};
