import {
  ROUTE_HOME,
  ROUTE_WORKFLOWS,
  ROUTE_WORKFLOW_DETAILS,
  ROUTE_SCHEMA_BUILDER
} from 'constant/routes';

export const WORKFLOW_HOMEPAGE = 'Workflow Homepage';

const HOME_ICON = 'HouseFill';
const WORKFLOWS_LIST = 'Workflows list';

// generate root breadcrumbs for navigation
export const geRootBreadCrumbs = () => [{ icon: HOME_ICON, path: ROUTE_HOME }];

// generate workflows list breadcrumbs for navigation
export const getWorkflowsBreadCrumbs = (includePath?: boolean) => [
  ...geRootBreadCrumbs(),
  { label: WORKFLOWS_LIST, path: includePath ? ROUTE_WORKFLOWS : undefined }
];

// generate workflow breadcrumbs for navigation
// if workflowId is unset we assume we are on the workflow details page, hence no path is reqred
// TODO: remove forSchemaBuilder once proper routes are restored
export const getWorkflowBreadCrumbs = (
  workflowName: string,
  workflowId?: string,
  forSchemaBuilder?: boolean
) => [
  ...getWorkflowsBreadCrumbs(true),
  {
    label: workflowName,
    path: workflowId
      ? (forSchemaBuilder
          ? ROUTE_SCHEMA_BUILDER
          : ROUTE_WORKFLOW_DETAILS
        ).replace(':id', workflowId)
      : undefined
  }
];
