import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@stratumn/atomic';

import useStyles from './tabLink.style';

interface Props {
  iconName: string;
  label: string;
  path: string;
}

export const TabLink = ({ iconName, label, path }: Props) => {
  const classes = useStyles();

  return (
    <li>
      <NavLink
        data-cy={`tab-${label.toLowerCase().replace(' ', '-')}`}
        className={classes.tabRoot}
        activeClassName={classes.selected}
        to={path}
      >
        <Icon name={iconName} size={20} />
        <span className={classes.tabText}>{label}</span>
      </NavLink>
    </li>
  );
};

export default memo(TabLink);
