import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  sectionsWrapper: {
    flexGrow: 1,
    paddingRight: 5,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    listStyleType: 'none'
  }
}));
