import React from 'react';
import { Panel } from 'components/workflow/ui';
import { FormikProps } from 'formik';
import { PresetInstance } from 'utils/presets';
import { FormikCheckbox, FormikTextField } from 'components/formik';

import { CancelInput } from '.';

const CancelForm: React.FC<FormikProps<PresetInstance<CancelInput>>> = () => (
  <Panel title="Settings">
    <FormikTextField name="input.warning" label="Warning message" />
    <FormikCheckbox name="input.enableComments" label="Allow commenting" />
  </Panel>
);

export default CancelForm;
