import React, { FC } from 'react';

import Option from './option';

interface Props {
  options?: { key: string; value?: string }[];
  path: string;
  icon?: React.ReactNode;
  disabled: boolean;
}

// this intermediate component is motivated by a tip from react-beautiful-dnd
// for perf optimization on the droppable list, to avoid rerendering the list content while dragging
// see https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md#recommended-droppable--performance-optimisation
export const List: FC<Props> = ({ options = [], path, icon, disabled }) => {
  return (
    <>
      {options.map((option, index) => (
        <Option
          key={option.key}
          index={index}
          option={option}
          path={`${path}.${index}`}
          icon={icon}
          disabled={disabled}
        />
      ))}
    </>
  );
};

export default React.memo(List);
