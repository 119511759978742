import { FC, useCallback, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

import { getWorkflowsBreadCrumbs } from 'constant/pages';
import { HeaderLayout } from 'components/layouts';

import { useDocumentTitle, useInput } from 'utils/hooks';

import WorkflowsList from './workflowsList';
import SubHeader from './subHeader';

import useStyles from './workflows.style';

export const WORKFLOWS_ORDER_BY = 'CREATED_AT_ASC';
export const WORKFLOWS_FILTER_DEBOUNCE = 250; // delay before running a new search query when text is typed

// build an or filter setup from a search string with comma-separated searches
const getFilterSetupFromFilterStr = searchStr =>
  !searchStr
    ? undefined
    : {
        name: { includesInsensitive: searchStr.trim() }
      };

export const QUERY = gql`
  query workflowsQuery($orderBy: [WorkflowsOrderBy!], $filter: WorkflowFilter) {
    me {
      rowId
      isSuperuser
    }
    workflows(orderBy: $orderBy, filter: $filter) {
      totalCount
      nodes {
        rowId
        name
        draft
      }
    }
  }
`;

export const Workflows: FC = () => {
  useDocumentTitle('List of Workflows');

  const classes = useStyles();

  const { loading, data, previousData, refetch } = useQuery(QUERY, {
    variables: {
      orderBy: WORKFLOWS_ORDER_BY
    },
    notifyOnNetworkStatusChange: true
  });

  // refetch data when the search input is updated
  const refetchWorkflowsWithFilter = useCallback(
    searchValue => {
      refetch({
        orderBy: WORKFLOWS_ORDER_BY,
        filter: getFilterSetupFromFilterStr(searchValue)
      } as any);
    },
    [refetch]
  );

  // search workflows linked to input field and refetch debounced callback
  const [filterStr, updateFilter] = useInput(
    '',
    refetchWorkflowsWithFilter,
    WORKFLOWS_FILTER_DEBOUNCE
  );

  const header = useMemo(
    () => <HeaderLayout breadcrumbs={getWorkflowsBreadCrumbs()} />,
    []
  );

  return (
    <div>
      {header}
      <div className={classes.main}>
        <SubHeader filterStr={filterStr} updateFilter={updateFilter} />
        <div className={classes.workflows}>
          <WorkflowsList
            isSuperuser={data?.me?.isSuperuser}
            loading={loading}
            workflows={data?.workflows || previousData?.workflows}
            filterStr={filterStr}
          />
        </div>
      </div>
    </div>
  );
};

export default Workflows;
