import { BooleanActionField } from 'schemaBuilder/types';
import { ConverterFieldPayload } from 'schemaBuilder/converter/types';
import { ResolvedExpressionType, StatementType } from '@stratumn/dsl';
import { key } from '../utils';

const DEFAULT_ENUM_NAMES = ['Yes', 'No'];

/**
 * JSONSchema7 interface expects a property name **enum**.
 * Our atomic/forms library is using react-jsonschema-form which expects a property name **enumNames** for booleans.
 * Therefore we override this property.
 * source: https://react-jsonschema-form.readthedocs.io/en/latest/usage/widgets/#for-boolean-fields
 */
interface ConverterFieldPayloadOverride
  extends Omit<ConverterFieldPayload, 'jsonSchema'> {
  jsonSchema: {
    type: string;
    properties: {
      [propertyKey: string]: {
        type: string;
        title: string;
        enumNames: string[]; // override
      };
    };
    required: string[] | undefined;
  };
}

export default (field: BooleanActionField): ConverterFieldPayloadOverride => ({
  jsonSchema: {
    type: 'object',
    properties: {
      [key(field)]: {
        type: 'boolean',
        title: field.schemaField.label,
        enumNames: [
          (field.schemaField.enumNames && field.schemaField.enumNames[0]) ||
            DEFAULT_ENUM_NAMES[0],
          (field.schemaField.enumNames && field.schemaField.enumNames[1]) ||
            DEFAULT_ENUM_NAMES[1]
        ]
      }
    },
    required: field.required ? [key(field)] : undefined
  },
  uiSchema: {
    'ui:order': [key(field)],
    [key(field)]: {
      'ui:widget': 'radio',
      'ui:options': {
        widget: 'radio'
      }
    }
  },
  effects: [
    {
      $tags: ['schemaBuilder'],
      $statement: StatementType.SetVariable,
      path: `state.data.${key(field)}`,
      value: {
        $expression: ResolvedExpressionType.Variable,
        query: `formData.${key(field)}`
      }
    }
  ]
});
