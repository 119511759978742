import { FC, memo, useContext, useCallback } from 'react';

import { Check } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import { WidgetSettingsComponentProps, WidgetSettings } from '../';

import useStyles from './link.style';

export const LinkSettings: FC<WidgetSettingsComponentProps> = ({ widget }) => {
  const classes = useStyles();

  const {
    view: { openInNewTab }
  } = widget;

  const { set } = useContext(DataContext);
  const handleChange = useCallback(
    e => {
      set('view.openInNewTab', !openInNewTab);
    },
    [set, openInNewTab]
  );

  return (
    <div className={classes.field}>
      <Check
        checked={openInNewTab}
        handleChange={handleChange}
        label="Open in a new tab"
        showLabel
        darkLabel
        largeLabel
      />
    </div>
  );
};

const settings: WidgetSettings = {
  icon: 'CircleLink',
  label: 'Set link settings',
  component: memo(LinkSettings)
};

export default settings;
