import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  tabRoot: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: '100%',
    padding: '22px 11px',
    borderLeft: '5px solid transparent',
    backgroundColor: theme.grey7
  },
  tabText: {
    marginLeft: 10,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    color: theme.grey1
  },
  selected: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    backgroundColor: theme.white1,
    borderLeftColor: theme.teal1,
    boxShadow: '-20px 3px 20px rgba(75, 53, 210, 0.1)',
    pointerEvents: 'none'
  }
}));
