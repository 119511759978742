import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useRef,
  useEffect
} from 'react';
import { Form, FormikProps, useField } from 'formik';

import mergeWith from 'lodash.mergewith';

import {
  FieldSelectCompact,
  Option,
  Pushbutton,
  Icon,
  InfoTooltip,
  SnackbarsContext,
  ConfirmationModal
} from '@stratumn/atomic';
import { Documentation } from 'components';
import { FormikTextField } from 'components/formik';

import * as presets from 'presets';
import * as docs from 'presets/docs';
import * as presetForms from 'presets/forms';

import { HIDE_UNFINISHED_PRESETS } from 'constant/environment';

import { PresetInstance } from 'utils/presets';
import { useToggle, useConfirmExit } from 'utils/hooks';

import IconSelectionModal from '../ui/iconSelectionModal';

import useStyles from './actionEditor.style';

const infoTooltipPosition = {
  place: 'below',
  placeShift: 3,
  adjustPlace: true
};

interface FormProps {
  formTitle: string;
  formikProps: FormikProps<PresetInstance<any>>;
  onCancel: () => void;
  stateIsDirty: boolean;
}

export const ActionEditorForm: React.FC<FormProps> = ({
  formTitle,
  formikProps,
  onCancel,
  stateIsDirty
}) => {
  // TODO: Display form errors (formikProps.errors) somewhere if necessary
  const {
    setValues,
    isSubmitting,
    submitCount,
    isValid,
    values: instance,
    dirty: formIsDirty
  } = formikProps;
  const classes = useStyles();

  const { successSnackbar, closeSnackbars } = useContext(SnackbarsContext);
  const closeConfirmationRef = useRef<any>();
  // register for cleaning up the confirmation service on unmount
  useEffect(() => {
    return () => {
      if (closeConfirmationRef.current) {
        closeConfirmationRef.current();
      }
    };
  }, []);

  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);

  useConfirmExit(formIsDirty || stateIsDirty);

  const handleCancel = useCallback(() => {
    if (formIsDirty && !showConfirmationModal)
      return toggleShowConfirmationModal();
    return onCancel();
  }, [
    formIsDirty,
    onCancel,
    showConfirmationModal,
    toggleShowConfirmationModal
  ]);

  const [{ value: icon }, , iconHelpers] = useField('input.action.icon');
  const [showIconSelectionModal, toggleShowIconSelectionModal] = useToggle(
    false
  );
  const selectIcon = useCallback(
    (iconStr: string) => {
      iconHelpers.setValue(iconStr);
      successSnackbar(
        'You successfully added an icon to the preset',
        'UNDO',
        () => {
          iconHelpers.setValue(icon);
        },
        undefined,
        true
      );
      closeConfirmationRef.current = closeSnackbars;
    },
    [iconHelpers, icon, successSnackbar, closeSnackbars]
  );

  const selectPreset = async (e: ChangeEvent<any>) => {
    const template = presets[e.currentTarget.value];
    const input = mergeWith(
      {},
      template.defaultValues,
      { action: instance.input.action },
      // This prevents merging falsy values
      (base, payload) => (payload ? undefined : base)
    );
    setValues({
      template,
      input,
      key: instance.key
    });
  };

  const PresetForm = presetForms[instance.template.key];

  // we disable the submit button when:
  // 1- the form is waiting for submission to complete
  // 2- the form has been submitted once by the user but it failed and the form is still invalid
  // 3- the form is not dirty
  const isSubmitDisabled =
    isSubmitting || (!isValid && submitCount > 0) || !formIsDirty;

  return (
    <>
      <Form>
        <div className={classes.actionHeader}>
          <div className={classes.actionHeaderTitle}>{formTitle}</div>
          <InfoTooltip
            text="Set the preset icon"
            position={infoTooltipPosition}
            textAlign="center"
            padding={7}
            delay={300}
          >
            <Pushbutton
              dataCy="toggle-icon-selection"
              onClick={toggleShowIconSelectionModal}
            >
              <div className={classes.iconButton}>
                Action icon
                <Icon name={icon || 'CirclePlus'} size={28} />
              </div>
            </Pushbutton>
          </InfoTooltip>
        </div>
        <div className={classes.actionMeta}>
          <FieldSelectCompact
            label="Preset"
            onValueChange={selectPreset}
            value={instance.template.key}
          >
            {(HIDE_UNFINISHED_PRESETS
              ? ['setData', 'comment']
              : Object.keys(presets)
            ).map(k => (
              <Option
                label={presets[k].name}
                value={k}
                key={k}
                selected={k === instance.template.key}
              />
            ))}
          </FieldSelectCompact>
          <FormikTextField
            inline
            label="Action name"
            name="input.action.title"
          />
        </div>
        <div className={classes.actionForm}>
          <Documentation source={docs[instance.template.key]} />
          <PresetForm {...formikProps} />
        </div>
        <div className={classes.submit}>
          <Pushbutton
            type="button"
            onClick={handleCancel}
            dataCy="cancel-button"
          >
            cancel
          </Pushbutton>
          <Pushbutton
            primary
            type="submit"
            disabled={isSubmitDisabled}
            dataCy="submit-button"
          >
            done
          </Pushbutton>
        </div>
      </Form>
      {showConfirmationModal && formIsDirty && (
        <ConfirmationModal
          data-cy="undo-confirmation-modal"
          warning
          title="Quit Action module"
          content="You’re about to quit Action module without saving your changes.<br>**All unsaved changes will be lost**.<br>Are you sure?"
          confirmBtnText="quit module"
          confirm={onCancel}
          cancel={toggleShowConfirmationModal}
        />
      )}
      {showIconSelectionModal && (
        <IconSelectionModal
          data-cy="icon-selection-modal"
          iconStr={icon || 'Doc'}
          setIconStr={selectIcon}
          close={toggleShowIconSelectionModal}
        />
      )}
    </>
  );
};

export default ActionEditorForm;
