import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  action: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.grey7}`
    },
    '&:hover': {
      backgroundColor: theme.grey9,
      '& $actionButtons': {
        opacity: 1
      },
      '& $pendingPill': {
        opacity: 0
      }
    }
  },
  actionInfo: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  actionNaming: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12
  },
  actionTitle: {
    fontWeight: 600,
    '&:hover': {
      color: theme.indigo2,
      textDecoration: 'underline'
    }
  },
  actionKey: {
    color: theme.grey4
  },
  actionPreset: {
    marginRight: 5,
    borderRight: `1px solid ${theme.grey4}`,
    paddingRight: 5
  },
  pendingPill: {
    backgroundColor: theme.teal1,
    height: 10,
    width: 10,
    borderRadius: '50%',
    display: 'inline-block',
    opacity: 1,
    position: 'absolute',
    right: 0
  },
  actionButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    opacity: 0,
    gap: 3
  }
}));
