import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  iconWrapper: {
    display: 'block',
    cursor: 'pointer',
    fontWeight: 600,
    padding: 3.5,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.grey8,
      color: theme.grey1
    }
  }
}));
