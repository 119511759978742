import React, { useCallback, useMemo } from 'react';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton
} from '@stratumn/atomic';

import { DataContext, useDataReducer } from 'utils/hooks';

import { typesCustomLabel } from '../utils';
import WidgetSettingsMap from '../widgetSettings';

import useStyles from './settingsModal.style';

type Widget = any; // TODO just to factorize the use of any until it is properly defined
interface Props {
  initWidget: Widget;
  submit: (newWidget: Widget) => void;
  cancel: () => void;
}

// The modal to modify a widget settings
export const WidgetSettingsModal = ({ initWidget, submit, cancel }: Props) => {
  const classes = useStyles();

  // we create a local reducer to manipulate a temporary new widget object
  // that is submitted only on confirmation from the dedicated button
  const [widget, widgetContext] = useDataReducer<Widget>(initWidget);

  const {
    view: { type }
  } = widget;

  // build the modal title by finding the relevant option defined in inputSelection component
  const title = useMemo(() => {
    return (
      <span>
        <span className={classes.titleWidgetType}>
          {typesCustomLabel[type] || type}
        </span>{' '}
        settings
      </span>
    );
  }, [type, classes.titleWidgetType]);

  // settings specific to the widget selected
  const { component: SettingsComponent } = WidgetSettingsMap[type] || {};
  const widgetSettings = <SettingsComponent widget={widget} />;

  // submit the current settings
  const onSubmit = useCallback(() => {
    submit(widget);
  }, [submit, widget]);

  return (
    <Modal
      title={title}
      closeButtonLabel="Cancel"
      handleCollapse={cancel}
      hideHeaderBorderBottom
    >
      <ModalContent>
        <DataContext.Provider value={widgetContext}>
          {widgetSettings}
        </DataContext.Provider>
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton onClick={cancel} dataCy="cancel-button">
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton primary onClick={onSubmit} dataCy="submit-button">
          confirm
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default React.memo(WidgetSettingsModal);
