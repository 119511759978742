import { memo, useMemo } from 'react';

import { Icon, Pushbutton } from '@stratumn/atomic';
import theme from 'style';

import { pluralize } from 'utils/strings';

import { IsDirtyMap } from '../../reducers';

import useStyles from './footer.style';

interface Props {
  onUndo: () => void;
  onSubmit: () => void;
  isDirty: boolean;
  isDirtyMap: IsDirtyMap;
}

const toNb = (field?: boolean): number => (field ? 1 : 0);

export const Footer = ({ onUndo, onSubmit, isDirty, isDirtyMap }: Props) => {
  const classes = useStyles();

  const pendingModulesMessage = useMemo(() => {
    // only some fields of isDirtyMap are accounted for
    // in the pending modules message so far....
    const nbModulesChanged =
      toNb(isDirtyMap.actions) +
      toNb(isDirtyMap.deadlines) +
      toNb(isDirtyMap.display) +
      toNb(isDirtyMap.transitions) +
      toNb(isDirtyMap.statuses);

    return nbModulesChanged
      ? `You have ${pluralize(nbModulesChanged, 'pending module')}.`
      : undefined;
  }, [isDirtyMap]);

  return (
    <footer className={classes.footerRoot}>
      <div className={classes.button}>
        <Pushbutton dataCy="undo" onClick={onUndo} disabled={!isDirty}>
          cancel
        </Pushbutton>
      </div>
      <div className={classes.button}>
        <Pushbutton
          primary
          dataCy="submit"
          onClick={onSubmit}
          disabled={!isDirty}
        >
          submit configuration
        </Pushbutton>
      </div>
      {pendingModulesMessage && (
        <div className={classes.warningWrapper}>
          <Icon name="CircleInfoFill" size={15} color={theme.teal1} />
          <p className={classes.warningContent}>{pendingModulesMessage}</p>
        </div>
      )}
    </footer>
  );
};

export default memo(Footer);
