import { ListActionField } from 'schemaBuilder/types';
import { test, enforce } from 'vest';

export default (field: ListActionField, fieldPath: string) => {
  test(
    `${fieldPath}.schemaField.label`,
    `${fieldPath} label is required`,
    () => {
      enforce(field.schemaField.label).isNotEmpty();
    }
  );

  test(`${fieldPath}.schemaField.property`, `${fieldPath} is invalid`, () => {
    enforce(field.schemaField.property.type).isNotEmpty();
  });
};
