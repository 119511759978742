import React, { FC } from 'react';

import { NumberField } from '../../../../fields';
import { FilesUploadInputDefinition } from '../../../../types';
import useStyles from './body.style';

interface Props {
  path: string;
  input: FilesUploadInputDefinition;
}

// form files upload input configuration body component
export const Body: FC<Props> = ({ path, input }) => {
  const classes = useStyles();

  const { maxItems } = input;

  return (
    <div className={classes.maxFiles}>
      <NumberField
        className={classes.maxFilesField}
        value={maxItems}
        path={`${path}.maxItems`}
        min={1}
      />
      files max
    </div>
  );
};

export default React.memo(Body);
