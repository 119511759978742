import React from 'react';
import injectSheet from 'react-jss';
import {
  Statement,
  StatementType,
  ResolvedExpression,
  ResolvedExpressionType,
  Expression,
  RefExpression
} from '@stratumn/dsl';

import styles from './effects.style';

interface Props {
  classes: any;
  effects: Statement[];
}

const Effects = ({ classes, effects }: Props) => {
  const isResolvedExpression = (e: any): e is ResolvedExpression =>
    e && e.$expression;
  const isRefExpression = (e: any): e is RefExpression => e && e.$ref;

  const renderBaseType = (e: any) => {
    if (Array.isArray(e)) {
      return (
        <>
          {e.map((item, idx) => (
            <div className={classes.listItem} key={idx}>
              {renderExpression(item)}
            </div>
          ))}
        </>
      );
    }
    if (typeof e === 'object' && e !== null) {
      return (
        <div className={classes.object}>
          <div>{'{'}</div>
          {Object.keys(e).map(k => (
            <div className={classes.objectProperty} key={k}>
              {k}: {renderExpression(e[k])},
            </div>
          ))}
          {'}'}
        </div>
      );
    }
    if (e === undefined) return <i>undefined</i>;
    return <>{e}</>;
  };
  const renderExpression = (e: Expression): any => {
    if (!isResolvedExpression(e)) return renderBaseType(e);
    if (isRefExpression(e)) return <>Reference {e.$ref}</>;

    // TODO: refactor merge, join and others that look the same
    if (e.$expression === ResolvedExpressionType.Merge) {
      return (
        <>
          <div>Merge</div>
          <div className={classes.arguments}>
            {renderExpression(e.arguments)}
          </div>
        </>
      );
    }
    if (e.$expression === ResolvedExpressionType.Join) {
      return (
        <>
          <div>Join</div>
          <div className={classes.arguments}>
            {renderExpression(e.arguments)}
          </div>
        </>
      );
    }
    if (e.$expression === ResolvedExpressionType.Concat) {
      return (
        <div>
          <div>Concatenate</div>
          <div className={classes.arguments}>
            {renderExpression(e.arguments)}
          </div>
        </div>
      );
    }

    if (e.$expression === ResolvedExpressionType.Variable) {
      return <>Variable({renderExpression(e.query)})</>;
    }
    return <>{JSON.stringify(e)}</>;
  };

  const renderStatement = (s: Statement, idx: number) => {
    // console.log(s);
    if (s.$statement === StatementType.Comment) {
      return (
        <div key={idx} className={classes.comment}>
          {s.comments}
        </div>
      );
    }

    if (s.$statement === StatementType.SetVariable) {
      return (
        <div key={idx}>
          variables.{JSON.stringify(s.path).replace(/"/g, '')} =
          <div className={classes.setVariable}>{renderExpression(s.value)}</div>
        </div>
      );
    }

    if (s.$statement === StatementType.Switch) {
      return (
        <div key={idx}>
          {s.cases.map((c, i) => (
            <div>
              <div>IF</div>
              <div>{renderExpression(c.if)}</div>
              <div>THEN</div>
              <div>{c.then.map(renderStatement)}</div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div key={idx} className={classes.statement}>
        {s.$statement}
      </div>
    );
  };

  return <div className={classes.root}>{effects.map(renderStatement)}</div>;
};

export default injectSheet(styles)(Effects);
