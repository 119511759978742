import React, { FC, useMemo } from 'react';

import { DraggableType, RadioButtonsInputDefinition } from '../../../../types';
import { OptionsList } from '../../../../fields';

import useStyles from './body.style';

interface Props {
  path: string;
  input: RadioButtonsInputDefinition;
}

// form radio buttons input configuration body component
export const Body: FC<Props> = ({ path, input }) => {
  const classes = useStyles();

  const radioIcon = useMemo(() => <div className={classes.emptyCircle} />, [
    classes.emptyCircle
  ]);

  const { options } = input;

  return (
    <div className={classes.root}>
      <OptionsList
        listType={DraggableType.RadioButtonsOptions}
        options={options}
        path={`${path}.options`}
        icon={radioIcon}
        placeholder="Add option"
      />
    </div>
  );
};

export default React.memo(Body);
