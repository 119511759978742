import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  newOption: {
    flexGrow: 1,
    padding: '4px 0px ',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover $newOptionInput': {
      borderColor: theme.grey7
    }
  },
  newOptionDefaultIcon: {
    color: theme.grey4
  },
  newOptionInput: {
    backgroundColor: 'transparent',
    flexGrow: 1,
    marginLeft: 5,
    padding: 4,
    outline: 'none',
    border: 'none',
    borderRadius: 2,
    boxSizing: 'border-box',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1,
    borderBottom: `1px solid transparent`,
    '&::placeholder': {
      color: theme.grey4
    }
  }
}));
