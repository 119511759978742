import React, { FC } from 'react';

import { DropdownInputDefinition, DraggableType } from '../../../../types';
import { OptionsList } from '../../../../fields';

import useStyles from './body.style';

interface Props {
  path: string;
  input: DropdownInputDefinition;
}

// form dropdown input configuration body component
export const Body: FC<Props> = ({ path, input }) => {
  const classes = useStyles();

  const { options } = input;

  return (
    <div className={classes.root}>
      <OptionsList
        listType={DraggableType.DropdownOptions}
        options={options}
        path={`${path}.options`}
        placeholder="Add option"
      />
    </div>
  );
};

export default React.memo(Body);
