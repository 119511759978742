import { useEffect } from 'react';

/**
 * @function useDocumentTitle - sets the description of the browser's tab
 * @param {string} title
 * @param {string} page
 */
export default (title: string, page?: string): void => {
  useEffect(() => {
    if (title) {
      document.title = `${title} ${page ? `- ${page} ` : ''}- Admin UI`;
    }
  }, [page, title]);
};
