import { validatePropertiesList } from '../../../../utils';
import { SubFormInputDefinition } from '../../../../types';

// sub form input validation
export default (
  input: SubFormInputDefinition,
  errors: string[],
  depth: number = 0,
  deepValidation: boolean = false
) => {
  const { properties } = input;
  validatePropertiesList(errors, properties, depth + 1, deepValidation);
};
