import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ROUTE_HOME } from 'constant/routes';
import { Oops } from '@stratumn/atomic';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<RouteComponentProps, State> {
  state: State = {
    hasError: false
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    console.error('componentDidCatch', error, info);
  }

  backHome = () => {
    this.setState({ hasError: false });
    this.props.history.push(ROUTE_HOME);
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Oops onHomeClick={this.backHome} />
        </>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
