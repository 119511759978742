import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    marginTop: 20,
    gap: '10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'stretch'
  },
  field: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start'
  },
  fieldTitle: {
    marginBottom: 10,
    color: theme.grey1,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '0.03em'
  },
  fieldInput: {
    alignSelf: 'stretch'
  }
}));
