import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `2fr 1fr`,
    width: '100%',
    paddingTop: 5
  },
  editableTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
    maxWidth: 480,
    height: 61
  },
  inputRoot: {
    padding: '5px 0 5px 5px',
    left: -5,
    '&:focus': {
      background: theme.grey8
    }
  },
  nameInput: {
    extend: 'inputRoot',
    height: 40,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: '0.03em',
    lineHeight: '30px',
    color: theme.grey1
  },
  descriptionInput: {
    extend: ['inputRoot'],
    height: 25,
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    color: theme.grey3,
    top: -5
  },
  action: {
    display: 'flex',
    cursor: 'pointer'
  },
  ctaButtonsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: 6
  },
  draftSwitchWrapper: {
    width: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 30
  },
  switchLabel: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '15px',
    color: theme.grey1,
    '&[data-is-disabled=true]': {
      color: theme.grey3,
      fontWeight: 'normal'
    }
  },
  automationCheck: {
    width: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 20,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    color: theme.grey1
  },
  automationCheckLabel: {
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    color: theme.grey1
  },
  barSeparator: {
    border: '1px solid #E5E3EB',
    transform: 'rotate(180deg)',
    width: 1,
    height: 35,
    marginRight: 20,
    bottom: -8,
    position: 'relative'
  },
  exportButton: {
    color: theme.grey1,
    bottom: -16,
    position: 'relative'
  },
  updateIcon: {
    color: theme.grey3,
    bottom: -16,
    position: 'relative'
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gridColumn: '1/3',
    alignItems: 'center'
  },
  workflowId: {
    flexShrink: 0,
    marginRight: 22,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1
  },
  iconBottomRowRoot: {
    flexShrink: 0,
    marginRight: 22,
    display: 'flex',
    color: theme.grey1
  },
  iconText: {
    alignSelf: 'flex-end'
  },
  prefixIcon: {
    display: 'flex',
    position: 'relative',
    bottom: -1
  },
  lastUpdate: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: theme.grey3
  },
  lastUpdateIcon: {
    flexShrink: 0
  },
  lastUpdateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  miscellaneous: {
    display: 'flex',
    textAlign: 'right',
    alignItems: 'flex-end'
  }
}));
