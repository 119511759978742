import React, { FC } from 'react';

import PropertiesList from '../../../../propertiesList';
import { ListInputDefinition } from '../../../../types';
import useStyles from './body.style';

interface Props {
  path: string;
  input: ListInputDefinition;
  depth: number; // this is the depth level of the parent object of this list
}

// form fub form input configuration body component
export const Body: FC<Props> = ({ path, input, depth }) => {
  const classes = useStyles();

  const { itemsProperties } = input;

  return (
    <div className={classes.root}>
      <PropertiesList
        path={`${path}.itemsProperties`}
        properties={itemsProperties}
        depth={depth + 1} // increment depth so that the property input selector can know whether to disable sub-forms or lists
        propertiesClassName={classes.property}
        addButtonLabel="Add list input"
      />
    </div>
  );
};

export default React.memo(Body);
