import React, { FC } from 'react';

import { NumberField } from '../../../../fields';
import { NumberInputSettingsDefinition } from '../../../../types';
import useStyles from './settings.style';

interface Props {
  path?: string;
  settings: NumberInputSettingsDefinition;
}

// form number input configuration settings component
export const Settings: FC<Props> = ({ path, settings }) => {
  const classes = useStyles();

  const { minValue, maxValue } = settings;

  const invalid =
    minValue !== undefined && maxValue !== undefined && minValue > maxValue;

  const pathRoot = path ? `${path}.` : '';

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <div className={classes.fieldTitle}>Min value</div>
        <NumberField
          className={classes.fieldInput}
          label="Min value"
          value={minValue}
          path={`${pathRoot}minValue`}
          invalid={invalid}
        />
      </div>
      <div className={classes.field}>
        <div className={classes.fieldTitle}>Max value</div>
        <NumberField
          className={classes.fieldInput}
          label="Max value"
          value={maxValue}
          path={`${pathRoot}maxValue`}
          invalid={invalid}
        />
      </div>
    </div>
  );
};

export default React.memo(Settings);
