import { useEffect } from 'react';

export default (isDirty: boolean) => {
  useEffect(() => {
    function confirmExit() {
      return 'show warning';
    }

    if (isDirty) {
      window.onbeforeunload = confirmExit;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);
};
