import React, { FC, useMemo, useCallback } from 'react';

import classnames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';

import { Trash } from '@stratumn/icons';
import { Check, Switch, FormWriter } from '@stratumn/atomic';

import { useDataReducer, useDocumentTitle } from 'utils/hooks';

import { WorkflowContext } from 'components/workflow/context';

import { JsonEditor } from 'components';
import FormBuilder, {
  FormDefinition,
  formToSchemas
} from 'components/formBuilder';

import useStyles from './formBuilderInt.style';

// this component is a hidden route to test the form builder
// and the dynamic rendering of the form writer with mock utilities
const voidFn = () => {};

// workflow context to emulate select-group input
const groups = Array.from(Array(5)).map((__, i) => ({
  rowId: 'i',
  label: `group-${i}`,
  name: `Group ${i}`,
  avatar: 'https://avatars2.githubusercontent.com/u/15095987?s=200&v=4'
}));
const formWorkflowContext = {
  author: {
    label: 'group-0'
  },
  groups
};
const formBuilderWorkflowContext = {
  rowId: '1',
  groups
};

// function to emulate file uplaod
const uploadFile = async (file, onSuccess, _onError, onProgress) => {
  await onProgress({ uploadPercent: 1 });
  return onSuccess({
    id: file.name,
    createdAt: Date().toString(),
    key: file.name,
    name: file.name,
    size: file.size,
    mimetype: file.type
  });
};

// state
interface FormBuilderIntState {
  form: FormDefinition;
  buildSchemas: boolean;
  showFormWriter: boolean;
  formData?: any;
}

// initializer
const defaultState: FormBuilderIntState = {
  form: {
    properties: []
  },
  buildSchemas: false,
  showFormWriter: false
};
const localStorageKey = 'formBuilder';
const initFormBuilder = (): FormBuilderIntState => {
  if (localStorageKey) {
    const formBuilderStr = localStorage.getItem(localStorageKey);
    if (formBuilderStr) return JSON.parse(formBuilderStr);
  }
  // no data in the local storage initialize empty form
  return defaultState;
};

interface Props {}
export const FormBuilderInt: FC<Props> = () => {
  const classes = useStyles();

  useDocumentTitle('Testing Form Builder');

  const [
    { form, buildSchemas, showFormWriter, formData },
    { set, toggle }
  ] = useDataReducer<FormBuilderIntState>(initFormBuilder, { localStorageKey });

  const updateForm = useCallback(
    (newForm: FormDefinition) => {
      // set the current form
      set('form', newForm);
    },
    [set]
  );

  // recompute schema and uischema on the fly
  const schemas = useMemo(
    () => (buildSchemas ? formToSchemas(form) : undefined),
    [buildSchemas, form]
  );

  // a fallback component for when the FormWriter produces an error
  const FormWriterErrorFallback = useCallback(
    ({ error }) => (
      <>
        <div className={classes.formWriterError}>
          <div className={classes.formWriterErrorTitle}>
            Rendering your form failed
          </div>
          <div>{error.message}</div>
          <button
            className={classnames(
              classes.rightFooterBtn,
              classes.formWriterErrorDeleteData
            )}
            data-warning
            onClick={() => {
              set('formData', {});
              toggle('showFormWriter');
            }}
            data-cy="clear-formdata"
          >
            <Trash className={classes.trashIcon} />
            Cleanup form data
          </button>
        </div>
      </>
    ),
    [classes, set, toggle]
  );

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <WorkflowContext.Provider value={formBuilderWorkflowContext}>
          <FormBuilder
            form={form}
            updateForm={updateForm}
            showErrors={buildSchemas}
          />
        </WorkflowContext.Provider>
      </div>
      <div className={classes.right}>
        <div className={classes.rightContent}>
          {showFormWriter && schemas ? (
            <div className={classes.formWriter}>
              <ErrorBoundary
                FallbackComponent={FormWriterErrorFallback}
                resetKeys={[schemas]} // note: we can't include formData in the resetKeys unfortunately as FormWriter can be updating it endlessly...
              >
                <FormWriter
                  id="formWriter"
                  schema={schemas.schema}
                  uiSchema={schemas.uiSchema}
                  onChange={value => set('formData', value.formData)}
                  formData={formData || {}}
                  uploadFile={uploadFile}
                  onSubmit={voidFn}
                  onError={voidFn}
                  workflowContext={formWorkflowContext}
                />
              </ErrorBoundary>
            </div>
          ) : (
            <div className={classes.jsonWrapper}>
              <JsonEditor
                readOnly
                jsonString={JSON.stringify(
                  { form, schemas, formData },
                  null,
                  2
                )}
              />
            </div>
          )}
          <div className={classes.rightFooter}>
            <Check
              label="Build Schemas"
              showLabel
              disabled={showFormWriter}
              checked={buildSchemas}
              handleChange={() => toggle('buildSchemas')}
              dataCy="schemas-toggle"
            />
            <div className={classes.viewerSwitch}>
              <div
                className={classes.switchLabel}
                data-is-active={!showFormWriter}
              >
                Json
              </div>
              <Switch
                label="showFormWriter"
                on={showFormWriter}
                disabled={!buildSchemas}
                handleChange={() => toggle('showFormWriter')}
                dataCy="viewer-toggle"
              />
              <div
                className={classes.switchLabel}
                data-is-active={showFormWriter}
              >
                Form Writer
              </div>
            </div>
            <button
              className={classes.rightFooterBtn}
              data-warning
              onClick={() => set('', defaultState)}
              data-cy="clear-builder"
            >
              <Trash className={classes.trashIcon} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FormBuilderInt);
