import {
  ActionBasedDeadlineDefinition,
  StateBasedDeadlineDefinition,
  Deadlines,
  DeadlineType
} from 'utils/interfaces/deadlines';

const ERROR_MESSAGE =
  'Please complete the required fields before submitting the config';

export const validateDeadlines = (deadlines: Deadlines): string[] => {
  let numberOfErrors = 0;

  deadlines.forEach(deadline => {
    const { label, type } = deadline;
    if (!label) numberOfErrors += 1;
    if (!type) numberOfErrors += 1;

    if (type === DeadlineType.ActionBased) {
      const {
        startTrigger,
        endTrigger,
        deadlineDays
      } = deadline as ActionBasedDeadlineDefinition;
      if (!startTrigger) numberOfErrors += 1;
      if (!endTrigger) numberOfErrors += 1;
      if (!deadlineDays) numberOfErrors += 1;
    } else if (type === DeadlineType.StateBased) {
      const {
        stateField,
        endTrigger
      } = deadline as StateBasedDeadlineDefinition;
      if (!stateField) numberOfErrors += 1;
      if (!endTrigger) numberOfErrors += 1;
    }
  });

  if (numberOfErrors === 0) return [];

  return [ERROR_MESSAGE];
};
