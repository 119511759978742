import React, { FC } from 'react';

import { NumberField } from '../../../../fields';
import { NumberInputDefinition } from '../../../../types';
import useStyles from './header.style';

interface Props {
  path: string;
  input: NumberInputDefinition;
}

// form number input configuration header component
export const Header: FC<Props> = ({ path, input }) => {
  const classes = useStyles();

  const { decimals } = input;

  return (
    <div className={classes.decimals}>
      <NumberField
        className={classes.decimalsField}
        label="0"
        value={decimals}
        path={`${path}.decimals`}
        min={0}
      />
      decimals
    </div>
  );
};

export default React.memo(Header);
