import { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton
} from '@stratumn/atomic';

interface Props {
  error: Error;
  close: () => void;
}

export const ImportErrorModal: FC<Props> = ({ error, close }) => (
  <Modal title="Import error" closeButtonLabel="OK" handleCollapse={close}>
    <ModalContent>
      <p>The import failed!</p>
      {error.message.split('\n').map(line => (
        <p>{line}</p>
      ))}
    </ModalContent>
    <ModalActions>
      <Pushbutton onClick={close} dataCy="cancel-button">
        OK
      </Pushbutton>
    </ModalActions>
  </Modal>
);
