import { createContext } from 'react';
import { TableColumns } from 'utils/trace';
import { Section } from './traceInfo/types';

export interface DisplayContextDefinition {
  workflowName: string;
  info: {
    path: string;
    sections: Section[];
  };
  overview: {
    path: string;
    data: {
      columns?: TableColumns;
      fixedColumns?: TableColumns;
    };
    isNewConfig: boolean;
  };
}

export const DisplayContext = createContext<DisplayContextDefinition | any>({});
