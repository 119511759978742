import React from 'react';
import { Panel } from 'components/workflow/ui';
import { FormikProps } from 'formik';
import { PresetInstance } from 'utils/presets';
import { FormikTextField } from 'components/formik';
import { GroupSelectionInput } from '.';

const GroupSelectionForm: React.FC<
  FormikProps<PresetInstance<GroupSelectionInput>>
> = () => (
  <Panel title="Settings">
    <FormikTextField name="input.fieldTitle" label="Name of the form field" />
  </Panel>
);

export default GroupSelectionForm;
