import { FC, memo } from 'react';

import { Icon, PushbuttonHref, Textinput } from '@stratumn/atomic';

import { ROUTE_NEW_WORKFLOW } from 'constant/routes';

import useStyles from './subHeader.style';

interface Props {
  filterStr?: string;
  updateFilter: (newFilter?: string) => void;
}

export const SubHeader: FC<Props> = ({ filterStr, updateFilter }) => {
  const classes = useStyles();

  return (
    <div className={classes.head}>
      <div className={classes.left}>
        <div className={classes.title}>Workflows</div>
        <div className={classes.searchInput}>
          <Textinput
            id="workflows-filter-input"
            type="search"
            placeholder="Search workflows"
            value={filterStr}
            handleChange={updateFilter}
            dataCy="workflows-filter-input"
            disableAutocomplete
            suffix={
              <div className={classes.searchIcon}>
                <Icon name="Search" size={23} />
              </div>
            }
          />
        </div>
      </div>
      <div className={classes.newWorkflowBtn}>
        <PushbuttonHref
          primary
          prefix={<Icon name="Plus" size={20} />}
          href={ROUTE_NEW_WORKFLOW}
        >
          New Workflow
        </PushbuttonHref>
      </div>
    </div>
  );
};

export default memo(SubHeader);
