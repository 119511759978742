import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  textField: {
    '& label': {
      height: 36
    },
    '& input': {
      backgroundColor: theme.white1
    }
  }
}));
