import type { Statement } from '@stratumn/dsl';
import type { Configuration, Action as TraceAction, Admin } from 'utils/trace';
import mergeAllOf from 'json-schema-merge-allof';
import mergeWith from 'lodash.mergewith';
import {
  Action,
  SchemaBuilderConfig,
  WorkflowSchema
} from 'schemaBuilder/types';

import { convert } from './converters';

export const convertAction = (
  action: Action,
  schema: WorkflowSchema
): TraceAction => {
  const convertedFields = action.fields.map(convert(schema));
  const jsonSchema = mergeAllOf({
    type: 'object',
    allOf: convertedFields.map(({ jsonSchema }) => jsonSchema)
  });
  const uiSchema = convertedFields.reduce(
    (schema, { uiSchema }) =>
      mergeWith(schema, uiSchema, (val, src, key) => {
        // ui:order should follow action fields order
        if (key === 'ui:order') {
          return (val || []).concat(src);
        }
        // If customizer returns undefined, merging is handled by the method instead
        return undefined;
      }),
    {}
  );

  return {
    key: action.key,
    icon: action.icon,
    title: action.name,
    form: {
      uiSchema,
      schema: jsonSchema
    },
    effects: convertedFields.reduce(
      (arr, { effects }) => (effects ? arr.concat(effects) : arr),
      [] as Statement[]
    )
  };
};

export default (config: SchemaBuilderConfig) => {
  const payload: Partial<Configuration> = {
    actions: {},
    admin: config as Admin
  };

  // Generate actions
  config.actions.forEach(action => {
    payload.actions![action.key] = convertAction(action, config.schema);
  });

  return payload;
};
