import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  card: {
    width: '100%',
    minWidth: 1030,
    padding: 20,
    background: theme.white1,
    borderRadius: 5,
    boxShadow: theme.indigoShadow1
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  fieldSelectCompactWrapper: {
    marginRight: 10,
    height: 35,
    '& button': {
      width: 300
    },
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 17
    }
  },
  removeBtn: {
    alignSelf: 'flex-start',
    marginTop: 24,
    marginLeft: 10,
    fontSize: 20,
    '&:focus': {
      outline: 'none'
    }
  },
  trashIcon: {
    height: 22,
    color: theme.grey1,
    transition: '200ms',
    '&:hover': {
      color: theme.warningRed
    }
  },
  groupsListWrapper: {
    flexGrow: 1
  },
  groupsListTitle: {
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '13px',
    color: theme.grey3
  },
  groupsList: {
    width: 450,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  groupLabel: {
    display: 'inline',
    width: '100%',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.grey1,
    '&:not(:last-child)': {
      '&::after': {
        marginRight: 10,
        content: '","'
      }
    }
  },
  checkWrapper: {
    marginRight: 30
  },
  setGroupsBtn: {
    '& > button': {
      fontSize: 12,
      lineHeight: '15px',
      color: theme.grey1
    }
  },
  setGroupsDownArrow: {
    width: 20,
    height: 20,
    transition: 'transform 0.2s ease-in-out',
    '&[data-is-active="true"]': {
      transform: 'rotate(-180deg)'
    }
  },
  ctaButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 264
  },
  fieldsRowWrapper: {
    marginTop: 20
  },
  addGroup: {
    marginTop: 18
  }
}));
