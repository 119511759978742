import React from 'react';
import theme from 'style';
import { FieldArray } from 'formik';

import { Pushtext, Icon } from '@stratumn/atomic';
import { Trash } from '@stratumn/icons';
import { FormikTextField, FormikCheckbox } from 'components/formik';

import useStyles from './value.style';

interface ValidationValueProps {
  name: string;
  value: {
    title: string;
    reasons: string[];
    enableComment: boolean;
  };
  onRemove: () => void;
}

const ValidationValue: React.FC<ValidationValueProps> = ({
  name,
  value,
  onRemove
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <h3 className={classes.columnTitle}>Value</h3>
        <FormikTextField
          data-cy={`assessment-${name}`}
          label="Assessment"
          name={`${name}.title`}
        />
        <FormikCheckbox
          data-cy={`enable-comment-${name}`}
          label="Use comment for reasons"
          name={`${name}.enableComment`}
        />
        <Pushtext
          data-cy={`remove-value-${name}`}
          onClick={onRemove}
          prefix={<Trash className={classes.trashIcon} />}
        >
          Remove value
        </Pushtext>
      </div>
      {!value.enableComment && (
        <div className={classes.right}>
          <h3 className={classes.columnTitle}>Reasons</h3>
          <FieldArray name={`${name}.reasons`}>
            {({ remove, push }) => (
              <>
                {value.reasons.map((_reason, i) => (
                  <div className={classes.reason}>
                    <FormikTextField
                      data-cy={`reason-${name}-${i}`}
                      label="Reason"
                      name={`${name}.reasons.${i}`}
                    />
                    <button
                      data-cy={`remove-reason-${name}-${i}`}
                      type="button"
                      onClick={() => remove(i)}
                    >
                      <Trash className={classes.trashIcon} />
                    </button>
                  </div>
                ))}
                {!value.reasons.length && (
                  <div className={classes.reasonsFiller} />
                )}
                <Pushtext
                  data-cy={`add-reason-${name}`}
                  onClick={() => push('')}
                  prefix={
                    <Icon color={theme.indigo2} name="CirclePlus" size={18} />
                  }
                >
                  Add reason
                </Pushtext>
              </>
            )}
          </FieldArray>
        </div>
      )}
    </div>
  );
};

export default ValidationValue;
