import { FC, memo } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import { TableColumns } from 'utils/trace';

import useStyles from './fixedColumnsList.style';
import List from '../list';

export interface Props {
  path: string;
  columns: TableColumns | null;
}

export const FixedColumnsList: FC<Props> = ({ path, columns = [] }) => {
  const classes = useStyles();

  return (
    <Droppable droppableId={path} type={path}>
      {providedDroppable => (
        <ul
          className={classes.columnsWrapper}
          ref={providedDroppable.innerRef}
          {...providedDroppable.droppableProps}
        >
          <List path={path} columns={columns} isFixed />
          {providedDroppable.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default memo(FixedColumnsList);
