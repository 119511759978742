import React, { FC, useCallback, useContext, useMemo } from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

import {
  Icon,
  Pushtext,
  SnackbarsContext,
  InfoTooltip
} from '@stratumn/atomic';

import {
  useDataContextBuilder,
  DataContext,
  DataContextConfig
} from 'utils/hooks';
import { onDrop } from 'utils/dragAndDrop';

import { FormBuilderContext } from './context';
import { FormDefinition } from './types';
import { validatePropertiesList } from './utils';

import { TextField } from './fields';
import PropertiesList from './propertiesList';

import useStyles from './formBuilder.style';

// export all types and parsing functionalities from main form builder component module
// to make them accessible to parents without diving into the folder structure
export * from './types';
export * from './parsing';
export { getFormErrors } from './utils';

const infoTooltipPosition = {
  place: 'below',
  placeShift: 3,
  adjustPlace: true
};

interface Props {
  form: FormDefinition;
  updateForm: (form: FormDefinition) => void;
  showErrors?: boolean;
}
export const FormBuilder: FC<Props> = ({ form, updateForm, showErrors }) => {
  const classes = useStyles();

  const { collapsed, title, description, properties, showPropertiesKey } = form;

  // build the configuration of the data context
  const { successSnackbar, closeSnackbars } = useContext(SnackbarsContext);
  const dataContextConfig = useMemo<DataContextConfig>(
    () => ({
      confirmationService: ({ message, label, accept, timeout }) => {
        successSnackbar(message, label, accept, timeout, true); // clear the snackbar stack, always only one bar available
        return closeSnackbars;
      }
    }),
    [successSnackbar, closeSnackbars]
  );

  const context = useDataContextBuilder<FormDefinition>(
    form,
    updateForm,
    dataContextConfig
  );

  const formBuilderContext = useMemo(
    () => ({
      showPropertiesKey,
      showErrors
    }),
    [showPropertiesKey, showErrors]
  );

  const toggleCollapsed = useCallback(() => {
    context.toggle('collapsed');
  }, [context]);

  const togglePropertiesKeys = useCallback(() => {
    context.toggle('showPropertiesKey');
  }, [context]);

  const onDragEnd = useCallback(dndResult => onDrop(dndResult, context), [
    context
  ]);

  const mainFormErrorsStr = useMemo(() => {
    if (!showErrors) return undefined;
    const errors: string[] = [];
    validatePropertiesList(errors, properties);
    return errors.join(', ');
  }, [showErrors, properties]);

  return (
    <FormBuilderContext.Provider value={formBuilderContext}>
      <DataContext.Provider value={context}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={classes.root}>
            <div className={classes.title}>
              <h1>Form builder</h1>
              <div className={classes.showPropertiesKeyWrapper}>
                <InfoTooltip
                  text="**Caution**: This will reveal editable keys that will have a direct impact on all the admin process inputs that are related to the edited keys."
                  position={infoTooltipPosition}
                  textAlign="center"
                  padding={5}
                >
                  <Pushtext
                    dataCy="toggle-properties-keys"
                    onClick={togglePropertiesKeys}
                  >
                    {!showPropertiesKey
                      ? 'Reveal field keys'
                      : 'Hide field keys'}
                  </Pushtext>
                </InfoTooltip>
              </div>
              <button
                type="button"
                className={classes.collapseBtn}
                onClick={toggleCollapsed}
                data-cy="toggle-collapsed"
              >
                <Icon
                  name={collapsed ? 'CirclePlus' : 'CircleMinus'}
                  size={23}
                />
              </button>
            </div>
            {!collapsed && (
              <>
                <div className={classes.separator} />
                <div className={classes.header}>
                  <TextField
                    className={classes.formTitle}
                    label="Form title"
                    value={title}
                    path="title"
                    data-cy="form-title"
                  />
                  <TextField
                    className={classes.formDescription}
                    label="Description"
                    value={description}
                    path="description"
                    data-cy="form-description"
                  />
                </div>
                <PropertiesList path="properties" properties={properties} />
                {mainFormErrorsStr && (
                  <span className={classes.errors}>{mainFormErrorsStr}</span>
                )}
              </>
            )}
          </div>
        </DragDropContext>
      </DataContext.Provider>
    </FormBuilderContext.Provider>
  );
};

export default React.memo(FormBuilder);
