import deepClone from 'lodash.clonedeep';

import getRandomId from 'utils/id';
import {
  Action,
  Workflow,
  Configuration,
  WorkflowSettingsPresets,
  WorkflowTransitions,
  TraceInfo,
  Overview
} from 'utils/trace';
import { Action as SchemaAction, ActionFieldType } from 'schemaBuilder/types';

import convertSchema from 'schemaBuilder/converter';

import { Statuses } from 'utils/interfaces/statuses';
import { Deadlines } from 'utils/interfaces/deadlines';
import { isConfigDirtyMap } from './utils';

export const UPDATE_DRAFT_STATUS = 'UPDATE_DRAFT_STATUS';
export const UPDATE_AUTOMATION_STATUS = 'UPDATE_AUTOMATION_STATUS';
export const CREATE_ACTION = 'CREATE_ACTION';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const UPDATING = 'UPDATING';
export const RESET = 'RESET';
export const UPDATE_WORKFLOW_NAME = 'UPDATE_WORKFLOW_NAME';
export const UPDATE_WORKFLOW_DESCRIPTION = 'UPDATE_WORKFLOW_DESCRIPTION';
export const UPDATE_WORKFLOW_SETTINGS_PRESETS =
  'UPDATE_WORKFLOW_SETTINGS_PRESETS';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_DEFINITIONS = 'UPDATE_DEFINITIONS';
export const UPDATE_TRANSITIONS = 'UPDATE_TRANSITIONS';
export const UPDATE_DISPLAY = 'UPDATE_DISPLAY';
export const UPDATE_STATUSES = 'UPDATE_STATUSES';
export const UPDATE_DEADLINES = 'UPDATE_DEADLINES';
export const SET_MESSAGE = 'SET_MESSAGE';

export const CREATE_SCHEMA_ACTION = 'CREATE_SCHEMA_ACTION';
export const UPDATE_SCHEMA_ACTION = 'UPDATE_SCHEMA_ACTION';

export interface IsDirtyMap {
  name?: boolean;
  description?: boolean;
  draft?: boolean;
  automation?: boolean;
  actions?: boolean;
  deadlines?: boolean;
  display?: boolean;
  transitions?: boolean;
  statuses?: boolean;
  actionsMap?: Record<string, boolean>;
}

export interface State {
  updating: boolean;
  isDirty: boolean;
  workflow: Workflow;
  initialData: Workflow;
  isDirtyMap: IsDirtyMap;
  message?: {
    title: string;
    content: string;
  };
}

export const initReducer = (data: Workflow) => {
  const initState: State = {
    isDirty: false,
    updating: false,
    workflow: deepClone(data),
    initialData: deepClone(data),
    isDirtyMap: {}
  };
  return initState;
};

export type ReducerAction =
  | {
      type: typeof UPDATE_WORKFLOW_NAME | typeof UPDATE_WORKFLOW_DESCRIPTION;
      data: string;
    }
  | {
      type: typeof UPDATE_WORKFLOW_SETTINGS_PRESETS;
      presets: WorkflowSettingsPresets;
    }
  | {
      type: typeof UPDATE_ACTION;
      data: Action;
    }
  | {
      type: typeof UPDATE_CONFIG;
      config: Configuration;
    }
  | {
      type: typeof RESET;
      data?: Workflow;
    }
  | {
      type: typeof UPDATE_DEFINITIONS;
      data: any;
    }
  | {
      type: typeof UPDATE_TRANSITIONS;
      data: WorkflowTransitions;
    }
  | {
      type: typeof UPDATE_DRAFT_STATUS | typeof UPDATE_AUTOMATION_STATUS;
      value: boolean;
    }
  | {
      type: typeof UPDATING;
    }
  | {
      type: typeof UPDATE_DISPLAY;
      data: {
        info: TraceInfo;
        overview: Overview;
      };
    }
  | {
      type: typeof UPDATE_STATUSES;
      data: Statuses;
    }
  | {
      type: typeof CREATE_SCHEMA_ACTION;
      data: SchemaAction;
    }
  | {
      type: typeof UPDATE_SCHEMA_ACTION;
      data: SchemaAction;
    }
  | {
      type: typeof UPDATE_DEADLINES;
      data: Deadlines;
    }
  | {
      type: typeof SET_MESSAGE;
      data?: {
        title: string;
        content: string;
      };
    };

export const reducer = (state: State, a: ReducerAction): State => {
  const newState = deepClone(state);

  switch (a.type) {
    case RESET:
      if (a.data) return initReducer(a.data);
      return initReducer(state.initialData);
    case UPDATING:
      newState.updating = true;
      break;
    case UPDATE_CONFIG:
      newState.workflow.config = a.config;
      break;
    case UPDATE_ACTION:
      newState.workflow.config.actions[a.data.key] = a.data;
      break;
    case UPDATE_WORKFLOW_NAME:
      newState.workflow.name = a.data;
      break;
    case UPDATE_WORKFLOW_DESCRIPTION:
      newState.workflow.description = a.data;
      break;
    case UPDATE_WORKFLOW_SETTINGS_PRESETS:
      newState.workflow.settings = {
        ...newState.workflow.settings,
        presets: {
          ...newState.workflow.settings?.presets,
          ...a.presets
        }
      };
      break;
    case UPDATE_DEFINITIONS:
      newState.workflow.config.definitions = a.data;
      break;
    case UPDATE_TRANSITIONS:
      newState.workflow.config.transitions = a.data;
      break;
    case UPDATE_DRAFT_STATUS:
      newState.workflow.draft = a.value;
      break;
    case UPDATE_AUTOMATION_STATUS:
      newState.workflow.config.allowAutomation = a.value;
      break;
    case UPDATE_DISPLAY:
      newState.workflow.config.info = a.data.info;
      newState.workflow.config.overview = a.data.overview;
      break;
    case UPDATE_STATUSES:
      newState.workflow.config.admin = {
        ...newState.workflow.config.admin,
        statuses: a.data
      };
      break;
    // TODO: For now, we directly convert the entire admin structure into config
    // and merge it with the rest of the config every time.
    // Eventually, we would like Admin UI to not deal with converting configuration at all,
    // or only upon actual submission to Trace API.
    case CREATE_SCHEMA_ACTION:
    case UPDATE_SCHEMA_ACTION: {
      const actions = state.workflow.config.admin?.actions || [];
      const schema = state.workflow.config.admin?.schema || {};
      if (a.type === CREATE_SCHEMA_ACTION) {
        actions.push({
          ...a.data,
          key: getRandomId(),
          createdAt: new Date(),
          updatedAt: new Date()
        });

        // Add new fields to the pending schema
        for (const field of a.data.fields || []) {
          if (field.type === ActionFieldType.New) {
            const { schemaField } = field;
            schema[schemaField.key] = schemaField;
          }
        }
      } else {
        // Since we enable editing actions that weren't necessarily
        // configured through the schema builder, they might not exist
        // among the schema actions yet
        const index = actions.findIndex(({ key }) => key === a.data.key);
        if (index === -1) {
          actions.push({
            ...a.data,
            createdAt: new Date(),
            updatedAt: new Date()
          });
        } else {
          actions[index] = {
            ...actions[index],
            ...a.data,
            updatedAt: new Date()
          };
        }

        // When updating an action, ActionFieldType.New fields can be either
        // existing fields that should be edited, or completely new fields

        // TODO: Handle field deletion: for now, it only removes them from the action,
        // but the field remains in the schema. It's a bit complicated because other
        // actions and modules may still be using the field.
        for (const field of a.data.fields || []) {
          if (field.type === ActionFieldType.New) {
            const { schemaField } = field;
            // The following should theoretically be done to clean up the schema after changing
            // a field key. However, this breaks the UI if the field was already used
            // in other actions or modules.

            // TODO: Think of a mechanism that allows patching previously used keys in other
            // actions/modules. Alternatively, we could also use the nanoid (schemaField.id)
            // which will not change at all.

            // const existingField = Object.values(schema).find(
            //   existingSchemaField => existingSchemaField.id === schemaField.id
            // );
            // if (existingField) {
            //   delete schema[existingField.key];
            // }
            schema[schemaField.key] = schemaField;
          }
        }
      }
      newState.workflow.config.actions = {
        ...newState.workflow.config.actions,
        ...convertSchema({ schema, actions }).actions
      };
      newState.workflow.config.admin = {
        ...newState.workflow.config.admin,
        schema,
        actions
      };
      break;
    }
    case UPDATE_DEADLINES:
      newState.workflow.config.admin = {
        ...newState.workflow.config.admin,
        deadlines: a.data
      };
      break;
    case SET_MESSAGE:
      newState.message = a.data;
      break;
    default:
      console.error(`Workflow details: unknown reducer action ${a}`);
  }

  // after any actions rebuild is dirty map
  const { isDirty, isDirtyMap } = isConfigDirtyMap(
    newState.workflow,
    newState.initialData
  );
  newState.isDirty = isDirty;
  newState.isDirtyMap = isDirtyMap;

  return newState;
};
