// @ts-nocheck
import { ListActionField } from 'schemaBuilder/types';
import { ConverterFieldPayload } from 'schemaBuilder/converter/types';
import { ResolvedExpressionType, StatementType } from '@stratumn/dsl';
import { key } from '../utils';

export default (
  field: ListActionField,
  convertSimples
): ConverterFieldPayload => {
  const item = convertSimples({
    schemaField: {
      ...field.schemaField.property,
      key: 'item'
    }
  });
  const id = key(field);

  const uiOptions = { order: [] };
  const uiOrder = [];
  const uiFields = {};
  uiOrder.push(id);
  uiOptions.order.push(id);
  uiFields[id] = item.uiSchema[id];

  const items = item.jsonSchema.properties.item;
  items.default = field.schemaField.property.placeholder || ''; // only text at the moment need it
  delete items.title;
  return {
    jsonSchema: {
      type: 'object',
      properties: {
        [key(field)]: {
          type: 'array',
          title: field.schemaField.label,
          required: field.required,
          items
        }
      },
      required: field.required ? [key(field)] : undefined
    },
    uiSchema: {
      'ui:order': [key(field)],
      [key(field)]: {
        items: {
          'ui:options': uiOptions,
          'ui:order': uiOrder,
          ...uiFields
        }
      }
    },
    effects: [
      {
        $tags: ['schemaBuilder'],
        $statement: StatementType.SetVariable,
        path: `state.data.${key(field)}`,
        value: {
          $expression: ResolvedExpressionType.Concat,
          arguments: [
            {
              $expression: ResolvedExpressionType.Variable,
              query: `state.data.${key(field)}`
            },
            {
              $expression: ResolvedExpressionType.Variable,
              query: `formData.${key(field)}`
            }
          ]
        }
      }
    ]
  };
};
