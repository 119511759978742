import { FC, memo, useCallback, useContext } from 'react';

import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton
} from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';

import useStyles from './confirmationModal.style';

interface Props {
  title: string;
  path: string;
  onClose: () => void;
}

export const ConfirmationModal: FC<Props> = ({ title, path, onClose }) => {
  const classes = useStyles();

  const { update } = useContext(DataContext);

  const handleConfirmModal = useCallback((): void => {
    const revertMessage = `The section **${title}** and its widgets have been successfully deleted`;

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
    onClose();
  }, [onClose, path, title, update]);

  return (
    <Modal title={`Remove the ${title} section`} handleCollapse={onClose}>
      <ModalContent>
        <div className={classes.content}>
          <p className={classes.contentParagraph}>
            You are about to remove the{' '}
            <span className={classes.contentSpan}>{title}</span> section from
            Trace info on the inspector. This action will involve the loss of
            all your changes and created widgets.
          </p>
          <p>Are you sure ?</p>
        </div>
      </ModalContent>
      <ModalActions
        adverseAction={
          <Pushbutton dataCy="cancel-disable-section" onClick={onClose}>
            cancel
          </Pushbutton>
        }
      >
        <Pushbutton
          dataCy="confirm-disable-section"
          warning
          onClick={handleConfirmModal}
        >
          confirm
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

export default memo(ConfirmationModal);
