import { FC } from 'react';

import { ViewTypes } from '../../types';

import LinkSettings from './link';
import CurrencySettings from './currency';

export interface WidgetSettingsComponentProps {
  widget: any; // TODO: strongly type
}
export interface WidgetSettings {
  icon?: string;
  label?: string;
  component?: FC<WidgetSettingsComponentProps>;
}

const map: {
  [key in ViewTypes]?: WidgetSettings;
} = {
  [ViewTypes.Link]: LinkSettings,
  [ViewTypes.Currency]: CurrencySettings
};
export default map;
