import React, { useMemo, useCallback, ReactNode } from 'react';
import { useMutation } from '@apollo/client';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withSnackbarsContext, ConfirmationModal } from '@stratumn/atomic';

import { TRANSITIVE } from 'utils/trace';
import { useToggle, useDocumentTitle, useConfirmExit } from 'utils/hooks';
import { pluralize } from 'utils/strings';
import {
  ROUTE_WORKFLOW_ACTION,
  ROUTE_WORKFLOW_TRANSITIONS,
  ROUTE_WORKFLOW_DISPLAY_OVERVIEW,
  ROUTE_WORKFLOW_DISPLAY_TRACE_INFO,
  ROUTE_WORKFLOW_GROUPS,
  ROUTE_STATUS,
  ROUTE_SCHEMA_ACTION,
  ROUTE_DEADLINES
} from 'constant/routes';
import {
  HIDE_DEADLINES_CONFIG,
  HIDE_STATUS_CONFIG,
  HIDE_OVERVIEW_CONFIG
} from 'constant/environment';
import { WORKFLOW_HOMEPAGE, getWorkflowBreadCrumbs } from 'constant/pages';

import { HeaderLayout } from 'components/layouts';
import {
  UPDATE_ACTION,
  RESET,
  UPDATING,
  ReducerAction,
  State,
  SET_MESSAGE
} from '../reducers';

import WorkflowInfo from './workflowInfo';
import Actions from './actions';
import DiffsModal from './diffsModal';

import WorkflowDetailsCard from './workflowDetailsCard';
import Footer from './footer';

import { isNewConfig } from '../utils';

import useStyles from './workflowDetails.style';
import { WORKFLOW_CONFIG_MUTATION } from './mutation';

interface Props extends RouteComponentProps {
  state: State;
  dispatch: React.Dispatch<ReducerAction>;
  errorSnackbar: (m: string) => void;
  successSnackbar: (m: string) => void;
  useSchemaBuilder?: boolean;
}

export const WorkflowDetails = ({
  history,
  state,
  dispatch,
  successSnackbar,
  errorSnackbar,
  useSchemaBuilder
}: Props) => {
  const workflowRowId = state.workflow.rowId;

  const [commitNewConfig] = useMutation(WORKFLOW_CONFIG_MUTATION);

  const classes = useStyles();

  useDocumentTitle(state.workflow.name);
  useConfirmExit(state.isDirty);

  const updateAction = useCallback(
    (a: string) =>
      dispatch({
        type: UPDATE_ACTION,
        data: JSON.parse(a)
      }),
    [dispatch]
  );

  const onUndo = useCallback(() => {
    dispatch({ type: RESET });
  }, [dispatch]);

  const onSubmit = useCallback(async () => {
    // Commit the new workflow config
    const {
      actions,
      initActions,
      definitions,
      transitions,
      allowAutomation,
      info,
      overview,
      admin,
      version
    } = state.workflow.config;

    const newConfigVersion = Number(version || '0') + 1;
    const config = {
      version: newConfigVersion,
      actions,
      initActions,
      definitions,
      transitions,
      allowAutomation,
      admin,
      info,
      overview
    };

    dispatch({ type: UPDATING });
    try {
      // Set draft to true only if was already true. The UI shouldn't let you
      // move a workflow from live to draft, but better be safe than sorry...
      const draft = !!(state.workflow.draft && state.initialData.draft);
      const { data } = await commitNewConfig({
        variables: {
          config,
          draft,
          workflowId: state.workflow.rowId,
          settings: state.workflow.settings,
          name: state.workflow.name,
          description: state.workflow.description
        }
      });
      successSnackbar('The workflow was successfully updated');
      dispatch({
        type: RESET,
        data: data.updateWorkflowDetails
      });
    } catch (err) {
      // check if the error received corresponds to a conflict of version on the config
      // so far we check the entire content of the text message, which is not very robust
      // later we'll check for proper error codes instead
      const versionErrorRegex = new RegExp(
        `New workflow config version # \\(\\d+\\) doesn't match specified version # \\(${newConfigVersion}\\)`
      );
      const serverErrors =
        err.networkError?.result?.errors || err.graphQLErrors;
      if (serverErrors && versionErrorRegex.test(serverErrors[0].message)) {
        dispatch({
          type: SET_MESSAGE,
          data: {
            title: 'An **other admin** is working on this workflow',
            content:
              'An other admin is currently working on this workflow. Your current edit can’t be submitted, unfortunately your changes will be lost.'
          }
        });
        return;
      }

      // otherwise throw classical error snackbar with the actual error message
      // try server error message first and if nothing stringify the error
      errorSnackbar(
        `Could not update the workflow: ${
          (serverErrors && serverErrors[0].message) || JSON.stringify(err)
        }`
      );
    }
  }, [
    state.workflow,
    state.initialData.draft,
    dispatch,
    commitNewConfig,
    successSnackbar,
    errorSnackbar
  ]);

  const [showUndoConfirmationModal, toggleUndoConfirmationModal] = useToggle(
    false
  );

  const [
    showSubmitConfirmationModal,
    toggleSubmitConfirmationModal
  ] = useToggle(false);

  const handleCreateNewAction = useCallback((): void => {
    if (useSchemaBuilder) {
      history.push(ROUTE_SCHEMA_ACTION.replace(':id', workflowRowId));
    } else {
      history.push(ROUTE_WORKFLOW_ACTION.replace(':id', workflowRowId));
    }
  }, [useSchemaBuilder, history, workflowRowId]);

  const renderCardContent = (count: number = 0, word: string): ReactNode => (
    <p>{pluralize(count, word)} created</p>
  );

  const renderDisplayCardContent = ({
    overview,
    info
  }: {
    overview: boolean;
    info: boolean;
  }): ReactNode => {
    if (overview && info)
      return <p>Overview table and Trace info are now set</p>;
    if (overview) return <p>Overview table is now set</p>;
    if (info) return <p>Trace info is now set</p>;
    return <p>No display created</p>;
  };

  const transitionsLength = useMemo(() => {
    if (!state.workflow.config.transitions) return 0;
    return Object.entries(state.workflow.config.transitions).reduce(
      (acc, curr) =>
        curr[1] === TRANSITIVE ? acc : acc + Object.keys(curr[1]).length,
      0
    );
  }, [state.workflow.config.transitions]);

  const existingDisplayConfig: {
    overview: boolean;
    info: boolean;
  } = useMemo(() => {
    const displayConfig = {
      overview: false,
      info: false
    };
    // Currently, we need this flag as the configuration can either be null or an empty object
    if (
      isNewConfig(state.workflow.config.overview) &&
      isNewConfig(state.workflow.config.info)
    )
      return displayConfig;
    if (state.workflow.config.overview) displayConfig.overview = true;
    if (state.workflow.config.info) displayConfig.info = true;

    return displayConfig;
  }, [state.workflow.config.info, state.workflow.config.overview]);

  const header = useMemo(
    () => (
      <HeaderLayout
        dirty={{
          module: WORKFLOW_HOMEPAGE,
          stateIsDirty: state.isDirty
        }}
        breadcrumbs={getWorkflowBreadCrumbs(state.workflow.name)}
      />
    ),
    [state.isDirty, state.workflow.name]
  );

  return (
    <>
      {header}
      <div className={classes.root}>
        <div className={classes.workflowInfoWrapper}>
          <WorkflowInfo data={state} dispatch={dispatch} />
        </div>

        <main className={classes.main}>
          <div className={classes.outerGrid}>
            <div>
              <WorkflowDetailsCard
                dataCy="go-to-create-actions"
                icon="CircleDoc"
                title="Actions"
                btnTitle="Create action"
                btnOnClick={handleCreateNewAction}
                content={
                  <Actions
                    workflowRowId={workflowRowId}
                    workflowPresets={state.workflow.settings?.presets}
                    actions={state.workflow.config.actions}
                    pending={state.isDirtyMap?.actionsMap}
                    updateAction={updateAction}
                    useSchemaBuilder={useSchemaBuilder}
                  />
                }
                pending={state.isDirtyMap?.actions}
              />
            </div>
            <ul className={classes.innerGrid}>
              <li>
                <WorkflowDetailsCard
                  dataCy="go-to-deadlines"
                  icon="Clock"
                  title="Deadlines"
                  btnTitle="Manage Deadlines"
                  content={renderCardContent(
                    state.workflow.config.admin?.deadlines?.length,
                    'deadline'
                  )}
                  count={state.workflow.config.admin?.deadlines?.length}
                  href={ROUTE_DEADLINES.replace(':id', workflowRowId)}
                  disabled={HIDE_DEADLINES_CONFIG}
                  pending={state.isDirtyMap?.deadlines}
                  tooltip="Set deadlines to follow and monitor due dates and delays"
                />
              </li>
              <li>
                <WorkflowDetailsCard
                  dataCy="go-to-display"
                  icon="CirclePicture"
                  title="Display"
                  btnTitle="Manage Display"
                  content={renderDisplayCardContent(existingDisplayConfig)}
                  count={
                    Object.values(existingDisplayConfig).filter(Boolean).length
                  }
                  href={(HIDE_OVERVIEW_CONFIG
                    ? ROUTE_WORKFLOW_DISPLAY_TRACE_INFO
                    : ROUTE_WORKFLOW_DISPLAY_OVERVIEW
                  ).replace(':id', workflowRowId)}
                  pending={state.isDirtyMap?.display}
                  tooltip="Set display to customize the workflow overview and trace info"
                />
              </li>
              <li>
                <WorkflowDetailsCard
                  dataCy="go-to-transitions"
                  icon="CircleArrowRight"
                  title="Transitions"
                  btnTitle="manage transitions"
                  content={renderCardContent(transitionsLength, 'transition')}
                  count={transitionsLength}
                  href={ROUTE_WORKFLOW_TRANSITIONS.replace(
                    ':id',
                    workflowRowId
                  )}
                  pending={state.isDirtyMap?.transitions}
                  tooltip="Configure transitions between your actions"
                />
              </li>
              <li>
                <WorkflowDetailsCard
                  dataCy="go-to-groups"
                  icon="CircleProfile"
                  title={`Groups ${`&`} Permissions`}
                  btnTitle="Manage Groups"
                  content={renderCardContent(
                    state.workflow.groups.totalCount,
                    'group'
                  )}
                  count={state.workflow.groups.totalCount}
                  href={ROUTE_WORKFLOW_GROUPS.replace(':id', workflowRowId)}
                  externalRef
                  tooltip="The group & permission module helps you define who can perform actions in this workflow"
                />
              </li>
              <li>
                <WorkflowDetailsCard
                  dataCy="go-to-status"
                  icon="CircleTick"
                  title="Status"
                  btnTitle="Set Status"
                  content={renderCardContent(
                    state.workflow.config.admin?.statuses?.length,
                    'status'
                  )}
                  count={state.workflow.config.admin?.statuses?.length}
                  href={ROUTE_STATUS.replace(':id', workflowRowId)}
                  disabled={HIDE_STATUS_CONFIG}
                  pending={state.isDirtyMap?.statuses}
                  tooltip="Set status to follow the progress of a process and especially if the goal of the process is achieved"
                />
              </li>
            </ul>
          </div>
        </main>

        <Footer
          onUndo={toggleUndoConfirmationModal}
          onSubmit={toggleSubmitConfirmationModal}
          isDirty={state.isDirty || state.updating}
          isDirtyMap={state.isDirtyMap}
        />
        {showUndoConfirmationModal && (
          <ConfirmationModal
            warning
            title="Revert changes to the workflow config"
            content={`Are you sure you want to revert all your changes to the config of the workflow **${state.workflow.name}** ?`}
            confirmBtnText="undo"
            confirm={onUndo}
            cancel={toggleUndoConfirmationModal}
          />
        )}
        {showSubmitConfirmationModal && (
          <DiffsModal
            data={state.workflow}
            initialData={state.initialData}
            submit={onSubmit}
            cancel={toggleSubmitConfirmationModal}
          />
        )}
      </div>
    </>
  );
};

WorkflowDetails.defaultProps = {
  useSchemaBuilder: false
};

export default withSnackbarsContext(withRouter(WorkflowDetails));
