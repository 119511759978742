import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  dependency: {
    borderRadius: 5,
    border: `1px solid ${theme.greyTable}`,
    backgroundColor: theme.grey9,
    padding: 15,
    marginBottom: 15,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    '&[data-has-errors=true]': {
      borderColor: theme.warningRed
    }
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  conditionInterface: {
    flexShrink: 0,
    height: 36,
    width: 300,
    '& button': {
      width: '100%'
    }
  },
  conditionLabel: {
    width: 220,
    margin: '0 10px'
  },
  collapseBtn: {
    outline: 'none',
    color: theme.grey3
  },
  body: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  inputHeader: {
    marginTop: 15,
    marginBottom: 5
  },
  footer: {
    marginTop: 15,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  footerLeft: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    overflow: 'hidden'
  },
  errors: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 20,
    whiteSpace: 'nowrap',
    color: theme.warningRed,
    fontSize: 11
  },
  footerRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  footerSep: {
    height: 25,
    marginLeft: 20,
    marginRight: 5,
    width: 1,
    backgroundColor: theme.grey7
  },
  deleteBtn: {
    marginLeft: 10,
    outline: 'none',
    color: theme.grey3,
    '&:hover': {
      color: theme.warningRed
    }
  }
}));
