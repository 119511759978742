import { infer as InferType, object, boolean } from 'zod';
import {
  PresetTemplate,
  actionPresetInputSchema,
  mergeEffects,
  mergeFormSchemas,
  mergeTransitions
} from 'utils/presets';
import { fileSchema } from 'utils/forms';
import { StatementType } from '@stratumn/dsl';
import { Form } from 'utils/trace';

import addComments from './addComments';

const commentInputSchema = object({
  enableFiles: boolean()
}).merge(actionPresetInputSchema);

export type CommentInput = InferType<typeof commentInputSchema>;

const commentPreset: PresetTemplate<CommentInput> = {
  key: 'comment',
  name: 'Comment action',
  schema: commentInputSchema,
  defaultValues: {
    action: {
      title: 'Comment'
    },
    enableFiles: false
  },
  generateAction: (action, { key, input }) => {
    const form: Form = {
      schema: {
        type: 'object',
        properties: {
          comment: {
            type: 'string',
            format: 'draft'
          }
        },
        required: ['comment']
      },
      uiSchema: {
        'ui:order': ['comment']
      }
    };
    if (input.enableFiles) {
      (form.schema as any).properties.files = {
        type: 'array',
        items: fileSchema
      };
      form.uiSchema.files = { 'ui:field': 'FileUploadField' };
      (form.uiSchema['ui:order'] as any).push('files');
    }
    return {
      ...action,
      key,
      title: input.action.title,
      stageName: input.action.title,
      icon: input.action?.icon || action?.icon,
      form: mergeFormSchemas(action?.form, form),
      effects: mergeEffects(action?.effects, [
        {
          $preset: key,
          $statement: StatementType.FunctionCall,
          function: { $ref: 'addComments' } as any
        }
      ])
    };
  },
  alterConfig: (config, { key }) => {
    if (!config.definitions) config.definitions = {};

    config.transitions = mergeTransitions(config.transitions, {
      // Allow commenting from any action
      '*': {
        [key]: [
          // Allow commenting from any group
          {
            groups: '*'
          }
        ]
      },
      // Comment action is transitive
      [key]: 'keep'
    });

    if (!config.definitions.addComments) {
      config.definitions.addComments = addComments;
    }
    return config;
  }
};

export default commentPreset;
