import { ReactNode, memo, useContext, useCallback } from 'react';
import { createPortal } from 'react-dom';

import { Draggable } from 'react-beautiful-dnd';

import { Icon } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import { EventChangeHandler, BlurEventHandler } from 'utils/types';

import { InputWithIcon } from '../../inputWithIcon';

import useStyles from './option.style';

interface Props {
  index: number;
  option: { key: string; value?: string };
  path: string;
  icon?: ReactNode;
  disabled: boolean;
}

export const Option = ({
  index,
  option: { key, value },
  path,
  icon = null,
  disabled
}: Props) => {
  const classes = useStyles();

  const { set, delete: deleteData } = useContext(DataContext);

  const updateOption: EventChangeHandler = useCallback(
    e => set(`${path}.value`, e.target.value),
    [path, set]
  );

  const removeOption = useCallback((): void => deleteData(path), [
    path,
    deleteData
  ]);

  const handleOnBlur: BlurEventHandler = useCallback(
    e => {
      if (e.target.value === '') {
        removeOption();
      }
    },
    [removeOption]
  );

  const getDraggableItem: ReactNode = useCallback(
    (providedDraggable, snapshotDraggable) => {
      const draggableItem = (
        <div
          ref={providedDraggable.innerRef}
          className={classes.draggable}
          {...providedDraggable.draggableProps}
        >
          <div
            className={classes.dragHandle}
            {...providedDraggable.dragHandleProps}
          >
            {!disabled && <Icon name="Drag" size={20} />}
          </div>
          <div className={classes.option}>
            <div className={classes.optionLabel}>
              <InputWithIcon
                data-cy="update-option"
                icon={icon || <span>-</span>}
                value={value ?? ''}
                onChange={updateOption}
                onBlur={handleOnBlur}
                disabled={disabled}
              />
            </div>
            <button
              type="button"
              className={classes.removeBtn}
              onClick={removeOption}
              data-cy="remove-option"
            >
              {!disabled && <Icon name="Trash" size={18} />}
            </button>
          </div>
        </div>
      );

      return snapshotDraggable.isDragging
        ? createPortal(draggableItem, document.body)
        : draggableItem;
    },
    [
      classes.draggable,
      classes.dragHandle,
      classes.option,
      classes.optionLabel,
      classes.removeBtn,
      disabled,
      icon,
      value,
      updateOption,
      handleOnBlur,
      removeOption
    ]
  );

  return (
    <Draggable draggableId={key} index={index}>
      {getDraggableItem}
    </Draggable>
  );
};

Option.defaultProps = {
  icon: null
};

export default memo(Option);
