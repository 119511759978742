import { FC, useCallback, useReducer } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { MessageModal } from '@stratumn/atomic';

import { Workflow } from 'utils/trace';
import * as routes from 'constant/routes';
import { PrivateRoute } from 'components';

import { Action } from 'schemaBuilder/types';
import SchemaActionModule from 'schemaBuilder/modules/action';
import Display from 'schemaBuilder/modules/display';
import Transitions from 'schemaBuilder/modules/transitions';
import Status from 'schemaBuilder/modules/status';
import Deadlines from 'schemaBuilder/modules/deadlines';

import {
  reducer,
  initReducer,
  CREATE_SCHEMA_ACTION,
  UPDATE_SCHEMA_ACTION,
  SET_MESSAGE
} from './reducers';
import WorkflowDetails from './workflowDetails';
import ActionEditor from './actionEditor';

interface Props {
  data: Workflow;
}

const WorkflowManager: FC<Props> = ({ data }) => {
  const [state, dispatch] = useReducer(reducer, data, initReducer);

  const handleCreateAction = useCallback(
    (data: Action) => dispatch({ type: CREATE_SCHEMA_ACTION, data }),
    [dispatch]
  );
  const handleUpdateAction = useCallback(
    (data: Action) => dispatch({ type: UPDATE_SCHEMA_ACTION, data }),
    [dispatch]
  );

  const closeMessage = useCallback(() => dispatch({ type: SET_MESSAGE }), []);

  return (
    <>
      <Switch>
        <PrivateRoute
          path={routes.ROUTE_SCHEMA_BUILDER}
          exact
          component={props => (
            <WorkflowDetails
              useSchemaBuilder
              state={state}
              dispatch={dispatch}
              {...props}
            />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_SCHEMA_ACTION}
          exact
          component={props => (
            <SchemaActionModule
              {...props}
              schema={state.workflow.config.admin?.schema || {}}
              submit={handleCreateAction}
              workflowRowId={state.workflow.rowId}
              workflowName={state.workflow.name}
            />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_SCHEMA_EDIT_ACTION}
          exact
          component={props => {
            let action = state.workflow.config.admin?.actions?.find(
              ({ key }) => key === props.match.params.action
            );
            // If the action does not exist in the schema actions yet,
            // polyfill it from the original config action
            if (!action) {
              const configAction =
                state.workflow.config.actions[props.match.params.action];
              action = {
                key: configAction.key,
                preset: 'setData',
                createdAt: new Date(),
                updatedAt: new Date(),
                name: configAction.title,
                icon: configAction.icon || '',
                fields: []
              };
            }
            return (
              <SchemaActionModule
                {...props}
                schema={state.workflow.config.admin?.schema || {}}
                submit={handleUpdateAction}
                action={action}
                workflowRowId={state.workflow.rowId}
                workflowName={state.workflow.name}
              />
            );
          }}
        />
        <PrivateRoute
          path={routes.ROUTE_WORKFLOW_DETAILS}
          exact
          component={props => (
            <WorkflowDetails state={state} dispatch={dispatch} {...props} />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_WORKFLOW_ACTION}
          component={props => (
            <ActionEditor state={state} dispatch={dispatch} {...props} />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_DEADLINES}
          component={props => (
            <Deadlines state={state} dispatch={dispatch} {...props} />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_WORKFLOW_DISPLAY_OVERVIEW}
          component={props => (
            <Display state={state} dispatch={dispatch} {...props} />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_WORKFLOW_DISPLAY_TRACE_INFO}
          component={props => (
            <Display state={state} dispatch={dispatch} {...props} />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_WORKFLOW_TRANSITIONS}
          component={props => (
            <Transitions state={state} dispatch={dispatch} {...props} />
          )}
        />
        <PrivateRoute
          path={routes.ROUTE_STATUS}
          component={props => (
            <Status state={state} dispatch={dispatch} {...props} />
          )}
        />
        <Redirect
          from={routes.ROUTE_WORKFLOW_DISPLAY}
          to={routes.ROUTE_WORKFLOW_DISPLAY_OVERVIEW}
        />
      </Switch>
      {state.message && (
        <MessageModal
          title={state.message.title}
          content={state.message.content}
          close={closeMessage}
        />
      )}
    </>
  );
};

export default WorkflowManager;
