import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    margin: '1em 1em 1em 0',
    '&[data-inline=true]': {
      display: 'inline-block'
    }
  }
});
