import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  formBuilder: {
    margin: '10px 0'
  }
});
