import { ENVIRONMENT } from 'constant/environment';
import { Configuration, Groups, WorkflowSettingsPresets } from 'utils/trace';
import { State } from 'components/workflow/reducers';

// https://docs.google.com/document/d/1FO_SN-xphq71U0_8rmSvSZovUV0s2TS9o_vKh_Xrz0I/edit#heading=h.b248dr3vdh1h
// The consequences on exports when altering the config structure are described there.

// Basically, adding or removing fields from the config structure should not be breaking for the exports,
// but renaming fields (which we wanna avoid doing anyway) is breaking,
// so it should be fine even including potentially deprecated fields in there.
export interface WorkflowExport extends Configuration {
  // Environment, to go along with the workflow config version
  environment: string;
  // Workflow fields
  name: string;
  description: string;
  tracePrefix: string;
  presetInstances: WorkflowSettingsPresets;
  // Export groups without their members
  // Since avatars are uploaded to media-api and media-api is environment dependent, we don't export them.
  // If we want to export/import them in the future, we could probably base64 the images and save that.
  groups: Groups;
}

export default (state: State) => {
  const { initialData } = state;
  const { config } = initialData;
  const payload: WorkflowExport = {
    version: config.version,
    environment: ENVIRONMENT,
    name: initialData.name,
    description: initialData.description,
    definitions: config.definitions || {},
    initState: config.initState || {},
    initActions: config.initActions || {},
    nextActions: config.nextActions || {},
    actions: config.actions || {},
    allowAutomation: config.allowAutomation || false,
    info: config.info,
    overview: config.overview,
    stateSchema: config.stateSchema,
    transitions: config.transitions,
    // settings may be null if the workflow has not been created
    // through admin-ui (e.g. generator or legacy workflow)
    tracePrefix: initialData.settings?.key,
    presetInstances: initialData.settings?.presets,
    groups: initialData.groups.nodes.map(group => ({
      label: group.label,
      name: group.name
    }))
  };

  return new Blob([JSON.stringify(payload)], {
    type: 'application/json'
  });
};
