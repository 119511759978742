import React, { FC, useState, useCallback, useContext } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import generateId from 'utils/id';
import { DataContext } from 'utils/hooks';

import { DraggableType } from '../../types';

import List from './list';

import useStyles from './optionsList.style';

interface Props {
  listType: DraggableType;
  options?: { key: string; value?: string }[];
  path: string;
  icon?: React.ReactNode;
  placeholder?: string;
}

// generic editable / sortable list of options
export const OptionsList: FC<Props> = ({
  listType,
  options = [],
  path,
  icon,
  placeholder
}) => {
  const classes = useStyles();

  const [newOption, setNewOption] = useState('');

  const { append } = useContext(DataContext);

  const addOption = useCallback(() => {
    if (newOption === '') return;
    append(path, { key: generateId(), value: newOption });
    setNewOption('');
  }, [newOption, path, append]);

  const handleNewOptionValue = e => setNewOption(e.target.value);
  const handleNewOptionKeyPress = e => e.key === 'Enter' && addOption();

  return (
    <>
      <Droppable droppableId={path} type={listType}>
        {providedDroppable => (
          <div
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            <List options={options} path={path} icon={icon} />
            {providedDroppable.placeholder}
            <div className={classes.newOption}>
              {icon || <span className={classes.newOptionDefaultIcon}>-</span>}
              <input
                className={classes.newOptionInput}
                value={newOption}
                placeholder={placeholder || 'Add option'}
                onChange={handleNewOptionValue}
                onKeyPress={handleNewOptionKeyPress}
                onBlur={addOption}
                data-cy="add-option"
              />
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
};

export default React.memo(OptionsList);
