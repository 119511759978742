import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  titleFieldText: {
    maxWidth: '16ch',
    minWidth: '1ch',
    width: ({ inputWidth }) => inputWidth,
    whiteSpace: 'nowrap',
    marginBottom: 3,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '25px',
    letterSpacing: '0.03em',
    color: theme.grey1,
    outline: 'none',
    transition: 'width 350ms linear',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  }
}));
