import type { WorkflowExport } from 'utils/export/getFile';
import { zodErrorToString } from 'utils/strings';
import * as presets from 'presets';

interface PresetsPayload {
  [key: string]: unknown;
}

export default ({
  presetInstances,
  actions
}: WorkflowExport): PresetsPayload => {
  if (!presetInstances) return {};
  const payload: PresetsPayload = {};
  let zodErrors = '';
  for (const [key, { input, template }] of Object.entries(presetInstances)) {
    const preset = presets[template];
    if (!preset) {
      throw new Error(`Preset template "${template}" does not exist`);
    }
    // If it's an action preset, check that there's an action with the same key
    // as the preset instance key
    if (preset.generateAction && !actions[key]) {
      throw new Error(`Preset instance "${key}" has no matching action`);
    }
    // Validate preset input
    const inputResult = preset.schema.safeParse(input);
    if (inputResult.success) {
      Object.assign(payload, {
        [key]: { template, input: inputResult.data }
      });
    } else {
      if (!zodErrors) {
        zodErrors =
          'Invalid preset configuration! Please try to edit the corresponding action(s) in the original workflow and export it again.';
      }
      zodErrors += `\nAction "${key}" (${template} preset):\n${zodErrorToString(
        inputResult.error
      )}`;
    }
  }
  if (zodErrors) {
    throw new Error(zodErrors);
  }
  return payload;
};
