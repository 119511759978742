import { FC, memo, useContext, useCallback } from 'react';

import { FieldText } from '@stratumn/atomic';

import { DataContext, useToggle } from 'utils/hooks';

import { EventChangeHandler } from 'utils/types';

import useStyles from './progressBar.style';

interface Props {
  path: string;
  data: {
    label: string;
    percent: number;
  };
}

export const ProgressBar: FC<Props> = ({
  path,
  data: { label, percent = 0 }
}) => {
  const classes = useStyles(percent);

  const { set } = useContext(DataContext);

  const [fieldFocused, toggleFieldFocused] = useToggle(false);

  const handleEditPercentage: EventChangeHandler = useCallback(
    e => {
      if (Number.isNaN(Number(e.target.value))) return;

      const newValue = Number(e.target.value);
      if (newValue > 100) return;

      set(`${path}.percentProgress`, newValue / 100);
    },
    [path, set]
  );

  const percentSymbol = fieldFocused ? '' : '%';

  return (
    <div className={classes.root}>
      <div className={classes.barWrapper}>
        <span className={classes.label}>{label}</span>
        <div className={classes.fullBar}>
          <div className={classes.dynamicBar} />
        </div>
      </div>
      <div className={classes.fieldTextWrapper}>
        <FieldText
          dataCy="edit-progressbar-percent"
          label="progress bar percent"
          onValueChange={handleEditPercentage}
          onFocus={toggleFieldFocused}
          onBlur={toggleFieldFocused}
          value={String(percent * 100) + percentSymbol}
          hideLabel
        />
      </div>
    </div>
  );
};

export default memo(ProgressBar);
