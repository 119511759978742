import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    gap: 20,
    backgroundColor: theme.grey8,
    height: `calc(100vh - ${theme.headerHeight || '70px'})`,
    overflow: 'hidden'
  },
  header: {
    width: 700,
    maxWidth: '100%',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '0.03em',
    color: theme.grey1
  },
  list: {
    flexGrow: 1,
    overflow: 'auto'
  },
  listContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15
  },
  addButton: {
    flexShrink: 0
  },
  ctaButtonsWrapper: {
    flexShrink: 0,
    display: 'flex'
  },
  cancelButton: {
    marginRight: 10
  },
  errors: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    color: theme.warningRed,
    marginLeft: 11,
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 'normal'
  },
  errorsContent: {
    marginLeft: 3
  }
}));
