import React, { FC, memo, useMemo, useState, useCallback } from 'react';

import { Action, WorkflowSettingsPresets } from 'utils/trace';

import { Tray, JsonEditor } from 'components';

import ActionRow from './actionRow';

import useStyles from './actions.style';

interface Props {
  workflowRowId: string;
  workflowPresets?: WorkflowSettingsPresets;
  actions?: Record<string, Action>;
  pending?: Record<string, boolean>;
  updateAction: (action: string) => void;
  useSchemaBuilder?: boolean;
}

export const Actions: FC<Props> = ({
  workflowRowId,
  workflowPresets = {},
  actions = {},
  pending = {},
  updateAction,
  useSchemaBuilder
}) => {
  const [selectedActionsCode, setSelectedActionsCode] = useState<string[]>([]);

  const classes = useStyles();

  const selectAction = useCallback(
    (key: string) => {
      if (selectedActionsCode.includes(key)) return;
      const newSelectedActions = [...selectedActionsCode];
      newSelectedActions.push(key);
      if (newSelectedActions.length > 2) newSelectedActions.shift();
      setSelectedActionsCode(newSelectedActions);
    },
    [selectedActionsCode, setSelectedActionsCode]
  );

  const unSelectAction = useCallback(
    (key: string) => {
      const newSelectedActions = [...selectedActionsCode];
      if (!selectedActionsCode.includes(key)) return;
      newSelectedActions.splice(selectedActionsCode.indexOf(key), 1);
      setSelectedActionsCode(newSelectedActions);
    },
    [selectedActionsCode, setSelectedActionsCode]
  );

  const toggleAction = useCallback(
    (key: string) => {
      if (selectedActionsCode.includes(key)) unSelectAction(key);
      else selectAction(key);
    },
    [selectedActionsCode, selectAction, unSelectAction]
  );

  const actionTrays = useMemo(() => {
    if (!actions) return null;

    return Object.values(actions)
      .filter(a => selectedActionsCode.includes(a.key))
      .map(a => {
        const closeActionCode = () => unSelectAction(a.key);
        return (
          <Tray key={a.key} title={a.title} onClose={closeActionCode}>
            <JsonEditor
              detach
              jsonString={JSON.stringify(a, null, 2)}
              onClose={closeActionCode}
              onSubmit={updateAction}
            />
          </Tray>
        );
      });
  }, [actions, unSelectAction, updateAction, selectedActionsCode]);

  const actionList = useMemo(() => {
    const list = actions
      ? Object.values(actions).sort((a1, a2) =>
          a1.title.localeCompare(a2.title)
        )
      : [];
    return list.map(action => (
      <ActionRow
        key={action.key}
        workflowRowId={workflowRowId}
        workflowPresets={workflowPresets}
        action={action}
        toggleActionCode={toggleAction}
        pending={pending[action.key]}
        useSchemaBuilder={useSchemaBuilder}
      />
    ));
  }, [
    workflowRowId,
    workflowPresets,
    actions,
    pending,
    toggleAction,
    useSchemaBuilder
  ]);

  return actionList.length ? (
    <>
      <ul className={classes.actionsWrapper}>{actionList}</ul>
      {actionTrays}
    </>
  ) : (
    <span>No actions created</span>
  );
};

export default memo(Actions);
