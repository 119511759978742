import { Dispatch, memo, useCallback, useMemo } from 'react';
import {
  withRouter,
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps
} from 'react-router-dom';

import { DragDropContext } from 'react-beautiful-dnd';

import { Pushbutton, ConfirmationModal } from '@stratumn/atomic';

import {
  ROUTE_WORKFLOW_DISPLAY_OVERVIEW,
  ROUTE_WORKFLOW_DISPLAY_TRACE_INFO,
  ROUTE_WORKFLOW_DETAILS
} from 'constant/routes';
import { HIDE_OVERVIEW_CONFIG } from 'constant/environment';
import { getWorkflowBreadCrumbs } from 'constant/pages';

import {
  DataContext,
  useDataReducer,
  useToggle,
  useConfirmExit
} from 'utils/hooks';
import { onDrop } from 'utils/dragAndDrop';
import { Overview as OverviewDefinition, Configuration } from 'utils/trace';

import { HeaderLayout } from 'components/layouts';
import { TabNav } from 'components/workflow/ui';
import { getTraceFieldOptions } from 'schemaBuilder/converter/admin';

import { DisplayContext } from './context';

import {
  State,
  ReducerAction,
  UPDATE_DISPLAY
} from '../../../components/workflow/reducers';
import { isConfigDirty, isNewConfig } from '../../../components/workflow/utils';

import { parseDisplayToInfo, parseInfoToDisplay } from './traceInfo/parsing';
import parseDisplayToOverview from './overview/parsing';

import { Section } from './traceInfo/types';

import { TabLink } from './ui';

import Overview from './overview';
import TraceInfo from './traceInfo';

import useStyles from './display.style';

export const path = {
  OVERVIEW: 'overview',
  INFO: 'info'
};

interface Props extends RouteComponentProps<{ id: string }> {
  state: State;
  dispatch: Dispatch<ReducerAction>;
}

export interface IDataReducer {
  workflowName: string;
  info: Section[];
  overview: OverviewDefinition;
}

export function useDisplayConfig({ state }) {
  const [displayConfig, displayConfigContext] = useDataReducer<IDataReducer>({
    workflowName: state.workflow.name,
    info: parseInfoToDisplay(state.workflow.config.info),
    overview:
      // We can expect both overview to be set to null or {}
      state.workflow.config.overview === null
        ? {}
        : state.workflow.config.overview || null
  });

  const displayContextValues = useMemo(
    () => ({
      workflowName: displayConfig.workflowName,
      info: {
        path: path.INFO,
        sections: displayConfig.info
      },
      overview: {
        path: path.OVERVIEW,
        data: displayConfig.overview,
        isNewConfig: isNewConfig(state.workflow.config.overview)
      },
      traceFieldOptions: getTraceFieldOptions(state.workflow.config.admin)
    }),
    [
      displayConfig.info,
      displayConfig.overview,
      displayConfig.workflowName,
      state.workflow.config.overview,
      state.workflow.config.admin
    ]
  );

  const onDragEnd = useCallback(
    dndResult => onDrop(dndResult, displayConfigContext),
    [displayConfigContext]
  );

  return {
    displayConfig,
    displayConfigContext,
    displayContextValues,
    onDragEnd
  };
}

export const Display = ({
  history,
  match: {
    params: { id: workflowRowId }
  },
  state,
  dispatch
}: Props) => {
  const classes = useStyles();

  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);

  const {
    displayConfig,
    displayConfigContext,
    displayContextValues,
    onDragEnd
  } = useDisplayConfig({ state });

  const displayIsDirty = useMemo(() => {
    const oldConfig: Pick<Configuration, 'info' | 'overview'> = {
      info: state.workflow.config.info,
      overview: state.workflow.config.overview
    };

    const newConfig: Pick<Configuration, 'info' | 'overview'> = {
      info: parseDisplayToInfo(displayConfig.info),
      overview: parseDisplayToOverview(
        displayConfig.overview,
        isNewConfig(state.workflow.config.overview)
      )
    };

    return isConfigDirty(oldConfig, newConfig);
  }, [
    displayConfig.info,
    displayConfig.overview,
    state.workflow.config.info,
    state.workflow.config.overview
  ]);

  useConfirmExit(displayIsDirty || state.isDirty);

  const handleCancel = useCallback((): void => {
    if (displayIsDirty && !showConfirmationModal)
      return toggleShowConfirmationModal();
    return history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [
    displayIsDirty,
    history,
    showConfirmationModal,
    toggleShowConfirmationModal,
    workflowRowId
  ]);

  const handleSubmit = useCallback((): void => {
    dispatch({
      type: UPDATE_DISPLAY,
      data: {
        info: parseDisplayToInfo(displayConfig.info),
        overview: parseDisplayToOverview(
          displayConfig.overview,
          isNewConfig(state.workflow.config.overview)
        )
      }
    });
    history.push(ROUTE_WORKFLOW_DETAILS.replace(':id', workflowRowId));
  }, [
    dispatch,
    displayConfig.info,
    displayConfig.overview,
    history,
    state.workflow.config.overview,
    workflowRowId
  ]);

  const header = useMemo(
    () => (
      <HeaderLayout
        dirty={{
          module: 'Display',
          moduleIsDirty: displayIsDirty,
          stateIsDirty: state.isDirty
        }}
        breadcrumbs={[
          ...getWorkflowBreadCrumbs(
            state.workflow.name,
            state.workflow.rowId,
            true
          ),
          { label: 'Display' }
        ]}
      />
    ),
    [displayIsDirty, state.isDirty, state.workflow.name, state.workflow.rowId]
  );

  return (
    <>
      {header}
      <DataContext.Provider value={displayConfigContext}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Router>
            <div className={classes.root}>
              <header className={classes.header}>
                <h1 className={classes.h1}>Display Configuration</h1>
              </header>
              <div className={classes.wrapper}>
                <TabNav>
                  {!HIDE_OVERVIEW_CONFIG && (
                    <TabLink
                      iconName="Table"
                      label="overview"
                      path={ROUTE_WORKFLOW_DISPLAY_OVERVIEW.replace(
                        ':id',
                        workflowRowId
                      )}
                    />
                  )}
                  <TabLink
                    iconName="IdLight"
                    label="trace info"
                    path={ROUTE_WORKFLOW_DISPLAY_TRACE_INFO.replace(
                      ':id',
                      workflowRowId
                    )}
                  />
                </TabNav>

                <div className={classes.activeTabWrapper}>
                  <DisplayContext.Provider value={displayContextValues}>
                    <Switch>
                      {!HIDE_OVERVIEW_CONFIG && (
                        <Route
                          path={ROUTE_WORKFLOW_DISPLAY_OVERVIEW}
                          component={Overview}
                        />
                      )}

                      <Route
                        path={ROUTE_WORKFLOW_DISPLAY_TRACE_INFO}
                        component={TraceInfo}
                      />
                    </Switch>
                  </DisplayContext.Provider>
                </div>
              </div>

              <div className={classes.ctaButtonsWrapper}>
                <div className={classes.cancelButton}>
                  <Pushbutton dataCy="cancel-btn" onClick={handleCancel}>
                    cancel
                  </Pushbutton>
                </div>
                <Pushbutton
                  dataCy="done-btn"
                  primary
                  onClick={handleSubmit}
                  disabled={!displayIsDirty}
                >
                  done
                </Pushbutton>
              </div>
            </div>
            {showConfirmationModal && displayIsDirty && (
              <ConfirmationModal
                data-cy="undo-confirmation-modal"
                warning
                title="Quit Display module"
                content="You’re about to quit Display module without saving your changes.<br>**All unsaved changes will be lost**.<br>Are you sure?"
                confirmBtnText="quit module"
                confirm={handleCancel}
                cancel={toggleShowConfirmationModal}
              />
            )}
          </Router>
        </DragDropContext>
      </DataContext.Provider>
    </>
  );
};

export default withRouter(memo(Display));
