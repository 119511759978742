import React from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Panel } from 'components/workflow/ui';
import { FormikCheckbox, FormikSelect } from 'components/formik';
import { Icon } from '@stratumn/atomic';

import { PresetInstance } from 'utils/presets';

import theme from 'style';
import MappingRow from './mappingRow';
import { ImportDataInput, TextEncodings, default as template } from '.';
import useStyles from './form.style';

const encodings = [
  { label: 'UTF-8', value: TextEncodings.UTF8 },
  { label: 'Windows (Latin-1)', value: TextEncodings.Latin1 }
];

const delimiters = [
  { label: 'Comma (,)', value: ',' },
  { label: 'Semicolon (;)', value: ';' },
  { label: 'Tab (\\t)', value: '\t' }
];

const decimalDelimiters = [
  { label: 'International (.)', value: '.' },
  { label: 'French (,)', value: ',' }
];

const thousandsDelimiters = [
  { label: 'International (,)', value: ',' },
  { label: 'French ( )', value: ' ' }
];

const ImportDataForm: React.FC<
  FormikProps<PresetInstance<ImportDataInput>>
> = ({ values }) => {
  const classes = useStyles();
  return (
    <>
      <Panel title="Settings">
        <div className={classes.settingRow}>
          <FormikSelect
            name="input.encoding"
            label="Encoding"
            options={encodings}
          />
          <FormikSelect
            name="input.csvDelimiter"
            label="CSV delimiter"
            options={delimiters}
          />
          <FormikCheckbox
            name="input.enableComments"
            label="Allow commenting"
          />
        </div>
        <div className={classes.settingRow}>
          <FormikSelect
            name="input.decimalDelimiter"
            label="Delimiter for decimals"
            options={decimalDelimiters}
          />
          <FormikSelect
            name="input.thousandsDelimiter"
            label="Delimiter for thousands"
            options={thousandsDelimiters}
          />
        </div>
      </Panel>
      <Panel title="Mapping">
        <FieldArray name="input.mapping">
          {({ remove, push }) => (
            <div>
              {values.input.mapping.map((value, i) => (
                <MappingRow
                  key={`input.mapping.${i}`}
                  name={`input.mapping.${i}`}
                  value={value}
                  onRemove={() => remove(i)}
                />
              ))}
              <button
                type="button"
                onClick={() =>
                  push({
                    ...template.defaultValues.mapping[0]
                  })
                }
              >
                <Icon color={theme.indigo2} name="CirclePlusFill" size={30} />
              </button>
            </div>
          )}
        </FieldArray>
      </Panel>
    </>
  );
};

export default ImportDataForm;
