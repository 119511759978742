import { FC, memo, useCallback, useContext, useRef } from 'react';

import { FieldText } from '@stratumn/atomic';
import { Section as SectionLayout } from 'components/workflow/ui';

import generateId from 'utils/id';
import { DataContext } from 'utils/hooks';
import { scrollToEndOfRef } from 'utils/scroll';

import { EventChangeHandler } from 'utils/types';
import { SectionDefinition } from 'utils/interfaces/statuses';

import { StatusContext } from '../context';

import { StatusCard } from './statusCard';

import useStyles from './section.style';

export interface Props {
  path: string;
  data: SectionDefinition | undefined;
}

export const Section: FC<Props> = ({ path, data }) => {
  const classes = useStyles();
  const sectionRef = useRef<HTMLDivElement>(null);

  const { set, append } = useContext(DataContext);
  const { showErrors } = useContext(StatusContext);

  const handleAddStatusValues = useCallback((): void => {
    append(`${path}.statuses`, {
      key: generateId(),
      actions: [{ key: generateId() }],
      isCollapsed: false
    });
    // scroll to the new status card after it is rendered
    scrollToEndOfRef(sectionRef);
  }, [append, path]);

  const handleSectionLabel: EventChangeHandler = useCallback(
    e => {
      set(`${path}.label`, e.target.value || '');
    },
    [path, set]
  );

  if (!data) return <p>Add a new section</p>;

  let errorsStr = '';
  if (showErrors && (!data.label || data.label === '')) {
    errorsStr = 'empty field';
  }

  return (
    <SectionLayout
      elementRef={sectionRef}
      overviewTab
      title=""
      path={`${path}.values`}
      sectionKey=""
      placeholder=""
      addElementFooter={{
        label: 'Add status values',
        callback: handleAddStatusValues
      }}
    >
      <div className={classes.fieldTextWrapper}>
        <FieldText
          dataCy="edit-section-label"
          label="section label"
          onValueChange={handleSectionLabel}
          value={data.label || ''}
          invalid={!!errorsStr}
          hideLabel
        />
      </div>
      {data.statuses?.length > 0 && (
        <ul>
          {data.statuses.map((status, index) => (
            <li key={status.key} className={classes.statusValueCardWrapper}>
              <StatusCard path={`${path}.statuses.${index}`} data={status} />
            </li>
          ))}
        </ul>
      )}
    </SectionLayout>
  );
};

export default memo(Section);
