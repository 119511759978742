import { ReactNode, memo } from 'react';

import {
  EventChangeHandler,
  KeyboardEventHandler,
  BlurEventHandler
} from 'utils/types';

import useStyles from './inputWithIcon.style';

interface Props {
  icon: ReactNode;
  value: string;
  placeholder?: string;
  onChange: EventChangeHandler;
  onKeyPress?: KeyboardEventHandler;
  onBlur?: BlurEventHandler;
  disabled?: boolean;
  invalid?: boolean;
}

export const InputWithIcon = ({
  icon,
  value,
  placeholder,
  onChange,
  onKeyPress,
  onBlur,
  disabled,
  invalid
}: Props) => {
  const classes = useStyles({ invalid, disabled });

  return (
    <div className={classes.inputWithIconRoot}>
      {icon}
      <input
        style={{ backgroundColor: 'transparent' }}
        className={classes.input}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

InputWithIcon.defaultProps = {
  placeholder: '',
  onKeyPress: () => {},
  onBlur: () => {},
  disabled: false,
  invalid: false
};

export default memo(InputWithIcon);
