import React from 'react';
import { Panel } from 'components/workflow/ui';
import { FormikProps } from 'formik';
import { PresetInstance } from 'utils/presets';
import { FormikCheckbox, FormikTextField } from 'components/formik';
import { FileUploadInput } from '.';

const FileUploadForm: React.FC<
  FormikProps<PresetInstance<FileUploadInput>>
> = () => (
  <Panel title="Settings">
    <FormikTextField name="input.fieldTitle" label="Name of the form field" />
    <FormikCheckbox name="input.enableHistory" label="Keep history in state" />
    <FormikCheckbox name="input.enableComments" label="Allow commenting" />
    <FormikCheckbox
      name="input.enableMultipleFiles"
      label="Allow multiple files upload"
    />
  </Panel>
);

export default FileUploadForm;
