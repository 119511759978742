import React, { Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { Snackbars } from '@stratumn/atomic';
import { ApolloProvider } from '@apollo/client';
import { traceClient } from 'client';
import { createBrowserHistory } from 'history';

import * as routes from 'constant/routes';
import { HIDE_ROOT_NAV } from 'constant/environment';
import {
  PrivateRoute,
  Home,
  Login,
  ErrorBoundary,
  OAuth,
  Workflows,
  Workflow,
  Organizations,
  NewWorkflow,
  DemoWorkflows,
  FormBuilderInt,
  TrayPortal
} from 'components';

import theme from 'style';

export const history = createBrowserHistory();

export const Root = () => (
  <ThemeProvider theme={theme}>
    <TrayPortal />
    <Router history={history}>
      <Snackbars>
        <ErrorBoundary>
          <ApolloProvider client={traceClient}>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path={routes.ROUTE_LOGIN} exact component={Login} />
                <Route path={routes.ROUTE_OAUTH} exact component={OAuth} />
                <PrivateRoute
                  path={routes.ROUTE_HOME}
                  exact
                  component={HIDE_ROOT_NAV ? Workflows : Home}
                />
                <PrivateRoute
                  path={routes.ROUTE_WORKFLOWS}
                  exact
                  component={Workflows}
                />
                <PrivateRoute
                  path={routes.ROUTE_WORKFLOW_DETAILS}
                  component={Workflow}
                />
                <PrivateRoute
                  path={routes.ROUTE_SCHEMA_BUILDER}
                  component={Workflow}
                />
                <PrivateRoute
                  path={routes.ROUTE_NEW_WORKFLOW}
                  exact
                  component={NewWorkflow}
                />
                <PrivateRoute
                  path={routes.ROUTE_ORGANIZATIONS}
                  exact
                  component={Organizations}
                />
                <PrivateRoute
                  path={routes.ROUTE_DEMO_WORKFLOWS}
                  exact
                  component={DemoWorkflows}
                />
                <PrivateRoute
                  path={routes.ROUTE_FORM_BUILDER}
                  exact
                  component={() => <FormBuilderInt />}
                />
              </Switch>
            </Suspense>
          </ApolloProvider>
        </ErrorBoundary>
      </Snackbars>
    </Router>
  </ThemeProvider>
);

export default Root;
