import {
  ResolvedExpressionType,
  SortDirection,
  BooleanExpression,
  EqualExpression,
  SortedExpression
} from '@stratumn/dsl';
import deepEqual from 'deep-equal';
import {
  TransitionGroups,
  WorkflowTransitions,
  ListOrWildcard,
  TRANSITIVE
} from 'utils/trace';
import {
  FromActionDefinition,
  ConditionEnums,
  Condition,
  TransitionGroup
} from '../types';

const WILDCARD = '*';

export const unparseCondition = (
  c: Condition
): BooleanExpression | undefined => {
  const { path, value, type } = c;
  let condition: BooleanExpression | undefined;

  const args = [
    {
      query: path,
      $expression: ResolvedExpressionType.Variable
    },
    value
  ];

  switch (type) {
    case ConditionEnums.IsTruthy:
      condition = {
        $expression: ResolvedExpressionType.Variable,
        query: path
      };
      break;
    case ConditionEnums.Equal:
      condition = {
        $expression: ResolvedExpressionType.Equal,
        arguments: args
      } as EqualExpression;
      break;
    case ConditionEnums.GreaterThan:
      condition = {
        $expression: ResolvedExpressionType.Sorted,
        arguments: args
      } as SortedExpression;
      break;
    case ConditionEnums.LessThan:
      condition = {
        $expression: ResolvedExpressionType.Sorted,
        arguments: args,
        direction: SortDirection.Descending
      } as SortedExpression;
      break;
    default:
  }

  return condition;
};

const unparseGroups = (groups: TransitionGroup[]): TransitionGroups[] =>
  // If a group has the same condition, we create only one array with both groups
  groups.reduce((accumulator: any[], group: TransitionGroup) => {
    const labelIsWildCard = group.label === WILDCARD;
    /**
     * Check if the condition already exists
     */
    const transitionGroupFound: {
      groups: ListOrWildcard;
    } = accumulator.find(
      gp =>
        group.condition &&
        deepEqual(gp.condition, unparseCondition(group.condition))
    );

    if (transitionGroupFound) {
      // If the label is a wildcard
      // since the groups' condition is identical, we replace the groups' labels array with the wildcard string
      if (labelIsWildCard) transitionGroupFound.groups = WILDCARD;
      if (Array.isArray(transitionGroupFound.groups))
        transitionGroupFound.groups.push(group.label);

      // If a group has no condition, we create a new TransitionGroups with only a groups' property
    } else if (!group.condition) {
      accumulator.push({ groups: labelIsWildCard ? WILDCARD : [group.label] });
      // If a group has a condition that doesn't already exist, we create a new TransitionGroups with groups and condition's propperties
    } else {
      // if group label is the wildcard, assign groups' a string value (*)
      // otherwise keep array of group.label
      accumulator.push({
        groups: labelIsWildCard ? WILDCARD : [group.label],
        condition: unparseCondition(group.condition)
      });
    }
    return accumulator;
  }, []);

export const buildNextActionsObj = (
  nextActions: FromActionDefinition[]
): WorkflowTransitions => {
  const nextActionsObj = {};

  nextActions.forEach(action => {
    if (action.nextActions.find(({ key }) => key === TRANSITIVE)) {
      nextActionsObj[action.actionKey] = TRANSITIVE;
      return nextActionsObj;
    }

    const nextAction = {};

    action.nextActions.forEach(({ key, groups }) => {
      nextAction[key] = unparseGroups(groups);
    });

    nextActionsObj[action.actionKey] = nextAction;

    return nextActionsObj;
  });

  return nextActionsObj;
};
