import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  draggable: {
    marginLeft: -20,
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    '&:hover $dragHandle': {
      opacity: 1
    },
    '&:hover $optionInput': {
      borderColor: theme.grey7
    }
  },
  dragHandle: {
    width: 20,
    outline: 'none',
    opacity: 0,
    color: theme.grey5,
    alignContent: 'center'
  },
  option: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 0px'
  },
  optionLabel: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  optionInput: {
    backgroundColor: 'transparent',
    flexGrow: 1,
    marginLeft: 5,
    padding: 4,
    outline: 'none',
    border: 'none',
    borderRadius: 2,
    boxSizing: 'border-box',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1,
    borderBottom: `1px solid transparent`,
    '&::placeholder': {
      color: theme.grey4
    }
  },
  removeBtn: {
    flexShrink: 0,
    '&:focus': {
      outline: 'none'
    }
  }
}));
