import {
  FormDefinition,
  PropertyDefinition,
  InputDefinition,
  AnyDependencyDefinition,
  AnyDependenciesDefinition,
  OptionsList,
  InputType
} from '../../types';
import { getPropertyKey } from '../../utils';
import Inputs from '../../property/inputs';

// tool functions used accross form inputs parsing
export const validateNumber = (
  errors: string[],
  minValue?: number,
  maxValue?: number,
  valueType: string = 'value'
) => {
  if (minValue !== undefined && maxValue !== undefined && minValue > maxValue) {
    errors.push(`Minimum ${valueType} is greater than maximum ${valueType}`);
  }
};

export const validateListSize = (
  errors: string[],
  minItems?: number,
  maxItems?: number
) => validateNumber(errors, minItems, maxItems, 'number of items');

export const validateOptionsList = (
  errors: string[],
  options?: OptionsList
) => {
  if (!options || !options.length) {
    errors.push(`No option defined`);
  } else {
    const valuesSet = new Set<string>();
    options.forEach(({ value }, idx) => {
      if (!value) {
        errors.push(`No value defined for option ${idx + 1}`);
      } else if (valuesSet.has(value)) {
        errors.push(`Duplicated value "${value}" in option ${idx + 1}`);
      } else {
        valuesSet.add(value);
      }
    });
  }
};

// return errors found on a properties list
export const validatePropertiesList = (
  errors: string[],
  properties?: PropertyDefinition[],
  depth: number = 0,
  deepValidation: boolean = false // whether to check for property's nested errors recursively (eg if sub-form or list)
) => {
  const formType = depth > 0 ? 'sub-form' : 'form';
  if (!properties || !properties.length) {
    errors.push(`No ${formType} input defined`);
  } else {
    const keysSet = new Set<string>();
    properties.forEach((property, idx) => {
      const { key, label, propertyKey } = property;
      const propKey = getPropertyKey(key, label, propertyKey);
      if (keysSet.has(propKey)) {
        errors.push(
          `Duplicated key "${propKey}" in ${formType} input ${idx + 1}`
        );
      } else {
        keysSet.add(propKey);
      }
      if (deepValidation) {
        validateProperty(errors, property, depth, true);
      }
    });
  }
};

// return errors found on a property definition
export const validateProperty = (
  errors: string[],
  property: PropertyDefinition,
  depth: number = 0,
  deepValidation: boolean = false // whether to check for property's nested errors recursively (eg if sub-form or list)
) => {
  const { label, input } = property;

  if (!label && input?.type !== InputType.Helper) {
    errors.push('No label defined for this input');
  }
  if (!input) {
    errors.push('No type selected for this input');
  } else if (!input.dependencies) {
    validateInput(errors, input, depth, deepValidation);
  }
};

// return errors found on an input definition
export const validateInput = (
  errors: string[],
  input: InputDefinition,
  depth: number = 0,
  deepValidation: boolean = false
) => {
  if (!input.dependencies) {
    // TODO: add validation in the case of dependencies
    const inputErrorsFn = (Inputs[input.type] || {}).getErrors;
    if (inputErrorsFn) {
      inputErrorsFn(input, errors, depth, deepValidation);
    }
  }
};

// return errors found on a properties list
export const validateDependenciesList = (
  errors: string[],
  dependencies: AnyDependenciesDefinition,
  sourceInput?: InputDefinition
) => {
  const { conditions = [] } = dependencies;

  // check the condition source is properly selected
  if (!sourceInput) {
    errors.push('No condition source');
  }

  // count the number of errors for each dependency
  const conditionsErrors: string[] = [];
  conditions.forEach(dependency => {
    const thisCondErrors = [];
    validateDependency(thisCondErrors, dependency, sourceInput, 1, true);
    if (thisCondErrors.length) {
      conditionsErrors.push(thisCondErrors.join(', '));
    }
  });
  if (conditionsErrors.length) {
    errors.push(`${conditionsErrors.length} conditions have errors`);
  }
};

// return errors found on a dependency definition
export const validateDependency = (
  errors: string[],
  dependency: AnyDependencyDefinition,
  dependencySourceInput?: InputDefinition,
  depth: number = 0,
  deepValidation: boolean = false
) => {
  const { condition, input } = dependency;

  if (dependencySourceInput) {
    const conditionErrorsFn = (Inputs[dependencySourceInput.type] || {})
      .conditions?.getErrors;
    if (conditionErrorsFn) {
      conditionErrorsFn(dependencySourceInput, errors, condition);
    }
  }

  validateInput(errors, input, depth, deepValidation);
};

// just count all errors found globally on a form
export const getFormErrors = (form: FormDefinition): number => {
  // just validate the properties list deeply
  const { properties } = form;

  const errors: string[] = [];
  validatePropertiesList(errors, properties, 0, true);

  return errors.length;
};
