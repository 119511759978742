import { createUseStyles } from 'react-jss';

// This is basically a "CSS un-reset" for the Markdown elements we need
export default createUseStyles(() => ({
  documentation: {
    '& h1': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #ECEBF2',
      paddingBottom: 14,
      marginBottom: 14,
      fontSize: 18,
      lineHeight: 1
    },
    '& h1, h2, h3, h4, h5, h6, b, strong': {
      fontWeight: 'bold'
    },
    '& em, i, dfn': {
      fontStyle: 'italic'
    },
    '& dfn': {
      fontWeight: 'bold'
    },
    '& p, code, pre, kbd': {
      margin: '0 0 1.5em 0'
    },
    '& blockquote': {
      margin: '0 1.5em 1.5em 1.5em'
    },
    '& cite': {
      fontStyle: 'italic'
    },
    '& ul, ol': {
      margin: '0 1.5em'
    },
    '& ul': {
      listStyleType: 'disc'
    },
    '& ol': {
      listStyleType: 'decimal'
    },
    '& ol ol': {
      listStyle: 'upper-alpha'
    },
    '& ol ol ol': {
      listStyle: 'lower-roman'
    },
    '& ol ol ol ol': {
      listStyle: 'lower-alpha'
    },
    '& dl': {
      margin: '0 0 1.5em 0'
    },
    '& dl dt': {
      fontWeight: 'bold'
    },
    '& dd': {
      marginLeft: '1.5em'
    },
    '& table': {
      marginBottom: '1.4em',
      width: '100%'
    },
    '& th': {
      fontWeight: 'bold'
    },
    '& th, td, caption': {
      padding: '4px 10px 4px 5px'
    },
    '& tfoot': {
      fontStyle: 'italic'
    },
    '& sup, sub': {
      lineHeight: 0
    },
    '& abbr, acronym': {
      borderBottom: '1px dotted'
    },
    '& address': {
      margin: '0 0 1.5em',
      fontStyle: 'italic'
    },
    '& del': {
      textDecoration: 'lineThrough'
    },
    '& pre': {
      margin: '1.5em 0',
      whiteSpace: 'pre'
    }
  }
}));
