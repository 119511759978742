import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    width: '100%',
    border: `1px solid ${theme.greyTable}`,
    transition: 'border-color 150ms linear',
    '&[data-has-errors=true]': {
      borderColor: theme.warningRed
    },
    '&[data-is-dragging=true]': {
      boxShadow: theme.indigoShadowHover
    },
    '& label': {
      height: 36
    },
    '& input': {
      backgroundColor: theme.white1
    }
  },
  dragHandle: {
    outline: 'none',
    color: theme.grey5
  },
  fieldKeyDisplay: {
    marginRight: 20,
    color: theme.grey4,
    fontWeight: 'normal',
    fontSize: 14
  },
  fieldKeyButton: {
    marginRight: 20,
    display: 'flex',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '18px',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:hover $penIcon': {
      display: 'flex'
    }
  },
  fieldKey: {
    color: theme.grey4
  },
  penIcon: {
    marginLeft: 3,
    display: 'none'
  }
}));
