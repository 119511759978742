import { memo, ReactNode, useMemo } from 'react';

import type {
  BaseTypeBodyProps,
  DropdownSchemaField
} from 'schemaBuilder/types';

import { DraggableType, OptionsType } from '../../ui/interfaces';
import { OptionsList } from '../../ui';

import useStyles from './dropdown.style';

interface Props extends BaseTypeBodyProps {
  schemaField: DropdownSchemaField;
}

const DropdownBody = ({ schemaField, readonly, path }: Props) => {
  const classes = useStyles();

  const icon: ReactNode = useMemo(() => <span>—</span>, []);

  const options: OptionsType[] = useMemo(
    () =>
      schemaField?.enumNames?.reduce((acc: OptionsType[], curr: any) => {
        acc.push({ key: curr.key, value: curr.value });
        return acc;
      }, []),
    [schemaField.enumNames]
  );

  return (
    <div className={classes.root}>
      <OptionsList
        listType={DraggableType.DropdownOptions}
        options={options}
        path={`${path}.schemaField.enumNames`}
        icon={icon}
        placeholder="Add dropdown option"
        disabled={readonly ?? false}
      />
    </div>
  );
};

export default memo(DropdownBody);
