import React from 'react';
import theme from 'style';
import {
  FormikCheckbox,
  FormikTextField,
  FormikSelect
} from 'components/formik';
import { DisplayWidths } from 'utils/traceWidgets';

import { Trash } from '@stratumn/icons';

import {
  DataTypes,
  StringFormats,
  NumberFormats,
  BooleanFormats,
  DateFormats
} from '..';
import useStyles from './mappingRow.style';

const displayWidths = [
  { label: 'Very small', value: DisplayWidths.VerySmall },
  { label: 'Small', value: DisplayWidths.Small },
  { label: 'Medium', value: DisplayWidths.Medium },
  { label: 'Large', value: DisplayWidths.Large },
  { label: 'Very large', value: DisplayWidths.VeryLarge }
];

const types = [
  { label: 'Text', value: DataTypes.String },
  { label: 'Number', value: DataTypes.Number },
  { label: 'Boolean', value: DataTypes.Boolean },
  { label: 'Date', value: DataTypes.Date }
];

const formats = {
  string: [
    { label: 'Short text', value: StringFormats.Short },
    { label: 'Long text', value: StringFormats.Long }
  ],
  number: [
    { label: 'No decimals (integer)', value: NumberFormats.Integer },
    { label: '2 decimals', value: NumberFormats.TwoDecimals },
    { label: 'Percentage', value: NumberFormats.Percent }
  ],
  boolean: [
    { label: 'Yes/No', value: BooleanFormats.YesNo },
    { label: 'True/False', value: BooleanFormats.TrueFalse },
    { label: '0/1', value: BooleanFormats.ZeroOne }
  ],
  date: [
    { label: 'DD/MM/YYYY', value: DateFormats.European },
    { label: 'DD MMM, YYYY', value: DateFormats.International },
    { label: 'YYYY-MM-DD', value: DateFormats.ISO }
  ]
};

interface MappingRowProps {
  name: string;
  value: {
    source: string;
    displayWidth: number | string;
    type: string;
    format: string;
    isPrimary: boolean;
  };
  onRemove: () => void;
}

const MappingRow: React.FC<MappingRowProps> = ({ name, value, onRemove }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <button type="button" onClick={onRemove}>
        <Trash className={classes.trashIcon} color={theme.indigo2} />
      </button>
      <FormikTextField label="Source column name" name={`${name}.source`} />
      <FormikSelect
        label="Display width"
        options={displayWidths}
        name={`${name}.displayWidth`}
      />
      <FormikSelect label="Column type" options={types} name={`${name}.type`} />
      <FormikSelect
        label="Format"
        options={formats[value.type]}
        name={`${name}.format`}
      />
      <FormikCheckbox label="Primary key" name={`${name}.isPrimary`} />
    </div>
  );
};

export default MappingRow;
