import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  cardRoot: {
    display: 'flex',
    width: '100%',
    background: theme.white1,
    borderRadius: 5,
    border: `1px solid transparent`,
    boxShadow: theme.indigoShadowLight,
    '&[data-is-dragging=true]': {
      boxShadow: theme.indigoShadowHover
    },
    '&[data-has-errors=true]': {
      border: `1px solid ${theme.warningRed}`
    }
  }
}));
