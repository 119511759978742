import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  numberField: {
    height: 36,
    outline: 'none',
    padding: '8px 5px 8px 11px',
    transition: 'border-color 150ms linear 0ms',
    border: `1px solid ${theme.grey5}`,
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: theme.white1,
    fontSize: 14,
    color: theme.grey1,
    '&::placeholder': {
      color: theme.grey4
    },
    '&[data-is-set=true]': {
      borderColor: theme.grey2
    },
    '&[data-is-invalid=true]': {
      borderColor: theme.warningRed
    },
    '&:focus': {
      borderColor: theme.indigo3
    }
  }
}));
