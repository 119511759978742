import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  head: {
    flexShrink: 0,
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    margin: 24
  },
  left: {
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    gap: 20,
    lineHeight: 'normal'
  },
  title: {
    flexShrink: 0,
    fontSize: 24,
    fontWeight: 600
  },
  searchInput: {
    maxWidth: 300
  },
  searchIcon: {
    color: theme.grey4
  },
  newWorkflowBtn: {
    flexShrink: 0
  }
}));
