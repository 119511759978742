import React, { FC } from 'react';

import Property from '../property';
import { PropertyDefinition } from '../types';

interface Props {
  path: string;
  properties: PropertyDefinition[];
  depth: number;
  propertiesClassName?: string;
}

// this intermediate component is motivated by a tip from react-beautiful-dnd
// for perf optimization on the droppable list, to avoid rerendering the list content while dragging
// see https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md#recommended-droppable--performance-optimisation
export const List: FC<Props> = ({
  path,
  properties,
  depth,
  propertiesClassName
}) => {
  return (
    <>
      {properties.map((property, index) => (
        <Property
          key={property.key}
          index={index}
          path={`${path}.${index}`}
          property={property}
          className={propertiesClassName}
          depth={depth}
        />
      ))}
    </>
  );
};

export default React.memo(List);
