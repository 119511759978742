import { FC, memo, useMemo } from 'react';

import { Icon } from '@stratumn/atomic';
import { generatePath } from 'react-router';
import { NavLink } from 'react-router-dom';

import { IconButtonTooltip } from 'components/workflow/ui';

import {
  ROUTE_SCHEMA_EDIT_ACTION,
  ROUTE_WORKFLOW_ACTION
} from 'constant/routes';
import { Action, WorkflowSettingsPresets } from 'utils/trace';
import * as presets from 'presets';

import useStyles from './actionRow.style';

interface Props {
  workflowRowId: string;
  workflowPresets?: WorkflowSettingsPresets;
  action: Action;
  toggleActionCode: (actionKey: string) => void;
  pending?: boolean;
  useSchemaBuilder?: boolean;
}

export const ActionRow: FC<Props> = ({
  workflowRowId,
  workflowPresets,
  action,
  toggleActionCode,
  pending,
  useSchemaBuilder
}) => {
  const classes = useStyles();

  const { key, icon, title } = action;

  const actionHRef = useMemo(
    () =>
      generatePath(
        useSchemaBuilder
          ? ROUTE_SCHEMA_EDIT_ACTION
          : `${ROUTE_WORKFLOW_ACTION}?key=${key}`,
        {
          id: workflowRowId,
          action: key
        }
      ),
    [useSchemaBuilder, workflowRowId, key]
  );

  const actionPreset = useMemo(() => {
    const templateKey = workflowPresets?.[key]?.template || 'generic';
    return presets[templateKey].name.replace(' action', '');
  }, [workflowPresets, key]);

  return (
    <li className={classes.action}>
      <div className={classes.actionInfo}>
        <Icon name={icon || 'DocEmpty'} size={25} />
        <div className={classes.actionNaming}>
          <NavLink
            data-cy="edit-action-title"
            to={actionHRef}
            className={classes.actionTitle}
          >
            {title}
          </NavLink>
          <div className={classes.actionKey}>
            <span className={classes.actionPreset}>
              {`Preset: ${actionPreset}`}
            </span>
            {`Key: ${key}`}
          </div>
        </div>
      </div>
      {pending && <div className={classes.pendingPill} />}
      <div className={classes.actionButtons}>
        <IconButtonTooltip
          dataCy="toggle-action-code"
          iconName="Code"
          iconSize={18}
          tooltipMessage="Code review"
          onClick={() => toggleActionCode(key)}
        />
        <IconButtonTooltip
          dataCy="edit-action-pen"
          iconName="Pen"
          iconSize={18}
          tooltipMessage="Edit action"
          url={actionHRef}
        />
      </div>
    </li>
  );
};

export default memo(ActionRow);
