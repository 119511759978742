import { useCallback, ReactElement, useContext, memo } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  IconButton
} from 'components/workflow/ui';
import { SmartDropdown, Switch } from '@stratumn/atomic';
import type { ExistingActionField, WorkflowSchema } from 'schemaBuilder/types';
import { DataContext } from 'utils/hooks';

import { useValidation } from '../../hooks/validator';
import { fieldTypesMap } from '../constants';
import useStyles from './existing.style';

export interface Props {
  isDragging: boolean;
  field: ExistingActionField;
  index: number;
  schema: WorkflowSchema;
  dragElement: ReactElement;
  removeElement: ReactElement;
  fieldKeyElement: ReactElement;
  rootPath: string;
}

export const Existing = ({
  isDragging,
  field,
  index,
  schema,
  dragElement,
  removeElement,
  fieldKeyElement,
  rootPath
}: Props) => {
  const classes = useStyles();

  const validation = useValidation();
  const { set, toggle } = useContext(DataContext);
  const schemaField = schema[field.ref || ''];
  const BodyElement = schemaField && fieldTypesMap.get(schemaField.type)?.body;

  const handleFieldSelect = useCallback(
    (value: string) => set(`${rootPath}.${index}.ref`, value),
    [set, index, rootPath]
  );

  const handleRequireToggle = useCallback(
    () => toggle(`${rootPath}.${index}.required`),
    [toggle, index, rootPath]
  );

  const fieldOptions = Object.values(schema).map(({ key: value, label }) => ({
    label,
    value
  }));

  return (
    <Card
      isDragging={isDragging}
      invalid={validation.hasErrorsByGroup(`${rootPath}.${index}`)}
    >
      <CardContent>
        <div className={classes.header}>
          {dragElement}
          <div className={classes.field}>
            <SmartDropdown
              compact
              options={fieldOptions}
              dataCy="field-select"
              label="Select an existing field"
              noMatchMessage="No existing field found"
              placeholder="Select an existing field"
              onSelect={handleFieldSelect}
              value={field.ref}
              invalid={validation.hasErrors(`${rootPath}.${index}.ref`)}
            />
          </div>
          <div className={classes.spacer} />
        </div>
        <div className={classes.bodyWrapper}>
          {schemaField && BodyElement && (
            <BodyElement readonly schemaField={schemaField} index={index} />
          )}
        </div>
        <div className={classes.footer}>
          <Switch
            label="Required"
            showLabel
            invert
            on={field.required}
            handleChange={handleRequireToggle}
            data-cy="toggle-required"
          />
          <div className={classes.spacer} />
          {fieldKeyElement}
        </div>
      </CardContent>
      <CardActions>
        <IconButton
          dataCy="toggle-custom-code"
          name="Code"
          onClick={() => {}}
          ariaLabel="Toggle custom code"
        />
        {removeElement}
      </CardActions>
    </Card>
  );
};

export default memo(Existing);
