import React, { FC, useContext, useCallback } from 'react';
import { createPortal } from 'react-dom';

import { Draggable } from 'react-beautiful-dnd';

import { Icon } from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import useStyles from './option.style';

interface Props {
  index: number;
  option: { key: string; value?: string };
  path: string;
  icon?: React.ReactNode;
}

export const Option: FC<Props> = ({
  index,
  option: { key, value },
  path,
  icon
}) => {
  const classes = useStyles();

  const { set, delete: deleteData } = useContext(DataContext);

  const updateOption = useCallback(e => set(`${path}.value`, e.target.value), [
    path,
    set
  ]);

  const removeOption = useCallback(() => deleteData(path), [path, deleteData]);

  const getDraggableItem = useCallback(
    (providedDraggable, snapshotDraggable) => {
      const draggableItem = (
        <div
          ref={providedDraggable.innerRef}
          className={classes.draggable}
          {...providedDraggable.draggableProps}
        >
          <div
            className={classes.dragHandle}
            {...providedDraggable.dragHandleProps}
          >
            <Icon name="Drag" size={20} />
          </div>
          <div className={classes.option}>
            <div className={classes.optionLabel}>
              {icon || <span>-</span>}
              <input
                className={classes.optionInput}
                value={value}
                onChange={updateOption}
                data-cy="update-option"
              />
            </div>
            <button
              type="button"
              className={classes.removeBtn}
              onClick={removeOption}
              data-cy="remove-option"
            >
              <Icon name="Cross" size={18} />
            </button>
          </div>
        </div>
      );

      return snapshotDraggable.isDragging
        ? createPortal(draggableItem, document.body)
        : draggableItem;
    },
    [classes, icon, value, removeOption, updateOption]
  );

  return (
    <Draggable draggableId={key} index={index}>
      {getDraggableItem}
    </Draggable>
  );
};

export default React.memo(Option);
