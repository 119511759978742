import { CheckboxesActionField } from 'schemaBuilder/types';
import { test as validate, enforce } from 'vest';
import { hasNoDuplicates, parseEnumNames } from '../../../utils/validation';

export default (field: CheckboxesActionField, fieldPath: string) => {
  // Label required
  validate(
    `${fieldPath}.schemaField.label`,
    `Please fill the required fields`,
    () => {
      enforce(field.schemaField.label).isNotEmpty();
    }
  );

  // No enumNames duplicates
  validate(
    `${fieldPath}.schemaField.enumNames`,
    `"${field.schemaField.label}" has duplicate options`,
    () => {
      enforce.extend({ hasNoDuplicates });

      enforce(parseEnumNames(field.schemaField.enumNames)).hasNoDuplicates();
    }
  );

  // At least 1 enumName required
  validate(
    `${fieldPath}.schemaField.enumNames.length`,
    `Please fill the required fields`,
    () => {
      enforce(field.schemaField.enumNames).isNotEmpty();
    }
  );

  // NB: We could enforce all option items to not be empty,
  // but since leaving an option empty deletes it by design,
  // it is not necessary.
};
