import { createUseStyles } from 'react-jss';

export default createUseStyles({
  widgetCardsWrapper: {
    paddingRight: 15
  },
  widgetCard: {
    '&:not(:first-child)': {
      margin: '10px 0'
    }
  }
});
