import { memo, useContext, useCallback } from 'react';
import { FieldTextCompact, SmartDropdown } from '@stratumn/atomic';

import { EventChangeHandler } from 'utils/types';
import { DataContext, useToggle } from 'utils/hooks';

import { ActionBasedDeadlineDefinition } from 'utils/interfaces/deadlines';

import { DeadlinesContext } from '../../context';

import useStyles from './actionBased.style';

export interface Props {
  path: string;
  data: ActionBasedDeadlineDefinition;
}

export const ActionBased = ({ path, data }: Props) => {
  const classes = useStyles();
  const { actions, showErrors } = useContext(DeadlinesContext);
  const { set } = useContext(DataContext);

  const { startTrigger, endTrigger, deadlineDays } = data;

  const handleSelectStartTrigger = useCallback(
    (actionKey: string) => {
      set(`${path}.startTrigger`, actionKey);
    },
    [path, set]
  );

  const handleSelectEndTrigger = useCallback(
    (actionKey: string) => {
      set(`${path}.endTrigger`, actionKey);
    },
    [path, set]
  );

  const handleEditDeadlineDays: EventChangeHandler = useCallback(
    e => {
      if (!e.target.value) {
        set(`${path}.deadlineDays`, undefined);
        return;
      }

      const newValue = Number(e.target.value);
      if (Number.isNaN(newValue)) return;

      set(`${path}.deadlineDays`, newValue);
    },
    [path, set]
  );
  const [deadlineDaysFieldFocused, toggleDeadlineDaysFieldFocused] = useToggle(
    false
  );
  const deadlineDaysValueSuffix = deadlineDaysFieldFocused ? '' : ' days';
  const deadlineDaysValue =
    deadlineDays === undefined
      ? ''
      : String(deadlineDays) + deadlineDaysValueSuffix;

  return (
    <>
      <div className={classes.el}>
        <SmartDropdown
          options={actions}
          dataCy="start-trigger"
          label="Start trigger"
          placeholder="Start trigger"
          noMatchMessage="No action found"
          onSelect={handleSelectStartTrigger}
          value={startTrigger}
          invalid={showErrors && !startTrigger}
          compact
          shadows
        />
      </div>
      <div className={classes.el}>
        <SmartDropdown
          options={actions}
          dataCy="end-trigger"
          label="End trigger"
          placeholder="End trigger"
          noMatchMessage="No action found"
          onSelect={handleSelectEndTrigger}
          value={endTrigger}
          invalid={showErrors && !endTrigger}
          compact
          shadows
        />
      </div>
      <div className={classes.el}>
        <FieldTextCompact
          dataCy="deadline-days"
          label="Deadline length (days)"
          placeholder="Deadline length (days)"
          onValueChange={handleEditDeadlineDays}
          onFocus={toggleDeadlineDaysFieldFocused}
          onBlur={toggleDeadlineDaysFieldFocused}
          value={deadlineDaysValue}
          invalid={showErrors && !deadlineDays}
        />
      </div>
    </>
  );
};

export default memo(ActionBased);

export const validate = (data: ActionBasedDeadlineDefinition) =>
  !data.startTrigger || !data.endTrigger || !data.deadlineDays;
