import { memo, useCallback, useContext } from 'react';
import type {
  SubFormSchemaField,
  BaseTypeBodyProps
} from 'schemaBuilder/types';

import { Pushtext, Icon } from '@stratumn/atomic';

import generateId from 'utils/id';
import { DataContext } from 'utils/hooks';

import useStyles from './style';
import { SubFormList } from './subformList';

interface Props extends BaseTypeBodyProps {
  schemaField: SubFormSchemaField;
  showFieldKeys: boolean;
}

const SubFormBody = ({
  schemaField,
  readonly = false,
  path,
  showFieldKeys
}: Props) => {
  const classes = useStyles();
  const { append } = useContext(DataContext);

  const addProperty = useCallback(() => {
    const id = generateId();
    append(`${path}.schemaField.properties`, {
      id,
      key: id
    });
  }, [append, path]);

  return (
    <div className={classes.body}>
      <SubFormList
        showFieldKeys={showFieldKeys}
        properties={schemaField.properties}
        readonly={readonly}
        path={`${path}.schemaField.properties`}
      />

      <br />

      <Pushtext
        onClick={addProperty}
        prefix={<Icon name="CirclePlusFill" size={25} />}
        data-cy="add-property"
      >
        Add new field
      </Pushtext>
    </div>
  );
};

export default memo(SubFormBody);
