import { DataUpdateType, Path, DataUpdate } from 'utils/data';

// a tool to generate helpers
// from a chained data updates function
export const getUpdateDataActions = (
  update: (updates: DataUpdate[]) => void
) => ({
  set: (path: Path, value: any) =>
    update([
      {
        type: DataUpdateType.Set,
        path,
        value
      }
    ]),
  delete: (path: Path) =>
    update([
      {
        type: DataUpdateType.Delete,
        path
      }
    ]),
  append: (path: Path, value: any) =>
    update([
      {
        type: DataUpdateType.Append,
        path,
        value
      }
    ]),
  remove: (path: Path, value: any) =>
    update([
      {
        type: DataUpdateType.Remove,
        path,
        value
      }
    ]),
  move: (path: Path, fromIndex: number, toIndex: number, toPath: Path) =>
    update([
      {
        type: DataUpdateType.Move,
        path,
        fromIndex,
        toIndex,
        toPath
      }
    ]),
  splice: (
    path: Path,
    startIndex?: number,
    deleteCount?: number,
    values?: any[]
  ) =>
    update([
      {
        type: DataUpdateType.Splice,
        path,
        startIndex,
        deleteCount,
        values
      }
    ]),
  toggle: (path: Path) =>
    update([
      {
        type: DataUpdateType.Toggle,
        path
      }
    ]),
  merge: (path: Path, value: any) =>
    update([
      {
        type: DataUpdateType.Merge,
        path,
        value
      }
    ])
});
