import { FC, useCallback, useContext, memo } from 'react';

import { DataUpdateType } from 'utils/data';
import generateId from 'utils/id';

import { DataContext } from 'utils/hooks';

import { Icon } from '@stratumn/atomic';
import { Tab } from 'components/workflow/ui';

import theme from 'style';

import { StatusContextDefinition } from '../context';

import useStyles from './tabsList.style';

// this limit was set by the product team
const TABS_LIMIT = 8;

interface Props {
  path: string;
  data: Pick<StatusContextDefinition, 'sections' | 'selectedTabKey'>;
}

export const TabsList: FC<Props> = ({
  path,
  data: { sections = [], selectedTabKey }
}) => {
  const classes = useStyles();

  const { append, update, set } = useContext(DataContext);

  const handleSelectTab = useCallback(
    (key: string): void => {
      set(`selectedTabKey`, key);
    },
    [set]
  );

  const handleAddSection = useCallback((): void => {
    const key: string = generateId();

    set(`selectedTabKey`, key);

    append(path, {
      key: key,
      label: 'new section',
      statuses: [
        {
          key: generateId(),
          actions: [{ key: generateId() }],
          isCollapsed: false
        }
      ]
    });
  }, [append, path, set]);

  const handleRemoveSection = useCallback(
    (index: number): void => {
      const revertMessage = 'You successfully deleted the section';
      const newSelectedTabKey = index > 0 ? index - 1 : index + 1;

      update(
        [
          {
            type: DataUpdateType.Delete,
            path: `${path}.${index}`
          }
        ],
        { revert: { message: revertMessage } }
      );

      if (sections.length > 1)
        return set('selectedTabKey', sections[newSelectedTabKey].key);

      return set('selectedTabKey', '');
    },
    [update, path, sections, set]
  );

  return (
    <>
      {sections.map(({ key, label = '' }, index) => (
        <Tab
          key={key}
          label={label}
          selected={key === selectedTabKey}
          onRemove={() => handleRemoveSection(index)}
          onSelect={() => handleSelectTab(key)}
        />
      ))}

      {sections.length < TABS_LIMIT && (
        <li
          className={classes.addSectionButton}
          data-cy="add-status-section"
          onClick={handleAddSection}
          aria-label="add a status section"
        >
          <div className={classes.prefixIconWrapper}>
            <Icon name="CirclePlus" size={20} color={theme.grey1} />
          </div>
          <span className={classes.addSectionBtnText}>add section</span>
        </li>
      )}
    </>
  );
};

export default memo(TabsList);
