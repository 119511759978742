import { string, boolean, object, infer as InferType } from 'zod';
import {
  PresetTemplate,
  actionPresetInputSchema,
  mergeFormSchemas,
  mergeEffects,
  StatementWithPreset,
  mergeTransitions
} from 'utils/presets';
import {
  Statement,
  StatementType,
  ResolvedExpressionType
} from '@stratumn/dsl';

import addComments from '../comment/addComments';

const cancelInputSchema = object({
  warning: string(),
  enableComments: boolean()
}).merge(actionPresetInputSchema);

export type CancelInput = InferType<typeof cancelInputSchema>;

const baseEffects: Statement[] = [
  {
    $statement: StatementType.Comment,
    comments: ['set cancellation date in the state']
  },
  {
    $statement: StatementType.SetVariable,
    path: 'state.data.cancellationDate',
    value: {
      $expression: ResolvedExpressionType.Variable,
      query: 'meta.createdAt'
    }
  }
];

const cancelPreset: PresetTemplate<CancelInput> = {
  key: 'cancel',
  name: 'Cancel action',
  schema: cancelInputSchema,
  defaultValues: {
    action: {
      title: 'Cancel'
    },
    enableComments: false,
    warning:
      'You are about to cancel a trace. After you cancel this trace, there will be no action possible on this trace.'
  },
  generateAction: (action, { key, input }) => {
    const effects: StatementWithPreset[] = baseEffects.map(statement => ({
      ...statement,
      $preset: key
    }));

    if (input.enableComments) {
      effects.push({
        $preset: key,
        $statement: StatementType.FunctionCall,
        function: { $ref: 'addComments' } as any
      });
    }

    return {
      ...action,
      key,
      title: input.action.title,
      stageName: input.action.title,
      icon: input.action?.icon || action?.icon,
      form: mergeFormSchemas(action?.form, {
        schema: {
          type: 'object',
          properties: {
            dummy: {
              type: 'null',
              title: input.warning
            }
          }
        },
        uiSchema: {}
      }),
      effects: mergeEffects(action?.effects, effects)
    };
  },
  alterConfig: (config, { key, input }) => {
    if (!config.definitions) config.definitions = {};

    config.transitions = mergeTransitions(config.transitions, {
      // Allow cancelling from any action
      '*': {
        [key]: [
          // This only adds the toAction to the transition management UI
          // so the user can fill it manually
        ]
      },
      // Disallow any action after a cancelling action
      [key]: {}
    });

    if (!config.definitions.addComments && input.enableComments) {
      config.definitions.addComments = addComments;
    }
    return config;
  }
};

export default cancelPreset;
