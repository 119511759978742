import React, { FC, useCallback } from 'react';
import moment from 'moment';

import { Switch, Check, Icon } from '@stratumn/atomic';
import { useToggle } from 'utils/hooks';

import { ExportConfigModal } from './export/exportModal';

import {
  ReducerAction,
  State,
  UPDATE_DRAFT_STATUS,
  UPDATE_AUTOMATION_STATUS,
  UPDATE_WORKFLOW_NAME,
  UPDATE_WORKFLOW_DESCRIPTION
} from '../../reducers';

import { EditableText } from './editableText';

import { IconButtonTooltip } from '../../ui';

import useStyles from './workflowInfo.style';

interface Props {
  data: State;
  dispatch: React.Dispatch<ReducerAction>;
}

export const WorkflowInfo: FC<Props> = ({ data, dispatch }) => {
  const classes = useStyles();

  const {
    rowId,
    name,
    description,
    draft,
    config,
    settings,
    owner,
    createdBy,
    updatedAt,
    updatedBy
  } = data.workflow;
  const disableDraftToggle = !data.initialData.draft;

  const [showExportModal, toggleExportModal] = useToggle(false);

  const onToggleDraft = useCallback((): void => {
    dispatch({ type: UPDATE_DRAFT_STATUS, value: !draft });
  }, [dispatch, draft]);

  const onToggleAutomation = useCallback((): void => {
    dispatch({
      type: UPDATE_AUTOMATION_STATUS,
      value: !config.allowAutomation
    });
  }, [dispatch, config]);

  const onSubmitWorkflowName = useCallback(
    (value: string): void => {
      dispatch({ type: UPDATE_WORKFLOW_NAME, data: value || name });
    },
    [dispatch, name]
  );

  const onSubmitWorkflowDescription = useCallback(
    (value: string): void => {
      dispatch({
        type: UPDATE_WORKFLOW_DESCRIPTION,
        data: value || description
      });
    },
    [description, dispatch]
  );

  return (
    <div className={classes.root}>
      <div className={classes.editableTextWrapper}>
        <EditableText
          inputClassName={classes.nameInput}
          inputValue={name}
          onSubmit={onSubmitWorkflowName}
        />
        <EditableText
          inputClassName={classes.descriptionInput}
          inputValue={description}
          onSubmit={onSubmitWorkflowDescription}
        />
      </div>
      <div className={classes.ctaButtonsWrapper}>
        <div className={classes.draftSwitchWrapper}>
          <span className={classes.switchLabel} data-is-disabled={!draft}>
            Draft
          </span>
          <Switch
            label="live"
            on={!draft}
            disabled={disableDraftToggle}
            handleChange={onToggleDraft}
          />
          <span className={classes.switchLabel} data-is-disabled={draft}>
            Live
          </span>
        </div>
        <div className={classes.automationCheck}>
          <Check
            label="automation"
            checked={config.allowAutomation}
            handleChange={onToggleAutomation}
          />
          <label className={classes.automationCheckLabel}>
            Enable automation
          </label>
        </div>
        <div className={classes.barSeparator} />
        <div className={classes.exportButton}>
          <IconButtonTooltip
            dataCy="export-workflow-config"
            iconName="Download"
            iconSize={35}
            tooltipMessage="Export workflow"
            onClick={toggleExportModal}
          />
        </div>
        {/** TODO: Update/refresh feature */}
        {false && (
          <div className={classes.updateIcon}>
            <IconButtonTooltip
              dataCy="import-workflow-update"
              iconName="Update"
              iconSize={35}
              tooltipMessage="Import workflow update"
            />
          </div>
        )}
      </div>

      <div className={classes.bottomRow}>
        <div className={classes.miscellaneous}>
          <div className={classes.workflowId}>Workflow ID: #{rowId} </div>
          {settings?.key && (
            <div className={classes.iconBottomRowRoot}>
              <div className={classes.prefixIcon}>
                <IconButtonTooltip
                  dataCy="trace-prefix"
                  iconName="TagFill"
                  iconSize={15}
                  tooltipMessage="Trace prefix"
                />
              </div>
              <span className={classes.iconText}>{settings?.key}</span>
            </div>
          )}

          <div className={classes.iconBottomRowRoot}>
            <IconButtonTooltip
              dataCy="owning-organization"
              iconName="HouseFill"
              iconSize={15}
              tooltipMessage="Owning organization"
            />
            <span className={classes.iconText}>
              {owner?.organization?.name}
            </span>
          </div>

          {createdBy && (
            <div className={classes.iconBottomRowRoot}>
              <IconButtonTooltip
                dataCy="workflow-creator"
                iconName="PersonFill"
                iconSize={15}
                tooltipMessage="Workflow creator"
              />
              <span className={classes.iconText}>{createdBy.user?.name}</span>
            </div>
          )}

          {updatedAt && updatedBy && (
            <div className={classes.lastUpdate}>
              <div className={classes.lastUpdateIcon}>
                <Icon dataCy="last-update" name="Clock" size={15} />
              </div>
              <span
                className={classes.lastUpdateText}
              >{`Last update on ${moment(updatedAt).format(
                'dddd, DD MMMM YYYY'
              )} by ${updatedBy.user?.name}`}</span>
            </div>
          )}
        </div>
      </div>

      {showExportModal && (
        <ExportConfigModal data={data} close={toggleExportModal} />
      )}
    </div>
  );
};
export default WorkflowInfo;
