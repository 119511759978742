import { PropertyDefinition, OptionsList, InputType } from '../../types';
import { getPropertyKey } from '../../utils';

// check if an object only has undefined values
// used to cleanup uiSchema stuff
export const isObjectEmpty = (obj?: { [k: string]: any }): boolean =>
  !obj ||
  Object.values(obj).reduce(
    (prev: boolean, value: any) => prev && value === undefined,
    true
  );

// get the property key in jsonschema from the ui property definition
export const getPropertyKeyFromDefinition = (
  property: PropertyDefinition
): string => {
  const { key, label, propertyKey, input } = property;
  // build the prop key form label (or shortid if label not set)
  return getPropertyKey(
    key,
    label,
    propertyKey,
    input?.type === InputType.Helper
  );
};

// return the list of non  null OptionsList 'values'
export const getNonNullOptionsListValues = (
  optionsList: OptionsList
): string[] =>
  optionsList.reduce<string[]>((currentOptions, { value }) => {
    if (value) currentOptions.push(value);
    return currentOptions;
  }, []);
