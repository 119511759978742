import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 300
  }
}));
