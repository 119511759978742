import { memo, useCallback, useContext } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Pushbutton, Icon, InfoTooltip, Pushtext } from '@stratumn/atomic';

import { DataContext, useToggle } from 'utils/hooks';
import getRandomId from 'utils/id';

import Panel from 'components/workflow/ui/panel';

import { Action, WorkflowSchema, ActionFieldType } from '../../../types';

import FieldList from '../fields/list';
import useStyles from './form.style';

export interface Props {
  schema: WorkflowSchema;
  action?: Partial<Action>;
}

const infoTooltipPosition = {
  place: 'below',
  placeShift: 3,
  adjustPlace: true
};

export const Form = ({ schema, action }: Props) => {
  const classes = useStyles();
  const { append } = useContext(DataContext);
  const [showKeys, toggleShowKeys] = useToggle(false);

  const addActionField = useCallback(() => {
    const id = getRandomId();
    append('fields', { id });
  }, [append]);

  const addExistingField = useCallback(() => {
    const id = getRandomId();
    append('fields', { id, type: ActionFieldType.Existing });
  }, [append]);

  return (
    <form>
      <Panel
        title={
          <>
            Form builder
            <div className={classes.showKeysWrapper}>
              <InfoTooltip
                text="**Caution**: This will reveal editable keys that will have a direct impact on all the admin process inputs that are related to the edited keys."
                position={infoTooltipPosition}
                textAlign="center"
                padding={5}
              >
                <Pushtext
                  dataCy="toggle-properties-keys"
                  onClick={toggleShowKeys}
                >
                  {!showKeys ? 'Reveal field keys' : 'Hide field keys'}
                </Pushtext>
              </InfoTooltip>
            </div>
          </>
        }
      >
        {action?.fields && action.fields.length > 0 && (
          <div className={classes.formBody}>
            <Droppable droppableId="fields">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <FieldList
                    fields={action.fields || []}
                    schema={schema}
                    showFieldKeys={showKeys}
                    path="fields"
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
        <div
          className={classes.formFooter}
          data-has-fields={action?.fields && action.fields.length > 0}
        >
          <div className={classes.addActionFieldButton}>
            <Pushbutton
              onClick={addActionField}
              prefix={<Icon name="CirclePlus" size={18} />}
              dataCy="add-action-field"
            >
              Add new field
            </Pushbutton>
          </div>

          <Pushbutton
            onClick={addExistingField}
            prefix={<Icon name="DuplicateFill" size={18} />}
            dataCy="add-existing-field"
          >
            Add existing field
          </Pushbutton>
        </div>
      </Panel>
    </form>
  );
};

export default memo(Form);
