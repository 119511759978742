import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  addSectionButton: {
    display: 'flex',
    height: 64,
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: '100%',
    padding: '22px 11px',
    border: '1px solid transparent',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    transition: '200ms ease-in-out',
    '&:hover': {
      border: `1px dashed ${theme.grey5}`,
      backgroundColor: theme.grey7,
      cursor: 'pointer'
    }
  },
  addSectionBtnText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    color: theme.grey1
  },
  prefixIconWrapper: {
    display: 'flex',
    marginRight: 10
  }
}));
