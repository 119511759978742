import React from 'react';
import injectSheet from 'react-jss';
import qs from 'query-string';
import { IdentityLogomark, Pushbutton } from '@stratumn/atomic';

import deepGet from 'lodash.get';
import { ACCOUNT_LOGIN_URL, CLIENT_ID } from 'constant/api';
import logo from 'assets/IndigoTrace_StackLogo.svg';
import { logout } from 'client/account';

import styles from './login.style';

interface Props {
  classes: any;
}

export class Login extends React.Component<Props> {
  getRedirectUri = () => {
    const redirect = deepGet(this.props, 'location.state.redirect');
    return `${window.location.origin}${redirect || ''}`;
  };
  login = async () => {
    // start by logging out the current user to clean the cookies
    await logout();

    const params = qs.stringify({
      clientId: CLIENT_ID,
      redirectUri: this.getRedirectUri()
    });
    window.location.replace(`${ACCOUNT_LOGIN_URL}?${params}`);
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.loginBG} role="none" />
        <div className={classes.container}>
          <div className={classes.topContainer}>
            <img className={classes.logo} src={logo} alt="" />
            <div className={classes.punchline}>
              The new backbone to business collaboration
            </div>
            <div className={classes.loginContainer}>
              <div className={classes.loginTitle}>
                Sign in with Stratumn Account
              </div>
              <Pushbutton primary onClick={this.login} data-cy="loginButton">
                <div className={classes.buttonWrapper}>
                  <IdentityLogomark className={classes.accountLogo1} />
                  Sign in
                </div>
              </Pushbutton>
            </div>
          </div>
          <div className={classes.footer}>
            Built by{' '}
            <a
              href="https://stratumn.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stratumn
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectSheet(styles)(Login);
