const idWithType = (typename: string, id: string) => `${typename}:${id}`;

export const dataIdFromObject = (obj: any) => {
  if (!obj.__typename) {
    return null;
  }

  // If the object is a paginated list, do not cache it
  if (obj.__typename.endsWith('Connection')) return null;
  if (obj.__typename === 'PageInfo') return null;

  // Use linkHash as key for links
  if (obj.__typename === 'Link') {
    if (!obj.linkHash) {
      console.warn('Missing linkHash for Link', obj);
    }
    return obj.linkHash;
  }

  if (obj.__typename === 'Evidence') {
    if (!obj.linkHash) {
      console.warn('Missing linkHash for Evidence', obj);
    }
    return idWithType(obj.__typename, obj.linkHash);
  }

  // Use action key for Actions
  if (obj.__typename === 'Action') {
    if (!obj.key) {
      console.warn('Missing key for Action', obj);
    }
    return idWithType(obj.__typename, obj.key);
  }

  // Use priority id for Priority
  if (obj.__typename === 'Priority') {
    if (!obj.id) {
      console.warn('Missing id for Priority', obj);
    }
    return idWithType(obj.__typename, obj.id);
  }

  if (obj.__typename === 'DeleteWorkflowPayload')
    return idWithType(obj.typename, obj.deletedWorkflowRowId);

  // If the object does not have an ID, do not cache it.
  if (!obj.rowId) {
    console.warn('Missing rowId for obj', obj);
    return null;
  }

  return idWithType(obj.__typename, obj.rowId);
};
