import React from 'react';

export { default as Root } from './root';
export { default as PrivateRoute } from './privateRoute';
export { default as Login } from './login';
export { default as ErrorBoundary } from './errorBoundary';
export { default as OAuth } from './oauth';
export { default as Home } from './home';
export { default as Workflows } from './workflows';
export { default as Workflow } from './workflow';
export { default as WorkflowDetails } from './workflow';
export { default as JsonEditor } from './jsonEditor';
export { default as Documentation } from './documentation';
export { default as Organizations } from './organizations';
export { default as Effects } from './effects';
export { default as Tray } from './tray';
export { default as TrayPortal } from './trayPortal';
export { default as NewWorkflow } from './newWorkflow';
export { default as Transitions } from './workflow/transitions';
export { default as FormBuilderInt } from './formBuilderInt';

// Lazy load DemoWorkflows to split the bundle.
// DemoWorkflows imports trace-generator, which is pretty big
export const DemoWorkflows = React.lazy(() => import('./demoWorkflows'));
