import { FormDefinition, InputType, PropertyDefinition } from '../../types';
import { getPropertyKey } from '../../utils';

// recursive function populating keys of a properties list
const populatePropertyKeys = (properties: PropertyDefinition[]) => {
  properties.forEach(property => {
    const { key, label, propertyKey, input } = property;
    if (!propertyKey) {
      property.propertyKey = getPropertyKey(key, label);
    }
    if (input?.type === InputType.SubForm && input.properties) {
      populatePropertyKeys(input.properties);
    }
    if (input?.type === InputType.List && input.itemsProperties) {
      populatePropertyKeys(input.itemsProperties);
    }
  });
};

// main export function populates a form's properties
export default (form: FormDefinition) => {
  populatePropertyKeys(form.properties);
};
