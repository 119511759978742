import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  modalContent: {
    marginTop: 30,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '15px',
    color: theme.cautionOrange,
    '& > span': {
      fontWeight: 600
    }
  }
}));
