export default [
  'IdLight',
  'CircleDownload',
  'CircleArrowDown',
  'CircleDoc',
  'CircleArrowUp',
  'CircleGraph',
  'CircleInfo',
  'CircleActions',
  'CircleLink',
  'CircleLocation',
  'CirclePlus',
  'CircleProfile',
  'CircleStar',
  'CircleSetting',
  'CircleSearch',
  'CircleTick',
  'CircleStop',
  'CircleCross',
  'DocDownload',
  'DocDuplicate',
  'Doc',
  'DocEye',
  'DocGraph',
  'DocInfo',
  'DocKey',
  'DocLink',
  'DocLocation',
  'DocMultiply',
  'DocPadlock',
  'DocPadlockOpen',
  'DocPen',
  'DocSearch',
  'DocTick',
  'DocUpload',
  'DocPlus',
  'Actions',
  'Board',
  'Calendar',
  'Download',
  'Review',
  'Speech',
  'ReplaceFile',
  'Meeting',
  'Group',
  'Condition',
  'Trash'
];
