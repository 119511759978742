import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  footerRoot: {
    padding: '10px 0 21px 20px',
    paddingBottom: 21,
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    backgroundColor: theme.grey8,
    width: '100%'
  },
  warningWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  warningContent: {
    marginLeft: 3,
    fontWeight: 'normal',
    color: theme.grey1,
    fontSize: 14,
    lineHeight: '18px'
  },
  button: {
    marginRight: 10
  }
}));
