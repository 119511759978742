import { FC, memo } from 'react';

import useStyles from './trayPortal.style';

export const TRAY_PORTAL_RIGHT = 'tray-portal-right';

export const TrayPortal: FC = () => {
  const classes = useStyles();
  return <div id={TRAY_PORTAL_RIGHT} className={classes.trayPortalContainer} />;
};

export default memo(TrayPortal);
