import React, { ChangeEvent, useCallback } from 'react';
import { useField } from 'formik';

import { FieldTextCompact } from '@stratumn/atomic';

import useStyles from './textField.style';

interface TextFieldProps {
  inline?: boolean;
  name: string;
  label: string;
}

export const FormikTextField: React.FC<TextFieldProps> = props => {
  const classes = useStyles();
  const [field, meta, helpers] = useField(props.name);

  const onChange = useCallback(
    (e: ChangeEvent<any>) => {
      helpers.setValue(e.currentTarget.value);
    },
    [helpers]
  );

  return (
    <div className={classes.container} data-inline={props.inline}>
      <FieldTextCompact {...field} {...props} onValueChange={onChange} />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};
