import React, { FC, useCallback } from 'react';
import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton
} from '@stratumn/atomic';
import { State } from 'components/workflow/reducers';

import getExportFile from 'utils/export/getFile';
import download from 'utils/download';

interface Props {
  data: State;
  close: () => void;
}

export const ExportConfigModal: FC<Props> = ({ data, close }) => {
  const handleExport = useCallback(() => {
    download(
      // Sanitize the workflow name just in case
      `${data.initialData.name.replace(/[^A-z0-9À-ž\s]+/g, '_')}.v${
        data.initialData.config.version
      }.json`,
      getExportFile(data)
    );
    close();
  }, [data, close]);

  return (
    <Modal
      title={`Export workflow #${data.workflow.rowId}`}
      closeButtonLabel="Cancel"
      handleCollapse={close}
    >
      <ModalContent>
        <p>
          {"You're about to export the workflow "}
          <b>
            {data.workflow.name} (#{data.workflow.rowId}).
          </b>
          {!!data.isDirty && (
            <div>
              These will <b>not contain your currently pending changes</b> to
              the workflow.
            </div>
          )}
        </p>
        <p>Do you confirm?</p>
      </ModalContent>

      <ModalActions>
        <Pushbutton onClick={close} dataCy="cancel-button">
          Cancel
        </Pushbutton>
        <Pushbutton primary onClick={handleExport} dataCy="export-button">
          Export workflow #{data.workflow.rowId}
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};
