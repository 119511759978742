import { FC, ReactNode, memo, useCallback, useMemo, useContext } from 'react';

import { useToggle, DataContext } from 'utils/hooks';

import {
  FieldTextCompact,
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  SmartDropdown
} from '@stratumn/atomic';

import { JsonEditor } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  IconButton
} from 'components/workflow/ui';
import { getTraceStateFieldsUtils } from 'schemaBuilder/converter/admin';

import { Widget as WidgetDefinition } from '../types';
import useStyles from './widgetCard.style';
import { DisplayContext } from '../../context';

export interface Props {
  index: number;
  path: string;
  value: WidgetDefinition;
  settings?: boolean;
  optionsTypes: String[];
  dataTab?: boolean;
}

export const WidgetCard: FC<Props> = props => {
  const classes = useStyles();

  const { index, path, value, dataTab = false } = props;

  const { path: widgetPath = '', label: widgetLabel, type } = value;

  const { traceFieldOptions } = useContext(DisplayContext);

  const {
    getIdFromPath,
    getTypes,
    getType,
    getPath,
    getOption
  } = getTraceStateFieldsUtils('traceInfo');

  const fieldKey = getIdFromPath(widgetPath);

  const availablesTypes = useMemo(() => {
    return getTypes(traceFieldOptions, fieldKey);
  }, [traceFieldOptions, fieldKey, getTypes]);

  const { set, delete: delData } = useContext(DataContext);

  const [showCustomCodeModal, setShowCustomCodeModal] = useToggle(false);

  const handleUpdateWidget = useCallback(
    (field: string, value: any): void =>
      set(`${path}.${field}`, value || undefined),
    [set, path]
  );

  const handleRemoveWidget = useCallback((): void => delData(path), [
    path,
    delData
  ]);

  const handleShowCustomCodeModal = () => setShowCustomCodeModal();

  // TODO: make this method functional
  const handleSubmitCustomCode = useCallback(
    (): void => setShowCustomCodeModal(),
    [setShowCustomCodeModal]
  );

  // TODO: make the JsonEditor functional
  const renderCustomCodeModal = useMemo(
    (): ReactNode => (
      <Modal title="Custom code widget" handleCollapse={setShowCustomCodeModal}>
        <ModalContent>
          <JsonEditor jsonString="" />
        </ModalContent>
        <ModalActions
          adverseAction={
            <Pushbutton onClick={setShowCustomCodeModal}>cancel</Pushbutton>
          }
        >
          <Pushbutton primary onClick={handleSubmitCustomCode}>
            save changes
          </Pushbutton>
        </ModalActions>
      </Modal>
    ),
    [handleSubmitCustomCode, setShowCustomCodeModal]
  );

  // TODO: Plug in to presets' options.
  // The current optionsTypes are only for testing the logic of the component
  const renderImportAction = useMemo(
    (): ReactNode => (
      <Card>
        <CardContent>
          <SmartDropdown
            compact
            label="Format"
            value={type}
            options={availablesTypes!.map(value => ({
              label: value,
              value
            }))}
            onSelect={selected => handleUpdateWidget('type', selected)}
            dataCy="select-widget-type"
            disabled={!fieldKey || availablesTypes.length === 1}
          />
        </CardContent>

        <CardActions>
          <IconButton
            name="Trash"
            onClick={handleRemoveWidget}
            ariaLabel="Remove widget"
          />
        </CardActions>
      </Card>
    ),
    [type, availablesTypes, fieldKey, handleRemoveWidget, handleUpdateWidget]
  );

  const onSelectColumn = (option: any) => {
    if (option) {
      handleUpdateWidget('label', option.label);
      const type = getType(option);
      handleUpdateWidget('type', getType(option));
      handleUpdateWidget('path', getPath(option, type));
    } else {
      handleUpdateWidget('label', '');
      handleUpdateWidget('type', '');
      handleUpdateWidget('path', '');
    }
  };

  const onSelectType = type => {
    const option = getOption(traceFieldOptions, fieldKey);
    if (option) {
      handleUpdateWidget('type', type);
      handleUpdateWidget('path', getPath(option, type));
    }
  };

  return (
    <>
      {dataTab ? (
        renderImportAction
      ) : (
        <Card>
          <CardContent>
            <div className={classes.row}>
              {/** as index starts at 0, we increment by one for display purposes */}
              <span className={classes.index}>{index + 1}</span>

              <div className={classes.fieldTextCompactWrapper}>
                <SmartDropdown
                  compact
                  label="Trace state field"
                  placeholder="Search for a trace state field"
                  value={fieldKey}
                  options={traceFieldOptions?.map(t => ({
                    label: t.label,
                    value: t.key
                  }))}
                  onSelect={selected => {
                    onSelectColumn(getOption(traceFieldOptions, selected));
                  }}
                  dataCy="edit-widget-path"
                />
              </div>

              <div className={classes.fieldTextCompactWrapper}>
                <FieldTextCompact
                  dataCy="edit-widget-label"
                  onValueChange={e =>
                    handleUpdateWidget('label', e.target.value)
                  }
                  value={widgetLabel}
                  label="Widget label"
                  disabled={!fieldKey}
                />
              </div>

              <div className={classes.dropdownWrapper}>
                <SmartDropdown
                  compact
                  label="Format"
                  value={type}
                  options={availablesTypes!.map(value => ({
                    label: value,
                    value
                  }))}
                  onSelect={onSelectType}
                  dataCy="select-widget-type"
                  disabled={!fieldKey || availablesTypes.length === 1}
                />
              </div>
            </div>
          </CardContent>

          <CardActions>
            <IconButton
              dataCy="toggle-custom-code-modal"
              name="Cog"
              onClick={handleShowCustomCodeModal}
              ariaLabel="Show custom code modal"
            />

            <IconButton
              dataCy="remove-widget"
              name="Trash"
              onClick={handleRemoveWidget}
              ariaLabel="Remove widget"
            />
          </CardActions>
        </Card>
      )}

      {showCustomCodeModal && renderCustomCodeModal}
    </>
  );
};

export default memo(WidgetCard);
