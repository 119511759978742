import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  condition: {
    fonttyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey5,
    textTransform: 'uppercase',
    marginRight: 20
  },
  fieldsWrapper: {
    display: 'flex',
    width: '100%',
    height: 36,
    justifyContent: 'flex-start'
  },
  inputStateValueWrapper: {
    display: 'flex',
    minWidth: 190,
    width: '29%',
    marginRight: '1%'
  },
  selectConditionWrapper: {
    display: 'flex',
    minWidth: 199,
    width: '30%',
    marginRight: '1%'
  },
  inputValueWrapper: {
    display: 'flex',
    minWidth: 100,
    width: '38%',
    marginRight: '1%'
  }
}));
