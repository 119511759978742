import { FC, memo, useCallback, useContext } from 'react';

import {
  FieldSelectCompact,
  OptionDrop,
  FieldTextCompact
} from '@stratumn/atomic';

import { DataContext } from 'utils/hooks';

import { EventChangeHandler } from 'utils/types';
import {
  Condition as ConditionType,
  ConditionEnums
} from 'components/workflow/transitions/types';

import { BTN_TYPE_REMOVE } from '../../../../utils/constants';
import { TransitionsContext } from '../../../../context';

import { ConditionButton } from '../ui';

import useStyles from './condition.style';

export enum DropdownOptions {
  Equal = 'Equals',
  GreaterThan = 'is higher than',
  LessThan = 'is lower than',
  IsTruthy = 'is true'
}

export enum DataCy {
  ChangeStateValue = 'change-state-value',
  SelectCondition = 'select-condition',
  ChangeValue = 'change-value'
}

interface Props {
  path: string;
  conditionSelected?: ConditionType;
}

export const Condition: FC<Props> = ({ path, conditionSelected = null }) => {
  const classes = useStyles();

  const { set, delete: deleteCondition } = useContext(DataContext);

  const { formIsValid } = useContext(TransitionsContext);

  const handleChangeTraceStateValue: EventChangeHandler = useCallback(
    e => set(`${path}.path`, e.target.value),
    [path, set]
  );

  const handleSelectConditionType: EventChangeHandler = useCallback(
    e => {
      const findTypeIndex = Object.values(DropdownOptions).findIndex(
        (value: string) => value === e.target?.value
      );
      set(`${path}.type`, Object.values(ConditionEnums)[findTypeIndex]);
    },
    [path, set]
  );

  const handleChangeConditionValue: EventChangeHandler = useCallback(
    e => set(`${path}.value`, e.target.value),
    [path, set]
  );

  const handleRemoveCondition = useCallback((): void => deleteCondition(path), [
    deleteCondition,
    path
  ]);

  const generatedStateValue: string = conditionSelected?.path
    ? String(conditionSelected?.path)
    : '';

  const generatedValue: string = conditionSelected?.value
    ? String(conditionSelected?.value)
    : '';

  return (
    <div className={classes.root}>
      <span className={classes.condition}>if</span>
      <div className={classes.fieldsWrapper}>
        <div className={classes.inputStateValueWrapper}>
          <FieldTextCompact
            dataCy={DataCy.ChangeStateValue}
            onValueChange={handleChangeTraceStateValue}
            value={generatedStateValue}
            label="Trace State Value"
            invalid={!generatedStateValue && !formIsValid}
          />
        </div>

        <div className={classes.selectConditionWrapper}>
          <FieldSelectCompact
            dataCy={DataCy.SelectCondition}
            label="Condition"
            onValueChange={handleSelectConditionType}
            value={conditionSelected?.type}
            invalid={!conditionSelected?.type && !formIsValid}
          >
            {Object.values(DropdownOptions).map((condition, index) => (
              <OptionDrop
                key={index}
                label={condition}
                value={condition}
                selected={
                  Object.values(ConditionEnums)[index] ===
                  conditionSelected?.type
                }
              />
            ))}
          </FieldSelectCompact>
        </div>
        {conditionSelected?.type !== ConditionEnums.IsTruthy && (
          <div className={classes.inputValueWrapper}>
            <FieldTextCompact
              dataCy={DataCy.ChangeValue}
              onValueChange={handleChangeConditionValue}
              value={generatedValue}
              label="Value"
              invalid={!generatedValue && !formIsValid}
            />
          </div>
        )}
      </div>

      <ConditionButton
        type={BTN_TYPE_REMOVE}
        label="Remove condition"
        onClick={handleRemoveCondition}
      />
    </div>
  );
};

export default memo(Condition);
