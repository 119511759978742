import { FC, ReactNode, memo, useMemo, useContext, useCallback } from 'react';

import generateId from 'utils/id';
import { DataContext } from 'utils/hooks';

import { Section } from 'components/workflow/ui';

import { WidgetTypesEnums, Section as SectionDefinition } from '../types';

import WidgetCard from '../widgetCard';

import useStyles from './genericSection.style';

export const defaultFormatOptions: Array<String> = Object.values(
  WidgetTypesEnums
);

interface Props {
  path: string;
  section: SectionDefinition;
  dragHandle?: ReactNode;
  isDragging?: boolean;
}

export const GenericSection: FC<Props> = ({
  path,
  section: {
    sectionKey,
    title,
    description,
    placeholder,
    items,
    sectionCollapse
  },
  dragHandle,
  isDragging
}) => {
  const classes = useStyles();

  const { append } = useContext(DataContext);

  const handleAddWidget = useCallback((): void => {
    append(`${path}.items`, {
      key: generateId()
    });
  }, [append, path]);

  const renderWidgetCards: ReactNode = useMemo(
    () =>
      items?.map((widgetDef, index) => (
        <li key={widgetDef.key} className={classes.widgetCard}>
          <WidgetCard
            index={index}
            path={`${path}.items.${index}`}
            value={widgetDef}
            optionsTypes={defaultFormatOptions}
            settings
          />
        </li>
      )),
    [classes.widgetCard, items, path]
  );

  return (
    <Section
      path={path}
      sectionKey={sectionKey}
      data-cy={title}
      title={title}
      description={description}
      placeholder={placeholder}
      addElementFooter={{
        label: 'Add widget',
        callback: handleAddWidget
      }}
      sectionCollapse={sectionCollapse}
      dragHandle={dragHandle}
      isDragging={isDragging}
    >
      {items && items.length > 0 && (
        <ul className={classes.widgetCardsWrapper}>{renderWidgetCards}</ul>
      )}
    </Section>
  );
};

export default memo(GenericSection);
