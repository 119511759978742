import { ReactElement } from 'react';
import { Statuses } from 'utils/interfaces/statuses';
import { Deadlines } from 'utils/interfaces/deadlines';

export enum ActionFieldType {
  // Reusing an existing schema field
  Existing = 'Existing',
  // Adding a new field to the schema
  New = 'New',
  // A form field that is not added to the schema
  Local = 'Local'
}

export type Theme = 'default' | 'embed';

export interface Action {
  key: string;
  preset: string;
  icon: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  fields: ActionField[];
}

export type WorkflowSchema = Record<string, ReusableSchemaField>;

export interface SchemaBuilderConfig {
  schema: WorkflowSchema;
  actions: Action[];
  statuses?: Statuses;
  deadlines?: Deadlines;
}

export enum SchemaFieldType {
  ShortText = 'ShortText',
  Boolean = 'Boolean',
  Date = 'Date',
  Groups = 'Groups',
  RichText = 'RichText',
  Textarea = 'Textarea',
  Dropdown = 'Dropdown',
  Number = 'Number',
  Checkboxes = 'Checkboxes',
  FileUpload = 'FileUpload',
  Helper = 'Helper',
  SubForm = 'SubForm',
  RichList = 'RichList',
  List = 'List',
  RadioButtons = 'RadioButtons'
}

export interface PropertyDefinition {
  key: string;
  propertyKey?: string;
  label?: string;
  isRequired?: boolean;
  input?: ActionField;
}

export type ReusableSchemaField =
  | ShortTextSchemaField
  | BooleanSchemaField
  | DateSchemaField
  | RichTextSchemaField
  | GroupsSchemaField
  | FileUploadSchemaField
  | TextareaSchemaField
  | RadioButtonsSchemaField
  | CheckboxesSchemaField
  | DropdownSchemaField
  | SubFormSchemaField
  | NumberSchemaField;

export type ActionField =
  | ExistingActionField
  | ShortTextActionField
  | RichTextActionField
  | TextareaActionField
  | BooleanActionField
  | DateActionField
  | HelperActionField
  | GroupsActionField
  | NumberActionField
  | CheckboxesActionField
  | RadioButtonsActionField
  | FileUploadActionField
  | SubFormActionField
  | DropdownActionField;

export interface BaseActionField {
  schemaField?: any;
  // Universally unique (nanoid), static identifier
  // Useful as React array keys, but maybe there's a use beyond that
  id: string;
  // Form-specific configuration
  required: boolean;
}
export interface BaseSchemaField {
  // Universally unique (nanoid), static identifier
  // This should be the same as the action field ID,
  // so if schema fields with the same key but with different ids
  // exist, there's a key conflict between them.
  id: string;
  // Unique (within a workflow) but dynamic identifier
  // This is the key that references should use
  key: string;
  // If a key doesn't exist yet (i.e. it's a new field) and no custom key has been defined,
  // this key is the one automatically generated from label camelisation.
  // It should get saved as the actual key upon action submission.
  provisionalKey?: string;
}

export type Option = { key: string; value: string };

export interface ExistingActionField extends BaseActionField {
  type: ActionFieldType.Existing;
  ref: string | null; // The key of the schema field it refers to
  label?: string; // Label override for that specific action
}

export interface ShortTextSchemaField extends BaseSchemaField {
  type: SchemaFieldType.ShortText;
  label: string;
  placeholder: string;
}
export interface ShortTextActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: ShortTextSchemaField;
}

export interface BooleanSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Boolean;
  label: string;
  enumNames: string[];
}
export interface BooleanActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: BooleanSchemaField;
}

export interface DateSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Date;
  label: string;
}
export interface DateActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: DateSchemaField;
}
export interface CheckboxesSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Checkboxes;
  label: string;
  enumNames: Option[];
}
export interface CheckboxesActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: CheckboxesSchemaField;
}

export interface GroupsSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Groups;
  label: string;
}
export interface GroupsActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: GroupsSchemaField;
}

export interface RichTextSchemaField extends BaseSchemaField {
  type: SchemaFieldType.RichText;
  label: string;
  placeholder: string;
}
export interface RichTextActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: RichTextSchemaField;
}

export interface TextareaSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Textarea;
  label: string;
  placeholder: string;
}
export interface TextareaActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: TextareaSchemaField;
}

export interface DropdownSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Dropdown;
  label: string;
  enumNames: Option[];
}
export interface DropdownActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: DropdownSchemaField;
}

export interface NumberSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Number;
  label: string;
}
export interface NumberActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: NumberSchemaField;
}

export interface SubFormSchemaField extends BaseSchemaField {
  type: SchemaFieldType.SubForm;
  label: string;
  properties: BaseActionField[];
}
export interface SubFormActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: SubFormSchemaField;
}

export interface ListSchemaField extends BaseSchemaField {
  type: SchemaFieldType.List;
  label: string;
  property: ActionField;
}
export interface ListActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: ListSchemaField;
}

export interface FileUploadSchemaField extends BaseSchemaField {
  type: SchemaFieldType.FileUpload;
  label: string;
  enableMultipleFiles: boolean;
}
export interface FileUploadActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: FileUploadSchemaField;
}

export interface HelperSchemaField extends BaseSchemaField {
  type: SchemaFieldType.Helper;
  helper: string;
}
export interface HelperActionField extends BaseActionField {
  type: ActionFieldType.Local;
  schemaField: HelperSchemaField;
}

export interface RadioButtonsSchemaField extends BaseSchemaField {
  type: SchemaFieldType.RadioButtons;
  label: string;
  enumNames: { key: string; value: string }[];
}
export interface RadioButtonsActionField extends BaseActionField {
  type: ActionFieldType.New | ActionFieldType.Local;
  schemaField: RadioButtonsSchemaField;
}
export interface BaseTypeProps {
  isDragging: boolean;
  dragElement: ReactElement;
  removeElement: ReactElement;
  typeSelectElement: ReactElement;
  fieldKeyElement: ReactElement;
  path: string;
  theme?: Theme;
  readonly?: boolean;
}
export interface BaseTypeBodyProps {
  readonly?: boolean;
  path: string;
  theme?: Theme;
}
