import { useState, useCallback, useRef } from 'react';

type TimeoutType = ReturnType<typeof setTimeout> | null;

// this hook provides a simple input state updated with the content of an event.target.value
// also, a callback function call can be debounced after the state update
export default (
  initInput: any,
  callbackFn?: (newInputValue: any) => void,
  callbackDelay?: number
) => {
  // State and setter for input
  const [input, setInput] = useState(initInput);

  // callback
  const timeoutRef = useRef<TimeoutType>(null);
  const update = useCallback(
    e => {
      const newInputValue = e.target.value;

      // set the state new value
      setInput(newInputValue);

      // if a callback has been provided, call it after a potential delay
      if (callbackFn) {
        if (!callbackDelay) {
          callbackFn(newInputValue);
          return;
        }

        // Cancel the previous timeout if already set
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }

        // then call callback fn after a delay, unless something is changed again
        timeoutRef.current = setTimeout(() => {
          callbackFn(newInputValue);
          clearTimeout(timeoutRef.current as any);
          timeoutRef.current = null;
        }, callbackDelay);
      }
    },
    [callbackFn, callbackDelay]
  );

  return [input, update];
};
