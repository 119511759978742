import { gql } from '@apollo/client';

export const FRAGMENT = gql`
  fragment workflowDetails on Workflow {
    rowId
    name
    description
    settings
    draft
    config {
      rowId
      version
      stateSchema
      initState
      initActions
      actions: rawActions
      info: rawInfo
      overview: rawOverview
      definitions
      transitions
      allowAutomation
      admin
    }
    traces {
      totalCount
    }
    groups {
      totalCount
      nodes {
        label
        rowId
        name
        avatar
      }
    }
    owner {
      rowId
      organization {
        rowId
        name
      }
    }
    createdBy {
      rowId
      user {
        rowId
        name
      }
    }
    updatedAt
    updatedBy {
      rowId
      user {
        rowId
        name
      }
    }
  }
`;
