import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  primaryTitle: {
    color: theme.grey3,
    fontSize: 24,
    lineHeight: '30px',
    textTransform: 'uppercase',
    userSelect: 'none'
  },
  secondaryTitle: {
    color: theme.grey1,
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'capitalize',
    userSelect: 'none'
  }
}));
