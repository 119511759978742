import { ResolvedExpressionType, SortDirection } from '@stratumn/dsl';
import { WorkflowTransitions } from 'utils/trace';
import { State } from '../../reducers';
import {
  FromActionDefinition,
  Transition,
  TransitionGroup,
  Condition,
  ConditionEnums
} from '../types';

enum GeneralActionsKey {
  AllActions = '*',
  StartingPoint = '-'
}

enum GeneralActionsTitle {
  AllActions = 'All actions',
  StartingPoint = 'Starting point'
}

// parse a condition according to its expression
export const parseCondition = (c: any): Condition => {
  const { $expression, query, arguments: args, direction } = c;
  let condition;

  switch ($expression) {
    case ResolvedExpressionType.Variable:
      condition = {
        type: ConditionEnums.IsTruthy,
        path: query
      };
      break;

    case ResolvedExpressionType.Equal:
      condition = {
        type: ConditionEnums.Equal,
        path: args[0].query,
        value: args[1]
      };
      break;

    case ResolvedExpressionType.Sorted:
      const conditionEnum =
        direction === SortDirection.Descending
          ? ConditionEnums.LessThan
          : ConditionEnums.GreaterThan;

      condition = {
        type: conditionEnum,
        path: args[0].query,
        value: args[1]
      };
      break;

    default:
  }

  return condition;
};

const parseGroups = (
  transitions: { groups: any; condition: Condition }[]
): TransitionGroup[] => {
  const parsedGroups: TransitionGroup[] = [];

  transitions.forEach(({ groups, condition }) => {
    // This is to safe guard groups: '*'
    if (typeof groups !== 'string') {
      groups.forEach(label => {
        if (condition)
          return parsedGroups.push({
            label,
            condition: parseCondition(condition)
          });

        return parsedGroups.push({ label });
      });
      return parsedGroups;
    }
    if (condition)
      return parsedGroups.push({
        label: groups,
        condition: parseCondition(condition)
      });

    return parsedGroups.push({ label: groups });
  });

  return parsedGroups;
};

export const parseTransition = (
  allTransitions: WorkflowTransitions | null,
  actionKey: string,
  actionTitle: string
) => {
  const transitionsArr: Transition[] = [];

  if (allTransitions && allTransitions![actionKey]) {
    // If a transition has not been flagged transitive
    if (allTransitions![actionKey] !== 'keep') {
      Object.keys(allTransitions![actionKey]).forEach(toActionKey => {
        const groups = parseGroups(allTransitions![actionKey][toActionKey]);

        transitionsArr.push({
          groups,
          key: toActionKey,
          groupsCollapsed: false
        });
        return transitionsArr;
      });
    } else {
      transitionsArr.push({
        key: 'keep'
      });
    }
  }

  return {
    actionKey,
    actionTitle,
    nextActions: transitionsArr,
    transitionCollapsed: false
  };
};

export const buildTransitionsArr = (state: State): FromActionDefinition[] => {
  const { actions, transitions = {} } = state?.workflow?.config || {};
  // initialize the next actions array manipulated within the NextActions' module
  const buildTransitionsArr: FromActionDefinition[] = [];

  /**
   * Push the general actions (all actions, starting point) at the beginning of the transitions array
   */
  buildTransitionsArr.push(
    parseTransition(
      transitions,
      GeneralActionsKey.AllActions,
      GeneralActionsTitle.AllActions
    ),
    parseTransition(
      transitions,
      GeneralActionsKey.StartingPoint,
      GeneralActionsTitle.StartingPoint
    )
  );

  // Since general actions such as - and * are not part of actions, we iterate separately on actions.
  Object.values(actions).forEach(action =>
    buildTransitionsArr.push(
      parseTransition(transitions, action.key, action.title)
    )
  );
  return buildTransitionsArr;
};
