import { StatementType, ResolvedExpressionType } from '@stratumn/dsl';
import { object, infer as InferType, any } from 'zod';
import {
  PresetTemplate,
  actionPresetInputSchema,
  mergeEffects
} from 'utils/presets';

import {
  FormDefinition,
  formToSchemas,
  getFormErrors,
  populatePropertyKeys
} from 'components/formBuilder';

const setDataInputSchema = object({
  form: any().refine(data => !getFormErrors(data as FormDefinition), {
    message: 'Errors were found in the form builder'
  })
}).merge(actionPresetInputSchema);

export type SetDataInput = InferType<typeof setDataInputSchema>;

const setDataPreset: PresetTemplate<SetDataInput> = {
  key: 'setData',
  name: 'Set data action',
  schema: setDataInputSchema,
  defaultValues: {
    action: {
      title: 'Set data'
    },
    form: {
      properties: []
    }
  },
  completeInput: input => populatePropertyKeys(input.form),
  generateAction: async (action, { key, input }) => ({
    ...action,
    key,
    title: input.action.title,
    stageName: input.action.title,
    icon: input.action?.icon || action?.icon,
    form: formToSchemas(input.form as FormDefinition),
    effects: mergeEffects(action?.effects, [
      {
        $preset: key,
        $statement: StatementType.SetVariable,
        path: 'state.data',
        value: {
          $expression: ResolvedExpressionType.Merge,
          arguments: [
            {
              $expression: ResolvedExpressionType.Variable,
              query: `state.data`
            },
            {
              $expression: ResolvedExpressionType.Variable,
              query: 'formData'
            }
          ]
        }
      }
    ])
  })
};

export default setDataPreset;
