import { Workflow } from 'utils/trace';
import { IsDirtyMap } from './reducers';

export const isNewConfig = (config: any): boolean =>
  config
    ? Object.keys(config).length === 0 && config.constructor === Object
    : true;

// a specific local deepEqual function
// motivated by the fact that when comparing configs, we want to ensure the equivalence of undefined and unset keys
// which deepEqual usual functions don't
// also we don't want to JSON.stringify() or canonicaljson.stringify() because that's an extra and expensive full parsing
// instead we just look for real json fields and stop as soon as a diff is found
const deepEqual = (value1: any, value2: any): boolean => {
  if (typeof value1 !== typeof value2) return false;

  // if not an object or null, just check values are exactly the same
  if (typeof value1 !== 'object' || value1 === null || value2 === null)
    return value1 === value2;

  // else, real objects or arrays, merge keys
  const keysSet = new Set([...Object.keys(value1), ...Object.keys(value2)]);

  // compare all properties
  for (const key of keysSet) {
    if (!deepEqual(value1[key], value2[key])) return false;
  }
  return true;
};
export const isConfigDirty = (oldConfig: any, newConfig: any): boolean => {
  return !deepEqual(oldConfig, newConfig);
};

// this function returns a more detailed view of a workflow pending changes
// various components of the config must be checked to fit with how the modules are orchestrating edition
export const isConfigDirtyMap = (
  oldWorkflow: Workflow,
  newWorkflow: Workflow
): { isDirty: boolean; isDirtyMap: IsDirtyMap } => {
  const { config: oldConfig } = oldWorkflow;
  const { config: newConfig } = newWorkflow;
  const isDirtyMap: IsDirtyMap = {
    name: oldWorkflow.name !== newWorkflow.name,
    description: oldWorkflow.description !== newWorkflow.description,
    draft: oldWorkflow.draft !== newWorkflow.draft,
    automation: oldConfig.allowAutomation !== newConfig.allowAutomation,
    deadlines: isConfigDirty(
      oldConfig.admin?.deadlines,
      newConfig.admin?.deadlines
    ),
    display:
      isConfigDirty(oldConfig.overview, newConfig.overview) ||
      isConfigDirty(oldConfig.info, newConfig.info),
    transitions: isConfigDirty(oldConfig.transitions, newConfig.transitions),
    statuses: isConfigDirty(
      oldConfig.admin?.statuses,
      newConfig.admin?.statuses
    )
  };

  // for actions merge the sets of action keys and compare all of them
  // hence get true if an action has been created or deleted
  const oldActions = oldConfig.actions || {};
  const newActions = newConfig.actions || {};
  const allActionsKeys = new Set([
    ...Object.keys(oldActions),
    ...Object.keys(newActions)
  ]);
  const actionsMap: Record<string, boolean> = [...allActionsKeys].reduce(
    (prev, actionKey) => {
      prev[actionKey] = isConfigDirty(
        oldActions[actionKey],
        newActions[actionKey]
      );
      return prev;
    },
    {}
  );
  isDirtyMap.actions = any(actionsMap);

  // check anything is dirty so far
  const isDirty = any(isDirtyMap);

  // finally add the actions details
  isDirtyMap.actionsMap = actionsMap;

  return { isDirty, isDirtyMap };
};

// check if any value is truthy in an object
const any = (obj?: object): boolean => !!obj && Object.values(obj).some(p => p);
