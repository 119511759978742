import React, { FC, ReactNode, memo } from 'react';

import { AvatarGroup } from '@stratumn/atomic';

import useStyles from './workflowGroup.style';

interface Props {
  name: string;
  avatar?: string;
  icon?: ReactNode;
}

export const WorkflowGroup: FC<Props> = ({ name, avatar = '', icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.groupLabelWrapper}>
        {avatar && (
          <div className={classes.groupAvatar}>
            <AvatarGroup src={avatar} size={22} />
          </div>
        )}
        {name}
      </div>

      {icon && icon}
    </div>
  );
};

export default memo(WorkflowGroup);
