import React, { useContext } from 'react';
import { UserContext } from 'components/privateRoute';
import compose from 'lodash.flowright';
import {
  Route,
  RouteComponentProps,
  RouteProps,
  withRouter
} from 'react-router-dom';
import { ROUTE_LOGIN } from 'constant/routes';
import { withSnackbarsContext } from '@stratumn/atomic';

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
  errorSnackbar: (m: string) => void;
}

const Organizations = ({
  component: Component,
  errorSnackbar,
  ...rest
}: Props) => {
  const isSuperUser = useContext(UserContext).isSuperuser;
  return (
    <Route
      {...rest}
      render={props => {
        if (!isSuperUser) {
          errorSnackbar(
            'Only superusers can view the organizations page, sorry...'
          );
          props.history.push(ROUTE_LOGIN);
          return null;
        }

        return <div>Organizations page: work in progress...</div>;
      }}
    />
  );
};

export default compose(
  withRouter,
  withSnackbarsContext as (p: any) => (p2: any) => React.ReactNode
)(React.memo(Organizations));
