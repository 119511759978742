export enum ViewTypes {
  Avatar = 'avatar',
  Activity = 'activity',
  Code = 'code',
  Currency = 'currency',
  Date = 'date',
  FileCompact = 'fileCompact',
  Icon = 'icon',
  Link = 'link',
  Number = 'number',
  Progress = 'progress',
  Text = 'text',
  ListCompact = 'listCompact'
}
