import { FC, memo, useCallback, useContext, useMemo } from 'react';

import { Dropdown, OptionDrop } from '@stratumn/atomic';
import { Trash } from '@stratumn/icons';

import { DataContext } from 'utils/hooks';
import { DataUpdateType } from 'utils/data';

import { EventChangeHandler } from 'utils/types';
import {
  Condition as ConditionType,
  WorkflowGroup as WorkflowGroupDefinition
} from '../../../types';

import { TransitionsContext } from '../../../context';

import { BTN_TYPE_ADD } from '../../../utils/constants';

import { ConditionButton, WorkflowGroup } from './ui';
import { Condition } from './condition';

import useStyles from './fieldsRow.style';

const DYNAMIC_GROUP = 'dynamicGroup';

interface Props {
  path: string;
  groupSelected?: string;
  allGroupsSelected?: string[];
  conditionSelected?: ConditionType;
}

export const FieldsRow: FC<Props> = ({
  path,
  groupSelected = '',
  allGroupsSelected = [],
  conditionSelected = null
}) => {
  const classes = useStyles();

  const { set, update } = useContext(DataContext);
  const { groups, formIsValid } = useContext(TransitionsContext);

  const handleSelectGroup: EventChangeHandler = useCallback(
    e => set(`${path}.label`, e.target.value),
    [path, set]
  );

  const handleRemoveGroup = useCallback((): void => {
    const revertMessage = `You successfully deleted the group`;

    update(
      [
        {
          type: DataUpdateType.Delete,
          path
        }
      ],
      { revert: { message: revertMessage } }
    );
  }, [path, update]);

  const handleAddCondition = useCallback(
    (): void => set(`${path}.condition`, {}),
    [path, set]
  );

  /**
   * This modified groups' list adds:
   * - an extra option which enables to either select All groups or All remaining groups.
   * - a temporary dynamic group name which will be removed.
   * TODO: Properly implement dynamic groups
   */
  const modifiedGroups: WorkflowGroupDefinition[] = useMemo(() => {
    if (!groups) return [];

    const newArray = [...groups];

    newArray.unshift({
      label: '*',
      // TODO: once we add multiple conditions per group, this can be 'All groups' only
      // For now, if a single group already exist, its condition will take over the All remaining groups
      name: allGroupsSelected.length > 1 ? 'All remaining groups' : 'All Groups'
    });

    // const dynamicGroup = {
    //   label: 'dynamicGroup',
    //   name: 'Dynamic Group',
    //   // add an extra field for dynamic group icon
    //   icon: <span className={classes.variableIcon}>variable</span>
    // };

    // // for now we just insert the dynamicGroup after the "All groups/ All remaining groups" feature
    // newArray.splice(1, 0, dynamicGroup);

    return newArray;
  }, [allGroupsSelected.length, groups]);

  /**
   * TODO: remember to remove this modified group selected
   */
  const tempGroupSelected: string =
    typeof groupSelected === 'string' ? groupSelected : DYNAMIC_GROUP;

  const groupDuplicated: boolean = useMemo(
    () =>
      allGroupsSelected.filter(
        (group: any) => group.label === tempGroupSelected
      ).length > 1,
    [allGroupsSelected, tempGroupSelected]
  );

  return (
    <div className={classes.root} data-group-duplicated={groupDuplicated}>
      <button
        data-cy="remove-transition-group"
        className={classes.removeBtn}
        onClick={handleRemoveGroup}
      >
        <Trash className={classes.trashIcon} />
      </button>

      <div className={classes.selectGroupWrapper}>
        <Dropdown
          data-cy="select-group"
          label="Select group"
          onValueChange={handleSelectGroup}
          value={
            typeof groupSelected === 'string'
              ? tempGroupSelected
              : DYNAMIC_GROUP
          } // TODO: remove once we know how we'll use the dsl query to fetch the dynamic group label.
          hideLabel
          invalid={groupDuplicated && !formIsValid}
          errorMessage="duplicate"
        >
          {/** a modified groups array from the workflow */}
          {modifiedGroups.map(({ label, name, avatar, icon }, index) => (
            <OptionDrop
              key={index}
              label={
                <WorkflowGroup
                  name={name || label}
                  avatar={avatar}
                  icon={icon}
                />
              }
              value={label}
              selected={label === tempGroupSelected}
            />
          ))}
        </Dropdown>
      </div>
      <div className={classes.addCondition}>
        {conditionSelected ? (
          <Condition
            path={`${path}.condition`}
            conditionSelected={conditionSelected}
          />
        ) : (
          <ConditionButton
            type={BTN_TYPE_ADD}
            label="Add condition"
            onClick={handleAddCondition}
          />
        )}
      </div>
    </div>
  );
};

export default memo(FieldsRow);
