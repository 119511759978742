import { createUseStyles, Styles } from 'react-jss';

export default createUseStyles((theme: Styles) => ({
  draggable: {
    marginBottom: 15
  },
  rootDefault: {
    backgroundColor: theme.grey9,
    borderRadius: 5,
    border: `1px solid ${theme.greyTable}`,
    padding: '15px 20px',
    transition: 'border-color 150ms linear',
    '&[data-has-errors=true]': {
      borderColor: theme.warningRed
    },
    '&[data-is-dragging=true]': {
      boxShadow: theme.indigoShadowHover
    }
  },
  header: {
    borderBottom: `1px solid ${theme.grey7}`,
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dragHandle: {
    marginLeft: -5,
    marginRight: 4,
    marginBottom: 15,
    outline: 'none',
    color: theme.grey5
  },
  inputLabel: {
    paddingBottom: 15,
    width: 320
  },
  inputHeader: {
    paddingBottom: 15,
    margin: '0 20px',
    flexGrow: 1
  },
  inputType: {
    paddingBottom: 15,
    width: 200,
    flexShrink: 0
  },
  conditionalDisplaySetMsg: {
    marginTop: 15,
    fontSize: 14,
    lineHeight: '18px'
  },
  footer: {
    marginTop: 20,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  footerLeft: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    overflow: 'hidden'
  },
  errors: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 20,
    whiteSpace: 'nowrap',
    color: theme.warningRed,
    fontSize: 11
  },
  footerRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  propertyKeyButton: {
    marginRight: 20,
    display: 'flex',
    fontWeight: ' normal',
    fontSize: 14,
    lineHeight: '18px',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:hover $penIcon': {
      display: 'flex'
    }
  },
  propertyKey: {
    color: theme.grey4
  },
  penIcon: {
    marginLeft: 3,
    display: 'none'
  },
  footerBtn: {
    height: 25,
    marginLeft: 10,
    color: theme.grey3,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: '200ms',
    '&:focus': {
      outline: 'none'
    },
    '&:not([disabled]):hover': {
      color: theme.grey1
    },
    '&[data-warning=true]:hover': {
      color: theme.warningRed
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: theme.grey5
    }
  },
  footerSep: {
    height: 25,
    marginLeft: 20,
    marginRight: 5,
    width: 1,
    backgroundColor: theme.grey7
  }
}));
