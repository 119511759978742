import { camelize } from 'utils/strings';

export const getPropertyKey = (
  key: string,
  label?: string,
  propertyKey?: string,
  isHelperField?: boolean
): string => {
  if (propertyKey) return propertyKey;
  if (isHelperField) return key;
  return camelize(label || key);
};
